import { Box, useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useUserManager } from '../contexts/UserContext';
const useStyles = makeStyles(theme => ({
  container: {
    transform: "translateY(-82px)"
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3)
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start'
  },
  spacedFlexBox: {
    "& > *+*": {
      marginLeft: "1rem"
    }
  }
}));

function Welcome() {
  const classes = useStyles();
  const userManager = useUserManager();
  const location = useLocation();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const oldLoc = `${location.pathname == "/welcome" ? "/home" : location.pathname}${location.search}`;
  useEffect(() => {
    window.history.replaceState(null, "", "/welcome");
    return () => {
      window.history.replaceState(null, "", oldLoc);
    };
  });
  return <Container className={classes.container} component="main" maxWidth="md">
            <CssBaseline />
            <Paper className={classes.paper}>
                <Box padding="1rem" borderBottom="1px solid lightgrey" width="100%">
                    <img src="/logo_horizontal.png" />
                </Box>
                <Box className={classes.spacedFlexBox} padding="1rem" display="flex" flexDirection={`${matches ? "row" : "column"}`}>
                    <Box>
                        <img style={{
            maxWidth: "100%"
          }} src="/home_art.png" />
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Typography variant="h4">Accedi</Typography>
                        <br />
                        <span>Per poter accedere alle funzionalità del sistema è necessario completare l'accesso.</span>
                        <Button onClick={() => userManager.signinRedirect({
            state: {
              redirectTo: oldLoc
            },
            extraQueryParams: {
              isPatient: true
            }
          })} fullWidth variant="contained" color="primary" style={{
            margin: '12px 0'
          }}>Vai al Login</Button>
                    </Box>
                </Box>
            </Paper>
        </Container>;
}

export { Welcome as default };