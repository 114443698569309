import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  CallEnd,
  MeetingRoom,
  Mic,
  MicOff,
  NetworkCheck,
  PresentToAll,
  Videocam,
  VideocamOff,
  VolumeUp,
} from "@material-ui/icons";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import logoHorizontalWhite from "../assets/various/logo_horizontal_white.png";
import MicLevels from "../components/MicLevels";
import NetworkResults from "../components/NetworkResults";
import PsyTimer from "../components/PsyTimer";
import SessionEnded from "../components/SessionEnded";
import VideoCallButton from "../components/VideoCallButton";
import VideoPoster from "../components/VideoPoster";
import VideocallSidebar from "../components/VideocallSidebar";
import DoubleArrowIcon from "../components/icons/DoubleArrowIcon";
import OpenWhiteboardIcon from "../components/icons/OpenWhiteboardIcon";
import { useAgoraContext } from "../contexts/AgoraContext";
import { useGlobalSnackbar } from "../contexts/GlobalSnackbarContext";
import { usePopups } from "../contexts/PopupContext";
import {
  useSignaling,
  useSignalingListener,
} from "../contexts/SignalingContext";
import useApi from "../hooks/useApi";
import useBrowserInfo from "../hooks/useBrowserInfo";
import { useEventEffect } from "../hooks/useEventEffect";
import useOnUnmount from "../hooks/useOnUnmount";
import { grayLog, lerpArray, validations } from "../utils/UtilsFunction";
import useMediaDevice from "../hooks/useMediaDevice";
import AgoraRTC, {
  ILocalAudioTrack,
  ILocalVideoTrack,
  ITrack,
} from "agora-rtc-sdk-ng";
import appsettings from "../config/appsettings.json";
import environment from "../config/environment.json";
import WarningIcon from "@material-ui/icons/Warning";
import { Alert, AlertTitle } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";

function attachToElement(
  videoElement: HTMLVideoElement | null | undefined,
  track: ITrack
) {
  if (!videoElement) return;
  if (!videoElement.srcObject) {
    videoElement.srcObject = new MediaStream();
  }
  (videoElement.srcObject as MediaStream).addTrack(track.getMediaStreamTrack());
}

function detachFromElement(
  videoElement: HTMLVideoElement | null | undefined,
  track: ITrack
) {
  if (!videoElement) return;
  if (!videoElement.srcObject) return;
  const videoSrcObject = videoElement.srcObject as MediaStream;
  videoElement.srcObject = null;
  videoSrcObject.removeTrack(track.getMediaStreamTrack());
  videoSrcObject.getTracks().forEach((track) => {
    if (!videoElement.srcObject) {
      videoElement.srcObject = new MediaStream();
    }
    (videoElement.srcObject as MediaStream).addTrack(track);
  });
}

//function to stop the stream
const stopStream = (stream) =>
  stream?.getTracks?.().forEach((track) => track.stop());
const getVideoIdFromStream = (stream) =>
  stream?.getVideoTracks?.()?.[0]?.getSettings?.()?.deviceId;
const getAudioIdFromStream = (stream) =>
  stream?.getAudioTracks?.()?.[0]?.getSettings?.()?.deviceId;

const below1000pxMediaQuery = "@media (max-width:62.5rem)";
const below1200pxMediaQuery = "@media (max-width:75rem)";
const over1200pxMediaQuery = "@media (min-width:75rem)";
const below600pxMediaQuery = "@media (max-width:37.5rem)";

const disabledSentence =
  "Funzionalità disponibile solo con pazienti registrati";

// @ts-ignore
const useStyles = makeStyles((theme) => ({
  container: {
    background: "#444",
    color: "white",
    width: "100vw",
    height: "100vh",
    boxSizing: "border-box",
    display: "grid",
    gridTemplateRows: "min-content 1fr max-content",
    overflowX: "hidden",
    position: "relative",
  },
  frontDoor: {
    height: "100%",
    display: "grid",
    placeItems: "center",
    gridAutoFlow: "row",
    gridTemplateColumns: "1fr",
    gridAutoRows: "min-content",
    //gap: "1.5rem",
    padding: "1rem",
    boxSizing: "border-box",
    "& > *": {
      textAlign: "center",
      maxWidth: "100%",
    },
    "& > h5": {
      fontWeight: 600,
    },
    "& > img": {
      height: "25vmin",
    },
  },
  logoWaitingRoom: {
    position: "absolute",
    margin: "1rem",
    maxWidth: "clamp(10rem, 35vw, 17rem)",
    top: 0,
    left: 0,
    objectFit: "contain",
  },
  alertWaitingRoom: {
    placeSelf: "end",
    maxWidth: "max(25vw,487px)",
    margin: "1rem",
  },
  mediaPreviewAndSelect: {
    minWidth: "50%",
    maxWidth: "75%",
    justifySelf: "center",
    justifyContent: "center",
    display: "grid",
    gap: "24px",
    marginBlock: "48px",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr",
    width: "90%",
    [over1200pxMediaQuery]: {
      gridTemplateColumns: "min-content 25%",
      gridTemplateRows: "unset",
    },
  },
  mediaPreview: {
    position: "relative",
    height: "unset",
    aspectRatio: "3 / 2",
    justifySelf: "end",
    border: "1px solid #cccccc",
    background: "#333",
    boxSizing: "border-box",
    width: "100%",
    [over1200pxMediaQuery]: {
      width: "unset",
      height: "40vh",
      gridTemplateColumns: "min-content 25%",
      gridTemplateRows: "unset",
    },
    "& > video": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
  videoMuted: {
    filter: "brightness(0)",
  },
  previewActions: {
    position: "absolute",
    width: "100%",
    display: "grid",
    justifyContent: "center",
    gap: "1rem",
    bottom: "1rem",
    gridAutoFlow: "column",
    "& > button": {
      border: 0,
      outline: 0,
      backgroundColor: "hsla(0, 0%, 20%, 0.9)",
      color: "white",
      padding: "0.25rem",
      aspectRatio: "1/1",
      margin: 0,
      borderRadius: ".5rem",
      display: "grid",
      opacity: 0.3,
      cursor: "not-allowed",
      "&:enabled": {
        opacity: 1,
        cursor: "pointer",
      },
    },
  },
  mediaSelect: {
    display: "grid",
    padding: "1rem",
    background: "#333",
    borderRadius: "1rem",
    gridAutoRows: "min-content",
    gap: ".3rem",
    "& > button": {
      width: "fit-content",
      background: "transparent",
      color: "white",
      margin: "0",
      padding: "0",
      border: "0",
      outline: "0",
      textDecoration: "underline",
      cursor: "pointer",
      placeSelf: "end",
      marginBottom: "-0.5rem",
      marginTop: "1rem",
    },
    "& > *:not(span)": {
      maxWidth: "100%",
    },
    "& > span": {
      width: "100%",
      textAlign: "center",
      fontSize: "0.875rem",
    },
  },
  checkConnection: {
    border: "1px solid white",
    padding: ".3rem",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: ".25rem",
    gap: ".5rem",
  },
  select: {
    display: "block",
    maxWidth: "12rem",
    color: "white",
    background: "rgba(255,255,255,.1)",
  },
  adornment: {
    marginRight: "1rem",
  },
  iconOutlinedStyles: {
    color: "white !important",
  },
  disabledStyles: {
    opacity: 0.3,
  },
  outlinedStyles: {
    color: "white !important",
    fontSize: "0.875rem",
  },
  labelStyles: {
    color: "white !important",
  },
  notchedOutlineStyles: {
    borderColor: "white !important",
  },
  joinedContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(20, 1fr)",
    gridTemplateRows: "repeat(20, 1fr)",
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    background: "#222",
    position: "relative",
    overflow: "hidden",
  },
  mobileTimer: {
    color: "white",
    position: "absolute",
    zIndex: 3,
    margin: ".5rem",
    padding: ".5rem",
    borderRadius: ".5rem",
    background: "#33333380",
  },
  mainGridElement: {
    position: "relative",
    gridColumn: "1 / -1",
    gridRow: "1 / -1",
  },
  screenShareGridElement: {
    position: "relative",
    gridColumn: "1 / -1",
    gridRow: "1 / -4",
  },
  loweredPatientGridElement: {
    gridColumn: "-7 / -4",
    gridRow: "-6 / -1",
    "&$littleVideo": {
      gridRow: "-4 / -1",
      gridColumn: "-5 / -3",
    },
    [below1200pxMediaQuery]: {
      gridRow: "-5 / -1",
      gridColumn: "-9 / -5",
      "&$littleVideo": {
        gridColumn: "-7 / -4",
      },
    },
    [below600pxMediaQuery]: {
      gridRow: "-4 / -1",
      gridColumn: "-17 / -9",
      "&$littleVideo": {
        gridColumn: "-13 / -7",
      },
    },
  },
  lowerRightGridElement: {
    gridColumn: "-4 / -1",
    gridRow: "-6 / -1",
    position: "relative",
    "&$littleVideo": {
      gridRow: "-4 / -1",
      gridColumn: "-3 / -1",
    },
    [below1200pxMediaQuery]: {
      gridRow: "-5 / -1",
      gridColumn: "-5 / -1",
      "&$littleVideo": {
        gridRow: "-4 / -1",
        gridColumn: "-4 / -1",
      },
    },
    [below600pxMediaQuery]: {
      gridRow: "-4 / -1",
      gridColumn: "-9 / -1",
      "&$littleVideo": {
        gridColumn: "-7 / -1",
      },
    },
  },
  littleVideo: {},
  hiddenVideo: {
    display: "none",
  },
  sideBar: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: "0px",
    color: "white",
    backgroundColor: "#333",
    transition: "transform 250ms ease-in-out",
    transform: "translateX(100%)",
    zIndex: 1000,
    borderLeft: "1px solid rgba(255,255,255, .3)",
    boxSizing: "border-box",
  },
  sideBarToggle: {
    color: "white",
    position: "absolute",
    top: "1rem",
    transform: "translateX(-100%)",
    background: "#333",
    cursor: "pointer",
    padding: ".5rem",
    display: "flex",
    border: "1px solid rgba(255,255,255, .3)",
    borderRight: 0,
    "& > svg": {
      transform: "rotate(180deg)",
      transition: "transform 250ms ease-in-out",
      fontSize: "1rem",
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.5rem",
      },
    },
  },
  toggleOpened: {
    "& > svg": {
      transform: "rotate(0deg)",
    },
  },
  open: {
    transform: "translateX(0)",
    "& svg": {
      transform: "rotate(0deg)",
    },
  },
  buttonsContainer: {
    backgroundColor: "#333333",
  },
  logoAndQoS: {
    display: "flex",
    color: "white",
    alignItems: "center",
    maxWidth: "100%",
    maxHeight: "3rem",
    padding: "0.5rem",
    "& > img": {
      maxHeight: "2rem",
      maxWidth: "100%",
    },
    [below1000pxMediaQuery]: {
      opacity: 0,
    },
  },
  therapistQoS: {
    textAlign: "center",
  },
  whiteboardMenuWrapper: {
    position: "absolute",
    minWidth: "max-content",
    transform: "scaleY(0)",
    transition: "transform 250ms",
    top: "0",
    left: "50%",
    color: "black",
  },
  whiteboardMenu: {
    position: "absolute",
    top: "0",
    left: "50%",
    transform: "translate(0%, -100%)",
    background: "white",
    boxShadow: "0 0 1rem 0 rgba(0,0,0,.7)",
    minWidth: "max-content",
  },
  closeAndTimer: {
    placeSelf: "end",
    display: "grid",
    gridTemplateColumns: "min-content",
    gap: "1rem",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "min-content min-content",
    },
  },
  loneButton: {
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "min-content",
    },
  },
  linkButton: {
    width: "fit-content",
    background: "transparent",
    color: "white",
    margin: "0",
    padding: "0",
    border: "0",
    outline: "0",
    textDecoration: "underline",
    cursor: "pointer",
    fontSize: "1rem",
    marginTop: "1rem",
  },
}));

const useModalContentStyles = makeStyles((theme) => ({
  root: {
    padding: "0",
    paddingTop: "0 !important",
    height: "100%",
  },
  lateralMenu: {
    position: "absolute",
    minHeight: "30%",
    backgroundColor: "white",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    boxShadow: "-.5rem 0 1rem 0 rgb(0, 0, 0, .15)",
  },
  nameAndSurnameContainer: {
    display: "flex",
    flexDirection: "column",
    gap: ".5rem",
    "&>*": {
      width: "100%",
    },
  },
  actions: {
    padding: "1rem",
    backgroundColor: "#333",
    borderTop: "2px solid lightgray",
  },
  dialogActions: {
    padding: ".5rem 1.5rem 1rem 1.5rem",
    "&>*": {
      flexBasis: "100%",
    },
  },
}));

const qualities = [
  {
    title: "Bassa",
    constraints: {},
  },
  {
    title: "Media",
    constraints: {},
  },
  {
    title: "Alta",
    constraints: {},
  },
];

const GuestSession = ({
  connectionMessage,
  isPatientSTRunning,
  patientSTResults,
}) => {
  const {
    engine,
    currentCamera,
    currentMicrophone,
    setCurrentCamera,
    setCurrentMicrophone,
  } = useAgoraContext();

  const [currentView, setCurrentView] = useState("contain");

  const signaling = useSignaling();

  const sendEvent = signaling.getSenderForUser(() => {
    const profile = videoCallSession as unknown as {};
    if (
      profile &&
      typeof profile === "object" &&
      "idTherapist" in profile &&
      typeof profile.idTherapist === "string"
    ) {
      return profile?.idTherapist;
    }
    return null;
  });

  const { cameraList, microphoneList } = useMediaDevice();
  const { sessionid: sessionId } = useParams();
  const [, setGlobalSnackbar] = useGlobalSnackbar();
  const classes = useStyles();
  const [, setPopups] = usePopups();
  const [videoCallSession, setVideoCallSession] = useState(null);
  const [isConferenceJoined, setIsConferenceJoined] = useState(false);
  const [isConsentGiven, setIsConsentGiven] = useState(true);
  const [isAudioActive, setIsAudioActive] = useState(true);
  const [isVideoActive, setIsVideoActive] = useState(true);
  const [isTherapistVideoPlaying, setIsTherapistVideoPlaying] = useState(false);
  const [settings, setSettings] = useState();
  const [isNameUpdated, setIsNameUpdated] = useState(false);
  const [nameAndSurname, setNameAndSurname] = useState({
    name: "",
    surname: "",
  });
  const [nameAndSurnameErrors, setNameAndSurnameErrors] = useState({});
  const [notAllowed, setNotAllowed] = useState(false);
  const [isEndend, setIsEnded] = useState(false);

  const audioStream = useRef<ILocalAudioTrack | undefined>();
  const videoStream = useRef<ILocalVideoTrack | undefined>();
  const videoTherapistRef = useRef<HTMLVideoElement>(null);
  const videoPatientRef = useRef<HTMLVideoElement>(null);

  const trackToUserIdMap = useRef(new Map<string, ITrack | undefined>());

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [patientQoS, setPatientQoS] = useState(-1);
  const patientQoSSentence = useMemo(() => {
    if (patientQoS < 0) {
      return "N.D.";
    } else {
      return lerpArray(["Scarsa", "Buona", "Ottima"], 0, 4, patientQoS);
    }
  }, [patientQoS]);

  const [joinStreamOptions, setJoinStreamOptions] = useState({
    videoMuted: false,
    audioMuted: false,
  });
  const [hideWRAlert, setHideWRAlert] = useState(false);
  const [hideAlert, setHideAlert] = useState(false);

  const networkConditions = useCallback(() => {
    return (
      <div className={classes.therapistQoS}>
        {/* <div>Qualità video</div>
        <span>{patientQoSSentence}</span> */}
      </div>
    );
  }, [patientQoSSentence, classes]);

  const [patientEnteringDate, setPatientEnteringDate] = useState(null);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const api = useApi();

  const browserInfo = useBrowserInfo();

  const styles = useStyles();
  const modalContentStyle = useModalContentStyles();

  const [hasPatientKnocked, setHasPatientKnocked] = useState(false);
  const defaultWebcam = useRef(null);


  const confirmClosingSession = () => {
    return window.confirm("Vuoi davvero chiudere la sessione?");
  };

  const leaveConference = async (askConfirmation?: boolean) => {
    let confirmRes = confirmClosingSession();
    if (!confirmRes) return;
    try {
      sendEvent("videocall:closing", {});
    } catch (e) {}
    try {
      engine.leave();
      if (videoStream.current) {
        videoStream.current.close();
      }
      if (audioStream.current) {
        audioStream.current.close();
      }
    } catch (e) {}
    setVideoCallSession(null);
    setIsEnded(true);
    defaultWebcam.current=null;
    setCurrentCamera(cameraList[0]);
  };

  const knock = useCallback(async () => {
    fetch(`/api/UnautenthicatedWaitingRoom/startwaitingroom`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({
        idSession: videoCallSession?.id,
        idPatient: videoCallSession?.idPatient,
        idTherapist: videoCallSession?.idTherapist,
        patientName: nameAndSurname?.name,
        patientSurname: nameAndSurname?.surname,
        patientEmail: videoCallSession?.guestPatientEmail,
        isMobile: browserInfo.mobile || browserInfo.isTablet,
        isGuest: true,
      }),
    });
    setHasPatientKnocked(true);
  }, [browserInfo, videoCallSession, nameAndSurname]);

  const [lastUpdateInterval, setLastUpdateInterval] = useState(null);

  useEffect(() => {
    if (!videoCallSession) return;
    const interval = setInterval(() => {
      fetch(
        `/api/UnautenthicatedWaitingRoom/${videoCallSession?.idPatient}/lastupdate`,
        {
          method: "PATCH",
          headers: {
            "content-type": "application/json",
          },
        }
      );
    }, 3000);
    setLastUpdateInterval(interval);
    return () => {
      clearInterval(interval);
    };
  }, [videoCallSession, hasPatientKnocked]);

  function askToEnter() {
    knock();
    sendEvent(
      "videocall:requestaccess",
      {
        idPatient: videoCallSession.idPatient,
      },
      true
    );
  }

  useSignalingListener("videocall:admit", () => {
    joinConference();
    setIsTherapistVideoPlaying(true);
  });

  useSignalingListener(
    "videocall:closing",
    async () => {
      if (isConferenceJoined) {
        try {
          engine.leave();
          if (videoStream.current) {
            videoStream.current.close();
          }
          if (audioStream.current) {
            audioStream.current.close();
          }
        } catch (e) {}
      }
      setVideoCallSession(null);
      setIsEnded(true);
    },
    [isConferenceJoined]
  );

  /*
   * This method create and publish the stream of the user, join the conference on Agora
   * if the videosession in memory doesn't have a idhost, sessionstart and isTherapist
   * calls the api to start the session.
   */
  const joinConference = useEventEffect(async () => {
    setIsAudioActive(true);
    setIsVideoActive(true);
    setIsTherapistVideoPlaying(false);
    try {
      let localVideoStream: ILocalVideoTrack | null = null;
      let localAudioStream: ILocalAudioTrack | null = null;
      testMicrophoneRef.current?.stop();
      const videoTrack = previewStream?.getVideoTracks()[0];
      const audioTrack = previewStream?.getAudioTracks()[0];
      if (!videoTrack || !audioTrack) {
        //TO DO SHOW ERROR
        return;
      }

      const tokenResponse = await api(
        undefined,
        // @ts-ignore
        `agoratoken/rtc/${videoCallSession?.idPatient}/${videoCallSession?.idTherapist}`
      );
      if (tokenResponse.ok === false) {
        // show error
        setGlobalSnackbar({
          open: true,
          severity: "error",
          message: "Impossibile avviare la videochiamata, riprova.",
        });
        return;
      }
      await engine.join(
        // @ts-ignore
        appsettings[environment.Environment].agora.appId,
        tokenResponse.roomId,
        tokenResponse.accessToken,
        // @ts-ignore
        videoCallSession?.idPatient
      );

      try {
        localVideoStream = AgoraRTC.createCustomVideoTrack({
          mediaStreamTrack: videoTrack,
        });
        localAudioStream = AgoraRTC.createCustomAudioTrack({
          mediaStreamTrack: audioTrack,
        });
        setIsConsentGiven(true);
        videoStream.current = localVideoStream;
        audioStream.current = localAudioStream;
      } catch (e) {
        switch (e.name) {
          case "NotAllowedError":
          case "PermissionDeniedError":
            setPopups((prev) => ({
              ...prev,
              errorWebcamOrMicrPopup: {
                open: true,
                props: {
                  title:
                    "Il browser non ha l’autorizzazione per accedere alla webcam o al microfono",
                  message: (
                    <>
                      <a
                        href="https://www.psycare.it/risoluzione-problemi-permessi-e-consensi-webcam-microfono/"
                        target="_blank"
                      >
                        Clicca qui
                      </a>{" "}
                      e scopri come permettere al tuo browser di accedere a
                      webcam e microfono in base al dispositivo che stai
                      utilizzando.
                    </>
                  ),
                },
              },
            }));
            break;
          case "TrackStartError": //chrome
          case "NotReadableError":
            setCurrentCamera(defaultWebcam.current);

            setPopups((prev) => ({
              ...prev,
              errorWebcamOrMicrPopup: {
                open: true,
                props: {
                  title:
                    "La webcam o il microfono risultano essere in uso da un altro software",
                  message:
                    "Chiudi gli altri programmi in uso e riaggiorna la pagina. In alternativa, seleziona un dispositivo diverso dal menu a destra.",
                },
              },
            }));
            break;
          case "DevicesNotFoundError": //chrome
          case "NotFoundError":
            setPopups((prev) => ({
              ...prev,
              errorWebcamOrMicrPopup: {
                open: true,
                props: {
                  title: "Non è stata trovata alcuna webcam o microfono",
                  message: (
                    <>
                      Assicurati di avere collegato correttamente webcam o
                      microfono. In alternativa,{" "}
                      <a
                        href="https://www.psycare.it/risoluzione-problemi-paziente/"
                        target="_blank"
                      >
                        clicca qui
                      </a>{" "}
                      per scoprire quali possono essere le cause e come
                      risolvere.
                    </>
                  ),
                },
              },
            }));
            break;
          case "OverconstrainedError":
          case "ConstraintNotSatisfiedError":
            setPopups((prev) => ({
              ...prev,
              errorWebcamOrMicrPopup: {
                open: true,
                props: {
                  title:
                    "Non è stata trovato alcun dispositivo valido per effettuare la videoseduta",
                  message: (
                    <>
                      Assicurati di avere collegato correttamente webcam o
                      microfono. In alternativa,{" "}
                      <a
                        href="https://www.psycare.it/risoluzione-problemi-paziente/"
                        target="_blank"
                      >
                        clicca qui
                      </a>{" "}
                      per scoprire quali possono essere le cause e come
                      risolvere.
                    </>
                  ),
                },
              },
            }));
            break;
          case "TypeError":
            setPopups((prev) => ({
              ...prev,
              errorWebcamOrMicrPopup: {
                open: true,
                props: {
                  title: "C’è un problema con la webcam o il microfono",
                  message: (
                    <>
                      <a
                        href="https://www.psycare.it/risoluzione-problemi-paziente/"
                        target="_blank"
                      >
                        Clicca qui
                      </a>{" "}
                      per scoprire quali possono essere le cause e come
                      risolvere.
                    </>
                  ),
                },
              },
            }));
            break;
          default:
            setPopups((prev) => ({
              ...prev,
              errorWebcamOrMicrPopup: {
                open: true,
                props: {
                  title: "C’è un problema con la webcam o il microfono",
                  message: (
                    <>
                      <a
                        href="https://www.psycare.it/risoluzione-problemi-paziente/"
                        target="_blank"
                      >
                        Clicca qui
                      </a>{" "}
                      per scoprire quali possono essere le cause e come
                      risolvere.
                    </>
                  ),
                },
              },
            }));
            break;
        }
        return;
      }
      setPreviewStream(null);
      setHasPatientKnocked(false);

      await engine?.publish([localVideoStream, localAudioStream]);
      if (joinStreamOptions?.videoMuted) {
        //localStream.muteVideo();
        localVideoStream.setEnabled(false);
        setIsVideoActive(false);
      }
      if (joinStreamOptions?.audioMuted) {
        //localStream.muteAudio();
        localAudioStream.setEnabled(false);
        setIsAudioActive(false);
      }
      attachToElement(videoPatientRef.current, localVideoStream);
    } catch (e) {
      console.log(e);
    }
  });

  /**
   * Toggle the audio of the active stream
   */
  const toggleAudio = useCallback(() => {
    if (!audioStream.current) return;
    if (isAudioActive) {
      audioStream.current.setEnabled(false);
      if (!isVideoActive) {
        setHideAlert(false);
      }
    } else {
      setHideAlert(true);
      audioStream.current.setEnabled(true);
    }
    setIsAudioActive((audioActive) => !audioActive);
  }, [isAudioActive]);

  const toggleVideo = useCallback(() => {
    if (!videoStream.current) return;
    if (isVideoActive) {
      videoStream.current.setEnabled(false);
      if (!isAudioActive) {
        setHideAlert(false);
      }
    } else {
      setHideAlert(true);
      videoStream.current.setEnabled(true);
    }
    setIsVideoActive((videoActive) => !videoActive);
  }, [isVideoActive]);

  const updateAndStart = useCallback(async () => {
    setNameAndSurnameErrors({});
    const errors = {};
    errors.name = nameAndSurname.name
      ? validations.firstName(nameAndSurname.name)
      : "Il nome è obbligatorio";
    errors.surname = nameAndSurname.surname
      ? validations.lastName(nameAndSurname.surname)
      : "Il cognome è obbligatorio";
    if (Object.values(errors).some((elem) => elem)) {
      setNameAndSurnameErrors(errors);
      return;
    }
    const guestSessionResponse = await fetch(
      `/api/guestsessions/${sessionId}/updateguestsessiondata`,
      {
        method: "PATCH",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          idSession: sessionId,
          name: nameAndSurname.name,
          surname: nameAndSurname.surname,
        }),
      }
    );
    if (guestSessionResponse.ok === false) {
      setGlobalSnackbar({
        open: true,
        severity: "error",
        message: "Impossibile aggiornare i dati, riprovare in seguito.",
      });
      return;
    }
    setIsNameUpdated(true);
  }, [sessionId, nameAndSurname]);

  /**
   * Change the settings for the webcam and the microphone
   */
  const changeVideoCallSettings = async (
    toChange: MediaDeviceInfo,
    whatToChange: "audio" | "video"
  ) => {
    if (
      !videoStream.current ||
      !audioStream.current ||
      !videoTherapistRef.current
    )
      return;
    try {
      // if what to change is audio we create the options for that
      // audio device otherwise we leave it undefined
      const audio =
        whatToChange === "audio"
          ? {
              deviceId: { exact: toChange?.deviceId },
            }
          : undefined;

      // if whatToChange is video we create the options for that
      // video device otherwise we leave it undefined
      const video =
        whatToChange === "video"
          ? {
              deviceId: { exact: toChange?.deviceId },
              width: {
                min: 320,
                ideal: patientSTResults?.min < 1 ? 320 : 640,
                max: 1280,
              },
              height: {
                min: 240,
                ideal: patientSTResults?.min < 1 ? 240 : 480,
                max: 768,
              },
            }
          : undefined;

      const localMediaStream = await navigator?.mediaDevices?.getUserMedia({
        audio,
        video,
      });

      const track =
        whatToChange === "audio"
          ? localMediaStream?.getAudioTracks()[0]
          : localMediaStream?.getVideoTracks()[0];

      const createFunction =
        whatToChange === "audio"
          ? AgoraRTC.createCustomAudioTrack
          : AgoraRTC.createCustomVideoTrack;

      const localStream = createFunction({
        mediaStreamTrack: track,
      });

      const toUnpublish = whatToChange === "audio" ? audioStream : videoStream;

      //unpublish the current stream
      await engine.unpublish(toUnpublish.current);
      //release the webcam
      toUnpublish.current!.close();

      toUnpublish.current = localStream;

      if (whatToChange === "video" && videoPatientRef.current) {
        // reset the video patient srcObject
        videoPatientRef.current.srcObject = null;
        //attach the new stream to the patient video element
        attachToElement(videoPatientRef.current, localStream);
      }
      //publish the stream
      await engine.publish(localStream);
      setIsAudioActive(true);
      setIsVideoActive(true);
    } catch (e) {
      grayLog({
        message: e.message,
        type: e.name,
        stack: e.stack,
        userId: "Uknown user",
      });
      switch (e.name) {
        case "NotAllowedError":
        case "PermissionDeniedError":
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title:
                  "Il browser non ha l’autorizzazione per accedere alla webcam o al microfono",
                message: (
                  <>
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-permessi-e-consensi-webcam-microfono/"
                      target="_blank"
                    >
                      Clicca qui
                    </a>{" "}
                    e scopri come permettere al tuo browser di accedere a webcam
                    e microfono in base al dispositivo che stai utilizzando.
                  </>
                ),
              },
            },
          }));
          break;
        case "TrackStartError": //chrome
        case "NotReadableError":
          setCurrentCamera(defaultWebcam.current);

          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title:
                  "La webcam o il microfono risultano essere in uso da un altro software",
                message:
                  "Chiudi gli altri programmi in uso e riaggiorna la pagina. In alternativa, seleziona un dispositivo diverso dal menu a destra.",
              },
            },
          }));
          break;
        case "DevicesNotFoundError": //chrome
        case "NotFoundError":
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title: "Non è stata trovata alcuna webcam o microfono",
                message: (
                  <>
                    Assicurati di avere collegato correttamente webcam o
                    microfono. In alternativa,{" "}
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-paziente/"
                      target="_blank"
                    >
                      clicca qui
                    </a>{" "}
                    per scoprire quali possono essere le cause e come risolvere.
                  </>
                ),
              },
            },
          }));
          break;
        case "OverconstrainedError":
        case "ConstraintNotSatisfiedError":
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title:
                  "Non è stata trovato alcun dispositivo valido per effettuare la videoseduta",
                message: (
                  <>
                    Assicurati di avere collegato correttamente webcam o
                    microfono. In alternativa,{" "}
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-paziente/"
                      target="_blank"
                    >
                      clicca qui
                    </a>{" "}
                    per scoprire quali possono essere le cause e come risolvere.
                  </>
                ),
              },
            },
          }));
          break;
        case "TypeError":
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title: "C’è un problema con la webcam o il microfono",
                message: (
                  <>
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-paziente/"
                      target="_blank"
                    >
                      Clicca qui
                    </a>{" "}
                    per scoprire quali possono essere le cause e come risolvere.
                  </>
                ),
              },
            },
          }));
          break;
        default:
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title: "C’è un problema con la webcam o il microfono",
                message: (
                  <>
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-paziente/"
                      target="_blank"
                    >
                      Clicca qui
                    </a>{" "}
                    per scoprire quali possono essere le cause e come risolvere.
                  </>
                ),
              },
            },
          }));
          break;
      }
    }
  };

  const [previewStream, setPreviewStream] = useState(null);
  const videoPreviewRef = useRef();
  const testMicrophoneRef = useRef();

  //   useEffect(() => {
  //     if (!previewStream) return;
  //     const videoDeviceId = getVideoIdFromStream(previewStream);
  //     const audioDeviceId = getAudioIdFromStream(previewStream);
  //     setInUseMichrophone(audioDeviceId);
  //     setInUseWebcam(videoDeviceId);
  //   }, [availableDevices]);

  useEffect(() => {
    handleVideoPreview(currentMicrophone, currentCamera);
  }, [currentMicrophone, currentCamera]);

  const isJoined = useRef(false);

  const handleVideoPreview = async (
    inUseMichrophone?: MediaDeviceInfo,
    inUseWebcam?: MediaDeviceInfo
  ) => {
    if (isJoined.current) return;
    try {
      const previewStreamLocal = await navigator?.mediaDevices?.getUserMedia({
        audio: inUseMichrophone
          ? {
              deviceId: { exact: inUseMichrophone.deviceId },
            }
          : true,
        video: inUseWebcam
          ? {
              deviceId: { exact: inUseWebcam.deviceId },
              width: {
                min: 320,
                ideal: patientSTResults?.min < 1 ? 320 : 640,
                max: 1024,
              },
              height: {
                min: 240,
                ideal: patientSTResults?.min < 1 ? 240 : 480,
                max: 768,
              },
            }
          : {
              width: {
                min: 320,
                ideal: patientSTResults?.min < 1 ? 320 : 640,
                max: 1024,
              },
              height: {
                min: 240,
                ideal: patientSTResults?.min < 1 ? 240 : 480,
                max: 768,
              },
            },
      });
      setPreviewStream((prev) => {
        stopStream(prev);
        return previewStreamLocal;
      });
      return previewStreamLocal;
    } catch (e) {
      grayLog({
        message: e.message,
        type: e.name,
        stack: e.stack,
        userId: "Uknown user",
      });
      switch (e.name) {
        case "NotAllowedError":
        case "PermissionDeniedError":
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title:
                  "Il browser non ha l’autorizzazione per accedere alla webcam o al microfono",
                message: (
                  <>
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-permessi-e-consensi-webcam-microfono/"
                      target="_blank"
                    >
                      Clicca qui
                    </a>{" "}
                    e scopri come permettere al tuo browser di accedere a webcam
                    e microfono in base al dispositivo che stai utilizzando.
                  </>
                ),
              },
            },
          }));
          break;
        case "TrackStartError": //chrome
        case "NotReadableError":
          setCurrentCamera(defaultWebcam.current);
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title:
                  "La webcam o il microfono risultano essere in uso da un altro software",
                message:
                  "Chiudi gli altri programmi in uso e riaggiorna la pagina. In alternativa, seleziona un dispositivo diverso dal menu a destra.",
              },
            },
          }));
          break;
        case "DevicesNotFoundError": //chrome
        case "NotFoundError":
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title: "Non è stata trovata alcuna webcam o microfono",
                message: (
                  <>
                    Assicurati di avere collegato correttamente webcam o
                    microfono. In alternativa,{" "}
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-paziente/"
                      target="_blank"
                    >
                      clicca qui
                    </a>{" "}
                    per scoprire quali possono essere le cause e come risolvere.
                  </>
                ),
              },
            },
          }));
          break;
        case "OverconstrainedError":
        case "ConstraintNotSatisfiedError":
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title:
                  "Non è stata trovato alcun dispositivo valido per effettuare la videoseduta",
                message: (
                  <>
                    Assicurati di avere collegato correttamente webcam o
                    microfono. In alternativa,{" "}
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-paziente/"
                      target="_blank"
                    >
                      clicca qui
                    </a>{" "}
                    per scoprire quali possono essere le cause e come risolvere.
                  </>
                ),
              },
            },
          }));
          break;
        case "TypeError":
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title: "C’è un problema con la webcam o il microfono",
                message: (
                  <>
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-paziente/"
                      target="_blank"
                    >
                      Clicca qui
                    </a>{" "}
                    per scoprire quali possono essere le cause e come risolvere.
                  </>
                ),
              },
            },
          }));
          break;
        default:
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title: "C’è un problema con la webcam o il microfono",
                message: (
                  <>
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-paziente/"
                      target="_blank"
                    >
                      Clicca qui
                    </a>{" "}
                    per scoprire quali possono essere le cause e come risolvere.
                  </>
                ),
              },
            },
          }));
          break;
      }
      return false;
    }
  };

  const hasSignalingLoggedIn = useRef(false);

  const [therapistJoined, setTherapistJoined] = useState(false);

  if (videoCallSession && !hasSignalingLoggedIn.current) {
    try {
      signaling
        .getTokenAndLogin({ profile: { sub: videoCallSession.idPatient } })
        .catch(() => {
          hasSignalingLoggedIn.current = false;
        });
      hasSignalingLoggedIn.current = true;
    } catch (e) {}
  }

  //everytime the preview video change we change the video in
  //the video element
  useEffect(() => {
    if (!videoPreviewRef.current) return;
    videoPreviewRef.current.srcObject = previewStream;
  }, [previewStream]);

  useOnUnmount(() => {
    if (previewStream) {
      stopStream(previewStream);
    }
  }, [previewStream]);

  useEffect(() => {
    let controller = new AbortController();
    (async () => {
      const guestSessionResponse = await fetch(
        `/api/guestsessions/${sessionId}/getguestsessiondata`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
          },
          signal: controller.signal,
        }
      );
      if (guestSessionResponse.ok === false) {
        setGlobalSnackbar({
          open: true,
          severity: "error",
          message: "Impossibile recuperare la seduta.",
        });
        return;
      }
      const guestSession = await guestSessionResponse.json();
      if (guestSession.sessionEnd) {
        setNotAllowed(true);
        return;
      }
      setVideoCallSession(guestSession);
    })();
    return () => {
      controller.abort();
    };
  }, [sessionId]);

  useEffect(() => {
    setHasPatientKnocked(false);
    //if we want implement stats we can use AgoraEngine getRemoteVideoStats getRTCStats
    engine.on("live-streaming-error", () => {
      setVideocallErrorPopup(true);
    });
    engine.on("user-published", async (userPublished, mediaType) => {
      const uid = userPublished.uid.toString();

      await engine.subscribe(userPublished, mediaType);
      const videoToAttach = videoTherapistRef.current;
      trackToUserIdMap.current.set(
        `${uid}-${mediaType}`,
        mediaType === "video"
          ? userPublished.videoTrack
          : userPublished.audioTrack
      );
      if (mediaType === "video") {
        if (!userPublished.videoTrack || !videoToAttach) {
          return;
        }
        attachToElement(videoToAttach, userPublished.videoTrack);
      }
      if (mediaType === "audio") {
        if (!userPublished.audioTrack || !videoToAttach) {
          return;
        }
        attachToElement(videoToAttach, userPublished.audioTrack);
      }

      setPatientEnteringDate((prev) => (!prev ? new Date() : prev));
    });
    engine.on("user-unpublished", async (userUnpublished, mediaType) => {
      const uid = userUnpublished.uid.toString();
      const videoToDetach = videoTherapistRef.current;
      const trackToDetach = trackToUserIdMap.current.get(`${uid}-${mediaType}`);
      if (trackToDetach) {
        detachFromElement(videoToDetach, trackToDetach);
        trackToUserIdMap.current.set(`${uid}-${mediaType}`, undefined);
      }
    });

    engine.on("user-joined", (userPublished) => {
      const uid = userPublished.uid.toString();
      // this will be -1 if the incoming stream is not a screenshare
      const screenShareIndexOf = uid.indexOf("-screenshare");
      if (screenShareIndexOf === -1) {
        setTherapistJoined(true);
      }
    });

    engine.on("connection-state-change", (curState) => {
      if (curState === "CONNECTED") {
        isJoined.current = true;
        clearInterval(lastUpdateInterval);
        setIsConferenceJoined(true);
        //recover settings to check if the patient can see himself
      }
    });

    return () => {
      engine.removeAllListeners();
    };
  }, [engine, videoCallSession]);

  return !isEndend ? (
    <>
      <div
        className={classes.container}
        style={
          isConferenceJoined ? { gridTemplateRows: "1fr max-content" } : null
        }
      >
        {!isConferenceJoined && (
          <Box display="flex" justifyContent="flex-end" padding="1rem">
            <img
              src={logoHorizontalWhite}
              className={classes.logoWaitingRoom}
            />
          </Box>
        )}
        <Box height="100%">
          <Box
            style={{ display: isConferenceJoined ? "" : "none" }}
            height="100%"
            position="relative"
          >
            <div className={classes.joinedContainer}>
              {isConferenceJoined && (
                <div
                  className={`${classes.sideBar} ${
                    isSidebarOpen ? classes.open : ""
                  }`}
                >
                  <button
                    onClick={() => setIsSidebarOpen((prev) => !prev)}
                    className={`${classes.sideBarToggle} ${
                      isSidebarOpen ? classes.toggleOpened : ""
                    }`}
                  >
                    <DoubleArrowIcon />
                  </button>
                  <VideocallSidebar
                    currentView={currentView}
                    setCurrentView={setCurrentView}
                    changeVideoCallSettings={changeVideoCallSettings}
                    isTherapistVideoPlaying={isTherapistVideoPlaying}
                    videoCallSession={videoCallSession}
                    networkConditions={networkConditions}
                    patient={nameAndSurname}
                    setSavedCamera={(data) => (defaultWebcam.current = data)}
                  />
                </div>
              )}
              <div className={classes.screenShareGridElement}></div>
              <div className={`${classes.mainGridElement}`}>
                <video
                  ref={videoTherapistRef}
                  onPlay={() => {
                    setIsTherapistVideoPlaying(true);
                  }}
                  style={{
                    position: "absolute",
                    objectFit: currentView,
                    maxWidth: "100%",
                    height: "100%",
                    width: "100%",
                    display: isTherapistVideoPlaying ? "" : "none",
                  }}
                  autoPlay
                  playsInline
                  id="therapist"
                  width="auto"
                  poster="/wait.png"
                />
              </div>
              <div
                className={`flipped ${classes.lowerRightGridElement} ${
                  !settings?.canSeeHimself ? classes.hiddenVideo : ""
                }`}
              >
                <video
                  ref={videoPatientRef}
                  style={{
                    position: "absolute",
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                    display: settings?.canSeeHimself ? "" : "none",
                  }}
                  muted
                  autoPlay
                  playsInline
                  id="patient"
                  poster="/wait_flipped.png"
                />
              </div>
              {!matches && settings?.canSeeTimer && (
                <PsyTimer
                  className={classes.mobileTimer}
                  autoStartOnNullDate={false}
                  start={patientEnteringDate}
                />
              )}
              <VideoPoster
                style={{
                  gridColumn: "1 / -1",
                  gridRow: "1 / -1",
                  height: "100%",
                  position: "absolute",
                  color: "black",
                }}
                hidden={therapistJoined}
                name={videoCallSession?.therapistFirstName}
                lastname={videoCallSession?.therapistLastname}
              />
            </div>
          </Box>
          <Box
            style={{ display: isConferenceJoined ? "none" : "" }}
            height="100%"
            className={styles.frontDoor}
          >
            <Typography variant="h4" style={{ marginTop: "3rem" }}>
              Benvenuto nella sala d'attesa virtuale di{" "}
              {videoCallSession?.therapistFirstName}{" "}
              {videoCallSession?.therapistLastname}.
            </Typography>
            <div>
              <Typography style={{ fontSize: "1.25rem", paddingTop: ".3rem" }}>
                {hasPatientKnocked ? (
                  <>
                    {videoCallSession?.therapistFirstName}{" "}
                    {videoCallSession?.therapistLastname} è stato informato
                    della tua presenza in sala d’attesa, attendi che ti accolga
                    nella stanza virtuale per iniziare la seduta.
                  </>
                ) : (
                  <>
                    Informa il terapeuta del tuo arrivo chiedendogli di entrare
                  </>
                )}
              </Typography>
              {!hasPatientKnocked && (
                <Button
                  disabled={isPatientSTRunning || !previewStream}
                  style={{ marginTop: "2rem", fontSize: "1rem" }}
                  startIcon={<MeetingRoom />}
                  color="primary"
                  variant="contained"
                  onClick={askToEnter}
                >
                  Chiedi di entrare
                </Button>
              )}
              <div>
                In caso di problemi,{" "}
                <button
                  className={classes.linkButton}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  aggiorna la pagina
                </button>
              </div>
            </div>
            <div className={classes.mediaPreviewAndSelect}>
              <div className={classes.mediaPreview}>
                <video
                  className={`flipped ${
                    joinStreamOptions?.videoMuted ? classes.videoMuted : null
                  }`}
                  ref={videoPreviewRef}
                  autoPlay
                  playsInline
                  muted
                  poster="./wait.png"
                />
                <div className={classes.previewActions}>
                  <button
                    disabled={hasPatientKnocked}
                    onClick={() => {
                      setJoinStreamOptions((prev) => ({
                        ...prev,
                        audioMuted: !prev.audioMuted,
                      }));
                      if (!joinStreamOptions.videoMuted) {
                        setHideWRAlert(true);
                      } else {
                        setHideWRAlert(false);
                      }
                    }}
                  >
                    {joinStreamOptions?.audioMuted ? <MicOff /> : <Mic />}
                  </button>
                  <button
                    disabled={hasPatientKnocked}
                    onClick={() => {
                      setJoinStreamOptions((prev) => ({
                        ...prev,
                        videoMuted: !prev.videoMuted,
                      }));
                      if (!joinStreamOptions.audioMuted) {
                        setHideWRAlert(true);
                      } else {
                        setHideWRAlert(false);
                      }
                    }}
                  >
                    {joinStreamOptions?.videoMuted ? (
                      <VideocamOff />
                    ) : (
                      <Videocam />
                    )}
                  </button>
                </div>
              </div>
              <div className={classes.mediaSelect}>
                <span>
                  Seleziona i dispositivi che vuoi utilizzare ed effettua un
                  test per verificare che tutto funzioni correttamente.
                </span>
                <div
                  onClick={
                    isPatientSTRunning
                      ? null
                      : () => {
                          window.SomApi.startTest();
                          setIsPatientSTRunning(true);
                        }
                  }
                  className={classes.checkConnection}
                >
                  {isPatientSTRunning ? (
                    <CircularProgress color="inherit" size="1.5rem" />
                  ) : !patientSTResults ? (
                    <NetworkCheck />
                  ) : (
                    <NetworkResults
                      size="1.5rem"
                      value={
                        Math.min(
                          patientSTResults?.download,
                          patientSTResults?.upload
                        ) || 0
                      }
                      min={0}
                      max={12}
                    />
                  )}
                  <span>
                    {connectionMessage?.message || "Test connessione"}
                  </span>
                </div>
                <button
                  disabled={!previewStream}
                  onClick={() => {
                    testMicrophoneRef.current.start();
                  }}
                >
                  Test
                </button>
                <TextField
                  disabled={hasPatientKnocked}
                  SelectProps={{
                    classes: {
                      outlined: classes.outlinedStyles,
                      iconOutlined: classes.iconOutlinedStyles,
                    },
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutlineStyles,
                      focused: classes.notchedOutlineStyles,
                      adornedStart: classes.outlinedStyles,
                      disabled: classes.disabledStyles,
                    },
                    startAdornment: <VolumeUp className={classes.adornment} />,
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelStyles,
                    },
                  }}
                  margin="dense"
                  variant="outlined"
                  label="Microfono"
                  key={`currentMicrophone-${!!currentMicrophone}`}
                  value={currentMicrophone?.deviceId}
                  onChange={async (e) => {
                    const newMic = microphoneList.find(
                      (mic) => mic.deviceId === e.target.value
                    );
                    if (!newMic) return;
                    setCurrentMicrophone(newMic);
                  }}
                  select
                  fullWidth
                >
                  {microphoneList.map((audioInput) => (
                    <MenuItem
                      key={audioInput.deviceId}
                      value={audioInput.deviceId}
                    >
                      {audioInput.label}
                    </MenuItem>
                  ))}
                </TextField>
                <MicLevels
                  ref={testMicrophoneRef}
                  bars={7}
                  color="#444"
                  progressColor="white"
                  width="100%"
                  height="2rem"
                  stream={previewStream}
                />
                <TextField
                  disabled={hasPatientKnocked}
                  SelectProps={{
                    classes: {
                      outlined: classes.outlinedStyles,
                      iconOutlined: classes.iconOutlinedStyles,
                    },
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutlineStyles,
                      focused: classes.notchedOutlineStyles,
                      adornedStart: classes.outlinedStyles,
                      disabled: classes.disabledStyles,
                    },
                    startAdornment: <Videocam className={classes.adornment} />,
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelStyles,
                    },
                  }}
                  margin="dense"
                  variant="outlined"
                  label="Fotocamera"
                  key={`currentCamera-${!!currentCamera}`}
                  value={currentCamera?.deviceId}
                  select
                  fullWidth
                  onChange={async (e) => {
                    const newCamera = cameraList.find(
                      (camera) => camera.deviceId === e.target.value
                    );
                    if (!newCamera) return;
                    defaultWebcam.current = currentCamera;
                    setCurrentCamera(newCamera);
                  }}
                >
                  {cameraList.map((videoInput) => (
                    <MenuItem
                      key={videoInput.deviceId}
                      value={videoInput.deviceId}
                    >
                      {videoInput.label}
                    </MenuItem>
                  ))}
                </TextField>
                <a
                  style={{ color: "white", justifySelf: "center" }}
                  href="https://www.psycare.it/risoluzione-problemi-paziente/"
                  target="_blank"
                >
                  Problemi audio/video?
                </a>
              </div>
            </div>
            {/* {
                            isSpeedTestAvailable &&
                            <Box style={{ gap: "1rem", backgroundColor: "#ededed", borderRadius: ".3rem" }} padding="1rem" fontWeight="bold" maxWidth="80%" fontSize=".9em" margin="1rem" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                Vuoi verificare se la tua connessione internet è sufficiente per una buona videochiamata?
                                <IconButton disabled={hasPatientKnocked} style={{ boxShadow: "0 0 1rem gray", alignSelf: "center" }} onClick={isPatientSTRunning ? null : () => {
                                    window.SomApi.startTest();
                                    setIsPatientSTRunning(true);
                                }}>
                                    {
                                        isPatientSTRunning ?
                                            <CircularProgress size="1.5rem" />
                                            :
                                            !patientSTResults ?
                                                <NetworkCheck /> :
                                                <NetworkResults size="1.5rem" value={patientSTResults?.min || 0} min={0} max={12} />
                                    }
                                </IconButton>
                                <span style={{ fontWeight: "normal", textAlign: "center" }}>
                                    {connectionMessage?.message}
                                    {
                                        connectionMessage?.message &&
                                        <br />
                                    }
                                    {
                                        connectionMessage?.link &&
                                        <Link target="_blank" href="https://www.psycare.it/psicologia-on-line/problemi-di-connessione/">Come migliorare la connessione?</Link>
                                    }
                                </span>
                            </Box>
                        }
                        {!isConsentGiven && <Box fontSize=".9em" margin="1rem" color="red">Non è possibile effettuare l'accesso senza dare il consenso all'utilizzo di webcam e microfono.</Box>} */}
          </Box>
        </Box>
        <div className={classes.buttonsContainer}>
          {isConferenceJoined && (
            <Box
              boxSizing="border-box"
              padding="1rem"
              width="100%"
              height="100%"
              position="relative"
              display="grid"
              gridTemplateColumns="20% 1fr 20%"
              alignItems="center"
            >
              <div className={classes.logoAndQoS}>
                <img style={{ maxWidth: "100%" }} src={logoHorizontalWhite} />
                {networkConditions()}
              </div>
              <Box
                style={{ gap: "1.5rem" }}
                width="100%"
                display="flex"
                justifyContent="center"
              >
                <VideoCallButton
                  size="small"
                  iconSize={matches ? "2rem" : "1.5rem"}
                  pressed={false}
                  disabled={true}
                  nonPressedTooltip={disabledSentence}
                  NonPressedIcon={OpenWhiteboardIcon}
                />
                <VideoCallButton
                  size="small"
                  iconSize={matches ? "2rem" : "1.5rem"}
                  disabled={true}
                  pressed={false}
                  nonPressedTooltip={disabledSentence}
                  NonPressedIcon={PresentToAll}
                />
                <VideoCallButton
                  size="small"
                  iconSize={matches ? "2rem" : "1.5rem"}
                  pressed={!isAudioActive}
                  pressedTooltip="Attiva audio"
                  nonPressedTooltip="Disattiva audio"
                  PressedIcon={MicOff}
                  NonPressedIcon={Mic}
                  onClick={toggleAudio}
                />
                <VideoCallButton
                  size="small"
                  iconSize={matches ? "2rem" : "1.5rem"}
                  pressed={!isVideoActive}
                  pressedTooltip="Attiva video"
                  nonPressedTooltip="Disattiva video"
                  PressedIcon={VideocamOff}
                  NonPressedIcon={Videocam}
                  onClick={toggleVideo}
                />
              </Box>
              <div
                className={`${classes.closeAndTimer} ${
                  !settings?.canSeeTimer ? classes.loneButton : ""
                }`}
              >
                {matches && settings?.canSeeTimer && (
                  <PsyTimer
                    style={{ color: "white" }}
                    autoStartOnNullDate={false}
                    start={patientEnteringDate}
                  />
                )}
                <VideoCallButton
                  style={{
                    backgroundColor: "rgb(196,49,75)",
                    border: "none",
                    color: "white",
                  }}
                  nonPressedTooltip="Concludi Sessione"
                  NonPressedIcon={CallEnd}
                  onClick={leaveConference}
                />
              </div>
            </Box>
          )}
        </div>
      </div>
      <Dialog
        open={!isNameUpdated && !notAllowed}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        fullWidth={true}
      >
        <DialogTitle variamnt="h6" disableTypography>
          <Typography style={{ fontWeight: "bold" }} variant="h6">
            Stai per partecipare alla tua prima seduta online
          </Typography>
        </DialogTitle>
        <DialogContent>
          <div className={modalContentStyle.nameAndSurnameContainer}>
            <span>
              Inserisci il tuo nome e cognome per inziare il primo consulto:
            </span>
            <TextField
              value={nameAndSurname.name}
              onChange={(e) => {
                e.persist();
                setNameAndSurname((prev) => ({
                  ...prev,
                  name: e.target.value,
                }));
              }}
              label="Nome"
              variant="outlined"
              margin="dense"
              inputProps={{ maxLength: 47 }}
              error={nameAndSurnameErrors.name}
              helperText={nameAndSurnameErrors.name}
            />
            <TextField
              value={nameAndSurname.surname}
              onChange={(e) => {
                e.persist();
                setNameAndSurname((prev) => ({
                  ...prev,
                  surname: e.target.value,
                }));
              }}
              label="Cognome"
              variant="outlined"
              margin="dense"
              inputProps={{ maxLength: 47 }}
              error={nameAndSurnameErrors.surname}
              helperText={nameAndSurnameErrors.surname}
            />
          </div>
        </DialogContent>
        <DialogActions
          classes={{
            root: modalContentStyle.dialogActions,
          }}
        >
          <Button
            onClick={updateAndStart}
            disabled={videoCallSession === null}
            startIcon={<Videocam />}
            color="primary"
            variant="contained"
          >
            Inizia la seduta
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={notAllowed}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        fullWidth={true}
      >
        <DialogTitle variamnt="h6" disableTypography>
          <Typography style={{ fontWeight: "bold" }} variant="h6">
            Seduta già conclusa
          </Typography>
        </DialogTitle>
        <DialogContent style={{ paddingBottom: "1.5rem" }}>
          La seduta di primo consulto alla quale stai cercando di accedere si è
          già conclusa. Se desideri proseguire, chiedi al terapeuta di
          aggiungerti come paziente e di inviarti il link per partecipare alle
          sedute.{" "}
        </DialogContent>
      </Dialog>
    </>
  ) : (
    <SessionEnded />
  );
};
export default GuestSession;
