import { SvgIcon } from '@material-ui/core';
import React from 'react';

const GenogramIcon = props => {
    return (
        <SvgIcon {...props}>
            <rect x="0.899988" y="5.78439" width="9.6" height="9.6" rx="0.6" fill="none" stroke="currentColor" stroke-width="1.2" />
            <circle cx="18.1616" cy="10.0758" r="4.8" fill="none" stroke="currentColor" stroke-width="1.2" />
            <line x1="5.99999" y1="15.6" x2="5.99999" y2="18.6" fill="none" stroke="currentColor" stroke-width="1.2" />
            <line x1="18.6" y1="15" x2="18.6" y2="18.6" fill="none" stroke="currentColor" stroke-width="1.2" />
            <line x1="5.39999" y1="18" x2="19.2" y2="18" fill="none" stroke="currentColor" stroke-width="1.2" />
        </SvgIcon>
    );
};
export default GenogramIcon;