import { DialogTitle, Dialog, DialogContent, DialogActions, DialogContentText, Button, CircularProgress, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react'

const useStyles = makeStyles({
    dialogTitle: {
        fontWeight: "bold",
    },
});

/**
 * @callback onClose
 * @param {bool} result - false if the alert was canceled, true if it was accepted
 */

/**
 * Component that shows an Alert Dialog
 * @param {Object} props 
 * @param {bool} props.open - Wether the alert is open or not
 * @param {onClose} props.onClose - Callback called on the close of the alert dialog
 * @param {string} props.title - The title of the alertbox
 * @param {string} props.rejectButtonText - The text of the reject button
 * @param {string} props.acceptButtonText - The text of the accept button
 * @returns 
 */
const AlertDialog = props => {
    const styles = useStyles();
    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose(false)}
            aria-labelledby={props.title}
            aria-describedby={props.children}
        >
            <Box style={{ backgroundColor: "rgba(255,255,255,.7)", pointerEvents: "none" }} display={props?.loading ? "flex" : "none"} justifyContent="center" alignItems="center" position="absolute" top="0" left="0" right="0" bottom="0">
                <CircularProgress />
            </Box>
            <DialogTitle id={props.title} disableTypography={true}>
                <Typography classes={{
                    root: styles.dialogTitle,
                }} variant="h6">
                    {props.title}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText color="textPrimary" id={props.children}>
                    {props.children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" disabled={props?.loading} onClick={() => props.onClose(false)} color="primary">
                    {props.rejectButtonText || "Annulla"}
                </Button>
                <Button variant="contained" disabled={props?.loading} onClick={() => props.onClose(true)} color="primary" autoFocus>
                    {props.acceptButtonText || "Ok"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AlertDialog;