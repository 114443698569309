import React, { useState, createContext, useContext } from 'react';

/**
 * @typedef {Object} Session
 * @property {string} id
 * @property {string} idHost
 * @property {boolean} isTherapist
 * @property {string} idPatient
 * @property {string} idTherapist
 * @property {Date} sessionStart
 * @property {Date} sessionEnd
 * @property {Date} createdAt
 * @property {string} patientFirstName
 * @property {string} patientLastName
 * @property {string} therapistFirstName
 * @property {string} therapistLastname
 * @property {string} patientSessionStart
 * @property {string} patientSessionEnd
 * @property {boolean} isTheFirstVideoCall
 * @property {Date} patientWaitingRoomStart
 * @property {Date} therapistWaitingRoomStart
 * @property {(-1|0|1|2)} permissions - -1 for NOWHITEBOARD
 * - 0 for CANMODIFY
 * - 1 for READONLY
 * - 2 for LOCKED
 * @property {boolean} haveImage
 */

/**
 * @typedef {Object} WhiteboardPopupOptions
 * @property {boolean} open - If the popup is open or not.
 * @property {Session} session - The session relative to the whiteboard.
 */

/**
* @typedef {Object} ErrorWebcamOrMicrPopupProps
* @property {string} message - The message to show
*/

/**
* @typedef {Object} ErrorWebcamOrMicrPopup
* @property {boolean} open - If the popup is open or not.
* @property {ErrorWebcamOrMicrPopupProps} props - If the popup is open or not.
*/

/**
 * The popup object.
 * @typedef {Object} PopupOptions
 * @property {WhiteboardPopupOptions} whiteboardPopup - Options for the whiteboard popup
 * @property {ErrorWebcamOrMicrPopup} errorWebcamOrMicrPopup - Options for the popup to open if the user has problems with camera/mic
 */

/**
 * @typedef {Function} PopupSetter - The setter for the popup options
 */

/**
 * @returns {[PopupOptions, PopupSetter]} An array containing popups and setPopups
 */
const usePopups = () => {
    return useContext(PopupContext);
};
const PopupContext = createContext(null);

const PopupContextProvider = ({ children }) => {
    const [popups, setPopups] = useState({
        whiteboardPopup: {
            open: false,
            session: null,
        },
        errorWebcamOrMicrPopup: {
            open: false,
            props: {
                message: "",
            }
        }
    });
    return (
        <PopupContext.Provider value={[popups, setPopups]}>
            {children}
        </PopupContext.Provider>
    );
};

export default PopupContextProvider;
export { usePopups };