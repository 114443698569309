
import { SvgIcon } from '@material-ui/core';
import React from 'react';
const DonnaIcon = props => {
        return (
                <SvgIcon {...props}>
                        <circle cx="12" cy="12" r="9.5" fill="none" stroke="currentColor" />
                </SvgIcon>
        )
};
export default DonnaIcon;
