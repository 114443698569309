import React from 'react';

function NotFound() {

    return (
        <div>NotFound</div>
    );
}

export default NotFound;
