import { SvgIcon } from '@material-ui/core';
import React from 'react';

const FullViewIcon = props => {
    return (
        <SvgIcon {...props}>
            <rect x="13.50003" y="15" width="6" height="4" fill="currentColor" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="m15.49997,5l-11,0l0,8l0,6l8,0l0,-5l4,0l0,-9l-1,0z" fill="currentColor" stroke="null" />
        </SvgIcon>
    );
};
export default FullViewIcon;