import React from 'react'
import { SvgIcon } from '@material-ui/core';

const PaymentToPayIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="M23.25 11.25L22.6331 11.7787L20.1875 9.68625V14.25H19.3125V9.68625L16.8713 11.7825L16.25 11.25L19.75 8.25L23.25 11.25Z"/>
            <path d="M13.5 16.25V17C13.5 17.825 12.825 18.5 12 18.5H1.5C0.6675 18.5 0 17.825 0 17V6.5C0 5.675 0.6675 5 1.5 5H12C12.825 5 13.5 5.675 13.5 6.5V7.25H6.75C5.9175 7.25 5.25 7.925 5.25 8.75V14.75C5.25 15.575 5.9175 16.25 6.75 16.25H13.5ZM6.75 14.75H14.25V8.75H6.75V14.75ZM9.75 12.875C9.1275 12.875 8.625 12.3725 8.625 11.75C8.625 11.1275 9.1275 10.625 9.75 10.625C10.3725 10.625 10.875 11.1275 10.875 11.75C10.875 12.3725 10.3725 12.875 9.75 12.875Z"/>
        </SvgIcon>
    )
}

export default PaymentToPayIcon