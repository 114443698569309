
import { SvgIcon } from '@material-ui/core';
import React from 'react';
const FiglioAdottivoIcon = props => {
        return (
                <SvgIcon {...props}>
                        <path d="M12.5112 16V14" stroke="currentColor" />
                        <path d="M12.5112 7V5" stroke="currentColor" />
                        <path d="M12.5112 22V20" stroke="currentColor" />
                        <path d="M12.5112 13V11" stroke="currentColor" />
                        <path d="M12.5114 3.99988L12.5225 2.99994" stroke="currentColor" />
                        <path d="M12.5226 2.99994L12.5337 2" stroke="currentColor" />
                        <path d="M12.5112 19V17" stroke="currentColor" />
                        <path d="M12.5112 10V8" stroke="currentColor" />
                        <line x1="10.0112" y1="22" x2="10.0112" y2="2" stroke="currentColor" />
                </SvgIcon>
        )
};
export default FiglioAdottivoIcon;
