import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from "react";
import AgoraRTM, { RtmStatusCode } from "agora-rtm-sdk";
import appsettings from "../config/appsettings.json";
import environment from "../config/environment.json";
import { useUser } from "./UserContext";
import useApi from "../hooks/useApi";

export type SignalingEventsMap = {
  "videocall:requestaccess": {
    idPatient: string;
  };
  "waitingroom:left": {
    idPatient: string;
  };
  "videocall:admit": {};
  "videocall:whiteboard": {};
  "videocall:leave-tab": {};
  "videocall:return-tab": {};
  "videocall:closing": {};
  "videocall:toggle-patient": {};
  "groupvideocall:toggle-audio": {};
  "groupvideocall:closing": {};
  "groupvideocall:user-online":{
    id:string;
  }
  "groupvideocall:user-offline":{
    id:string;
  }
  "groupvideocall:request-all-user":{}
  "groupvideocall:send-all-user":{
    ids:string[];
  }
  "whiteboard:adapt": {
    size: {
      width: number;
      height: number;
    };
  };
  "whiteboard:toggle": {
    isOpen: boolean;
  };
  "whiteboard:update-canvas": {
    canvas: any;
  };
  "whiteboardpopup:update-canvas": {
    canvas: any;
    idSession: string;
  };
  ack: {};
};

export type SignalingEvent = keyof SignalingEventsMap;

type SignalingContextType = {
  signaling: typeof signaling;
  on: <T extends SignalingEvent>(
    event: T,
    fn: (props: SignalingEventsMap[T]) => void
  ) => () => void;
  send: <T extends SignalingEvent>(
    user: string,
    event: T,
    data: SignalingEventsMap[T],
    needResponse?: boolean,
    discardIfNoListener?: boolean
  ) => void;
  getSenderForUser: (
    getUser: () => string | null
  ) => <T extends SignalingEvent>(
    event: T,
    data: SignalingEventsMap[T],
    needResponse?: boolean,
    discardIfNoListener?: boolean
  ) => void;
  getTokenAndLogin: (user: any) => Promise<() => void>;
};

export function useSignaling() {
  const context = useContext(SignalingContext);
  if (!context)
    throw new Error(
      "You must be inside a SignalingContextProvider to use this hook"
    );
  return context;
}

const MAX_CHUNK_DATA = 25000;

function chunkifyData(data: any) {
  let result = [];
  try {
    let stringifiedData = JSON.stringify(data);
    while (stringifiedData) {
      result.push(stringifiedData.substring(0, MAX_CHUNK_DATA));
      if (stringifiedData.length < MAX_CHUNK_DATA) {
        stringifiedData = "";
      } else {
        stringifiedData = stringifiedData.substring(MAX_CHUNK_DATA);
      }
    }
  } catch (e) {}
  return result;
}

function dateifyChunks(
  chunks: {
    data: string;
    id: string;
    chunksLength: number;
    chunkNumber: number;
  }[]
) {
  let data = "";
  let sorted_chunk = [...chunks].sort((chunkA, chunkB) => {
    return chunkA.chunkNumber - chunkB.chunkNumber;
  });
  for (let chunk of sorted_chunk) {
    data += chunk.data;
  }
  return JSON.parse(data);
}

export function useSignalingListener<T extends SignalingEvent>(
  event: T,
  callback: (props: SignalingEventsMap[T]) => void,
  deps: unknown[] = []
) {
  const { on } = useSignaling();
  useEffect(() => {
    return on(event, callback);
  }, [event, ...deps]);
}

const SignalingContext = createContext<SignalingContextType | null>(null);

const signaling = AgoraRTM.createInstance(
  // @ts-ignore
  appsettings[environment.Environment].agora.appId
);

const callbacks = new Map<SignalingEvent, Set<(data: any) => void>>();

const messagesChunks = new Map<
  string,
  { data: string; id: string; chunksLength: number; chunkNumber: number }[]
>();

const messagesQueue = new Map<string, Set<() => void>>();

const needResponseQueue = new Map<string, Set<() => void>>();

const noCallbacksQueue = new Map<
  SignalingEvent,
  Set<{ data: unknown; needResponse?: boolean; from: string; id: string }>
>();

const subscribedPeersIds = new Set<string>();

export default function SignalingContextProvider({
  children,
}: {
  children: JSX.Element;
}) {
  const on = useCallback((event, fn) => {
    if (!callbacks.has(event)) {
      callbacks.set(event, new Set());
    }
    const callbacksSet = callbacks.get(event);
    callbacksSet!.add(fn);
    const oldDataQueue = noCallbacksQueue.get(event);
    if (oldDataQueue && oldDataQueue.size > 0) {
      oldDataQueue.forEach((oldData) => {
        // using settimout to delay the fn call to after
        // the first function execution
        setTimeout(fn, 0, oldData.data);
        oldDataQueue.delete(oldData);
        // if the message need a response we send the ack back to the peerId
        // with the id of the message we are responding to
        if (oldData.needResponse) {
          send(oldData.from, "ack", { id: oldData.id });
        }
      });
    }
    return () => {
      callbacksSet!.delete(fn);
    };
  }, []);

  const send = useCallback(
    (user, event, data, needResponse = false, discardIfNoListener = false) => {
      const id = window.crypto.randomUUID();
      async function sendMessageOrAddToQueue() {
        const chunkedData = chunkifyData(data);
        const originalLength = chunkedData.length;
        while (chunkedData.length > 0) {
          const actualData = chunkedData.shift();
          const result = await signaling.sendMessageToPeer(
            {
              text: JSON.stringify({
                kind: event,
                data: actualData,
                needResponse,
                id,
                chunkNumber: originalLength - chunkedData.length,
                chunksLength: originalLength,
                discardIfNoListener,
              }),
            },
            user
          );
          if (!result.hasPeerReceived) {
            if (!messagesQueue.has(user)) {
              messagesQueue.set(user, new Set());
            }
            const userQueue = messagesQueue.get(user);
            userQueue!.add(sendMessageOrAddToQueue);
            if (!subscribedPeersIds.has(user)) {
              signaling.subscribePeersOnlineStatus([user]);
              subscribedPeersIds.add(user);
            }
          } else if (needResponse) {
            // if this message need a response and the user has received
            // the message we push this message to the needResponseQueue.
            // this will allow us to send the message again if the user
            // come back online and we have yet to receive an ack for this
            // message
            if (!needResponseQueue.has(user)) {
              needResponseQueue.set(user, new Set());
            }
            const userQueue = needResponseQueue.get(user);
            userQueue!.add(sendMessageOrAddToQueue);
            if (!subscribedPeersIds.has(user)) {
              signaling.subscribePeersOnlineStatus([user]);
              subscribedPeersIds.add(user);
            }
            // we than add a listener on "ack" where we check if
            // the id received is the id of this message, if it is
            // we delete the message from the needResponseQueue and
            // remove the listener
            const resolveAck = on("ack", (data: unknown) => {
              if (data instanceof Object && "id" in data) {
                if (data.id === id) {
                  userQueue!.delete(sendMessageOrAddToQueue);
                  resolveAck();
                }
              }
            });
          }
        }
      }
      sendMessageOrAddToQueue();
    },
    []
  );

  const user = useUser();
  const api = useApi();

  async function getTokenAndLogin(user: any) {
    const tokenResponse = await api(
      undefined,
      // @ts-ignore
      `agoratoken/rtm/${user.profile.sub}`,
      "GET",
      null,
      true,
      false
    );
    if (tokenResponse.ok === false) throw new Error("Can't recover token");
    signaling.login({
      // @ts-ignore
      uid: user.profile.sub,
      token: tokenResponse.rtmAccessToken,
    });
    return () => {
      signaling.logout();
    };
  }

  useEffect(() => {
    // @ts-ignore
    if (!user?.profile?.sub) return;
    let cleanup: (() => void) | undefined;
    (async () => {
      cleanup = await getTokenAndLogin(user);
    })();
    return () => {
      if (cleanup) {
        cleanup();
      }
    };
    // @ts-ignore
  }, [user?.profile?.sub]);

  useEffect(() => {
    signaling.on("MessageFromPeer", (message, peerId) => {
      try {
        const data = JSON.parse(message.text ?? "");
        if (!messagesChunks.has(data.id)) {
          messagesChunks.set(data.id, []);
        }
        const chunksArray = messagesChunks.get(data.id)!;
        chunksArray.push(data);
        if (chunksArray.length === data.chunksLength) {
          const actualData = dateifyChunks(chunksArray);
          const callbacksToCall = callbacks.get(data.kind);
          if (callbacksToCall && callbacksToCall.size > 0) {
            callbacksToCall.forEach((cb) => {
              cb(actualData);
              // if the message need a response we send the ack back to the peerId
              // with the id of the message we are responding to
              if (data.needResponse) {
                send(peerId, "ack", { id: data.id });
              }
            });
          } else if (!data.discardIfNoListener) {
            if (!noCallbacksQueue.has(data.kind)) {
              noCallbacksQueue.set(data.kind, new Set());
            }
            const callbackQueue = noCallbacksQueue.get(data.kind);
            callbackQueue!.add({ ...data, from: peerId, data: actualData });
          }
        }
      } catch (e) {}
    });

    signaling.on("PeersOnlineStatusChanged", (peerStatus) => {
      // whenever the state of an user change back online we loop over
      // each message in the queue and each needResponse queue and send them again
      messagesQueue.forEach((sendReplays, user) => {
        if (peerStatus[user] === "ONLINE") {
          sendReplays.forEach((send) => {
            send();
            sendReplays.delete(send);
          });
        }
      });
      needResponseQueue.forEach((sendReplays, user) => {
        if (peerStatus[user] === "ONLINE") {
          sendReplays.forEach((send) => {
            send();
            sendReplays.delete(send);
          });
        }
      });
    });

    return () => {
      signaling.removeAllListeners();
    };
  }, []);

  const getSenderForUser = useCallback(
    (getUser: () => string | null) => {
      return <T extends SignalingEvent>(
        event: T,
        data: SignalingEventsMap[T],
        needResponse?: boolean,
        discardIfNoListener?: boolean
      ) => {
        const user = getUser();
        if (!user) return;
        send(user, event, data, needResponse, discardIfNoListener);
      };
    },
    [send]
  );

  return (
    <SignalingContext.Provider
      value={{ signaling, on, send, getSenderForUser, getTokenAndLogin }}
    >
      {children}
    </SignalingContext.Provider>
  );
}
