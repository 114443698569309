import React, { createContext, useContext, useState } from 'react';

const IsVideoCallModalOpenedContext = createContext(null);
const UpdateIsVideoCallModalOpenedContext = createContext(null);
const VideoCallSessionContext = createContext(null);
const UpdateVideoCallSessionContext = createContext(null);
const IsApiRTCAvailableContext = createContext(null);
const UpdateIsApiRTCAvailableContext = createContext(null);

const useIsVideoCallModalOpened = () => {
    return useContext(IsVideoCallModalOpenedContext);
};

const useSetIsVideoCallModalOpened = () => {
    return useContext(UpdateIsVideoCallModalOpenedContext);
};

const useVideoCallSession = () => {
    return useContext(VideoCallSessionContext);
};

const useSetVideoCallSession = () => {
    return useContext(UpdateVideoCallSessionContext);
};

const useIsApiRtcAvailable = () => {
    return useContext(IsApiRTCAvailableContext);
};

const useSetIsApiRtcAvailable = () => {
    return useContext(UpdateIsApiRTCAvailableContext);
};


const VideoCallModalContextProvider = ({ children }) => {
    const [isVideoCallModalOpened, setIsVideoCallModalOpened] = useState(false);
    const [videoCallPatient, setVideoCallPatient] = useState(null);
    const [isApiRtcAvailable, setIsApiRtcAvailable] = useState(false);



    return (
        <IsApiRTCAvailableContext.Provider value={isApiRtcAvailable}>
            <UpdateIsApiRTCAvailableContext.Provider value={setIsApiRtcAvailable}>
                <IsVideoCallModalOpenedContext.Provider value={isVideoCallModalOpened}>
                    <UpdateIsVideoCallModalOpenedContext.Provider value={setIsVideoCallModalOpened}>
                        <VideoCallSessionContext.Provider value={videoCallPatient}>
                            <UpdateVideoCallSessionContext.Provider value={setVideoCallPatient}>
                                {children}
                            </UpdateVideoCallSessionContext.Provider>
                        </VideoCallSessionContext.Provider>
                    </UpdateIsVideoCallModalOpenedContext.Provider>
                </IsVideoCallModalOpenedContext.Provider>
            </UpdateIsApiRTCAvailableContext.Provider>
        </IsApiRTCAvailableContext.Provider>
    );
};

export default VideoCallModalContextProvider;
export { useIsVideoCallModalOpened, useSetIsVideoCallModalOpened, useVideoCallSession, useSetVideoCallSession, useIsApiRtcAvailable, useSetIsApiRtcAvailable };
