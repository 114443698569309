import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useRef, useState } from "react";
import PsyWhiteboard from "../components/PsyWhiteboard";
import { useSignaling } from "../contexts/SignalingContext";

const useStyles = makeStyles({
  wrapper: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
});
export const Whiteboard = () => {
  const [user, setUser] = useState<any>(window.REACT_NATIVE_USER);
  const [jsonCanvas, setJsonCanvas] = useState<any>({});
  const psyWhiteboardRef = useRef<PsyWhiteboard>(null);
  const styles = useStyles();

  useEffect(() => {
    const newUserHandler = () => {
      setUser(window.REACT_NATIVE_USER);
    };
    window.addEventListener("reactnative:newuser", newUserHandler);
    return () => {
      window.removeEventListener("reactnative:newuser", newUserHandler);
    };
  }, []);

  useEffect(() => {
    const newJsonCanvas = () => {
      setJsonCanvas(window.REACT_NATIVE_JSONCANVAS);
    };
    window.addEventListener("reactnative:newjsoncanvas", newJsonCanvas);
    return () => {
      window.removeEventListener("reactnative:newjsoncanvas", newJsonCanvas);
    };
  }, []);

  useEffect(() => {
    const adaptCanvasHandler = () => {
      if (
        window.REACT_NATIVE_WHITEBOARD_ADAPT_SIZE &&
        psyWhiteboardRef.current
      ) {
        psyWhiteboardRef.current.adapt(
          window.REACT_NATIVE_WHITEBOARD_ADAPT_SIZE
        );
      }
    };
    window.addEventListener(
      "reactnative:whiteboardadaptsize",
      adaptCanvasHandler
    );
    return () => {
      window.removeEventListener(
        "reactnative:whiteboardadaptsize",
        adaptCanvasHandler
      );
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <PsyWhiteboard
        hasExit={false}
        readOnly={!!window.REACT_NATIVE_WHITEBOARD_READONLY}
        onExit={() => {
          window.ReactNativeWebView.postMessage("whiteboard:exit");
        }}
        ref={psyWhiteboardRef}
        jsonCanvas={jsonCanvas}
        onCanvasUpdate={(json: any, skipValue: boolean) => {
          if (!skipValue) {
            setJsonCanvas(json);
          }
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              kind: "reactnative:newjsoncanvas",
              data: json,
            })
          );
        }}
      />
    </div>
  );
};
