import { makeStyles } from '@material-ui/core';
import React from "react";

const useStyles = makeStyles(theme => ({
    nameAndAction: {
        display: "flex",
        justifyContent: "space-between",
        fontSize: "clamp(0.7rem, 1.7vmin, 1rem)",
        opacity: ".3",
        userSelect: "none",
        pointerEvents: "none",
        padding: ".5rem",
        "& span": {
            whiteSpace: "nowrap",
        },
        "& > * + *": {
            marginLeft: "1rem",
        },
        "& svg": {
            fontSize: "1rem",
        },
        [theme.breakpoints.up("sm")]: {
            "& svg": {
                fontSize: "1.5rem",
            },
        },
    },
    online: {
        opacity: "1",
        pointerEvents: "auto",
    }
}));

const ParticipantRow = ({ participant, isOnline, isTherapist }) => {
    const classes = useStyles();
    return (
        !participant ? null :
            <div className={`${classes.nameAndAction} ${isOnline ? classes.online : ''}`}>
                <span>{isTherapist ? `${participant.name} ${participant.lastName}` : participant.name}</span>
            </div>
    );
};
export default ParticipantRow;