import React, { useContext } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import LastClickedProvider from './contexts/LastClickedContext';
import UserContextProvider from './contexts/UserContext';
import VideoCallModalContextProvider from './contexts/VideoCallModalContext';
import GlobalSnackbarContextProvider from './contexts/GlobalSnackbarContext';
import NotificationContextProvider from './contexts/NotificationContext';
import PopupContextProvider from './contexts/PopupContext';
import GroupCallContextProvider from './contexts/GroupCallContext';
import ErrorBoundary from './views/ErrorBoundary';
import SignalingContextProvider from './contexts/SignalingContext';
import AgoraContextProvider from './contexts/AgoraContext';

const polyfill = (() => {
  // Object.entries
  if (!Object.entries)
    Object.entries = function (obj) {
      var ownProps = Object.keys(obj),
        i = ownProps.length,
        resArray = new Array(i); // preallocate the Array
      while (i--)
        resArray[i] = [ownProps[i], obj[ownProps[i]]];

      return resArray;
    };
})();

ReactDOM.render(
  <React.Fragment>
    <UserContextProvider>
      <ErrorBoundary>
        <AgoraContextProvider>
          <SignalingContextProvider>
            <NotificationContextProvider>
              <LastClickedProvider>
                <VideoCallModalContextProvider>
                  <GlobalSnackbarContextProvider>
                    <GroupCallContextProvider>
                      <PopupContextProvider>
                        <App />
                      </PopupContextProvider>
                    </GroupCallContextProvider>
                  </GlobalSnackbarContextProvider>
                </VideoCallModalContextProvider>
              </LastClickedProvider>
            </NotificationContextProvider>
          </SignalingContextProvider>
        </AgoraContextProvider>
      </ErrorBoundary>
    </UserContextProvider>
  </React.Fragment>,
  document.getElementById('root')
);

serviceWorker.unregister();
