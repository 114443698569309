
import { SvgIcon } from '@material-ui/core';
import React from 'react';
const DistanteIcon = props => {
        return (
                <SvgIcon {...props}>
                        <path d="M2 12H4" stroke="currentColor" />
                        <path d="M11 12H13" stroke="currentColor" />
                        <path d="M17 12H19" stroke="currentColor" />
                        <path d="M5 12H7" stroke="currentColor" />
                        <path d="M20.0001 12L21.0001 12.0112" stroke="currentColor" />
                        <path d="M21.0001 12.0112L22 12.0223" stroke="currentColor" />
                        <path d="M8 12H10" stroke="currentColor" />
                        <path d="M14 12H16" stroke="currentColor" />
                </SvgIcon>
        )
};
export default DistanteIcon;
