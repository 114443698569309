
import { SvgIcon } from '@material-ui/core';
import React from 'react';
const RelazioneSignificativaIcon = props => {
        return (
                <SvgIcon {...props}>
                        <line x1="2.5" y1="9" x2="2.5" y2="11" stroke="currentColor" />
                        <line x1="2.5" y1="12" x2="2.5" y2="14" stroke="currentColor" />
                        <path d="M2 15H3.73914" stroke="currentColor" />
                        <path d="M9.82617 15H11.5653" stroke="currentColor" />
                        <path d="M17.6522 15H19.3914" stroke="currentColor" />
                        <path d="M4.60876 15H6.3479" stroke="currentColor" />
                        <path d="M12.4348 15H14.174" stroke="currentColor" />
                        <path d="M20.261 15L21.1305 15.0111" stroke="currentColor" />
                        <path d="M21.1305 15.0111L22 15.0223" stroke="currentColor" />
                        <path d="M7.21741 15H8.95655" stroke="currentColor" />
                        <path d="M15.0436 15H16.7827" stroke="currentColor" />
                        <path d="M21.5653 14V12" stroke="currentColor" />
                        <path d="M21.5653 11V9" stroke="currentColor" />
                </SvgIcon>
        )
};
export default RelazioneSignificativaIcon;
