import React, { useCallback, useEffect, useMemo, useState } from "react";
import useAnimationFrame from "../hooks/useAnimationFrame";

const PsyTimer = ({ start, autoStartOnNullDate, ...props }) => {
    const [startTime, setStartTime] = useState();
    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const timer = useMemo(() => {
        return `${minutes
            .toString()
            .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
    }, [seconds, minutes]);
    const handleRefresh = useCallback(() => {
        if (!startTime || isNaN(startTime)) return;
        const now = new Date();
        const passed = now - startTime;
        const minutesPassed = Math.floor(passed / 1000 / 60);
        const secondsPassed = Math.floor(passed / 1000 - minutesPassed * 60);
        setMinutes(minutesPassed);
        setSeconds(secondsPassed);
    }, [startTime]);
    useAnimationFrame(1000, handleRefresh);
    useEffect(() => {
        if (!autoStartOnNullDate && !start) return;
        let startDate = start || new Date();
        if (typeof start === "string") {
            startDate = new Date(start);
            if (isNaN(startDate)) {
                startDate = new Date();
            }
        }
        setStartTime(startDate);
    }, [start]);
    return <span {...props}>{timer}</span>;
};

export default PsyTimer;
