import React, { useRef, useState, useCallback } from 'react';
import { Button, TextField } from '@material-ui/core';

const FileField = (props) => {
    const { onChange: customOnchange } = props;
    const inputRef = useRef();
    const [files, setFiles] = useState("Nessun file selezionato");
    const handleChange = useCallback((event) => {
        if (!event?.target?.files) {
            event.target.files = {};
        }
        let values = Object.values(event.target.files).map(e => e.name).join(",");
        setFiles(values == "" ? "Nessun file selezionato" : values);
        if (customOnchange) {
            customOnchange(event);
        }
    }, [customOnchange]);
    return (
        <React.Fragment>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }} onClick={() => { inputRef.current.click(); }}>
                <Button variant="contained" color="primary">Sfoglia...</Button>
                <TextField readonly {...props} value={files} style={{ marginLeft: "1rem", width: "100%" }} />
            </div>
            <input {...props} style={{ display: "none" }} onChange={handleChange} ref={inputRef} type="file" />
        </React.Fragment>
    );
};

export default FileField;