import { Refresh } from "@material-ui/icons";
import { Button, IconButton, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import GetAppIcon from "@material-ui/icons/GetApp";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useGlobalSnackbar } from "../../contexts/GlobalSnackbarContext";
import { usePopups } from "../../contexts/PopupContext";
import { useUser, useUserProfile } from "../../contexts/UserContext";
import useApi from "../../hooks/useApi";
import {
  downloadFile,
  eurCurrency,
  onlyDateFormatter,
} from "../../utils/UtilsFunction";
import PsyTable from "../PsyTable";
import SessionsPaymentPopup from "./SessionsPaymentPopup";

const tableLocalization = {
  body: {
    emptyDataSourceMessage: "Non hai nessun pagamento da effettuare!",
  },
  toolbar: {
    searchTooltip: "",
    searchPlaceholder: "Cerca pagamenti...",
  },
  header: {
    actions: "",
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count}",
    firstTooltip: "Prima pagina",
    lastTooltip: "Ultima pagina",
    nextTooltip: "Successiva",
    previousTooltip: "Precedente",
    labelRowsSelect: "righe",
  },
};

const PaymentToPay = (props) => {
  const classes = useStyles();
  const user = useUser();

  //State of payment's table loading data
  const [isPaymentLoading, setIsPaymentLoading] = useState(false);

  //Data for sessionPopup
  const [isSessionsPopupOpen, setIsSessionsPopupOpen] = useState(false);
  const [sessionPaymentData, setSessionPaymentData] = useState({});

  //List of payments
  const [payment, setPayment] = useState([]);

  const api = useApi();
  const userProfile = useUserProfile();
  const [_, setGlobalSnackbar] = useGlobalSnackbar();
  const [order, setOrder] = useState({ orderDirection: "desc" });

  const handleOrderChange = (orderDirection) => {
    setOrder({ orderDirection });
  };

  //At start try to fetch patient's payments
  useEffect(() => {
    updatePaymentList();
  }, []);

  //This callback try to fetch the requested,to-pay patient's payments
  const updatePaymentList = useCallback(async () => {
    setIsPaymentLoading(true);
    const paymentList = await api(
      user,
      `payments/${user?.profile?.sub}/paymentrequest?state=requested,failed`,
      "GET"
    );
    setPayment(paymentList);
    setIsPaymentLoading(false);
  }, []);

  //This callback redirect to Stripe checkout for pay the session
  const payNow = useCallback((rowData) => {
    if (rowData?.checkoutUrl) {
      window.location = rowData?.checkoutUrl;
    } else {
      setGlobalSnackbar({
        open: true,
        severity: "error",
        message:
          "Impossibile prendere in carico il pagamento. Riprovare più tardi",
      });
    }
  }, []);

  //This callback is called for download invoice
  const downloadInvoice = useCallback(async (rowData) => {
    setGlobalSnackbar({
      open: true,
      severity: "info",
      message: `Il download del file ${rowData?.filename} partirà a breve.`,
    });
    let endpoint = `documents/download/${rowData?.id}`;
    const rawFile = await api(user, endpoint, "GET");
    rawFile.ok !== false
      ? downloadFile(rawFile)
      : setGlobalSnackbar({
          open: true,
          severity: "error",
          message: `Il download del file ${rowData.filename} è fallito.`,
        });
  }, []);

  //This callback is for open SessionsPaymentPopup
  const openSessionsSummary = useCallback((rowData) => {
    setSessionPaymentData({
      sessions: rowData?.sessions,
      amount: rowData?.amount,
    });
    setIsSessionsPopupOpen(true);
  }, []);

  //Memoized for name and surname
  const nameAndSurname = useMemo(() => {
    const nameAndSurname = `${userProfile?.therapistName} ${userProfile?.therapistLastName}`;
    return !userProfile?.therapistName && !userProfile?.therapistLastName
      ? "-"
      : nameAndSurname;
  }, [userProfile?.therapistName, userProfile?.therapistLastName]);

  //This check is for retrieve list of payment when tab change
  useEffect(() => {
    if (props?.refresh) updatePaymentList();
  }, [props?.refresh]);
  const theme = useTheme();
  const matchesUpSm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <div className={classes.container}>
      <PsyTable
        isLoading={isPaymentLoading}
        columns={[
          {
            showOnMobile: true,
            mobileTitle: "Da effettuare",
            title: "Data",
            field: "data",
            sorting: true,
            defaultSort: "desc",
            mobileRender: (rowData) => {
              return (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span>
                    {rowData.createdAt
                      ? onlyDateFormatter.format(
                          new Date(`${rowData.createdAt}Z`)
                        )
                      : "Data non disponibile"}
                  </span>
                  <span>
                    {rowData?.amount
                      ? `${eurCurrency(rowData?.amount)} euro`
                      : "Importo non reperibile"}
                  </span>
                </div>
              );
            },
            customSort: (dateA, dateB) =>
              new Date(`${dateA.createdAt}Z`) - new Date(`${dateB.createdAt}Z`),
            render: (rowData) =>
              rowData.createdAt
                ? onlyDateFormatter.format(new Date(`${rowData.createdAt}Z`))
                : "Data non disponibile",
          },
          {
            title: "Terapeuta",
            field: "terapeuta",
            sorting: false,
            render: (rowData) => nameAndSurname,
          },
          {
            title: "Allegato",
            field: "allegato",
            cellStyle: {
              wordBreak: "break-all",
              // width:"20%"
            },
            sorting: false,
            render: (rowData) => {
              return (
                <div className={classes.invoiceDiv}>
                  {!rowData?.documents?.length
                    ? "-"
                    : rowData?.documents?.map((doc) => {
                        return (
                          <div key={doc?.id} className={classes.invoiceItemDiv}>
                            <p className={classes.p_text}>{`${
                              doc?.filename || "Documento"
                            }`}</p>
                            <IconButton
                              onClick={() => {
                                downloadInvoice(doc);
                              }}
                            >
                              <GetAppIcon />
                            </IconButton>
                          </div>
                        );
                      })}
                </div>
              );
            },
          },
          {
            title: "Dettagli",
            field: "dettagli",
            cellStyle: {
              width:"10%"
            },
            sorting: false,
            render: (rowData) => (
              <Button
                disabled={!rowData?.sessions?.length}
                classes={{ text: classes.summaryBtn, root: classes.btn }}
                onClick={() => {
                  openSessionsSummary(rowData);
                }}
              >
                Riepilogo
              </Button>
            ),
          },
          {
            title: "Importo",
            field: "importo",
            customSort: (a, b) => a.amount - b.amount,
            sorting: false,
            render: (rowData) =>
              rowData?.amount
                ? `${eurCurrency(rowData?.amount)} euro`
                : "Importo non reperibile",
          },
          {
            sorting: false,
            render: (rowData) => {
              return (
                <Button
                  classes={{ root: classes.actionBtn }}
                  disabled={!rowData.amount} //if is 0 or undefined
                  onClick={() => {
                    payNow(rowData);
                  }}
                  variant="contained"
                  color="primary"
                  startIcon={<AccountBalanceWalletIcon />}
                >
                  PAGA ORA
                </Button>
              );
            },
          },
        ]}
        actions={[
          {
            icon: () => <Refresh style={{ color: "currentColor" }} />,
            tooltip: "Aggiorna",
            isFreeAction: true,
            onClick: updatePaymentList,
          },
        ]}
        data={payment}
        options={{
          search: false,
          actionsColumnIndex: -1,
          padding: "dense",
          sorting: true,
          pageSize: 10,
          thirdSortClick: false,
          draggable: false,
          orderDirection: order.orderDirection,
          onOrderChange: handleOrderChange,
        }}
        localization={tableLocalization}
      />
      <SessionsPaymentPopup
        open={isSessionsPopupOpen}
        sessionPaymentData={sessionPaymentData}
        onClose={() => {
          setIsSessionsPopupOpen(false);
          setSessionPaymentData({});
        }}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  invoiceDiv: {
    display: "flex",
    flexDirection: "column",
  },
  invoiceItemDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  summaryBtn: {
    textTransform: "none",
    textDecoration: "underline",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
    },
  },
  btn: {
    padding: "0rem",
  },
  p_text: {
    margin: "0rem",
  },
  actionBtn: {
    whiteSpace: "nowrap",
  },
  container: { paddingTop: "1rem" },
  tabsTitle: {
    fontWeight: 600,
    fontSize: "16px",
  },
}));

export default PaymentToPay;
