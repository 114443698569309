import { SvgIcon } from '@material-ui/core';
import React from 'react';

const FillIcon = props => {
    return (
        <SvgIcon {...props}>
            <path d="M2.905 2.10501L4.195 0.815012L14.635 11.255C15.095 11.715 15.325 12.28 15.325 12.95C15.325 13.62 15.095 14.185 14.635 14.645L9.295 19.985C8.83498 20.445 8.29498 20.675 7.675 20.675C7.05502 20.675 6.51502 20.445 6.055 19.985L0.715 14.645C0.254998 14.185 0.0250001 13.62 0.0250001 12.95C0.0250001 12.28 0.254998 11.715 0.715 11.255L6.385 5.585L2.905 2.10501ZM7.675 6.875L1.705 12.845H13.645L7.675 6.875ZM17.815 21.185C17.215 21.185 16.705 20.975 16.285 20.555C15.865 20.135 15.655 19.625 15.655 19.025C15.655 18.685 15.735 18.315 15.895 17.915C16.055 17.515 16.275 17.105 16.555 16.685C16.715 16.425 16.91 16.145 17.14 15.845C17.37 15.545 17.595 15.265 17.815 15.005C18.035 15.265 18.26 15.545 18.49 15.845C18.72 16.145 18.915 16.425 19.075 16.685C19.355 17.105 19.575 17.515 19.735 17.915C19.895 18.315 19.975 18.685 19.975 19.025C19.975 19.625 19.765 20.135 19.345 20.555C18.925 20.975 18.415 21.185 17.815 21.185Z" fill="currentcolor" />
        </SvgIcon>
    );
};
export default FillIcon;