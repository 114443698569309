import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  ArrowRightAlt,
  CheckBoxOutlineBlankSharp,
  CheckCircleOutline,
  DeleteOutlined,
  RadioButtonUnchecked,
  Redo,
  Undo,
  ZoomIn,
  ZoomOut,
} from "@material-ui/icons";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { SketchField, Tools } from "react-sketch-3.0";
import { useGlobalSnackbar } from "../contexts/GlobalSnackbarContext";
import { loadImageAsDataUrl } from "../utils/UtilsFunction";
import GenogramMenu from "./GenogramMenu";
import AddImageIcon from "./icons/AddImageIcon";
import BrushIcon from "./icons/BrushIcon";
import ColorIcon from "./icons/ColorIcon";
import FillIcon from "./icons/FillIcon";
import GenogramIcon from "./icons/GenogramIcon";
import HelpIcon from "./icons/HelpIcon";
import LineIcon from "./icons/LineIcon";
import NavigationIcon from "./icons/NavigationIcon";
import PaletteIcon from "./icons/PaletteIcon";
import PanIcon from "./icons/PanIcon";
import Pencil from "./icons/Pencil";
import RubberIcon from "./icons/RubberIcon";
import SelectionIcon from "./icons/SelectionIcon";
import SettingsIcon from "./icons/SettingsIcon";
import ShapesIcon from "./icons/ShapesIcon";
import TextIcon from "./icons/TextIcon";
import WhiteboardButton from "./WhiteboardButton";
import WhiteboardGuide from "./WhiteboardGuide";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    width: "100%",
    backgroundColor: "white",
    position: "relative",
    color: "black",
  },
  topExit: {
    backgroundColor: "white",
    boxShadow: "0 .5rem 1rem 0 rgb(0, 0, 0, .15)",
    borderRadius: "5rem",
    maxWidth: "max-content",
  },
  exitButton: {
    width: "100%",
    height: "100%",
    background: "transparent",
    border: 0,
    padding: "0.75rem",
    cursor: "pointer",
  },
  topActionsWrapper: {
    marginTop: "1rem",
    gap: "0.5rem",
    display: "flex",
    position: "absolute",
    left: "50%",
    top: 0,
    transform: "translateX(-50%)",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  actionsContainer: {
    position: "absolute",
    left: 0,
    top: 0,
    transform: "unset",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "min-content 1fr",
  },
  actions: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    paddingBlock: ".5rem",
    boxShadow: ".5rem 0 1rem 0 rgb(0, 0, 0, .15)",
  },
  colorGrid: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "fit-content",
    "& > *": {
      width: "1.5rem",
      height: "1.5rem",
      borderRadius: "50%",
      margin: ".2rem",
      boxShadow: "0 0 .3rem 0 rgba(0,0,0,.15)",
      cursor: "pointer",
    },
  },
  topTextIcon: {
    fontSize: "1rem",
    color: "#00cc00",
    paddingRight: ".2rem",
  },
  topWrapper: {
    display: "flex",
    fontSize: ".8rem",
    alignItems: "center",
  },
  highlighted: {
    marginBottom: "3rem",
  },
  spacing: {
    marginTop: "auto",
  },
  hidden: {
    display: "none",
  },
}));

const colors = [
  "#FFFFFF",
  "#000000",
  "#FFC0CB",
  "#654321",
  "#FFFF00",
  "#0000FF",
  "#FFA500",
  "#ADD8E6",
  "#FF0000",
  "#00FF00",
];

const PsyWhiteboard = (
  {
    hidden,
    onCanvasUpdate,
    jsonCanvas,
    readOnly,
    className,
    onExit,
    hasExit = true,
  },
  ref
) => {
  const [tool, setTool] = useState(readOnly ? "Pan" : "Pencil");
  const [color, setColor] = useState("black");
  const [canRedo, setCanRedo] = useState(false);
  const [isOpenGuide, setIsOpenGuide] = useState(false);
  const [canUndo, setCanUndo] = useState(false);
  const [isEditingText, setIsEditingText] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isTooltipOpened, setIsTooltipOpened] = useState(false);
  const canvasRef = useRef();
  const styles = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const [, setGlobalSnackbar] = useGlobalSnackbar();

  useImperativeHandle(
    ref,
    () => ({
      getWhiteboardPng: () => canvasRef?.current?.toDataURL(),
      adapt: (size) => {
        const { width: myWidth, height: myHeight } =
          canvasRef?.current?._canvas?.getBoundingClientRect();
        const { width, height } = size;
        const xOff = (myWidth - width) / 2;
        const yOff = (myHeight - height) / 2;
        if (canvasRef?.current?._fc?.relativePan) {
          canvasRef.current._fc.relativePan({
            x: xOff,
            y: yOff,
          });
        }
      },
    }),
    []
  );

  const updateJson = useCallback(
    (e) => {
      if (
        Tools[tool] === Tools.Select &&
        !e?.transform?.actionPerformed &&
        !e.forceUpdate
      )
        return;
      const jsonVal = canvasRef.current.toJSON([
        "_controlsVisibility",
        "isGenogram",
        "strokeUniform",
        "grid",
        "skipFill",
        "skipStroke",
      ]);
      if (onCanvasUpdate) {
        onCanvasUpdate(jsonVal, e.skipValue);
      }
      setCanUndo(canvasRef?.current?.canUndo());
      setCanRedo(canvasRef?.current?.canRedo());
    },
    [tool, onCanvasUpdate, canvasRef]
  );

  const cancSelected = useCallback(
    (forceCanc) => {
      if (!canvasRef?.current || (!forceCanc && isEditingText)) return;
      canvasRef.current.removeSelected();
      setCanUndo(canvasRef?.current?.canUndo());
      setCanRedo(canvasRef?.current?.canRedo());
      updateJson({ forceUpdate: true });
    },
    [updateJson, isEditingText]
  );

  useEffect(() => {
    const cancListener = (e) => {
      if (e.key !== "Delete") return;
      cancSelected(false);
    };
    window.addEventListener("keyup", cancListener);
    return () => {
      window.removeEventListener("keyup", cancListener);
    };
  }, [cancSelected]);

  const setIsSubMenuOpenRef = useRef();

  const handleSubMenuOpen = useCallback((setIsSubMenuOpen) => {
    if (
      typeof setIsSubMenuOpenRef?.current === "function" &&
      setIsSubMenuOpenRef.current !== setIsSubMenuOpen
    ) {
      setIsSubMenuOpenRef.current(false);
    }
    setIsSubMenuOpenRef.current = setIsSubMenuOpen;
  }, []);

  return hidden ? null : (
    <div className={`${styles.container} ${className || ""}`}>
      <SketchField
        backgroundColor="#fff"
        height="100%"
        tool={Tools[tool]}
        ref={canvasRef}
        value={jsonCanvas}
        lineColor={color}
        lineWidth={3}
        forceValue={false}
        onMouseUp={updateJson}
        onTextEditingExit={() => {
          setIsEditingText(false);
          updateJson({ forceUpdate: true });
        }}
        onTextChanged={() => updateJson({ forceUpdate: true, skipValue: true })}
        onTextEditingEntered={() => {
          setIsEditingText(true);
        }}
        onSelection={() => setIsSelected(true)}
        onSelectionCleared={() => setIsSelected(false)}
      />
      {hasExit && (
        <div className={styles.topActionsWrapper}>
          <div className={styles.topExit}>
            <button
              dataPendo="individualsession_whiteboard_exitformwhiteboard"
              className={styles.exitButton}
              onClick={() => {
                if (typeof onExit === "function") onExit();
              }}
            >
              Esci dalla lavagna
            </button>
          </div>
          {matches ? (
            <div className={styles.topWrapper}>
              <CheckCircleOutline className={styles.topTextIcon} />
              <i> La lavagna viene salvata ad ogni modifica.</i>
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      {!readOnly && (
        <>
          <div className={styles.actionsContainer}>
            <div className={styles.actions}>
              <WhiteboardButton
                onSubMenuOpen={handleSubMenuOpen}
                size="small"
                description="Geno&shy;gramma"
                Icon={GenogramIcon}
                subMenu="right"
                subMenuRender={(setIsSubMenuOpen) => (
                  <GenogramMenu
                    onSelect={(selectedGenogram) => {
                      canvasRef.current.addGenogram(selectedGenogram, 30);
                      updateJson({ forceUpdate: true, skipValue: true });
                      setIsSubMenuOpen(false);
                      setTool("Select");
                    }}
                  />
                )}
              />
              <WhiteboardButton
                onSubMenuOpen={handleSubMenuOpen}
                description="Naviga"
                size="small"
                Icon={NavigationIcon}
                subMenu="right"
                subMenuRender={(setIsSubMenuOpen) => (
                  <div>
                    <WhiteboardButton
                      description="Seleziona"
                      active={tool === "Select"}
                      size="small"
                      Icon={SelectionIcon}
                      onClick={() => {
                        setTool("Select");
                        setIsSubMenuOpen(false);
                      }}
                    />
                    <WhiteboardButton
                      description="Sposta"
                      active={tool === "Pan"}
                      size="small"
                      Icon={PanIcon}
                      onClick={() => {
                        setTool("Pan");
                        setIsSubMenuOpen(false);
                      }}
                    />
                  </div>
                )}
              />
              <WhiteboardButton
                onSubMenuOpen={handleSubMenuOpen}
                description="Strumenti"
                size="small"
                Icon={Pencil}
                subMenu="right"
                subMenuRender={(setIsSubMenuOpen) => (
                  <div>
                    <WhiteboardButton
                      active={tool === "Pencil"}
                      description="Disegna"
                      size="small"
                      Icon={BrushIcon}
                      onClick={() => {
                        setTool("Pencil");
                        setIsSubMenuOpen(false);
                      }}
                    />
                    <Tooltip
                      open={isTooltipOpened}
                      title="Seleziona prima un elemento per cancellarlo"
                    >
                      <span
                        onPointerEnter={() => {
                          if (!isSelected) setIsTooltipOpened(true);
                        }}
                        onPointerLeave={() => {
                          if (!isSelected) setIsTooltipOpened(false);
                        }}
                      >
                        <WhiteboardButton
                          disabled={!isSelected}
                          description="Gomma"
                          size="small"
                          Icon={RubberIcon}
                          onClick={() => {
                            cancSelected(true);
                            setIsSubMenuOpen(false);
                          }}
                        />
                      </span>
                    </Tooltip>
                  </div>
                )}
              />
              <WhiteboardButton
                onSubMenuOpen={handleSubMenuOpen}
                description="Forme"
                size="small"
                Icon={ShapesIcon}
                subMenu="right"
                subMenuRender={(setIsSubMenuOpen) => (
                  <div>
                    <WhiteboardButton
                      description="Rettangolo"
                      active={tool === "Rectangle"}
                      size="small"
                      Icon={CheckBoxOutlineBlankSharp}
                      onClick={() => {
                        setTool("Rectangle");
                        setIsSubMenuOpen(false);
                      }}
                    />
                    <WhiteboardButton
                      description="Cerchio"
                      active={tool === "Circle"}
                      size="small"
                      Icon={RadioButtonUnchecked}
                      onClick={() => {
                        setTool("Circle");
                        setIsSubMenuOpen(false);
                      }}
                    />
                    <WhiteboardButton
                      description="Freccia"
                      active={tool === "Arrow"}
                      size="small"
                      Icon={ArrowRightAlt}
                      onClick={() => {
                        setTool("Arrow");
                        setIsSubMenuOpen(false);
                      }}
                    />
                    <WhiteboardButton
                      description="Linea"
                      active={tool === "Line"}
                      size="small"
                      Icon={LineIcon}
                      onClick={() => {
                        setTool("Line");
                        setIsSubMenuOpen(false);
                      }}
                    />
                  </div>
                )}
              />
              <WhiteboardButton
                onSubMenuOpen={handleSubMenuOpen}
                size="small"
                description="Immagini"
                Icon={AddImageIcon}
                subMenu="right"
                subMenuRender={(setIsSubMenuOpen) => (
                  <List dense={true}>
                    <ListItem
                      button
                      onClick={async () => {
                        const image = await loadImageAsDataUrl(300);
                        setGlobalSnackbar({
                          open: true,
                          severity: "info",
                          message:
                            "L'inserimento delle immagini potrebbe rallentare la trasmissione della lavagna al tuo terapeuta.",
                        });
                        canvasRef.current.addImg(image);
                        setIsSubMenuOpen(false);
                        setTimeout(updateJson, 100, { forceUpdate: true });
                      }}
                    >
                      <ListItemText
                        disableTypography
                        style={{ fontSize: ".8rem" }}
                      >
                        Aggiungi da file
                      </ListItemText>
                    </ListItem>
                    <ListItem button disabled>
                      <ListItemText
                        disableTypography
                        style={{ fontSize: ".8rem" }}
                      >
                        Aggiungi da URL
                      </ListItemText>
                    </ListItem>
                  </List>
                )}
              />
              <WhiteboardButton
                description="Testo"
                size="small"
                Icon={TextIcon}
                onClick={() => {
                  if (!canvasRef?.current) return;
                  canvasRef.current.addText("Inserisci testo");
                  updateJson({ forceUpdate: true, skipValue: true });
                  setTool("Select");
                }}
              />
              <WhiteboardButton
                onSubMenuOpen={handleSubMenuOpen}
                description="Colore"
                size="small"
                Icon={PaletteIcon}
                ySubMenu="up"
                subMenu="right"
                subMenuRender={(setIsSubMenuOpen) => (
                  <div>
                    <WhiteboardButton
                      disableColor={false}
                      description="Colore"
                      colore={color}
                      size="small"
                      Icon={ColorIcon}
                      subMenu="right"
                      ySubMenu="up"
                      subMenuRender={(setIsSubMenuOpen) => (
                        <div className={styles.colorGrid}>
                          {colors.map((color) => (
                            <div
                              dataPendo={`individualsession_whiteboard_${color}`}
                              style={{
                                backgroundColor: color,
                                border: "1px solid lightgray",
                              }}
                              onClick={() => {
                                setColor(color);
                                canvasRef?.current &&
                                  canvasRef.current.setStroke(color);
                                updateJson({ forceUpdate: true });
                                setIsSubMenuOpen(false);
                              }}
                            ></div>
                          ))}
                        </div>
                      )}
                    />
                    <WhiteboardButton
                      description="Riempi"
                      size="small"
                      Icon={FillIcon}
                      active={tool === "Fill"}
                      onClick={() => {
                        setTool("Fill");
                        setIsSubMenuOpen(false);
                      }}
                    />
                  </div>
                )}
              />

              <WhiteboardButton
                onSubMenuOpen={handleSubMenuOpen}
                Spacing={styles.spacing}
                description="Opzioni"
                size="small"
                Icon={SettingsIcon}
                subMenu="right"
                ySubMenu="up"
                subMenuRender={(setIsSubMenuOpen) => (
                  <div>
                    <WhiteboardButton
                      description="Annulla"
                      size="small"
                      Icon={Undo}
                      disabled={false}
                      onClick={() => {
                        if (!canvasRef?.current?.canUndo()) return;
                        canvasRef?.current && canvasRef.current.undo();
                        setCanUndo(canvasRef?.current?.canUndo());
                        setCanRedo(canvasRef?.current?.canRedo());
                        updateJson({ forceUpdate: true, skipValue: true });
                      }}
                    />
                    <WhiteboardButton
                      description="Cancella"
                      size="small"
                      Icon={DeleteOutlined}
                      onClick={() => {
                        canvasRef?.current && canvasRef.current.clear();
                        setCanUndo(canvasRef?.current?.canUndo());
                        setCanRedo(canvasRef?.current?.canRedo());
                        updateJson({ forceUpdate: true });
                      }}
                    />
                    <WhiteboardButton
                      description={
                        <>
                          Zoom
                          <br /> indietro
                        </>
                      }
                      size="small"
                      Icon={ZoomOut}
                      onClick={() => {
                        canvasRef?.current && canvasRef.current.zoom(0.9);
                      }}
                    />
                    <WhiteboardButton
                      description={
                        <>
                          Zoom
                          <br /> avanti
                        </>
                      }
                      size="small"
                      Icon={ZoomIn}
                      onClick={() => {
                        canvasRef?.current && canvasRef.current.zoom(1.1);
                      }}
                    />
                  </div>
                )}
              />
              <WhiteboardButton
                description="Aiuto"
                size="small"
                Icon={HelpIcon}
                onClick={() => {
                  setIsOpenGuide(true);
                }}
              />
            </div>
          </div>
          <WhiteboardGuide
            open={isOpenGuide}
            close={() => setIsOpenGuide(false)}
          />
        </>
      )}
    </div>
  );
};
export default forwardRef(PsyWhiteboard);
