import useLocalStorage from './useLocalStorage.js';
import { useEffect } from 'react';

const mobileAndTabletCheck = function () {
    let check = false;
    (function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
                a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
};

const oldBrowserInfo = () => {
    //get the user agent
    const ua = navigator.userAgent;
    let tem;
    //match the user agent against the regoular expression
    let M =
        ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
        [];
    //test the first match to see if it's trident
    if (/trident/i.test(M[1])) {
        //find the version inside the user agent
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        //return IE as the browser name and the just discovered version
        return { name: 'IE', version: tem[1] || '' };
    }
    //if the match is Chrome
    if (M[1] === 'Chrome') {
        //check if the user agent has OPR or Edge inside followed by the version number
        tem = ua.match(/\bOPR|Edge\/(\d+)/);
        //if there is a match return Opera with the just discovered version
        if (tem != null) {
            return { name: 'Opera', version: tem[1] };
        }
    }
    //if there's a second match this means the user agent already have a version
    //otherwise we found the app name and the appversion inside the navigator object
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
    }
    const isIOS =
        /iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    if (ua.indexOf('CriOS') !== -1) {
        M[0] = 'Chrome';
    }
    const isTablet =
        /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
            ua
        );
    const exceptions = ['SamsungBrowser', 'MiuiBrowser', 'WebView'];
    const isException = exceptions.some((elem) => new RegExp(elem, 'i').test(ua));
    return {
        name: M[0],
        version: M[1],
        mobile: mobileAndTabletCheck(),
        isIOS,
        isTablet,
        isException,
    };
};

const STATIC_BROWSER_INFO = oldBrowserInfo();

const useBrowserInfo = () => {
    const [browserInfo, setBrowserInfo] = useLocalStorage('browser-info', STATIC_BROWSER_INFO);
    useEffect(() => {
        //we check if there's a browser info already saved in the local storage and if it's the complete version
        if (browserInfo && browserInfo.complete) return;
        //creating the abort controller to abort the fetch operation in case of unmount
        const abortController = new AbortController();
        (async () => {
            //initialize the old browser info
            let oldBi = oldBrowserInfo();
            //get the signal
            const signal = abortController.signal;
            //do the fetch to whatismybrowser to get a better user agent parse
            let { parse: newBi, result } = await fetch('api/browserInfo', { signal }).then((res) => res.json());
            //if the resultcode is successful
            if (result?.code === 'success') {
                //we update the name and the version and we set the complete attribute to true
                oldBi.name = newBi?.software_name;
                oldBi.version = newBi?.software_version;
                oldBi.complete = true;
                //we merge the old and the new browser info
                oldBi = { ...oldBi, ...newBi };
            }
            //we set the browser info
            setBrowserInfo(oldBi);
        })();
        return () => {
            //aborting the fetch in case of unmount
            abortController.abort();
        };
    }, []);
    return browserInfo;
};

export default useBrowserInfo;