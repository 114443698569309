
import { SvgIcon } from '@material-ui/core';
import React from 'react';
const NavigationIcon = props => {
    return (
        <SvgIcon {...props} >
            <path d="M18.9288 7.73855L17.8681 8.79919L20.3001 11.2312H12.7688V3.69994L15.2008 6.13187L16.2615 5.07127L12.0188 0.828568L7.77619 5.07127L8.83688 6.13187L11.2688 3.69994V11.2312H3.69994L6.13191 8.79919L5.07127 7.73855L0.828613 11.9812L5.07122 16.2238L6.13191 15.1631L3.69989 12.7312H11.2688V20.3001L8.83688 17.8682L7.77619 18.9287L12.0188 23.1714L16.2615 18.9287L15.2008 17.8682L12.7688 20.3001V12.7312H20.3001L17.8681 15.1631L18.9288 16.2238L23.1714 11.9812L18.9288 7.73855V7.73855Z" fill="currentColor" />
        </SvgIcon>
    );
};
export default NavigationIcon;
