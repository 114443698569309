import { useState } from 'react';

function useLocalStorage(key, initialValue) {
    //state to store the value
    //pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(() => {
        try {
            //get from local storage by key
            const item = window.localStorage.getItem(key);
            //parse stored json or if none return initialValue
            if (!item) {
                const valueToStore = initialValue instanceof Function ? initialValue() : initialValue;
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
                return initialValue;
            }
            return JSON.parse(item);
        } catch (error) {
            //if there's an error getting the key set the value and return the initial value
            const valueToStore = initialValue instanceof Function ? initialValue() : initialValue;
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
            return initialValue;
        }
    });

    //Return a wrapped version of useState's setter function that
    //persists the new value to localStorage.
    const setValue = value => {
        try {
            //value can be a function (to have the same API as setState), in that case
            //we call the function to store the value
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            //save the value 
            setStoredValue(valueToStore);
            //save to local storage
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) { }
    };

    return [storedValue, setValue];
}

export default useLocalStorage;