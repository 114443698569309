import { useEffect, useRef, useState } from "react";
import { useUser, useUserProfile } from "../contexts/UserContext";
import useApi from "./useApi";

const useAnonUser = (nanoId) => {
    const loggedUser = useUser();
    const api = useApi();
    const [error, setError] = useState(false);
    const [user, setUser] = useState(loggedUser);
    const loggedUserProfile = useUserProfile();
    const [userProfile, setUserProfile] = useState(loggedUserProfile);
    const [sessionIds, setSessionIds] = useState();
    const isAnon = useRef();
    const getSessionIds = () => {
        return fetch(`/api/sessions/ids/${nanoId}`, {
            headers: {
                "content-type": "application/json",
            }
        }).then(res => {
            if (res.ok === false) {
                return {
                    ok: false,
                };
            }
            try {
                return res.json();
            } catch (e) {
                return {
                    ok: false,
                };
            }
        });
    };

    useEffect(() => {
        if (user) {
            getSessionIds().then(ids => {
                if (ids.ok === false) {
                    setError(true);
                    return;
                }
                setSessionIds(ids);
            });
            return;
        }
        (async () => {
            const token = await fetch("/api/anontoken", {
                headers: {
                    "content-type": "application/json",
                }
            }).then(res => res.text());
            const ids = await getSessionIds();
            if (ids.ok === false) {
                setError(true);
                return;
            }
            setSessionIds(ids);
            isAnon.current = true;
            const localUser = {
                access_token: token,
                profile: {
                    sub: ids.idPatient,
                }
            };
            let res = await api(localUser, `patients/${localUser.profile.sub}`, "GET");
            if (res.ok !== false) {
                setUserProfile({
                    isCompleted: res?.isCompleted,
                    idTherapist: res?.idTherapist,
                    therapistName: res?.therapistFirstName,
                    therapistLastName: res?.therapistLastName,
                    firstName: res?.name,
                    lastName: res?.lastName
                });
                localUser.profile.email = res.email;
                setUser(localUser);
            }
        })();
    }, [nanoId]);

    return { user, userProfile, error, sessionIds, isAnon };
};

export { useAnonUser };