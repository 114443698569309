import { makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
    container: {
        height: "85%",
        width: "100%",
        display: "flex",
        background: "white",
        "& > div": {
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }
    },
    image: {
        width: 'min(35vw, 15rem)',
        height: '100%',
        objectFit: 'contain',
    },
    message: {
        textAlign: "center",
        fontSize: "1.3rem",
        padding: "1rem",
    },
    hidden: {
        display: "none"
    }
}));

const VideoPoster = props => {

    const styles = useStyles();

    return (
        <div style={{ ...props.style }} className={`${styles.container} ${props.hidden ? styles.hidden : ""}`}>
            <div>
                <img className={styles.image} src="/logo_transparent.png" />
                <span className={styles.message}>
                    {props.message || <>In attesa che {props.name} {props.lastname} entri nella stanza virtuale.</>}
                </span>
            </div>
        </div >
    );
};
export default VideoPoster;