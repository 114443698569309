import { makeStyles } from "@material-ui/core";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";

const pill = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjE2MCIgaGVpZ2h0PSI0MCIgdmlld0JveD0iMCAwIDE2IDQiPg0KICA8cmVjdCB4PSIxIiB5PSIxIiB3aWR0aD0iMTQiIGhlaWdodD0iMiIgcnk9IjEiIHJ4PSIxIi8+DQo8L3N2Zz4=";

const useStyles = makeStyles((theme) => ({
    progressBar: ({ bars, width, height, percentage, color, progressColor }) => ({
        width: width,
        height: height,
        background: `linear-gradient(90deg, ${progressColor || "black"} ${(percentage || 0) * 100
            }%, ${color || "transparent"} ${(percentage || 0) * 100}%)`,
        mask: `url(${pill})`,
        maskSize: `${100 / (bars || 3)}% 100%`
    })
}));

const min = 0;
const max = 100;


const MicLevels = ({ bars, width, height, stream, color, progressColor }, ref) => {
    const [value, setValue] = useState(0);
    const [micTestInterval, setMicTestInterval] = useState();

    const handleMicVolume = useCallback((stream) => {
        clearInterval(micTestInterval);
        const audioContext = new AudioContext();
        const audioSource = audioContext.createMediaStreamSource(stream);
        const analyser = audioContext.createAnalyser();
        analyser.fftSize = 512;
        analyser.minDecibels = -127;
        analyser.maxDecibels = 0;
        analyser.smoothingTimeConstant = 0.4;
        audioSource.connect(analyser);
        const volumes = new Uint8Array(analyser.frequencyBinCount);
        const volumeCallback = () => {
            analyser.getByteFrequencyData(volumes);
            let volumeSum = 0;
            for (const volume of volumes) volumeSum += volume;
            const averageVolume = volumeSum / volumes.length;
            // Value range: 127 = analyser.maxDecibels - analyser.minDecibels;
            analyser.smoothingTimeConstant = 0.8;
            analyser.fftSize = 1024;
            setValue(averageVolume);
        };
        setMicTestInterval(setInterval(volumeCallback, 10));
    }, [micTestInterval]);

    const start = useCallback(() => {
        if (!stream) return;
        handleMicVolume(stream);
    }, [stream, handleMicVolume]);

    const stop = useCallback(() => {
        clearInterval(micTestInterval);
        setValue(0);
    }, [micTestInterval]);

    useEffect(() => {
        stop();
    }, [stream]);

    useImperativeHandle(ref, () => ({
        start,
        stop,
    }), [start, stop]);

    const percentage = useMemo(
        () => Math.max(Math.min((value - min) / (max - min), 1), 0),
        [value]
    );
    const styles = useStyles({
        bars,
        width,
        height,
        percentage,
        color,
        progressColor
    });
    return <div className={styles.progressBar}></div>;
};

export default forwardRef(MicLevels);
