import { makeStyles, Box, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Close, Height } from '@material-ui/icons';
import MaterialTable, { MTableToolbar, MTableHeader, MTableBody } from 'material-table';
import React from 'react';



const PsyTable = props => {
    const styles = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const desktop = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <div className={`${styles.materialTBContainer} ${(props && props.options && props.options.search === false ) ? styles.materialTBContainerNoSearch : null}`}>
            {
                //Show externalTitle if resolution is lower than 'sm' breakpoint (mobile)
                matches &&
                <Box className={styles.mobileTitle}>
                    <h6 className={styles.titleTable} >{props.title}</h6>
                </Box>
            }
            <MaterialTable
                {...props}
                //Show internal title of MaterialTable if resolution is greater then 'sm' breakpoint
                title={!matches && ((typeof props.title === 'function') ?
                    props.title
                    :
                    <h6 className={styles.titleTable} >{props.title}</h6>)
                }

                classes={{
                    paginationRoot: styles.paginationRoot
                }}
                style={{
                    ...props.style,
                    borderTopLeftRadius: "1rem",
                    borderTopRightRadius: "1rem",
                    background: 'transparent',
                    boxShadow: 'none',
                }}
                columns={
                    props?.columns.map(col => ({
                        ...col,
                        title: desktop ? col.title : (col.mobileTitle === undefined ? col.title : col.mobileTitle),
                        hidden: !desktop && !col.showOnMobile,
                        render: desktop ? col.render : (col.mobileRender || col.render)
                    })
                    )
                }
                components={{
                    ...props.components,
                    Toolbar: toolbarProps => (
                        <>
                            <MTableToolbar
                                {...toolbarProps}
                                classes={
                                    {
                                        root: styles.root,
                                        searchField: styles.searchField,
                                        actions: `${styles.actionToolbar} ${(props.options && props.options.search === false) ? styles.actionToolbarWithoutSearchField : null}`
                                    }
                                }
                                searchFieldStyle={{ color: "white" }}
                            />
                        </>
                    ),
                    Header: propsHeader => <MTableHeader {...propsHeader} classes={{
                        header: styles.header,
                    }} />,
                }}
                options={{
                    ...props.options,
                    rowStyle: (rowData, index) => {
                        if (index % 2) {
                            return { backgroundColor: '#F3F3F3' }
                        }
                        return { backgroundColor: 'white' }
                    },
                    headerStyle: {
                        paddingTop: '15px',
                        paddingBottom: '15px',
                        backgroundColor: '#00858B',
                        color: 'white',
                        fontSize: '1.125rem',
                        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.25)',
                    }
                }}
                actions={
                    props.actions
                }
                icons={{
                    ...props.icons,
                    ResetSearch: () => <Close style={{ color: 'white' }} />
                }}
                localization={{
                    ...props.localization,
                    pagination: {
                        labelDisplayedRows: "{from}-{to} di {count}",
                        firstTooltip: "Prima pagina",
                        lastTooltip: "Ultima pagina",
                        nextTooltip: "Successiva",
                        previousTooltip: "Precedente",
                        labelRowsSelect: 'Righe',
                        labelRowsPerPage: 'Righe per pagina',

                    }
                }}
                detailPanel={
                    (!desktop && props?.columns.filter(e => !e.showOnMobile && !e.hideInDetailPanel).length >= 1) ?
                        rowData => {
                            return (
                                props?.columns.filter(e => !e.showOnMobile && !e.hideInDetailPanel).map(e => {
                                    return (
                                        <div className={styles.detailPanelItem}>
                                            <span className={styles.detailPaneTitleText}>{`${e?.title ? `${e?.title}:` : "" }`}</span>
                                            {
                                                e.render ?
                                                    e.render(rowData)
                                                    :
                                                    rowData[e.field]
                                            }
                                        </div>
                                    )
                                })
                            );
                        }
                        :
                        null
                }
            />
        </div>
    )
};

const useStyles = makeStyles(theme => ({
    materialTBContainer: {
        width: '100%',
        //First header of column
        '& .MuiTableCell-root.MuiTableCell-head:first-child': {
            borderTopLeftRadius: '1rem'
        },
        '& .MuiTable-root': {
            backgroundColor: 'white',
            borderTopLeftRadius: '1rem'
        }
    },
    materialTBContainerNoSearch: {
        '& .MuiTableCell-root.MuiTableCell-head:last-child': {
            borderTopRightRadius: '1rem'
        },
    },
    mobileTitle: {
        width: '100%',
    },
    titleTable: {
        fontSize: '1.25rem',
        color: 'black',
        marginBottom: '0px',
        marginTop: '0px',
        marginBottom: '10px',
        fontWeight: '400',
    },
    root: {
        background: 'transparent',
        borderTopLeftRadius: "1rem",
        borderTopRightRadius: "1rem",
        color: "white",
        alignItems: 'flex-end',
        paddingRight: '0px',
        paddingLeft: '0px',
        [theme.breakpoints.down('sm')]: {
            borderTopRightRadius: "0rem",
            //marginTop: '3rem'
        },
    },
    searchField: {
        background: theme.palette.primary.main,
        width: '18.75rem',
        padding: ".5rem",
        borderTopLeftRadius: "1rem",
        underline: 'white',
        "&::placeholder": {
            color: "white"
        },
        '& .MuiInput-underline::before': {
            borderColor: 'white !important'
        },
        '& .MuiInput-underline::after': {
            borderColor: 'white !important'
        }
    },
    underline: {
        color: "white"
    },
    header: {
        '& .MuiTableSortLabel-active': {
            color: 'white'
        },
        '& .MuiTableSortLabel-root:hover': {
            color: 'white'
        },
        '& .MuiTableSortLabel-icon': {
            color: 'white !important'
        }
    },
    paginationRoot: {
        display: 'flex',
        backgroundColor: 'white',
        float: 'unset !important',
        '& .MuiTablePagination-toolbar': {
            justifyContent: 'center'
        },
        '& .MuiTablePagination-toolbar .MuiTablePagination-spacer': {
            flex: 0
        },
        '& .MuiTypography-caption': {
            flexShrink: '0 !important',
            flexGrow: '0 !important'
        }
    },
    actionToolbar: {
        borderRadius: '0px',
        backgroundColor: theme.palette.primary.main,
        borderTopRightRadius: '1rem',
    },
    actionToolbarWithoutSearchField: {
        backgroundColor: "unset",
        color: "black",
    },
    detailPanelItem: {
        paddingLeft: '1rem',
        width: '100%',
        margin: '1rem 0rem',
        display: 'flex',
        flexDirection: 'column'
    },
    detailPaneTitleText: {
        fontWeight: 600,

    },
}))


export default PsyTable;