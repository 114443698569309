
import { SvgIcon } from '@material-ui/core';
import React from 'react';
const GravidanzaIcon = props => {
        return (
                <SvgIcon {...props}>
                        <mask id="path-1-inside-1" fill="white">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0164 10.866L12.0164 2H11.0164L11.0164 10.8483L4.51123 22H18.5113L12.0164 10.866Z" />
                        </mask>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0164 10.866L12.0164 2H11.0164L11.0164 10.8483L4.51123 22H18.5113L12.0164 10.866Z" fill="white" />
                        <path d="M12.0164 2L13.0164 2V1H12.0164V2ZM12.0164 10.866H11.0164V11.1364L11.1526 11.3699L12.0164 10.866ZM11.0164 2V1H10.0164V2L11.0164 2ZM11.0164 10.8483L11.8802 11.3521L12.0164 11.1186V10.8483H11.0164ZM4.51123 22L3.64745 21.4961L2.77019 23H4.51123V22ZM18.5113 22V23H20.2523L19.375 21.4961L18.5113 22ZM11.0164 2L11.0164 10.866H13.0164V2L11.0164 2ZM11.0164 3H12.0164V1H11.0164V3ZM12.0164 10.8483L12.0164 2L10.0164 2L10.0164 10.8483H12.0164ZM5.37501 22.5039L11.8802 11.3521L10.1526 10.3444L3.64745 21.4961L5.37501 22.5039ZM18.5113 21H4.51123V23H18.5113V21ZM11.1526 11.3699L17.6475 22.5039L19.375 21.4961L12.8802 10.3622L11.1526 11.3699Z" mask="url(#path-1-inside-1)" />
                </SvgIcon>
        )
};
export default GravidanzaIcon;
