import { SvgIcon } from '@material-ui/core';
import React from 'react';

const AdaptedViewIcon = props => {
    return (
        <SvgIcon {...props}>
            <rect x="15.00006" y="15" width="6" height="4" fill="white" id="svg_2" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="m20.99996,5l-18.00002,0l0,8l0.00018,0l0,6l10.00004,0l0,-6l7.9998,0l0,-8z" fill="white" id="svg_3" />
        </SvgIcon>
    );
};
export default AdaptedViewIcon;