import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import ReplyIcon from "@material-ui/icons/Reply";
import SaveIcon from "@material-ui/icons/Save";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { PhoneField } from "../components";
import DateField from "../components/DateField";
import {
  useSetUserProfile,
  useUser,
  useUserProfile,
} from "../contexts/UserContext";
import useApi from "../hooks/useApi";
import {
  formatDate,
  objectFromForm,
  reformatDate,
  validations,
} from "../utils/UtilsFunction";
import { useHistory } from "react-router";
import { Alert, AlertTitle } from "@material-ui/lab";
import WarningIcon from "@material-ui/icons/Warning";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "1rem",
  },
  profilePicture: {
    width: 200,
    height: 200,
    margin: "auto",
    display: "block",
    borderRadius: "50%",
  },
  imageButton: {
    position: "relative",

    "&:hover::after": {
      content: "'Carica immagine'",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0,0,0,.2)",
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "white",
      fontSize: "1.2em",
      fontWeight: "900",
      borderRadius: "50%",
    },
  },
  formBox: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: ".3rem",
    margin: "1em 0",
    width: "100%",
    padding: "1em",
    position: "relative",

    "&::before": {
      content: "attr(data-title)",
      position: "absolute",
      left: "1em",
      backgroundColor: "white",
      padding: "0 1em",
      transform: "translateY(calc(-50% - 1em))",
      fontStyle: "italic",
    },
  },
  linkButton: {
    fontSize: "0.75rem",
    textDecoration: "underline",
    cursor: "pointer",
    color: "#000AFF",
    lineHeight: 1,
  },
  alertContainer: {
    color: "black",
    backgroundColor: "#FDE5D7",
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  alertTitle: {
    fontSize: ".75rem",
    margin: 0,
    lineHeight: 1,
  },
}));

//method to dinamically validate every required field in a form plus the special validations in the validations object
function validateRequired(form) {
  let retval = {};
  for (let input of form) {
    retval[input.name] = !(input.required && !input.value.trim())
      ? ""
      : "Campo obbligatorio";
    if (retval[input.name] == "") {
      //for the phone i need to send the phone prefix together with the number for the validation process
      if (input.name !== "phoneNumber") {
        retval[input.name] = validations[input.name]
          ? validations[input.name](
              input.type !== "file" ? input.value : input.files
            )
          : "";
      } else {
        retval.phoneNumber = validations.phoneNumber(
          `+${form["prefix"].value}${form["phoneNumber"].value}`
        );
      }
    }
  }
  return retval;
}

const Profilo_DatiGenerali = (props) => {
  const [editing, setEditing] = useState(false);
  const [formDatiAnagraficiErrors, setFormDatiAnagraficiErrors] = useState({});
  const { userProfile } = props;
  const [user, setUser] = useState(null);
  const [defaultSelect, setDefaultSelect] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMarketingChecked, setIsMarketingChecked] = useState(false);
  const datePickerRef = useRef();
  const api = useApi();
  const userGlobal = useUser();
  const setUserProfile = useSetUserProfile();
  const globalProfile = useUserProfile();
  const history = useHistory();

  useEffect(() => {
    setIsMarketingChecked(userProfile?.isMarketingAccepted);
    setUser(userProfile);
  }, [userProfile]);

  //method to reset the selects in the form
  const resetSelect = useCallback(async () => {
    setDefaultSelect((prev) => ({
      ...prev,
      gender: user?.gender,
    }));
    setFormDatiAnagraficiErrors({});
  }, [user, defaultSelect]);

  //method to init the default select values
  const initDefaultSelect = useCallback(async () => {
    setDefaultSelect({
      gender: user?.gender,
    });
  }, [user]);

  useEffect(() => {
    async function initAsync() {
      initDefaultSelect();
      setLoading(false);
    }
    if (user) {
      initAsync();
    }
  }, [user]);

  const toggleEdit = () => {
    setEditing(!editing);
  };

  //method to reset the form without saving
  const back = useCallback(
    async (e) => {
      if (window.confirm("Vuoi davvero uscire senza salvare?")) {
        document.forms["datiAnagraficiForm"].reset();
        datePickerRef.current.reset();
        resetSelect();
        setIsMarketingChecked(userProfile?.isMarketingAccepted);
        setEditing(false);
      }
    },
    [resetSelect, datePickerRef]
  );

  //method to validate the form and eventually do the call to update the user information
  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateRequired(document.forms["datiAnagraficiForm"]);
    if (Object.values(errors).some((x) => x != "")) {
      setFormDatiAnagraficiErrors(errors);
    } else {
      setFormDatiAnagraficiErrors({});
      if (userGlobal) {
        setLoading(true);
        const datiAnagrafici = "datiAnagraficiForm";
        const formObj = objectFromForm(document.forms[datiAnagrafici]);
        const patientObj = {
          idPatient: userGlobal.profile.sub,
          idTherapist: user?.idTherapist,
          email: userGlobal.profile.email,
          name: formObj[`${datiAnagrafici}-firstName`],
          lastName: formObj[`${datiAnagrafici}-lastName`],
          fiscalCode: formObj[`${datiAnagrafici}-fiscalCode`],
          birthdate: reformatDate(formObj[`${datiAnagrafici}-birthdate`]),
          gender: formObj[`${datiAnagrafici}-gender`],
          phoneNumber: formObj[`${datiAnagrafici}-phoneNumber`],
          phonePrefix: formObj[`${datiAnagrafici}-prefix`],
          isMarketingAccepted: isMarketingChecked,
          addresses: [
            {
              countryCode: formObj[`${datiAnagrafici}-stato`],
              region: formObj[`${datiAnagrafici}-regione`],
              district: formObj[`${datiAnagrafici}-provincia`],
              city: formObj[`${datiAnagrafici}-comune`],
              zipCode: formObj[`${datiAnagrafici}-CAP`],
              type: "birthplace",
            },
            {
              region: formObj[`${datiAnagrafici}-regioneResidenza`],
              district: formObj[`${datiAnagrafici}-provinciaResidenza`],
              city: formObj[`${datiAnagrafici}-comuneResidenza`],
              zipCode: formObj[`${datiAnagrafici}-CAPresidenza`],
              addressLine: formObj[`${datiAnagrafici}-via`],
              number: formObj[`${datiAnagrafici}-numeroCivico`],
              type: "residenceplace",
            },
          ],
        };
        const response = await api(
          userGlobal,
          `patients/${userGlobal.profile.sub}`,
          "PUT",
          patientObj
        );
        if (response.ok !== false) {
          setUser(patientObj);
          setEditing(false);
          setUserProfile((prev) => ({
            ...prev,
            firstName: patientObj.name,
            lastName: patientObj.lastName,
          }));
        } else {
          setFormDatiAnagraficiErrors({
            generic:
              "Impossibile aggiornare le informazioni del profilo, riprovare in seguito.",
          });
          setLoading(false);
        }
      }
    }
  };
  let styles = useStyles();
  return (
    user && (
      <React.Fragment>
        {globalProfile?.isCompleted ? (
          <form
            style={{ position: "relative" }}
            id="datiAnagraficiForm"
            name="datiAnagraficiForm"
            onSubmit={handleSubmit}
            noValidate
          >
            <Box
              style={{
                backgroundColor: "rgba(255,255,255,.7",
                zIndex: 1000,
                display: loading ? "" : "none",
              }}
              position="absolute"
              height="100%"
              width="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              py={5}
            >
              <CircularProgress size="3rem" />
            </Box>
            <Grid container spacing="3">
              <Grid item xs="12" lg="12">
                <Box display="flex" flexDirection="row-reverse">
                  {!editing && (
                    <Button
                      startIcon={<EditIcon />}
                      variant="contained"
                      color="primary"
                      onClick={toggleEdit}
                      className={styles.button}
                    >
                      Modifica
                    </Button>
                  )}
                  {editing && (
                    <Button
                      startIcon={<SaveIcon />}
                      variant="contained"
                      color="primary"
                      type="submit"
                      className={styles.button}
                    >
                      Salva
                    </Button>
                  )}
                  {editing && (
                    <Button
                      onClick={back}
                      startIcon={<ReplyIcon />}
                      variant="outlined"
                      color="primary"
                      className={styles.button}
                    >
                      Annulla
                    </Button>
                  )}
                </Box>
              </Grid>
              {formDatiAnagraficiErrors.generic && (
                <Grid item xs="12">
                  <Box color="red" width="100%" textAlign="center">
                    <Typography>{formDatiAnagraficiErrors.generic}</Typography>
                  </Box>
                </Grid>
              )}
              <Grid item xs="12" lg="12">
                <Grid container spacing="3">
                  <Grid item xs="12" lg="3" style={{ position: "relative" }}>
                    <TextField
                      required
                      disabled={!editing}
                      style={{ width: "100%" }}
                      id="lastName"
                      name="lastName"
                      inputProps={{ maxLength: 47 }}
                      label="Cognome"
                      defaultValue={user?.lastName}
                      error={formDatiAnagraficiErrors.lastName}
                      helperText={formDatiAnagraficiErrors.lastName}
                    />
                  </Grid>
                  <Grid item xs="12" lg="3" style={{ position: "relative" }}>
                    <TextField
                      required
                      disabled={!editing}
                      style={{ width: "100%" }}
                      id="firstName"
                      name="firstName"
                      label="Nome"
                      inputProps={{ maxLength: 47 }}
                      defaultValue={user?.name}
                      error={formDatiAnagraficiErrors.firstName}
                      helperText={formDatiAnagraficiErrors.firstName}
                    />
                  </Grid>
                  <Grid item xs="12" lg="3" style={{ position: "relative" }}>
                    <DateField
                      required
                      ref={datePickerRef}
                      disabled={!editing}
                      style={{ width: "100%" }}
                      id="birthdate"
                      name="birthdate"
                      label="Data di nascita"
                      defaultValue={formatDate(user?.birthDate)}
                      error={formDatiAnagraficiErrors.birthdate}
                      helperText={formDatiAnagraficiErrors.birthdate}
                    />
                  </Grid>
                  <Grid item xs="12" lg="3" style={{ position: "relative" }}>
                    <PhoneField
                      required
                      prefixName="prefix"
                      prefixDefaultValue={user?.phonePrefix || "39"}
                      disabled={!editing}
                      style={{ width: "100%" }}
                      id="phoneNumber"
                      name="phoneNumber"
                      label="Numero di telefono"
                      defaultValue={user?.phoneNumber}
                      error={formDatiAnagraficiErrors.phoneNumber}
                      helperText={formDatiAnagraficiErrors.phoneNumber}
                    />
                  </Grid>
                  <Grid item xs="12" lg="3" style={{ position: "relative" }}>
                    <TextField
                      disabled={!editing}
                      select
                      style={{ width: "100%" }}
                      id="gender"
                      name="gender"
                      label="Sesso"
                      value={defaultSelect?.gender || ""}
                      onChange={(e) => {
                        setDefaultSelect((prev) => ({
                          ...prev,
                          gender: e.target.value,
                        }));
                      }}
                      required
                      error={formDatiAnagraficiErrors.gender}
                      helperText={formDatiAnagraficiErrors.gender}
                    >
                      <MenuItem value="M">M</MenuItem>
                      <MenuItem value="F">F</MenuItem>
                      <MenuItem value="ND">
                        Preferisco non specificarlo
                      </MenuItem>
                    </TextField>
                  </Grid>
                  <Grid item xs="12" lg="6" style={{ position: "relative" }}>
                    <TextField
                      disabled={!editing}
                      style={{ width: "100%" }}
                      required
                      id="fiscalCode"
                      name="fiscalCode"
                      label="Codice Fiscale"
                      defaultValue={user?.fiscalCode}
                      error={formDatiAnagraficiErrors.fiscalCode}
                      helperText={formDatiAnagraficiErrors.fiscalCode}
                    />
                  </Grid>
                  <Grid item xs="12" lg="12">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isMarketingChecked}
                          onChange={() => {
                            setIsMarketingChecked((prev) => !prev);
                          }}
                          name="isMarketingAccepted"
                          color="primary"
                        />
                      }
                      disabled={!editing}
                      label="Acconsento al trattamento dei dati personali per finalità di marketing diretto"
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs="12"
                  className={styles.formBox}
                  data-title="Luogo di Nascita"
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        disabled={!editing}
                        name="stato"
                        label="Stato"
                        defaultValue={
                          user?.addresses?.find(
                            (elem) => elem.type == "birthplace"
                          )?.countryCode
                        }
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        required
                        error={formDatiAnagraficiErrors.stato}
                        helperText={formDatiAnagraficiErrors.stato}
                      ></TextField>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        disabled={!editing}
                        name="regione"
                        label="Regione"
                        defaultValue={
                          user?.addresses?.find(
                            (elem) => elem.type == "birthplace"
                          )?.region
                        }
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        required
                        error={formDatiAnagraficiErrors.regione}
                        helperText={formDatiAnagraficiErrors.regione}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        disabled={!editing}
                        name="provincia"
                        label="Provincia"
                        defaultValue={
                          user?.addresses?.find(
                            (elem) => elem.type == "birthplace"
                          )?.district
                        }
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        required
                        error={formDatiAnagraficiErrors.provincia}
                        helperText={formDatiAnagraficiErrors.provincia}
                      />
                    </Grid>

                    <Grid item xs={12} sm={7}>
                      <TextField
                        disabled={!editing}
                        name="comune"
                        label="Comune"
                        defaultValue={
                          user?.addresses?.find(
                            (elem) => elem.type == "birthplace"
                          )?.city
                        }
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        required
                        error={formDatiAnagraficiErrors.comune}
                        helperText={formDatiAnagraficiErrors.comune}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        disabled={!editing}
                        name="CAP"
                        label="CAP"
                        defaultValue={
                          user?.addresses?.find(
                            (elem) => elem.type == "birthplace"
                          )?.zipCode
                        }
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        required
                        error={formDatiAnagraficiErrors.CAP}
                        helperText={formDatiAnagraficiErrors.CAP}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs="12"
                  className={styles.formBox}
                  data-title="Luogo di Residenza"
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="regioneResidenza"
                        defaultValue={
                          user?.addresses?.find(
                            (elem) => elem.type == "residenceplace"
                          )?.region || ""
                        }
                        disabled={!editing}
                        label="Regione"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        required
                        error={formDatiAnagraficiErrors.regioneResidenza}
                        helperText={formDatiAnagraficiErrors.regioneResidenza}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="provinciaResidenza"
                        defaultValue={
                          user?.addresses?.find(
                            (elem) => elem.type == "residenceplace"
                          )?.district || ""
                        }
                        disabled={!editing}
                        label="Provincia"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        required
                        error={formDatiAnagraficiErrors.provinciaResidenza}
                        helperText={formDatiAnagraficiErrors.provinciaResidenza}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        name="comuneResidenza"
                        defaultValue={
                          user?.addresses?.find(
                            (elem) => elem.type == "residenceplace"
                          )?.city || ""
                        }
                        disabled={!editing}
                        label="Comune"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        required
                        error={formDatiAnagraficiErrors.comuneResidenza}
                        helperText={formDatiAnagraficiErrors.comuneResidenza}
                      />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <TextField
                        disabled={!editing}
                        name="via"
                        label="Via"
                        defaultValue={
                          user?.addresses?.find(
                            (elem) => elem.type == "residenceplace"
                          )?.addressLine
                        }
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        required
                        error={formDatiAnagraficiErrors.via}
                        helperText={formDatiAnagraficiErrors.via}
                      />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <TextField
                        disabled={!editing}
                        name="numeroCivico"
                        defaultValue={
                          user?.addresses?.find(
                            (elem) => elem.type == "residenceplace"
                          )?.number
                        }
                        label="Numero"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        required
                        error={formDatiAnagraficiErrors.numeroCivico}
                        helperText={formDatiAnagraficiErrors.numeroCivico}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        disabled={!editing}
                        name="CAPresidenza"
                        label="CAP"
                        defaultValue={
                          user?.addresses?.find(
                            (elem) => elem.type == "residenceplace"
                          )?.zipCode
                        }
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        required
                        error={formDatiAnagraficiErrors.CAPresidenza}
                        helperText={formDatiAnagraficiErrors.CAPresidenza}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box
              textAlign="left"
              width="100%"
              fontStyle="italic"
              fontSize=".8em"
              padding="1rem"
            >
              * Campo obbligatorio
            </Box>
          </form>
        ) : (
          <Grid
            style={{
              marginTop: "1.5rem",
              marginBottom: "1rem",
            }}
            items
            xs={12}
          >
            <Alert
              className={styles.alertContainer}
              severity="warning"
              icon={<WarningIcon fontSize="inherit" />}
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                style={{
                  alignItems: "center",
                  columnGap: ".5rem",
                  flexWrap: "wrap",
                }}
              >
                <AlertTitle className={styles.alertTitle}>
                  Non hai ancora completato il tuo profilo,
                </AlertTitle>

                <span
                  className={styles.linkButton}
                  onClick={() => history.push("/complete-registration")}
                >
                  puoi farlo ora da qui
                </span>
              </Box>
            </Alert>
          </Grid>
        )}
      </React.Fragment>
    )
  );
};

export default Profilo_DatiGenerali;
