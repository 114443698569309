import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  Divider,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  CallEnd,
  CancelPresentation,
  Close,
  MeetingRoom,
  Mic,
  MicOff,
  NetworkCheck,
  PresentToAll,
  SignalWifi0Bar,
  Videocam,
  VideocamOff,
  VolumeUp,
  Done,
  Fullscreen,
  FullscreenExit,
  ExpandMore,
  ExpandLess,
} from "@material-ui/icons";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import logoHorizontalWhite from "../assets/various/logo_horizontal_white.png";
import { usePopups } from "../contexts/PopupContext";
import {
  useNotificationApi,
  useNotifications,
} from "../contexts/NotificationContext";
import { useUser, useUserProfile } from "../contexts/UserContext";
import useApi from "../hooks/useApi";
import useBrowserInfo from "../hooks/useBrowserInfo";
import { FRAME_RATE, grayLog, lerpArray } from "../utils/UtilsFunction";
import AlertDialog from "../components/AlertDialog";
import CloseWhiteboardIcon from "../components/icons/CloseWhiteboardIcon";
import DoubleArrowIcon from "../components/icons/DoubleArrowIcon";
import OpenWhiteboardIcon from "../components/icons/OpenWhiteboardIcon";
import NetworkResults from "../components/NetworkResults";
import PsyWhiteboard from "../components/PsyWhiteboard";
import VideoCallButton from "../components/VideoCallButton";
import VideocallSidebar from "../components/VideocallSidebar";
import VideoPoster from "../components/VideoPoster";
import { Alert } from "@material-ui/lab";
import MicLevels from "../components/MicLevels";
import useOnUnmount from "../hooks/useOnUnmount";
import { useHistory, useParams } from "react-router";
import { useGlobalSnackbar } from "../contexts/GlobalSnackbarContext";
import PsyTimer from "../components/PsyTimer";
import SessionEnded from "../components/SessionEnded";
import { NotIn, NoRoom } from "../components/SessionErrorPage";
import { useAnonUser } from "../hooks/useAnonUser";
import GridViewIcon from "../components/icons/GridViewIcon";
import AdaptedViewIcon from "../components/icons/AdaptedViewIcon";
import FullViewIcon from "../components/icons/FullViewIcon";
import VoidIcon from "../components/icons/VoidIcon";
import useVisibility from "../hooks/useVisibility";
import { useAgoraContext } from "../contexts/AgoraContext";
import useMediaDevice from "../hooks/useMediaDevice";
import appsettings from "../config/appsettings.json";
import environment from "../config/environment.json";
import AgoraRTC, {
  IAgoraRTCClient,
  ILocalAudioTrack,
  ILocalVideoTrack,
  ITrack,
  IAgoraRTCRemoteUser,
} from "agora-rtc-sdk-ng";
import {
  SignalingEvent,
  useSignaling,
  useSignalingListener,
} from "../contexts/SignalingContext";
import { useEventEffect } from "../hooks/useEventEffect";

function attachToElement(
  videoElement: HTMLVideoElement | null | undefined,
  track: ITrack
) {
  if (!videoElement) return;
  if (!videoElement.srcObject) {
    videoElement.srcObject = new MediaStream();
  }
  (videoElement.srcObject as MediaStream).addTrack(track.getMediaStreamTrack());
}

function detachFromElement(
  videoElement: HTMLVideoElement | null | undefined,
  track: ITrack
) {
  if (!videoElement) return;
  if (!videoElement.srcObject) return;
  const videoSrcObject = videoElement.srcObject as MediaStream;
  videoElement.srcObject = null;
  videoSrcObject.removeTrack(track.getMediaStreamTrack());
  videoSrcObject.getTracks().forEach((track) => {
    if (!videoElement.srcObject) {
      videoElement.srcObject = new MediaStream();
    }
    (videoElement.srcObject as MediaStream).addTrack(track);
  });
}

//function to stop the stream
const stopStream = (stream) =>
  stream?.getTracks?.().forEach((track) => track.stop());
const getVideoIdFromStream = (stream) =>
  stream?.getVideoTracks?.()?.[0]?.getSettings?.()?.deviceId;
const getAudioIdFromStream = (stream) =>
  stream?.getAudioTracks?.()?.[0]?.getSettings?.()?.deviceId;

const below1000pxMediaQuery = "@media (max-width:62.5rem)";
const below1200pxMediaQuery = "@media (max-width:75rem)";
const over1200pxMediaQuery = "@media (min-width:75rem)";
const below600pxMediaQuery = "@media (max-width:37.5rem)";

// @ts-ignore
const useStyles = makeStyles((theme) => ({
  loader: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 999,
    background: "rgba(255,255,255,.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    background: "#444",
    color: "white",
    width: "100vw",
    height: "100vh",
    boxSizing: "border-box",
    display: "grid",
    gridTemplateRows: "min-content 1fr max-content",
    overflowX: "hidden",
    position: "relative",
  },
  frontDoor: {
    height: "100%",
    display: "grid",
    placeItems: "center",
    gridAutoFlow: "row",
    gridTemplateColumns: "1fr",
    gridAutoRows: "min-content",
    //gap: "1.5rem",
    padding: "1rem",
    boxSizing: "border-box",
    "& > *": {
      textAlign: "center",
      maxWidth: "100%",
    },
    "& > h5": {
      fontSize: "2rem",
    },
    "& > img": {
      height: "25vmin",
    },
  },
  logoWaitingRoom: {
    position: "absolute",
    margin: "1rem",
    maxWidth: "clamp(10rem, 35vw, 17rem)",
    top: 0,
    left: 0,
    objectFit: "contain",
  },
  alertWaitingRoom: {
    placeSelf: "end",
    maxWidth: "max(25vw,487px)",
    margin: "1rem",
  },
  mediaPreviewAndSelect: {
    minWidth: "50%",
    maxWidth: "75%",
    justifySelf: "center",
    justifyContent: "center",
    display: "grid",
    gap: "24px",
    marginBlock: "48px",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr",
    width: "90%",
    [over1200pxMediaQuery]: {
      gridTemplateColumns: "min-content 25%",
      gridTemplateRows: "unset",
    },
  },
  mediaPreview: {
    position: "relative",
    height: "unset",
    aspectRatio: "3 / 2",
    justifySelf: "end",
    border: "1px solid #cccccc",
    background: "#333",
    boxSizing: "border-box",
    width: "100%",
    [over1200pxMediaQuery]: {
      width: "unset",
      height: "40vh",
      gridTemplateColumns: "min-content 25%",
      gridTemplateRows: "unset",
    },
    "& > video": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
  videoMuted: {
    filter: "brightness(0)",
  },
  previewActions: {
    position: "absolute",
    width: "100%",
    display: "grid",
    justifyContent: "center",
    gap: "1rem",
    bottom: "1rem",
    gridAutoFlow: "column",
    "& > button": {
      border: 0,
      outline: 0,
      backgroundColor: "hsla(0, 0%, 20%, 0.9)",
      color: "white",
      padding: "0.25rem",
      aspectRatio: "1/1",
      margin: 0,
      borderRadius: ".5rem",
      display: "grid",
      opacity: 0.3,
      cursor: "not-allowed",
      "&:enabled": {
        opacity: 1,
        cursor: "pointer",
      },
    },
  },
  mediaSelect: {
    display: "grid",
    padding: "1rem",
    background: "#333",
    borderRadius: "1rem",
    gridAutoRows: "min-content",
    gap: ".3rem",
    "& > button": {
      width: "fit-content",
      background: "transparent",
      color: "white",
      margin: "0",
      padding: "0",
      border: "0",
      outline: "0",
      textDecoration: "underline",
      cursor: "pointer",
      placeSelf: "end",
      marginBottom: "-0.5rem",
      marginTop: "1rem",
    },
    "& > *:not(span)": {
      maxWidth: "100%",
    },
    "& > span": {
      width: "100%",
      textAlign: "center",
      fontSize: "0.875rem",
    },
  },
  checkConnection: {
    border: "1px solid white",
    padding: ".3rem",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: ".25rem",
    gap: ".5rem",
  },
  select: {
    display: "block",
    maxWidth: "12rem",
    color: "white",
    background: "rgba(255,255,255,.1)",
  },
  adornment: {
    marginRight: "1rem",
  },
  iconOutlinedStyles: {
    color: "white !important",
  },
  disabledStyles: {
    opacity: 0.3,
  },
  outlinedStyles: {
    color: "white !important",
    fontSize: "0.875rem",
  },
  labelStyles: {
    color: "white !important",
  },
  notchedOutlineStyles: {
    borderColor: "white !important",
  },
  joinedContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(20, 1fr)",
    gridTemplateRows: "repeat(20, 1fr)",
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    background: "#222",
    position: "relative",
    overflow: "hidden",
  },
  mobileTimer: {
    color: "white",
    position: "absolute",
    zIndex: "3",
    margin: ".5rem",
    padding: ".5rem",
    borderRadius: ".5rem",
    background: "#33333380",
  },
  viewSwitcherList: {
    position: "absolute",
    top: "1rem",
    right: "4rem",
    margin: "0",
    padding: "0",
    background: "#333",
    borderRadius: ".5rem",
    listStyle: "none",
    "&:not($viewSwitcherOpened) > :not(:first-child)": {
      height: 0,
      padding: 0,
      opacity: 0,
      pointerEvents: "none",
    },
  },
  viewSwitcherOpened: {},
  viewSwitcherButton: {
    color: "white",
    justifyContent: "flex-start",
    textTransform: "none",
    padding: ".5rem",
  },
  viewSwitcherButtonLabel: {
    gap: ".5rem",
  },
  endIcon: {
    marginLeft: "auto",
  },
  mainGridElement: {
    position: "relative",
    gridColumn: "1 / -1",
    gridRow: "1 / -1",
  },
  screenShareGridElement: {
    position: "relative",
    gridColumn: "1 / -1",
    gridRow: "1 / -4",
  },
  loweredPatientGridElement: {
    gridColumn: "-7 / -4",
    gridRow: "-6 / -1",
    "&$littleVideo": {
      gridRow: "-4 / -1",
      gridColumn: "-5 / -3",
    },
    [below1200pxMediaQuery]: {
      gridRow: "-5 / -1",
      gridColumn: "-9 / -5",
      "&$littleVideo": {
        gridColumn: "-7 / -4",
      },
    },
    [below600pxMediaQuery]: {
      gridRow: "-4 / -1",
      gridColumn: "-17 / -9",
      "&$littleVideo": {
        gridColumn: "-13 / -7",
      },
    },
  },
  lowerRightGridElement: {
    gridColumn: "-4 / -1",
    gridRow: "-6 / -1",
    position: "relative",
    "&$littleVideo": {
      gridRow: "-4 / -1",
      gridColumn: "-3 / -1",
    },
    [below1200pxMediaQuery]: {
      gridRow: "-5 / -1",
      gridColumn: "-5 / -1",
      "&$littleVideo": {
        gridRow: "-4 / -1",
        gridColumn: "-4 / -1",
      },
    },
    [below600pxMediaQuery]: {
      gridRow: "-4 / -1",
      gridColumn: "-9 / -1",
      "&$littleVideo": {
        gridColumn: "-7 / -1",
      },
    },
  },
  littleVideo: {},
  hiddenVideo: {
    opacity: 0,
    pointerEvents: "none",
  },
  sideBar: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: "0px",
    color: "white",
    backgroundColor: "#333",
    transition: "transform 250ms ease-in-out",
    transform: "translateX(100%)",
    zIndex: 1000,
    borderLeft: "1px solid rgba(255,255,255, .3)",
    boxSizing: "border-box",
  },
  sideBarToggle: {
    color: "white",
    position: "absolute",
    top: "1rem",
    transform: "translateX(-100%)",
    background: "#333",
    cursor: "pointer",
    padding: ".5rem",
    display: "flex",
    border: "1px solid rgba(255,255,255, .3)",
    borderRight: 0,
    "& > svg": {
      transform: "rotate(180deg)",
      transition: "transform 250ms ease-in-out",
      fontSize: "1rem",
      [theme.breakpoints.up("sm")]: {
        fontSize: "1.5rem",
      },
    },
  },
  toggleOpened: {
    "& > svg": {
      transform: "rotate(0deg)",
    },
  },
  open: {
    transform: "translateX(0)",
    "& svg": {
      transform: "rotate(0deg)",
    },
  },
  buttonsContainer: {
    backgroundColor: "#333333",
  },
  logoAndQoS: {
    display: "flex",
    color: "white",
    alignItems: "center",
    maxWidth: "100%",
    maxHeight: "3rem",
    padding: "0.5rem",
    "& > img": {
      maxHeight: "2rem",
      maxWidth: "100%",
    },
    [below1000pxMediaQuery]: {
      opacity: 0,
    },
  },
  therapistQoS: {
    textAlign: "center",
  },
  whiteboardMenuWrapper: {
    position: "absolute",
    minWidth: "max-content",
    transform: "scaleY(0)",
    transition: "transform 250ms",
    top: "0",
    left: "50%",
    color: "black",
    zIndex: 10,
  },
  whiteboardMenu: {
    position: "absolute",
    top: "0",
    left: "50%",
    transform: "translate(0%, -100%)",
    background: "white",
    boxShadow: "0 0 1rem 0 rgba(0,0,0,.7)",
    minWidth: "max-content",
  },
  closeAndTimer: {
    placeSelf: "end",
    display: "grid",
    gridTemplateColumns: "min-content",
    gap: "1rem",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "min-content min-content",
    },
  },
  loneButton: {
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "min-content",
    },
  },
  linkButton: {
    width: "fit-content",
    background: "transparent",
    color: "white",
    margin: "0",
    padding: "0",
    border: "0",
    outline: "0",
    textDecoration: "underline",
    cursor: "pointer",
    fontSize: "1rem",
    marginTop: "1rem",
  },
}));

const useModalStyles = makeStyles((theme) => ({
  paperWidthFalse: {
    width: "97%",
    height: "97%",
    maxWidth: "unset",
    maxHeight: "unset",
    margin: "unset",
  },
  action: {
    alignItems: "flex-start",
  },
  callButton: {
    border: `1px solid #006166`,
    boxShadow: "0 0 .5rem 0 #006166",
    color: "#006166",
    "&.pressed": {
      boxShadow: "inset 0 0 .2rem 0 #006166",
      backgroundColor: "#00616633",
    },
  },
}));

const useModalContentStyles = makeStyles({
  root: {
    padding: "0",
    paddingTop: "0 !important",
    height: "100%",
  },
  lateralMenu: {
    position: "absolute",
    minHeight: "30%",
    backgroundColor: "white",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    boxShadow: "-.5rem 0 1rem 0 rgb(0, 0, 0, .15)",
  },
});

let callStatsArray = [];
const streams = [];

const qualities = [
  {
    title: "Bassa",
    constraints: {},
  },
  {
    title: "Media",
    constraints: {},
  },
  {
    title: "Alta",
    constraints: {},
  },
];

const VideoCallPopup = (
  {
    isSpeedTestAvailable,
    setSnackbar,
    connectionMessage,
    setConnectionMessage,
    isPatientSTRunning,
    setIsPatientSTRunning,
    patientSTResults,
    setPatientSTResults,
    ...props
  }: any,
  ref
) => {
  const {
    engine,
    currentCamera,
    currentMicrophone,
    setCurrentCamera,
    setCurrentMicrophone,
  } = useAgoraContext();
  const signaling = useSignaling();

  const sendEvent = signaling.getSenderForUser(() => {
    const profile = userProfile as unknown as {};
    if (
      profile &&
      typeof profile === "object" &&
      "idTherapist" in profile &&
      typeof profile.idTherapist === "string"
    ) {
      return profile.idTherapist;
    }
    return null;
  });

  const { cameraList, microphoneList } = useMediaDevice();
  const [, setGlobalSnackbar] = useGlobalSnackbar();
  const classes = useStyles();
  const [, setPopups] = usePopups();
  const [notifications, setNotifications] = useNotifications();
  const { nanoId } = useParams();
  const { user, userProfile, error, sessionIds, isAnon } = useAnonUser(nanoId);
  const [patientId, setPatientId] = useState();
  const [videoCallSession, setVideoCallSession] = useState(
    props?.location?.state
  );
  const [sessionMessage, setSessionMessage] = useState("");
  const [isClickedOnJoin, setIsClickedOnJoin] = useState(false);
  const [isConferenceJoined, setIsConferenceJoined] = useState(false);
  const [isConsentGiven, setIsConsentGiven] = useState(true);
  const [isScreenshareOn, setIsScreenshareOn] = useState(false);
  const [isWhiteboardOn, setIsWhiteboardOn] = useState(false);
  const [isAudioActive, setIsAudioActive] = useState(true);
  const [isVideoActive, setIsVideoActive] = useState(true);
  const [isVideoSettingsShowing, setIsVideoSettingsShowing] = useState(false);
  /*const [availableDevices, setAvailableDevices] = useState(false);
    const [inUseMichrophone, setInUseMichrophone] = useState(false);
    const [inUseWebcam, setInUseWebcam] = useState(false);*/
  const [shareScreenStream, setShareScreenStream] = useState<
    (ILocalVideoTrack | [ILocalVideoTrack, ILocalAudioTrack]) | null
  >(null);
  const [isTherapistVideoPlaying, setIsTherapistVideoPlaying] = useState(false);
  const [jsonCanvas, setJsonCanvas] = useState("");
  const [settings, setSettings] = useState();

  const audioStream = useRef<ILocalAudioTrack | undefined>();
  const videoStream = useRef<ILocalVideoTrack | undefined>();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isWhiteboardMenuOn, setIsWhiteboardMenuOn] = useState(false);
  const [isClosingSession, setIsClosingSession] = useState(false);

  const [quality, setQuality] = useState(1);
  const [patientQoS, setPatientQoS] = useState(-1);
  const patientQoSSentence = useMemo(() => {
    if (patientQoS < 0) {
      return "N.D.";
    } else {
      return lerpArray(["Scarsa", "Buona", "Ottima"], 0, 4, patientQoS);
    }
  }, [patientQoS]);

  const [joinStreamOptions, setJoinStreamOptions] = useState({
    videoMuted: false,
    audioMuted: false,
  });

  const [isViewSwitcherOpen, setIsViewSwitcherOpen] = useState(false);
  const [currentView, setCurrentView] = useState("contain");

  const networkConditions = useCallback(() => {
    return (
      <div className={classes.therapistQoS}>
        {/* <div>Qualità video</div>
        <span>{patientQoSSentence}</span> */}
      </div>
    );
  }, [patientQoS, patientQoSSentence, classes]);

  const [therapistEnteringDate, setTherapistEnteringDate] = useState(null);

  const [videocallErrorPopup, setVideocallErrorPopup] = useState(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const api = useApi();

  const browserInfo = useBrowserInfo();

  const psyWhiteboardRef = useRef();

  const styles = useStyles();

  const [isOpeningOldWhiteboard, setIsOpeningOldWhiteboard] = useState(false);

  const [lastUpdateInterval, setLastUpdateInterval] = useState(null);
  const [hasPatientKnocked, setHasPatientKnocked] = useState(false);
  const [settingpatient, setSettingpatient] = useState();

  const { patientQuit } = useNotificationApi();
  const defaultWebcam = useRef(null);

  const knock = useCallback(async () => {
    api(user, `waitingroom/startwaitingroom`, "POST", {
      idPatient: user?.profile?.sub,
      idTherapist: userProfile?.idTherapist,
      patientName: userProfile?.firstName,
      patientSurname: userProfile?.lastName,
      patientEmail: user?.profile?.email,
      mobile: browserInfo.mobile || browserInfo.isTablet,
    });
    setHasPatientKnocked(true);
  }, [user, userProfile, browserInfo]);

  const [loading, setLoading] = useState(true);

  const patientSetting = useCallback(async () => {
    let settingsResponse = await api(
      user,
      `patients/${user?.profile?.sub}/${userProfile?.idTherapist}/settings`,
      "GET"
    );
    if (settingsResponse.ok === false) {
      setGlobalSnackbar({
        open: true,
        severity: "error",
        message: "Impossibile avviare la videoseduta",
      });
      return;
    } else {
      setSettingpatient(settingsResponse);
      if (settingsResponse.isArchived) {
        setGlobalSnackbar({
          open: true,
          severity: "error",
          message:
            "Impossibile avviare la videoseduta. Contatta il tuo terapeuta.",
        });
      }
    }
  }, [user]);

  useEffect(() => {
    if (!user) return;
    const interval = setInterval(() => {
      api(user, `waitingroom/${user?.profile?.sub}/lastupdate`, "PATCH");
    }, 3000);
    setLastUpdateInterval(interval);
    return () => {
      clearInterval(interval);
      setPatientSTResults(null);
      setConnectionMessage(null);
    };
  }, [user]);
  const [notIn, setNotIn] = useState(false);
  useEffect(() => {
    if (!user || !userProfile || !sessionIds) return;
    patientSetting();
    //getting the videocall session on open
    (async () => {
      if (
        sessionIds?.idPatient !== user?.profile?.sub ||
        sessionIds?.idTherapist !== userProfile?.idTherapist
      ) {
        setNotIn(true);
        return;
      }
      const sessionDTO = {
        IdPatient: user.profile.sub,
        IdTherapist: userProfile.idTherapist,
        PatientEmail: user.profile.email,
        TherapistName: userProfile.therapistName,
        TherapistLastName: userProfile.therapistLastName,
        PatientName: userProfile.firstName,
        PatientLastName: userProfile.lastName,
      };
      const response = await api(
        user,
        "sessions/getorcreate",
        "POST",
        sessionDTO
      );
      if (response.ok !== false) {
        setVideoCallSession(response);
        setLoading(false);
        if (
          (browserInfo.name === "Safari" || browserInfo.isException) &&
          isAnon.current
        ) {
          setGlobalSnackbar({
            open: true,
            severity: "warning",
            message: (
              <>
                Per effettuare le videochiamate, consigliamo di utilizzare un
                browser diverso.{" "}
                <Link target="_blank" href="https://www.google.com/chrome/">
                  Scarica o utilizza Google Chrome.
                </Link>
              </>
            ),
          });
        }
      } else {
        setGlobalSnackbar({
          open: true,
          severity: "error",
          message:
            "Impossibile recuperare la seduta, prova a ricaricare la pagina.",
        });
      }
    })();
  }, [user, userProfile, sessionIds]);

  const confirmClosingSession = () => {
    return window.confirm("Vuoi davvero chiudere la sessione?");
  };

  /**
   * Metodh to send a session custom event from the patient to the therapist
   */
  const sendCustomEvent = useCallback((event, data) => {
    //   conference.sendCustomEvent(event, data);
  }, []);

  useVisibility(
    (hidden) => {
      if (hidden) {
        sendEvent("videocall:leave-tab", {});
        return;
      }
      sendEvent("videocall:return-tab", {});
    },
    [sendEvent]
  );

  const onWhiteboardExit = useCallback(async () => {
    setIsWhiteboardOn(false);
    try {
      sendEvent("whiteboard:toggle", { isOpen: false });
    } catch (e) {}
    if (psyWhiteboardRef?.current && videoCallSession) {
      api(user, `sessions/${videoCallSession.id}/whiteboard/image`, "PATCH", {
        sessionId: videoCallSession.id,
        image: psyWhiteboardRef?.current.getWhiteboardPng(),
      });
    }
  }, [user, videoCallSession]);

  const [isEndedForAnonUser, setIsEndedForAnonUser] = useState(false);

  const screenShareEngine = useRef<IAgoraRTCClient | undefined>();

  const isJoined = useRef(false);

  const trackToUserIdMap = useRef(new Map<string, ITrack | undefined>());

  useEffect(() => {
    setHasPatientKnocked(false);
    //if we want implement stats we can use AgoraEngine getRemoteVideoStats getRTCStats
    engine.on("live-streaming-error", () => {
      setVideocallErrorPopup(true);
    });

    engine.on("user-published", async (userPublished, mediaType) => {
      const uid = userPublished.uid.toString();
      // this will be -1 if the incoming stream is not a screenshare
      const screenShareIndexOf = uid.indexOf("-screenshare");
      const screenShareUserId = uid.substring(0, screenShareIndexOf);
      if (
        screenShareUserId !== "" &&
        // @ts-ignore
        screenShareUserId === user?.profile?.sub
      )
        return;

      await engine.subscribe(userPublished, mediaType);
      if (screenShareIndexOf !== -1 && isScreenshareOn) {
        closeShareScreen();
      }
      const videoToAttach =
        screenShareIndexOf !== -1
          ? videoScreenshareRef.current
          : videoTherapistRef.current;
      trackToUserIdMap.current.set(
        `${uid}-${mediaType}`,
        mediaType === "video"
          ? userPublished.videoTrack
          : userPublished.audioTrack
      );
      if (mediaType === "video") {
        if (!userPublished.videoTrack || !videoToAttach) {
          return;
        }
        attachToElement(videoToAttach, userPublished.videoTrack);
      }
      if (mediaType === "audio") {
        if (!userPublished.audioTrack || !videoToAttach) {
          return;
        }
        attachToElement(videoToAttach, userPublished.audioTrack);
      }
      if (screenShareIndexOf !== -1) {
        if (
          !videoScreenshareTimeout.current &&
          videoScreenshareRef.current?.paused
        ) {
          videoScreenshareTimeout.current = setTimeout(() => {
            setIsProblemsPopupOpen(true);
          }, 3000);
        }
        setIsScreenshareOn(true);
        setIsWhiteboardOn(false);
      } else {
        if (
          !videoTherapistTimeout.current &&
          videoTherapistRef.current?.paused
        ) {
          videoTherapistTimeout.current = setTimeout(() => {
            setIsProblemsPopupOpen(true);
          }, 3000);
        }
      }
    });

    engine.on("user-unpublished", async (userUnpublished, mediaType) => {
      const uid = userUnpublished.uid.toString();
      // this will be -1 if the incoming stream is not a screenshare
      const screenShareIndexOf = uid.indexOf("-screenshare");
      const screenShareUserId = uid.substring(0, screenShareIndexOf);
      if (
        screenShareUserId !== "" &&
        // @ts-ignore
        screenShareUserId === user?.profile?.sub
      )
        return;
      const videoToDetach =
        screenShareIndexOf !== -1
          ? videoScreenshareRef.current
          : videoTherapistRef.current;
      const trackToDetach = trackToUserIdMap.current.get(`${uid}-${mediaType}`);
      if (trackToDetach) {
        detachFromElement(videoToDetach, trackToDetach);
        trackToUserIdMap.current.set(`${uid}-${mediaType}`, undefined);
      }
      if (screenShareIndexOf !== -1) {
        videoScreenshareTimeout.current = undefined;
        if (!shareScreenStream) {
          setIsScreenshareOn(false);
        }
        return;
      }
      videoTherapistTimeout.current = undefined;
    });

    engine.on("user-joined", (userPublished) => {
      const uid = userPublished.uid.toString();
      // this will be -1 if the incoming stream is not a screenshare
      const screenShareIndexOf = uid.indexOf("-screenshare");
      if (screenShareIndexOf === -1) {
        setTherapistEnteringDate((prev) => (!prev ? new Date() : prev));
      }
    });

    engine.on(
      "user-left",
      (userPublished: IAgoraRTCRemoteUser, reason: string) => {
        if (reason != "Quit") return;

        //LUDO
        setIsTherapistVideoPlaying(false);
      }
    );

    engine.on("connection-state-change", (curState) => {
      if (curState === "CONNECTED") {
        isJoined.current = true;
        clearInterval(lastUpdateInterval);
        setIsConferenceJoined(true);
        setIsClickedOnJoin(false);
        //recover settings to check if the patient can see himself
        api(
          user,
          `patients/${user.profile.sub}/${userProfile.idTherapist}/settings`,
          "GET"
        ).then((patientSettings) => {
          if (patientSettings.ok !== false) {
            setSettings(patientSettings);
          }
        });
      }
    });

    return () => {
      engine.removeAllListeners();
    };
  }, [engine, user, userProfile, isScreenshareOn, shareScreenStream]);

  useEffect(() => {
    if (user) {
      return () => {
        if (testMicrophoneRef.current) {
          testMicrophoneRef.current.stop();
        }
        if (!isJoined.current) {
          sendEvent(
            "waitingroom:left",
            {
              idPatient: user?.profile?.sub,
            },
            false,
            true
          );
        }
      };
    }
  }, [user]);

  const hasSignalingLoggedIn = useRef(false);

  if (isAnon.current && !hasSignalingLoggedIn.current && user) {
    try {
      signaling.getTokenAndLogin(user).catch(() => {
        hasSignalingLoggedIn.current = false;
      });
      hasSignalingLoggedIn.current = true;
    } catch (e) {}
  }

  useSignalingListener(
    "whiteboard:toggle",
    async (data) => {
      if (data.isOpen) {
        setIsWhiteboardOn(true);
        closeShareScreen();
      } else {
        setIsWhiteboardOn(false);
      }
    },
    []
  );
  useSignalingListener(
    "videocall:toggle-patient",
    () => {
      setSettings((prev) => ({ ...prev, canSeeHimself: !prev.canSeeHimself }));
    },
    []
  );

  useSignalingListener(
    "whiteboard:update-canvas",
    (data) => {
      setJsonCanvas(data.canvas);
    },
    []
  );

  useSignalingListener(
    "whiteboard:adapt",
    (data) => {
      if (psyWhiteboardRef?.current) {
        psyWhiteboardRef.current.adapt(data.size);
      }
    },
    []
  );

  const leaveConference = async (askConfirmation?: boolean) => {
    if (askConfirmation) {
      setIsClosingSession(true);
    }
    try {
      sendEvent("videocall:closing", {});
    } catch (e) {}
    try {
      engine.leave();
      if (screenShareEngine.current) {
        screenShareEngine.current.leave();
      }
      if (videoStream.current) {
        videoStream.current.close();
      }
      if (audioStream.current) {
        audioStream.current.close();
      }
      if (Array.isArray(shareScreenStream)) {
        shareScreenStream.forEach((track) => {
          track.stop();
        });
      } else {
        shareScreenStream?.stop();
      }
    } catch (e) {}
    await api(user, `sessions/${videoCallSession.id}/end`, "PATCH");
    if (unblock.current) unblock.current();
    if (!isAnon.current) {
      history.push("/home");
    } else {
      cleanUp();
      setIsEndedForAnonUser(true);
    }
    setPatientSTResults(null);
    setIsConferenceJoined(false);
    patientQuit(userProfile?.idTherapist, {
      id: user?.profile?.sub,
      patientFirstName: userProfile.firstName,
      patientLastName: userProfile.lastName,
    });
    defaultWebcam.current=null;
    setCurrentCamera(cameraList[0]);
  };

  function askToEnter() {
    setIsClickedOnJoin(true);
    knock();
    sendEvent(
      "videocall:requestaccess",
      {
        idPatient: user?.profile?.sub,
      },
      true
    );
  }

  const joinConference = useEventEffect(async () => {
    setIsAudioActive(true);
    setIsVideoActive(true);
    setIsWhiteboardOn(false);
    setJsonCanvas("");
    setIsVideoSettingsShowing(false);
    setIsTherapistVideoPlaying(false);
    try {
      let localVideoStream: ILocalVideoTrack | null = null;
      let localAudioStream: ILocalAudioTrack | null = null;
      testMicrophoneRef.current?.stop();
      const videoTrack = previewStream?.getVideoTracks()[0];
      const audioTrack = previewStream?.getAudioTracks()[0];
      if (!videoTrack || !audioTrack) {
        //TO DO SHOW ERROR
        return;
      }

      const tokenResponse = await api(
        user,
        // @ts-ignore
        `agoratoken/rtc/${user.profile.sub}/${userProfile?.idTherapist}`
      );
      if (tokenResponse.ok === false) {
        // show error
        setGlobalSnackbar({
          open: true,
          severity: "error",
          message: "Impossibile avviare la videochiamata, riprova.",
        });
        setIsClickedOnJoin(false);
        return;
      }
      await engine.join(
        // @ts-ignore
        appsettings[environment.Environment].agora.appId,
        tokenResponse.roomId,
        tokenResponse.accessToken,
        // @ts-ignore
        user.profile.sub
      );

      screenShareEngine.current = AgoraRTC.createClient({
        mode: "rtc",
        codec: "vp8",
      });
      await screenShareEngine.current.join(
        // @ts-ignore
        appsettings[environment.Environment].agora.appId,
        tokenResponse.roomId,
        tokenResponse.screenShareAccessToken,
        // @ts-ignore
        `${user.profile.sub}-screenshare`
      );
      try {
        localVideoStream = AgoraRTC.createCustomVideoTrack({
          mediaStreamTrack: videoTrack,
        });
        localAudioStream = AgoraRTC.createCustomAudioTrack({
          mediaStreamTrack: audioTrack,
        });
        setIsConsentGiven(true);
        videoStream.current = localVideoStream;
        audioStream.current = localAudioStream;
      } catch (e) {
        switch (e.name) {
          case "NotAllowedError":
          case "PermissionDeniedError":
            setPopups((prev) => ({
              ...prev,
              errorWebcamOrMicrPopup: {
                open: true,
                props: {
                  title:
                    "Il browser non ha l’autorizzazione per accedere alla webcam o al microfono",
                  message: (
                    <>
                      <a
                        href="https://www.psycare.it/risoluzione-problemi-permessi-e-consensi-webcam-microfono/"
                        target="_blank"
                      >
                        Clicca qui
                      </a>{" "}
                      e scopri come permettere al tuo browser di accedere a
                      webcam e microfono in base al dispositivo che stai
                      utilizzando.
                    </>
                  ),
                },
              },
            }));
            break;
          case "TrackStartError": //chrome
          case "NotReadableError":
            setCurrentCamera(defaultWebcam.current);
            setPopups((prev) => ({
              ...prev,
              errorWebcamOrMicrPopup: {
                open: true,
                props: {
                  title:
                    "La webcam o il microfono risultano essere in uso da un altro software",
                  message:
                    "Chiudi gli altri programmi in uso e riaggiorna la pagina. In alternativa, seleziona un dispositivo diverso dal menu a destra.",
                },
              },
            }));
            break;
          case "DevicesNotFoundError": //chrome
          case "NotFoundError":
            setPopups((prev) => ({
              ...prev,
              errorWebcamOrMicrPopup: {
                open: true,
                props: {
                  title: "Non è stata trovata alcuna webcam o microfono",
                  message: (
                    <>
                      Assicurati di avere collegato correttamente webcam o
                      microfono. In alternativa,{" "}
                      <a
                        href="https://www.psycare.it/risoluzione-problemi-paziente/"
                        target="_blank"
                      >
                        clicca qui
                      </a>{" "}
                      per scoprire quali possono essere le cause e come
                      risolvere.
                    </>
                  ),
                },
              },
            }));
            break;
          case "OverconstrainedError":
          case "ConstraintNotSatisfiedError":
            setPopups((prev) => ({
              ...prev,
              errorWebcamOrMicrPopup: {
                open: true,
                props: {
                  title:
                    "Non è stata trovato alcun dispositivo valido per effettuare la videoseduta",
                  message: (
                    <>
                      Assicurati di avere collegato correttamente webcam o
                      microfono. In alternativa,{" "}
                      <a
                        href="https://www.psycare.it/risoluzione-problemi-paziente/"
                        target="_blank"
                      >
                        clicca qui
                      </a>{" "}
                      per scoprire quali possono essere le cause e come
                      risolvere.
                    </>
                  ),
                },
              },
            }));
            break;
          case "TypeError":
            setPopups((prev) => ({
              ...prev,
              errorWebcamOrMicrPopup: {
                open: true,
                props: {
                  title: "C’è un problema con la webcam o il microfono",
                  message: (
                    <>
                      <a
                        href="https://www.psycare.it/risoluzione-problemi-paziente/"
                        target="_blank"
                      >
                        Clicca qui
                      </a>{" "}
                      per scoprire quali possono essere le cause e come
                      risolvere.
                    </>
                  ),
                },
              },
            }));
            break;
          default:
            setPopups((prev) => ({
              ...prev,
              errorWebcamOrMicrPopup: {
                open: true,
                props: {
                  title: "C’è un problema con la webcam o il microfono",
                  message: (
                    <>
                      <a
                        href="https://www.psycare.it/risoluzione-problemi-paziente/"
                        target="_blank"
                      >
                        Clicca qui
                      </a>{" "}
                      per scoprire quali possono essere le cause e come
                      risolvere.
                    </>
                  ),
                },
              },
            }));
            break;
        }
        return;
      }
      if (videoCallSession) {
        let updatedSession = await api(
          user,
          `sessions/${videoCallSession.id}/start`,
          "PATCH",
          {
            id: videoCallSession.id,
            idHost: videoCallSession.idPatient,
            isTherapist: false,
          }
        );
      }
      setPreviewStream(null);
      setHasPatientKnocked(false);

      await engine?.publish([localVideoStream, localAudioStream]);
      if (joinStreamOptions?.videoMuted) {
        //localStream.muteVideo();
        localVideoStream.setEnabled(false);
        setIsVideoActive(false);
      }
      if (joinStreamOptions?.audioMuted) {
        //localStream.muteAudio();
        localAudioStream.setEnabled(false);
        setIsAudioActive(false);
      }
      attachToElement(videoPatientRef.current, localVideoStream);
    } catch (e) {
      console.log(e);
    }
  });

  useSignalingListener("videocall:admit", () => {
    joinConference();
    setIsTherapistVideoPlaying(true);
  });

  /**
   * Toggle the audio of the active stream
   */
  const toggleAudio = useCallback(() => {
    if (!audioStream.current) return;
    if (isAudioActive) {
      audioStream.current.setEnabled(false);
    } else {
      audioStream.current.setEnabled(true);
    }
    setIsAudioActive((audioActive) => !audioActive);
  }, [isAudioActive]);

  const toggleVideo = useCallback(() => {
    if (!videoStream.current) return;
    if (isVideoActive) {
      videoStream.current.setEnabled(false);
    } else {
      videoStream.current.setEnabled(true);
    }
    setIsVideoActive((videoActive) => !videoActive);
  }, [isVideoActive]);

  /**
   * Metodh to close the screen sharing. It unpublish
   * the stream, release it and update the state.
   */
  const closeShareScreen = useEventEffect(async () => {
    if (!shareScreenStream || !screenShareEngine.current) return;
    screenShareEngine.current.unpublish(shareScreenStream);
    if (Array.isArray(shareScreenStream)) {
      shareScreenStream.forEach((track) => {
        try {
          detachFromElement(videoScreenshareRef.current, track);

          track.close();
        } catch (e) {}
      });
    } else {
      try {
        detachFromElement(videoScreenshareRef.current, shareScreenStream);
        shareScreenStream.close();
      } catch (e) {}
    }
    setShareScreenStream(null);
    setIsScreenshareOn(false);
  });

  /**
   * Change the settings for the webcam and the microphone
   */
  const changeVideoCallSettings = async (
    toChange: MediaDeviceInfo,
    whatToChange: "audio" | "video"
  ) => {
    if (
      !videoStream.current ||
      !audioStream.current ||
      !videoTherapistRef.current
    )
      return;
    try {
      // if what to change is audio we create the options for that
      // audio device otherwise we leave it undefined
      const audio =
        whatToChange === "audio"
          ? {
              deviceId: { exact: toChange?.deviceId },
            }
          : undefined;

      // if whatToChange is video we create the options for that
      // video device otherwise we leave it undefined
      const video =
        whatToChange === "video"
          ? {
              deviceId: { exact: toChange?.deviceId },
              width: {
                min: 320,
                ideal: patientSTResults?.min < 1 ? 320 : 640,
                max: 1280,
              },
              height: {
                min: 240,
                ideal: patientSTResults?.min < 1 ? 240 : 480,
                max: 768,
              },
            }
          : undefined;

      const localMediaStream = await navigator?.mediaDevices?.getUserMedia({
        audio,
        video,
      });

      const track =
        whatToChange === "audio"
          ? localMediaStream?.getAudioTracks()[0]
          : localMediaStream?.getVideoTracks()[0];

      const createFunction =
        whatToChange === "audio"
          ? AgoraRTC.createCustomAudioTrack
          : AgoraRTC.createCustomVideoTrack;

      const localStream = createFunction({
        mediaStreamTrack: track,
      });

      const toUnpublish = whatToChange === "audio" ? audioStream : videoStream;

      //unpublish the current stream
      await engine.unpublish(toUnpublish.current);
      //release the webcam
      toUnpublish.current!.close();

      toUnpublish.current = localStream;

      if (whatToChange === "video" && videoPatientRef.current) {
        // reset the video patient srcObject
        videoPatientRef.current.srcObject = null;
        //attach the new stream to the patient video element
        attachToElement(videoPatientRef.current, localStream);
      }
      //publish the stream
      await engine.publish(localStream);
      setIsAudioActive(true);
      setIsVideoActive(true);
    } catch (e) {
      grayLog({
        message: e.message,
        type: e.name,
        stack: e.stack,
        userId: user?.profile?.sub || "Uknown user",
      });
      switch (e.name) {
        case "NotAllowedError":
        case "PermissionDeniedError":
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title:
                  "Il browser non ha l’autorizzazione per accedere alla webcam o al microfono",
                message: (
                  <>
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-permessi-e-consensi-webcam-microfono/"
                      target="_blank"
                    >
                      Clicca qui
                    </a>{" "}
                    e scopri come permettere al tuo browser di accedere a webcam
                    e microfono in base al dispositivo che stai utilizzando.
                  </>
                ),
              },
            },
          }));
          break;
        case "TrackStartError": //chrome
        case "NotReadableError":
          setCurrentCamera(defaultWebcam.current);
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title:
                  "La webcam o il microfono risultano essere in uso da un altro software",
                message:
                  "Chiudi gli altri programmi in uso e riaggiorna la pagina. In alternativa, seleziona un dispositivo diverso dal menu a destra.",
              },
            },
          }));
          break;
        case "DevicesNotFoundError": //chrome
        case "NotFoundError":
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title: "Non è stata trovata alcuna webcam o microfono",
                message: (
                  <>
                    Assicurati di avere collegato correttamente webcam o
                    microfono. In alternativa,{" "}
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-paziente/"
                      target="_blank"
                    >
                      clicca qui
                    </a>{" "}
                    per scoprire quali possono essere le cause e come risolvere.
                  </>
                ),
              },
            },
          }));
          break;
        case "OverconstrainedError":
        case "ConstraintNotSatisfiedError":
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title:
                  "Non è stata trovato alcun dispositivo valido per effettuare la videoseduta",
                message: (
                  <>
                    Assicurati di avere collegato correttamente webcam o
                    microfono. In alternativa,{" "}
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-paziente/"
                      target="_blank"
                    >
                      clicca qui
                    </a>{" "}
                    per scoprire quali possono essere le cause e come risolvere.
                  </>
                ),
              },
            },
          }));
          break;
        case "TypeError":
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title: "C’è un problema con la webcam o il microfono",
                message: (
                  <>
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-paziente/"
                      target="_blank"
                    >
                      Clicca qui
                    </a>{" "}
                    per scoprire quali possono essere le cause e come risolvere.
                  </>
                ),
              },
            },
          }));
          break;
        default:
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title: "C’è un problema con la webcam o il microfono",
                message: (
                  <>
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-paziente/"
                      target="_blank"
                    >
                      Clicca qui
                    </a>{" "}
                    per scoprire quali possono essere le cause e come risolvere.
                  </>
                ),
              },
            },
          }));
          break;
      }
    }
    //close the popup
    setIsVideoSettingsShowing(false);
  };

  //function to manage sharescreen
  const shareScreen = async () => {
    if (!screenShareEngine.current) return;
    const screenVideoTrack = await AgoraRTC.createScreenVideoTrack({});
    screenShareEngine.current.publish(screenVideoTrack);
    setShareScreenStream(screenVideoTrack);
    const videoTrack = Array.isArray(screenVideoTrack)
      ? screenVideoTrack[0]
      : screenVideoTrack;
    videoTrack.once("track-ended", () => {
      closeShareScreen();
    });
    setIsScreenshareOn(true);
    attachToElement(videoScreenshareRef.current, videoTrack);
    setIsWhiteboardOn(false);
  };

  const openOldWhiteboard = async () => {
    //if for some reason there's no videocallsession we just return
    if (!videoCallSession) return;
    //do the api call the retrieve the last whiteboard
    const oldWhiteboard = await api(
      user,
      `sessions/${videoCallSession?.id}/${videoCallSession?.idTherapist}/${videoCallSession?.idPatient}/whiteboard`,
      "GET"
    );
    //set the whiteboard on
    setIsWhiteboardOn(true);
    try {
      //send the custom event to open the whiteboard
      sendEvent("whiteboard:toggle", { isOpen: true });
      if (oldWhiteboard.ok !== false) {
        //if the request to retrieve the old whiteboard is succesfull we send also the event to
        //update the canvas on the patient side
        sendEvent("whiteboard:update-canvas", {
          canvas: JSON.parse(oldWhiteboard.whiteboard),
        });
      }
    } catch (e) {}
    if (oldWhiteboard.ok !== false) {
      //if the request to retrieve the old whiteboard is succesfull
      //we set our jsonCanvas
      setJsonCanvas(oldWhiteboard.whiteboard);
    }
    closeShareScreen();
  };

  const [previewStream, setPreviewStream] = useState<MediaStream | null>(null);
  const videoPreviewRef = useRef<HTMLVideoElement>();
  const videoTherapistRef = useRef<HTMLVideoElement>();
  const videoScreenshareRef = useRef<HTMLVideoElement>();
  const videoPatientRef = useRef<HTMLVideoElement>();
  const [isProblemsPopupOpen, setIsProblemsPopupOpen] = useState(false);
  const videoTherapistTimeout = useRef<typeof setTimeout>();
  const videoScreenshareTimeout = useRef<typeof setTimeout>();
  const videoPatientTimeout = useRef<typeof setTimeout>();
  const testMicrophoneRef = useRef();

  useSignalingListener(
    "videocall:closing",
    async () => {
      if (isConferenceJoined) {
        //if the conference is joined this means the therapist closed the session so we can
        //leave the conference, destroy it, release the webcam and update the
        //state variables relative to the videocall
        setSnackbar({
          open: true,
          message: "Il terapeuta ha chiuso la sessione.",
          isToJoin: false,
        });
        try {
          engine.leave();
          if (screenShareEngine.current) {
            screenShareEngine.current.leave();
          }
          if (videoStream.current) {
            videoStream.current.close();
          }
          if (audioStream.current) {
            audioStream.current.close();
          }
        } catch (e) {}
        closeShareScreen();
        if (unblock.current) unblock.current();
        if (!isAnon.current) {
          history.push("/home");
        } else {
          cleanUp();
          setIsEndedForAnonUser(true);
        }
        setIsConferenceJoined(false);
        api(user, `sessions/${videoCallSession.id}/end`, "PATCH");
      }
    },
    [isConferenceJoined, previewStream]
  );

  const history = useHistory();

  const unblock = useRef();

  useEffect(() => {
    if (unblock.current) unblock.current();
    unblock.current = history.block(() => {
      if (isConferenceJoined) {
        setIsClosingSession(true);
        return false;
      }
    });
  }, [isConferenceJoined]);

  useEffect(() => {
    handleVideoPreview(currentMicrophone, currentCamera);
  }, [currentMicrophone, currentCamera]);

  const handleVideoPreview = async (
    inUseMichrophone?: MediaDeviceInfo,
    inUseWebcam?: MediaDeviceInfo
  ) => {
    if (isJoined.current) return;
    try {
      const previewStreamLocal = await navigator?.mediaDevices?.getUserMedia({
        audio: inUseMichrophone
          ? {
              deviceId: { exact: inUseMichrophone.deviceId },
            }
          : true,
        video: inUseWebcam
          ? {
              deviceId: { exact: inUseWebcam.deviceId },
              width: {
                min: 320,
                ideal: patientSTResults?.min < 1 ? 320 : 640,
                max: 1024,
              },
              height: {
                min: 240,
                ideal: patientSTResults?.min < 1 ? 240 : 480,
                max: 768,
              },
            }
          : {
              width: {
                min: 320,
                ideal: patientSTResults?.min < 1 ? 320 : 640,
                max: 1024,
              },
              height: {
                min: 240,
                ideal: patientSTResults?.min < 1 ? 240 : 480,
                max: 768,
              },
            },
      });
      setPreviewStream((prev) => {
        stopStream(prev);
        return previewStreamLocal;
      });
      return previewStreamLocal;
    } catch (e) {
      grayLog({
        message: e.message,
        type: e.name,
        stack: e.stack,
        userId: user?.profile?.sub || "Uknown user",
      });
      switch (e.name) {
        case "NotAllowedError":
        case "PermissionDeniedError":
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title:
                  "Il browser non ha l’autorizzazione per accedere alla webcam o al microfono",
                message: (
                  <>
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-permessi-e-consensi-webcam-microfono/"
                      target="_blank"
                    >
                      Clicca qui
                    </a>{" "}
                    e scopri come permettere al tuo browser di accedere a webcam
                    e microfono in base al dispositivo che stai utilizzando.
                  </>
                ),
              },
            },
          }));
          break;
        case "TrackStartError": //chrome
        case "NotReadableError":
          setCurrentCamera(defaultWebcam.current);
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title:
                  "La webcam o il microfono risultano essere in uso da un altro software",
                message:
                  "Chiudi gli altri programmi in uso e riaggiorna la pagina. In alternativa, seleziona un dispositivo diverso dal menu a destra.",
              },
            },
          }));
          break;
        case "DevicesNotFoundError": //chrome
        case "NotFoundError":
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title: "Non è stata trovata alcuna webcam o microfono",
                message: (
                  <>
                    Assicurati di avere collegato correttamente webcam o
                    microfono. In alternativa,{" "}
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-paziente/"
                      target="_blank"
                    >
                      clicca qui
                    </a>{" "}
                    per scoprire quali possono essere le cause e come risolvere.
                  </>
                ),
              },
            },
          }));
          break;
        case "OverconstrainedError":
        case "ConstraintNotSatisfiedError":
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title:
                  "Non è stata trovato alcun dispositivo valido per effettuare la videoseduta",
                message: (
                  <>
                    Assicurati di avere collegato correttamente webcam o
                    microfono. In alternativa,{" "}
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-paziente/"
                      target="_blank"
                    >
                      clicca qui
                    </a>{" "}
                    per scoprire quali possono essere le cause e come risolvere.
                  </>
                ),
              },
            },
          }));
          break;
        case "TypeError":
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title: "C’è un problema con la webcam o il microfono",
                message: (
                  <>
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-paziente/"
                      target="_blank"
                    >
                      Clicca qui
                    </a>{" "}
                    per scoprire quali possono essere le cause e come risolvere.
                  </>
                ),
              },
            },
          }));
          break;
        default:
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title: "C’è un problema con la webcam o il microfono",
                message: (
                  <>
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-paziente/"
                      target="_blank"
                    >
                      Clicca qui
                    </a>{" "}
                    per scoprire quali possono essere le cause e come risolvere.
                  </>
                ),
              },
            },
          }));
          break;
      }
      return false;
    }
  };

  //everytime the preview video change we change the video in
  //the video element
  useEffect(() => {
    if (!videoPreviewRef.current) return;
    videoPreviewRef.current.srcObject = previewStream;
  }, [previewStream]);

  const cleanUp = () => {
    if (previewStream) {
      stopStream(previewStream);
    }
    if (unblock.current) unblock.current();
  };

  useOnUnmount(cleanUp, [previewStream]);

  useImperativeHandle(
    ref,
    () => ({
      //   sendSessionMessage,
    }),
    []
  );

  if (error) {
    cleanUp();
    return <NoRoom style={{ background: "white" }} />;
  }
  if (notIn) {
    cleanUp();
    return <NotIn style={{ background: "white" }} />;
  }

  return isEndedForAnonUser ? (
    <SessionEnded />
  ) : (
    <>
      <div
        className={classes.container}
        style={
          isConferenceJoined ? { gridTemplateRows: "1fr max-content" } : null
        }
      >
        {loading && (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        )}
        {!isConferenceJoined && (
          <Box display="flex" justifyContent="flex-end" padding="1rem">
            <img
              src={logoHorizontalWhite}
              className={classes.logoWaitingRoom}
            />
            <IconButton
              disabled={isClickedOnJoin}
              onClick={async () => {
                if (!isAnon.current) {
                  history.push("/home");
                } else {
                  cleanUp();
                  setIsEndedForAnonUser(true);
                }
                setPatientSTResults(null);
                setCurrentCamera(cameraList[0]);
                defaultWebcam.current = null;
              }}
            >
              <Close style={{ cursor: "pointer", color: "#fff" }} />
            </IconButton>
          </Box>
        )}
        <Box height="100%">
          <Box
            style={{ display: isConferenceJoined ? "" : "none" }}
            height="100%"
            position="relative"
          >
            <div className={classes.joinedContainer}>
              {isConferenceJoined && (
                <div
                  className={`${classes.sideBar} ${
                    isSidebarOpen ? classes.open : ""
                  }`}
                >
                  <button
                    onClick={() => setIsSidebarOpen((prev) => !prev)}
                    className={`${classes.sideBarToggle} ${
                      isSidebarOpen ? classes.toggleOpened : ""
                    }`}
                  >
                    <DoubleArrowIcon />
                  </button>
                  <VideocallSidebar
                    changeVideoCallSettings={changeVideoCallSettings}
                    isTherapistVideoPlaying={isTherapistVideoPlaying}
                    videoCallSession={videoCallSession}
                    networkConditions={networkConditions}
                    currentView={currentView}
                    setCurrentView={setCurrentView}
                    setSavedCamera={(data) => (defaultWebcam.current = data)}
                  />
                </div>
              )}
              <div className={classes.screenShareGridElement}>
                <video
                  ref={videoScreenshareRef}
                  onPlay={() => {
                    setIsTherapistVideoPlaying(true);
                    clearTimeout(videoScreenshareTimeout.current);
                  }}
                  style={{
                    position: "absolute",
                    background: "black",
                    objectFit: "contain",
                    maxWidth: "100%",
                    height: "100%",
                    width: "100%",
                    display:
                      isScreenshareOn &&
                      !isWhiteboardOn &&
                      isTherapistVideoPlaying
                        ? ""
                        : "none",
                  }}
                  autoPlay
                  playsInline
                  id="screenshare"
                  width="auto"
                  poster="/wait.png"
                />
              </div>
              <PsyWhiteboard
                className={classes.mainGridElement}
                ref={psyWhiteboardRef}
                hidden={!isWhiteboardOn}
                onExit={onWhiteboardExit}
                jsonCanvas={jsonCanvas}
                onCanvasUpdate={(json, skipValue) => {
                  if (!skipValue) {
                    setJsonCanvas(json);
                  }
                  try {
                    sendEvent("whiteboard:update-canvas", { canvas: json });
                  } catch (e) {}
                  if (videoCallSession) {
                    api(
                      user,
                      `sessions/${videoCallSession.id}/whiteboard/json`,
                      "PATCH",
                      {
                        sessionId: videoCallSession.id,
                        whiteboard: JSON.stringify(json),
                      }
                    );
                  }
                }}
              />
              <div
                className={`${classes.mainGridElement} ${
                  isScreenshareOn || isWhiteboardOn
                    ? settings?.canSeeHimself
                      ? `${classes.loweredPatientGridElement} ${classes.littleVideo}`
                      : `${classes.lowerRightGridElement} ${classes.littleVideo}`
                    : ""
                }`}
              >
                <video
                  ref={videoTherapistRef}
                  onPlay={() => {
                    setIsTherapistVideoPlaying(true);
                    clearTimeout(videoTherapistTimeout.current);
                  }}
                  style={{
                    position: "absolute",
                    objectFit: currentView,
                    maxWidth: "100%",
                    height: "100%",
                    width: "100%",
                    display: isTherapistVideoPlaying ? "" : "none",
                  }}
                  autoPlay
                  playsInline
                  id="therapist"
                  width="auto"
                  poster="/wait.png"
                />
              </div>
              <div
                className={`${classes.lowerRightGridElement} ${
                  isScreenshareOn || isWhiteboardOn ? classes.littleVideo : null
                } ${!settings?.canSeeHimself ? classes.hiddenVideo : ""}`}
              >
                <video
                  className="flipped"
                  ref={videoPatientRef}
                  style={{
                    position: "absolute",
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                  }}
                  muted
                  autoPlay
                  playsInline
                  id="patient"
                  poster="/wait_flipped.png"
                />
              </div>
              {!matches && settings?.canSeeTimer && (
                <PsyTimer
                  className={classes.mobileTimer}
                  autoStartOnNullDate={false}
                  start={therapistEnteringDate}
                />
              )}
              {matches && (
                <ul
                  onClick={() => setIsViewSwitcherOpen((prev) => !prev)}
                  className={`${classes.viewSwitcherList} ${
                    isViewSwitcherOpen ? classes.viewSwitcherOpened : ""
                  }`}
                >
                  <li>
                    <Button
                      classes={{
                        endIcon: classes.endIcon,
                        label: classes.viewSwitcherButtonLabel,
                      }}
                      startIcon={<GridViewIcon />}
                      endIcon={
                        !isViewSwitcherOpen ? <ExpandMore /> : <ExpandLess />
                      }
                      fullWidth
                      className={classes.viewSwitcherButton}
                    >
                      Vista
                    </Button>
                  </li>
                  <li>
                    <Button
                      classes={{
                        endIcon: classes.endIcon,
                        label: classes.viewSwitcherButtonLabel,
                      }}
                      startIcon={<FullViewIcon />}
                      endIcon={
                        currentView === "contain" ? <Done /> : <VoidIcon />
                      }
                      onClick={() => setCurrentView("contain")}
                      fullWidth
                      className={classes.viewSwitcherButton}
                    >
                      Originale
                    </Button>
                  </li>
                  <li>
                    <Button
                      classes={{
                        endIcon: classes.endIcon,
                        label: classes.viewSwitcherButtonLabel,
                      }}
                      startIcon={<AdaptedViewIcon />}
                      endIcon={
                        currentView === "cover" ? <Done /> : <VoidIcon />
                      }
                      onClick={() => setCurrentView("cover")}
                      fullWidth
                      className={classes.viewSwitcherButton}
                    >
                      Adatta a schermo
                    </Button>
                  </li>
                  {document?.body?.requestFullscreen && (
                    <>
                      <Divider
                        style={{ backgroundColor: "rgba(255,255,255,.7)" }}
                      />
                      <li>
                        <Button
                          onClick={() => {
                            if (!document.fullscreenElement) {
                              document.body.requestFullscreen();
                              return;
                            }
                            document.exitFullscreen();
                          }}
                          startIcon={
                            document.fullscreenElement ? (
                              <FullscreenExit />
                            ) : (
                              <Fullscreen />
                            )
                          }
                          endIcon={
                            document.fullscreenElement ? <Done /> : <VoidIcon />
                          }
                          classes={{
                            endIcon: classes.endIcon,
                            label: classes.viewSwitcherButtonLabel,
                          }}
                          fullWidth
                          className={classes.viewSwitcherButton}
                        >
                          Schermo intero
                        </Button>
                      </li>
                    </>
                  )}
                </ul>
              )}
              <VideoPoster
                style={{
                  gridColumn: "1 / -1",
                  gridRow: "1 / -1",
                  height: "100%",
                  position: "absolute",
                  color: "black",
                }}
                hidden={!!therapistEnteringDate}
                name={videoCallSession?.therapistFirstName}
                lastname={videoCallSession?.therapistLastname}
              />
            </div>
          </Box>
          <Box
            style={{ display: isConferenceJoined ? "none" : "" }}
            height="100%"
            className={styles.frontDoor}
          >
            <Typography variant="h4" style={{ marginTop: "3rem" }}>
              Benvenuto nella sala d'attesa virtuale di{" "}
              {userProfile?.therapistName} {userProfile?.therapistLastName}.
            </Typography>
            <div>
              <Typography style={{ fontSize: "1.25rem", paddingTop: ".3rem" }}>
                {hasPatientKnocked ? (
                  <>
                    {userProfile?.therapistName}{" "}
                    {userProfile?.therapistLastName} è stato informato della tua
                    presenza in sala d’attesa, attendi che ti accolga nella
                    stanza virtuale per iniziare la seduta.
                  </>
                ) : (
                  <>
                    Informa il terapeuta del tuo arrivo chiedendogli di entrare
                  </>
                )}
              </Typography>
              {!hasPatientKnocked && (
                <Button
                  disabled={!previewStream || settingpatient?.isArchived}
                  style={{ marginTop: "2rem", fontSize: "1rem" }}
                  startIcon={<MeetingRoom />}
                  color="primary"
                  variant="contained"
                  onClick={askToEnter}
                >
                  Chiedi di entrare
                </Button>
              )}
              <div>
                In caso di problemi,{" "}
                <button
                  className={classes.linkButton}
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  aggiorna la pagina
                </button>
              </div>
            </div>
            <div className={classes.mediaPreviewAndSelect}>
              <div className={classes.mediaPreview}>
                <video
                  className={`flipped ${
                    joinStreamOptions?.videoMuted ? classes.videoMuted : null
                  }`}
                  ref={videoPreviewRef}
                  autoPlay
                  playsInline
                  muted
                  poster="./wait_flipped.png"
                />
                <div className={classes.previewActions}>
                  <button
                    disabled={hasPatientKnocked}
                    onClick={() => {
                      setJoinStreamOptions((prev) => ({
                        ...prev,
                        audioMuted: !prev.audioMuted,
                      }));
                    }}
                  >
                    {joinStreamOptions?.audioMuted ? <MicOff /> : <Mic />}
                  </button>
                  <button
                    disabled={hasPatientKnocked}
                    onClick={() => {
                      setJoinStreamOptions((prev) => ({
                        ...prev,
                        videoMuted: !prev.videoMuted,
                      }));
                    }}
                  >
                    {joinStreamOptions?.videoMuted ? (
                      <VideocamOff />
                    ) : (
                      <Videocam />
                    )}
                  </button>
                </div>
              </div>
              <div className={classes.mediaSelect}>
                <span>
                  Seleziona i dispositivi che vuoi utilizzare ed effettua un
                  test per verificare che tutto funzioni correttamente.
                </span>
                <div
                  style={{
                    opacity: hasPatientKnocked ? 0.5 : 1,
                  }}
                  onClick={
                    isPatientSTRunning || hasPatientKnocked
                      ? null
                      : () => {
                          window.SomApi.startTest();
                          setIsPatientSTRunning(true);
                        }
                  }
                  className={classes.checkConnection}
                >
                  {isPatientSTRunning ? (
                    <CircularProgress color="inherit" size="1.5rem" />
                  ) : !patientSTResults ? (
                    <NetworkCheck />
                  ) : (
                    <NetworkResults
                      size="1.5rem"
                      value={
                        Math.min(
                          patientSTResults?.download,
                          patientSTResults?.upload
                        ) || 0
                      }
                      min={0}
                      max={12}
                    />
                  )}
                  <span>
                    {connectionMessage?.message || "Test connessione"}
                  </span>
                </div>
                <button
                  disabled={!previewStream}
                  onClick={() => {
                    testMicrophoneRef.current.start();
                  }}
                >
                  Test
                </button>
                <TextField
                  style={{ textAlign: "left" }}
                  disabled={hasPatientKnocked}
                  SelectProps={{
                    classes: {
                      outlined: classes.outlinedStyles,
                      iconOutlined: classes.iconOutlinedStyles,
                    },
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutlineStyles,
                      focused: classes.notchedOutlineStyles,
                      adornedStart: classes.outlinedStyles,
                      disabled: classes.disabledStyles,
                    },
                    startAdornment: <VolumeUp className={classes.adornment} />,
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelStyles,
                    },
                  }}
                  margin="dense"
                  variant="outlined"
                  label="Microfono"
                  key={`currentMicrophone-${!!currentMicrophone}`}
                  value={currentMicrophone?.deviceId}
                  onChange={async (e) => {
                    const newMic = microphoneList.find(
                      (mic) => mic.deviceId === e.target.value
                    );
                    if (!newMic) return;
                    setCurrentMicrophone(newMic);
                  }}
                  select
                  fullWidth
                >
                  {microphoneList.map((audioInput) => (
                    <MenuItem
                      key={audioInput.deviceId}
                      value={audioInput.deviceId}
                    >
                      {audioInput.label}
                    </MenuItem>
                  ))}
                </TextField>
                <MicLevels
                  ref={testMicrophoneRef}
                  bars={7}
                  color="#444"
                  progressColor="white"
                  width="100%"
                  height="2rem"
                  stream={previewStream}
                />
                <TextField
                style={{textAlign:"left"}}
                  disabled={hasPatientKnocked}
                  SelectProps={{
                    classes: {
                      outlined: classes.outlinedStyles,
                      iconOutlined: classes.iconOutlinedStyles,
                    },
                  }}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.notchedOutlineStyles,
                      focused: classes.notchedOutlineStyles,
                      adornedStart: classes.outlinedStyles,
                      disabled: classes.disabledStyles,
                    },
                    startAdornment: <Videocam className={classes.adornment} />,
                  }}
                  InputLabelProps={{
                    classes: {
                      root: classes.labelStyles,
                    },
                  }}
                  margin="dense"
                  variant="outlined"
                  label="Fotocamera"
                  key={`currentCamera-${!!currentCamera}`}
                  value={currentCamera?.deviceId}
                  select
                  fullWidth
                  onChange={async (e) => {
                    const newCamera = cameraList.find(
                      (camera) => camera.deviceId === e.target.value
                    );
                    if (!newCamera) return;
                    defaultWebcam.current = currentCamera;
                    setCurrentCamera(newCamera);
                  }}
                >
                  {cameraList.map((videoInput) => (
                    <MenuItem
                      key={videoInput.deviceId}
                      value={videoInput.deviceId}
                    >
                      {videoInput.label}
                    </MenuItem>
                  ))}
                </TextField>
                <a
                  style={{ color: "white", justifySelf: "center" }}
                  href="https://www.psycare.it/risoluzione-problemi-paziente/"
                  target="_blank"
                >
                  Problemi audio/video?
                </a>
              </div>
            </div>
            {/* {
                            isSpeedTestAvailable &&
                            <Box style={{ gap: "1rem", backgroundColor: "#ededed", borderRadius: ".3rem" }} padding="1rem" fontWeight="bold" maxWidth="80%" fontSize=".9em" margin="1rem" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                                Vuoi verificare se la tua connessione internet è sufficiente per una buona videochiamata?
                                <IconButton disabled={hasPatientKnocked} style={{ boxShadow: "0 0 1rem gray", alignSelf: "center" }} onClick={isPatientSTRunning ? null : () => {
                                    window.SomApi.startTest();
                                    setIsPatientSTRunning(true);
                                }}>
                                    {
                                        isPatientSTRunning ?
                                            <CircularProgress size="1.5rem" />
                                            :
                                            !patientSTResults ?
                                                <NetworkCheck /> :
                                                <NetworkResults size="1.5rem" value={patientSTResults?.min || 0} min={0} max={12} />
                                    }
                                </IconButton>
                                <span style={{ fontWeight: "normal", textAlign: "center" }}>
                                    {connectionMessage?.message}
                                    {
                                        connectionMessage?.message &&
                                        <br />
                                    }
                                    {
                                        connectionMessage?.link &&
                                        <Link target="_blank" href="https://www.psycare.it/psicologia-on-line/problemi-di-connessione/">Come migliorare la connessione?</Link>
                                    }
                                </span>
                            </Box>
                        }
                        {!isConsentGiven && <Box fontSize=".9em" margin="1rem" color="red">Non è possibile effettuare l'accesso senza dare il consenso all'utilizzo di webcam e microfono.</Box>} */}
          </Box>
        </Box>
        <div className={classes.buttonsContainer}>
          {isConferenceJoined && (
            <Box
              boxSizing="border-box"
              padding="1rem"
              width="100%"
              height="100%"
              position="relative"
              display="grid"
              gridTemplateColumns="20% 1fr 20%"
              alignItems="center"
            >
              <div className={classes.logoAndQoS}>
                <img style={{ maxWidth: "100%" }} src={logoHorizontalWhite} />
                {networkConditions()}
              </div>
              <Box
                style={{ gap: "1.5rem" }}
                width="100%"
                display="flex"
                justifyContent="center"
              >
                <Box position="relative">
                  <div
                    className={classes.whiteboardMenuWrapper}
                    style={{ transform: isWhiteboardMenuOn ? "scaleY(1)" : "" }}
                  >
                    <div className={classes.whiteboardMenu}>
                      <List dense={true}>
                        <ListItem
                          button
                          onClick={() => {
                            setIsWhiteboardOn(true);
                            setIsWhiteboardMenuOn(false);
                            try {
                              sendEvent("whiteboard:toggle", { isOpen: true });
                            } catch (e) {}
                            closeShareScreen();
                          }}
                        >
                          <ListItemText>Apri lavagna</ListItemText>
                        </ListItem>
                        <ListItem
                          button
                          onClick={async () => {
                            if (jsonCanvas) {
                              setIsOpeningOldWhiteboard(true);
                            } else {
                              openOldWhiteboard();
                            }
                            setIsWhiteboardMenuOn(false);
                          }}
                        >
                          <ListItemText>Carica ultima lavagna</ListItemText>
                        </ListItem>
                      </List>
                    </div>
                  </div>
                  <VideoCallButton
                    size="small"
                    iconSize={matches ? "2rem" : "1.5rem"}
                    pressed={isWhiteboardMenuOn}
                    disabled={!isTherapistVideoPlaying}
                    pressedTooltip="Lavagna virtuale"
                    nonPressedTooltip="Lavagna virtuale"
                    PressedIcon={OpenWhiteboardIcon}
                    NonPressedIcon={
                      isWhiteboardOn ? CloseWhiteboardIcon : OpenWhiteboardIcon
                    }
                    onClick={() => {
                      if (!isWhiteboardOn) {
                        setIsWhiteboardMenuOn((prev) => !prev);
                        return;
                      }
                      onWhiteboardExit();
                    }}
                  />
                </Box>
                <VideoCallButton
                  size="small"
                  iconSize={matches ? "2rem" : "1.5rem"}
                  disabled={browserInfo.mobile || browserInfo.isIOS}
                  pressed={shareScreenStream}
                  pressedTooltip="Chiudi condivisione"
                  nonPressedTooltip="Condividi schermo"
                  PressedIcon={CancelPresentation}
                  NonPressedIcon={PresentToAll}
                  onClick={shareScreenStream ? closeShareScreen : shareScreen}
                />
                <VideoCallButton
                  size="small"
                  iconSize={matches ? "2rem" : "1.5rem"}
                  pressed={!isAudioActive}
                  pressedTooltip="Attiva audio"
                  nonPressedTooltip="Disattiva audio"
                  PressedIcon={MicOff}
                  NonPressedIcon={Mic}
                  onClick={toggleAudio}
                />
                <VideoCallButton
                  size="small"
                  iconSize={matches ? "2rem" : "1.5rem"}
                  pressed={!isVideoActive}
                  pressedTooltip="Attiva video"
                  nonPressedTooltip="Disattiva video"
                  PressedIcon={VideocamOff}
                  NonPressedIcon={Videocam}
                  onClick={toggleVideo}
                />
              </Box>
              <div
                className={`${classes.closeAndTimer} ${
                  !settings?.canSeeTimer ? classes.loneButton : ""
                }`}
              >
                {matches && settings?.canSeeTimer && (
                  <PsyTimer
                    style={{ color: "white" }}
                    autoStartOnNullDate={false}
                    start={therapistEnteringDate}
                  />
                )}
                <VideoCallButton
                  style={{
                    backgroundColor: "rgb(196,49,75)",
                    border: "none",
                    color: "white",
                  }}
                  nonPressedTooltip="Concludi Sessione"
                  NonPressedIcon={CallEnd}
                  onClick={() => setIsClosingSession(true)}
                />
              </div>
            </Box>
          )}
        </div>
      </div>
      <Dialog open={isProblemsPopupOpen}>
        <DialogTitle disableTypography={true}>
          <Typography style={{ fontWeight: "bold" }} variant="h6">
            Si è verificato un problema nel collegamento alla videoseduta
          </Typography>
        </DialogTitle>
        <DialogContent>
          Clicca su riprova per riprendere la videoseduta. Se il problema
          dovesse ripresentarsi, ti consigliamo di utilizzare Google Chrome.
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (videoTherapistRef?.current?.play)
                videoTherapistRef.current.play();
              if (videoPatientRef?.current?.play)
                videoPatientRef.current.play();
              if (videoScreenshareRef?.current?.play)
                videoScreenshareRef.current.play();
              setIsProblemsPopupOpen(false);
            }}
          >
            Riconnetti
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={videocallErrorPopup}>
        <DialogTitle disableTypography={true}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography style={{ fontWeight: "bold" }} variant="h6">
              Si è verificato un problema
            </Typography>
            <button
              style={{
                appearance: "none",
                background: "none",
                border: "none",
                margin: "0",
                padding: "0",
                cursor: "pointer",
              }}
              onClick={() => {
                setVideocallErrorPopup(false);
              }}
            >
              <Close />
            </button>
          </div>
        </DialogTitle>
        <DialogContent>
          Clicca su Aggiorna per aggiornare la pagina e ripetere il processo di
          inizializzazione della videochiamata.
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              window.location = window.location;
            }}
          >
            Aggiorna
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog
        open={isOpeningOldWhiteboard}
        acceptButtonText="Continua"
        title="Stai per caricare la lavagna della seduta precedente"
        onClose={(result) => {
          if (result) {
            openOldWhiteboard();
          }
          setIsOpeningOldWhiteboard(false);
        }}
      >
        Proseguendo, il lavoro sulla lavagna attuale verrà perso. Vuoi
        continuare?
      </AlertDialog>
      <AlertDialog
        open={isClosingSession}
        acceptButtonText="Conferma"
        title="Stai uscendo dalla seduta"
        onClose={(result) => {
          if (result) {
            leaveConference(false);
          }
          setIsClosingSession(false);
        }}
      >
        Sei sicuro di voler chiudere la seduta?
      </AlertDialog>
    </>
  );
};
export default forwardRef(VideoCallPopup);
