
import { SvgIcon } from '@material-ui/core';
import React from 'react';
const ColorIcon = props => {
    return (
        <SvgIcon {...props}>
            <circle cx="12" cy="12" r="11.5" fill="currentColor" stroke="lightgray" />
        </SvgIcon>
    );
};
export default ColorIcon;
