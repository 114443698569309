import { Box, Icon, Stepper, Step, StepLabel, StepConnector, makeStyles, withStyles, useTheme, useMediaQuery } from '@material-ui/core';
import React, { useMemo, useState } from 'react';

const useStyles = makeStyles((theme) => {
    return ({
        active: {
            backgroundColor: theme.palette.primary.light,
            backgroundImage: `linear-gradient(135deg, rgba(255,255,255,.5), rgba(0,0,0,.6))`,
            cursor: "pointer"
        },
        disabled: {
            backgroundColor: "lightgrey"
        },
        activeText: {
            fontWeight: "bold"
        },
        disabledText: {
            color: "lightgrey"
        },
        bullet: {
            position: "relative",

            "&::before": {
                content: "''",
                position: "absolute",
                top: "50%",
                right: "100%",
                width: "2rem",
                height: "5px",
                backgroundColor: "lightgrey",
                zIndex: -1
            }
        }
    })
});

const ColorlibConnector = withStyles( theme => ({
    alternativeLabel: {
        padding: "1.5em",
    },
    active: {
        '& $line': {
            backgroundImage:
                `linear-gradient(135deg, #f0f0f5, ${theme.palette.primary.main})`,
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
            `linear-gradient(135deg, #f0f0f5, ${theme.palette.primary.main})`,
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
}))(StepConnector);

const ProgressTabs = (props) => {
    let { list, active, goToStep } = props;
    const styles = useStyles();
    const activeStep = useMemo(() => {
        return list.findIndex(elem => elem.id == active);
    }, [list, active])
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        matches?
        <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
            {list.map((elem) => (
                <Step key={elem.title}>
                    <StepLabel icon={
                        <Box onClick={elem.id<=active?()=> goToStep(elem.id):()=>{}} boxSizing="content" padding="1em" borderRadius="50%" display="flex" justifyContent="center" alignItems="center" className={elem.id<=active?styles.active:styles.disabled}>
                            <Icon fontSize="large" style={{color: "white"}}>{elem.icon}</Icon>
                        </Box>
                    }>{elem.title}</StepLabel>
                </Step>
            ))}
        </Stepper>
        :
        <Box px={4} py={2} style={{ gap: "1rem" }} width="100%" display="flex" justifyContent="flex-start" alignItems="center">
            <Box boxSizing="content" padding=".5em" borderRadius="50%" display="flex" justifyContent="center" alignItems="center" className={styles.active}>
                <Icon style={{ color: "white" }}>{list[activeStep].icon}</Icon>
            </Box>
            <Box><h3>{list[activeStep].title} {`(${activeStep + 1}/${list.length})`}</h3></Box>
        </Box>
    );
};

export default ProgressTabs;