import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    container: {
        background: "white",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: "auto",
        display: "grid",
        gridAutoRows: "min-content",
        justifyItems: "center",
        gap: "1rem",
        padding: "10vh 1rem",
        "& > *": {
            maxWidth: "95%",
            textAlign: "center",
            maxHeight: "30vh",
            [theme.breakpoints.up("sm")]: {
                maxWidth: "60%",
            }
        },
        "& :is(a, h4)": {
            fontWeight: 600,
            color: "black",
            textDecoration: "none",
        },
        "& > span": {
            marginBottom: "-1rem",
            fontSize: "1.25rem",
        }
    }
}));

const GeneralError = props => {
    const styles = useStyles();
    return <div className={styles.container}>
        <img src="/logo_transparent_psycare.png" />
        <Typography variant="h4">Errore imprevisto, ci scusiamo per il disagio</Typography>
        <span>Si è verificato un errore</span>
        <span>Si prega di ricaricare la pagina o di contattare PsyCare all'indirizzo mail <a href="mailto:help@psycare.it">help@psycare.it</a></span>
    </div>;
};
export default GeneralError;