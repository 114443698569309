
import { SvgIcon } from '@material-ui/core';
import React from 'react';
const FiglioIcon = props => {
        return (
                <SvgIcon {...props}>
                        <line x1="11.5112" y1="22" x2="11.5112" y2="2" stroke="currentColor" />
                </SvgIcon>
        )
};
export default FiglioIcon;
