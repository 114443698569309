import { Avatar } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import React from 'react';

const avatarInitialsName = (firstName, lastName) => {
    if (!firstName && !lastName) return null; //You can't get name and surname
    const initialName = firstName ? firstName.charAt(0) : "";
    const initialSurname = lastName ? lastName.charAt(0) : "";
    return `${initialName.toUpperCase()}${initialSurname.toUpperCase()}`;
};

const PsyAvatar = ({ firstName, lastName, ...props }) => {
    return (
        <Avatar {...props}>
            {avatarInitialsName(firstName, lastName) || <Person />}
        </Avatar>
    )
};
export default PsyAvatar;