import React from 'react'
import { SvgIcon } from '@material-ui/core';

const PaymentHistoryIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <path d="M14.2632 15.8333V16.5556C14.2632 17.35 13.6 18 12.7895 18H2.47368C1.65579 18 1 17.35 1 16.5556V6.44444C1 5.65 1.65579 5 2.47368 5H12.7895C13.6 5 14.2632 5.65 14.2632 6.44444V7.16667H7.63158C6.81368 7.16667 6.15789 7.81667 6.15789 8.61111V14.3889C6.15789 15.1833 6.81368 15.8333 7.63158 15.8333H14.2632ZM7.63158 14.3889H15V8.61111H7.63158V14.3889ZM10.5789 12.5833C9.96737 12.5833 9.47368 12.0994 9.47368 11.5C9.47368 10.9006 9.96737 10.4167 10.5789 10.4167C11.1905 10.4167 11.6842 10.9006 11.6842 11.5C11.6842 12.0994 11.1905 12.5833 10.5789 12.5833Z"/>
            <path d="M22 7H17V9.7H17.0042L17 9.7045L18.6667 11.5L17 13.3L17.0042 13.3045H17V16H22V13.3045H21.9958L22 13.3L20.3333 11.5L22 9.7045L21.9958 9.7H22V7ZM21.1667 13.525V15.1H17.8333V13.525L19.5 11.725L21.1667 13.525ZM21.1667 9.475L19.5 11.275L17.8333 9.475V7.9H21.1667V9.475Z"/>
        </SvgIcon>
    )
}

export default PaymentHistoryIcon