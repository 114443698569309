import { SvgIcon } from '@material-ui/core';
import React from 'react';

const ShapesIcon = props => {
    return (
        <SvgIcon {...props}>
            <path fill="none" stroke="currentColor" d="M15.8683 16.0225H1.51126L8.6898 3.17674L15.8683 16.0225Z" stroke-width="1.51127" stroke-linejoin="round" />
            <path fill="none" stroke="currentColor" d="M12.5303 10.096C13.8319 9.36272 15.3388 9.07843 16.8181 9.28706C18.2974 9.49569 19.6668 10.1856 20.7148 11.2503C21.7629 12.3149 22.4312 13.695 22.6165 15.1774C22.8019 16.6598 22.4939 18.162 21.7403 19.4519C20.9866 20.7418 19.8292 21.7477 18.4468 22.3141C17.0644 22.8805 15.5339 22.976 14.0919 22.5857C12.6498 22.1955 11.3764 21.3413 10.4683 20.155C9.56016 18.9687 9.06793 17.5165 9.06763 16.0225" stroke-width="1.51127" stroke-linejoin="round" />
        </SvgIcon>
    );
};
export default ShapesIcon;