import { Box, Chip, CircularProgress, Grid, makeStyles, Paper, Tab, TextField, useMediaQuery } from '@material-ui/core';
import { AccountBalanceWallet, AlternateEmail, ContactPhone, PhoneAndroid, PhoneEnabled } from '@material-ui/icons';
import PersonIcon from '@material-ui/icons/Person';
import { useTheme } from '@material-ui/styles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PaymentItem from '../components/Payments/PaymentItem';
import PsyTabs from '../components/PsyTabs';
import { useUser, useUserProfile } from '../contexts/UserContext';
import useApi from '../hooks/useApi';

const useStyles = makeStyles(theme => ({
    marginLi: {
        margin: "0rem 0",
        [theme.breakpoints.up('sm')]: {
            margin: "1rem 0",
        }
    },
    paddingUl: {
        paddingLeft: '1rem',
    },
    formBox: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: ".3rem",
        margin: "2em 0",
        width: "100%",
        padding: "1em",
        position: "relative",

        "&::before": {
            content: "attr(data-title)",
            position: "absolute",
            left: "1em",
            backgroundColor: "white",
            padding: "0 1em",
            transform: "translateY(calc(-50% - 1em))",
            fontStyle: "italic"
        }
    },
    circularProgressDiv: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    nrmText: {
        fontWeight: '400',
        fontSize: '1.125rem',
    },
    paper: {
        width: "100%", height: "100%", padding: "1rem",
        [theme.breakpoints.up('sm')]: {
            width: "100%", height: "100%", padding: "2rem",
        }
    },
}));

const Therapist = props => {
    const [userDTO, setUserDTO] = useState(null);
    const [mobilePhone, setMobilePhone] = useState(null);
    const [phone, setPhone] = useState(null);
    const api = useApi();
    const user = useUser();
    const userProfile = useUserProfile();
    const styles = useStyles();
    const theme = useTheme();
    const matchesUpSm = useMediaQuery(theme.breakpoints.up('sm'));
    const [activeTab, setActiveTab] = useState(props?.location?.state?.activeTab || 0);
    useEffect(() => {
        const getUserAsync = async () => {
            const response = await api(user, `therapists/${userProfile.idTherapist}/fullprofile`, "GET");
            if (response.isSuccessStatusCode === false) {

            } else {
                setUserDTO(response);
                setMobilePhone(response?.company?.listPhones?.find(elem => elem.type == "cellphone"));
                setPhone(response?.company?.listPhones?.find(elem => elem.type == "phone"));
            }
        };
        if (user) {
            getUserAsync();
        }
    }, [user, userProfile]);

    useEffect(() => {
        if (props.layoutOptions) {
            props.layoutOptions({
                title: "Il mio terapeuta",
                disabled: false,
                Icon: PersonIcon,
            });
        }
    }, []);

    const [paymentItems, setPaymentItems] = useState({}); //List of payment items to map with PaymentItem functional component
    const [isLoading, setIsLoading] = useState(false);
    const [isPaymentError, setIsPaymentError] = useState(false);
    const isPaymentExist = useMemo(() => {
        if (!paymentItems) return false;
        return !(Object.values(paymentItems).flat().length === 0);
    }, [paymentItems]);
    //UseEffect for fetch payment data
    useEffect(() => {
        getPaymentAsync();
    }, [user, userProfile]);

    const getPaymentAsync = useCallback(async () => {
        setIsLoading(true);
        setIsPaymentError(false);
        let response = await api(user, `payments/${userProfile?.idTherapist}`, "GET");
        if (response.ok === false) {
            setIsLoading(false);
            setIsPaymentError(true);
        } else {
            setPaymentItems(response);
            setIsLoading(false);
            setIsPaymentError(false);
        }
    }, [user, userProfile]);

    return (
        <>
            <PsyTabs
                variant="scrollable"
                value={activeTab}
                indicatorColor="primary"
                onChange={(_, newValue) => { setActiveTab(newValue); }}>
                <Tab
                    icon={<PersonIcon />}
                    label="Dati generali" />
                <Tab
                    icon={<ContactPhone />}
                    label="Contatti" />
                <Tab
                    icon={<AccountBalanceWallet />}
                    label="Metodi di pagamento" />
            </PsyTabs>
            <Box marginTop="1rem" width="100%" display="flex" justifyContent="center" alignItems="center">
                <Paper className={styles.paper}>
                    {
                        !userDTO ?
                            <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Box>
                            :
                            <>
                                <Box hidden={activeTab != 0}>
                                    <Grid container spacing="3">
                                        <Grid item xs="12" lg="12">
                                            <Grid container spacing="3">
                                                <Grid item xs="12" lg="4" style={{ position: "relative" }}>
                                                    <TextField inputProps={{ readOnly: true }} style={{ width: "100%" }} id="cognome" label="Cognome" defaultValue={userDTO?.therapist?.lastName} />
                                                </Grid>
                                                <Grid item xs="12" lg="4" style={{ position: "relative" }}>
                                                    <TextField inputProps={{ readOnly: true }} style={{ width: "100%" }} id="nome" label="Nome" defaultValue={userDTO?.therapist?.firstName} />
                                                </Grid>
                                                <Grid item xs="12" lg="4" style={{ position: "relative" }}>
                                                    <TextField inputProps={{ readOnly: true }} style={{ width: "100%" }} id="partitaIva" name="partitaIva" label="Partita Iva" defaultValue={userDTO?.company?.vatCode} />
                                                </Grid>
                                                <Grid item xs="12" lg="4" style={{ position: "relative" }}>
                                                    <TextField inputProps={{ readOnly: true }} style={{ width: "100%" }} id="qualifica" name="qualifica" label="Qualifica" defaultValue={userDTO?.therapist?.title} />
                                                </Grid>
                                                <Grid item xs="12" lg="4" style={{ position: "relative" }}>
                                                    <TextField inputProps={{ readOnly: true }} style={{ width: "100%" }} id="iscrizioneAlbo" name="iscrizioneAlbo" label="N. iscrizione Albo" value={userDTO?.therapist?.registrationNumber} />
                                                </Grid>
                                                <Grid item xs="12" lg="4" style={{ position: "relative" }}>
                                                    <TextField inputProps={{ readOnly: true }} style={{ width: "100%" }} id="regioneIscrizione" name="regioneIscrizione" label="Regione/Provincia iscrizione" defaultValue={userDTO?.therapist?.registrationDistrict} />
                                                </Grid>
                                            </Grid>

                                            {!userDTO?.company?.isAddressHidden &&
                                                <Grid item xs="12" className={styles.formBox} data-title="Indirizzo studio">
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={4}>
                                                            <TextField inputProps={{ readOnly: true }} name="regione" label="Regione" defaultValue={userDTO?.company?.addresses?.find(elem => elem.type == "office")?.region} InputLabelProps={{ shrink: true }} fullWidth />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <TextField inputProps={{ readOnly: true }} name="provincia" label="Provincia" defaultValue={userDTO?.company?.addresses?.find(elem => elem.type == "office")?.district} InputLabelProps={{ shrink: true }} fullWidth />
                                                        </Grid>
                                                        <Grid item xs={12} sm={4}>
                                                            <TextField inputProps={{ readOnly: true }} name="comune" label="Comune" defaultValue={userDTO?.company?.addresses?.find(elem => elem.type == "office")?.city} InputLabelProps={{ shrink: true }} fullWidth />
                                                        </Grid>
                                                        <Grid item xs={12} sm={8}>
                                                            <TextField inputProps={{ readOnly: true }} name="address" label="Via" defaultValue={userDTO?.company?.addresses?.find(elem => elem.type == "office")?.addressLine} InputLabelProps={{ shrink: true }} fullWidth />
                                                        </Grid>
                                                        <Grid item xs={12} sm={2}>
                                                            <TextField inputProps={{ readOnly: true }} name="numero" label="Numero" defaultValue={userDTO?.company?.addresses?.find(elem => elem.type == "office")?.number} InputLabelProps={{ shrink: true }} fullWidth />
                                                        </Grid>
                                                        <Grid item xs={12} sm={2}>
                                                            <TextField inputProps={{ readOnly: true }} name="CAP" label="CAP" defaultValue={userDTO?.company?.addresses?.find(elem => elem.type == "office")?.zipCode} InputLabelProps={{ shrink: true }} fullWidth />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>}
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box hidden={activeTab != 1}>
                                    <ul className={styles.paddingUl}>
                                        <li className={styles.marginLi}>
                                            <Chip
                                                icon={<AlternateEmail />}
                                                label={userDTO?.therapist?.email || "Email non disponibile"}
                                                onClick={() => {
                                                    if (userDTO?.therapist?.email) {
                                                        window.location.href = `mailto:${userDTO?.therapist?.email}`;
                                                    }
                                                }}
                                                variant="outlined"
                                            />
                                        </li>
                                        <li className={styles.marginLi}>
                                            <Chip
                                                icon={<PhoneAndroid />}
                                                label={mobilePhone?.phoneNumber ? `${mobilePhone?.phonePrefix ? `+${mobilePhone?.phonePrefix}` : ""}${mobilePhone?.phoneNumber}` : "Cellulare non disponibile"}
                                                onClick={() => {
                                                    if (mobilePhone?.phoneNumber) {
                                                        window.location.href = `tel:${mobilePhone?.phonePrefix ? `+${mobilePhone?.phonePrefix}` : ""}${mobilePhone?.phoneNumber}`;
                                                    }
                                                }}
                                                variant="outlined"
                                            />
                                        </li>
                                        <li className={styles.marginLi}>
                                            <Chip
                                                icon={<PhoneEnabled />}
                                                label={phone?.phoneNumber ? `${phone?.phonePrefix ? `+${phone?.phonePrefix}` : ""}${phone?.phoneNumber}` : "Telefono non disponibile"}
                                                onClick={() => {
                                                    if (phone?.phoneNumber) {
                                                        window.location.href = `tel:${phone?.phonePrefix ? `+${phone?.phonePrefix}` : ""}${phone?.phoneNumber}`;
                                                    }
                                                }}
                                                variant="outlined"
                                            />
                                        </li>
                                    </ul>
                                </Box>
                                <Box hidden={activeTab != 2} >
                                    <div className={styles.circularProgressDiv}>{isLoading && <CircularProgress />}</div>
                                    {
                                        !isLoading ?
                                            !isPaymentError ?
                                                !isPaymentExist ?
                                                    <h1 className={styles.nrmText}>
                                                        Il tuo terapeuta non ha ancora inserito nessun metodo di pagamento.
                                                    </h1>
                                                    :
                                                    <h1 className={styles.nrmText}>
                                                        Di seguito trovi i metodi di pagamento inseriti dal tuo terapeuta.
                                                    </h1>
                                                :
                                                <h1 className={styles.nrmText} >Al momento non è possibile visualizzare i metodi di pagamento. Riprova più tardi.</h1>
                                            :
                                            null
                                    }
                                    {paymentItems?.bonifico?.map(pay => <PaymentItem key={pay.idPayment} type='bonifico' data={pay} />)}
                                </Box>
                            </>
                    }
                </Paper>
            </Box>
        </>
    );
};

export default Therapist;