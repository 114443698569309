import { SvgIcon } from '@material-ui/core';
import React from 'react';

const SignDocumentIcon = props => {
    return (
        <SvgIcon {...props}>
            <g>
                <path d="m0,0l24,0l0,24l-24,0l0,-24z" fill="none" id="svg_1" />
                <path d="m14,2l-8,0c-1.1,0 -1.99,0.9 -1.99,2l-0.01,16c0,1.1 0.89,2 1.99,2l12.01,0c1.1,0 2,-0.9 2,-2l0,-4l-4,4l-3,0l0,-3l7,-7l0,-2l-6,-6zm-1,7l0,-5.5l5.5,5.5l-5.5,0z" id="svg_2" />
                <g id="svg_8" stroke="null">
                    <path d="m11.94544,8.00948l12.98251,0l0,12.98251l-12.98251,0l0,-12.98251z" fill="none" id="svg_6" stroke="null" />
                    <path d="m13.56826,17.34066l0,2.02852l2.02852,0l5.98278,-5.98278l-2.02852,-2.02852l-5.98278,5.98278zm9.58001,-5.52298c0.21097,-0.21097 0.21097,-0.55176 0,-0.76272l-1.2658,-1.2658c-0.21097,-0.21097 -0.55176,-0.21097 -0.76272,0l-0.98992,0.98992l2.02852,2.02852l0.98992,-0.98992z" id="svg_7" stroke="null" />
                </g>
            </g>
        </SvgIcon>
    )
};
export default SignDocumentIcon;