import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useUser, useUserProfile } from '../contexts/UserContext';
import useApi from '../hooks/useApi';

const GroupSessionRedirect = props => {
    const user = useUser();
    const userProfile = useUserProfile();
    const api = useApi();
    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
        if (!user || !userProfile) return;
        (async () => {
            const ids = await api(user, `sessions/ids/${userProfile?.idTherapist}/${user?.profile?.sub}`);
            if (ids.ok !== false) {
                history.replace(`${location.pathname}/${ids.nanoId}`);
            }
        })();
    }, [user, userProfile]);
    return <></>;
};
export default GroupSessionRedirect;