import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { eurCurrency, nameAndSurname, onlyDateFormatter } from '../../utils/UtilsFunction';

const SessionsPaymentPopup = (props) => {
    const classes = useStyles();

    return (
        <Dialog
            open={props?.open}
        >
            <DialogTitle>
                <p className={classes.title}>Riepilogo della richiesta di pagamento</p>
            </DialogTitle>
            <DialogContent>
                {
                    props?.sessionPaymentData?.sessions?.map(session => {
                        return (
                            <div className={classes.sessionDiv}>
{session.sessionStart
                ? `Seduta terapeutica con ${nameAndSurname(
                    session?.patientName,
                    session?.patientLastName,
                    "-"
                  )} del ${onlyDateFormatter.format(
                    new Date(session.sessionStart)
                  )}`
                : `Seduta terapeutica con ${nameAndSurname(
                    session?.patientName,
                    session?.patientLastName,
                    "-"
                  )}`}                            </div>
                        )
                    })
                }
                <div className={classes.amountDiv}>
                    <p>per un importo totale complessivo di</p>
                    <p style={{ fontWeight: 600, marginLeft: "0.5rem" }}>{`${eurCurrency(props?.sessionPaymentData?.amount)} euro`}</p>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    classes={{ root: classes.bntText }}
                    variant='contained'
                    onClick={() => {
                        if(props?.onClose){
                            props.onClose();
                        }
                    }}
                    color="primary"
                >
                    Chiudi
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const useStyles = makeStyles((theme) => ({
    sessionDiv: {
        padding: "0.5rem 0rem",
        fontSize: "1.125rem",
    },
    title: {
        margin: '0rem',
        padding: '0rem',
        fontSize: "1.125rem",
        fontWeight: 600,
    },
    amountDiv: {
        padding: "0.5rem 0rem",
        fontSize: "1.125rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    bntText:{
        textShadow: "1px 1px 1px rgba(0, 0, 0, 0.25)"
    }
}));

export default SessionsPaymentPopup