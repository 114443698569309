import { makeStyles, MenuItem, TextField } from '@material-ui/core';
import React from 'react';
import internationalPrefixes from '../utils/interationalPrefixes.json';

const useStyles=makeStyles(()=>({
    rootLeft: {
        borderRadius: "4px",
        borderTopRightRadius: "0",
        borderBottomRightRadius: "0"
    },
    rootRight: {
        borderRadius: "4px",
        borderTopLeftRadius: "0",
        borderBottomLeftRadius: "0"
    }
}));

const PhoneField = (props) => {
    const styles=useStyles();
    const {disabled, prefixName, prefixValue, prefixDefaultValue, variant, size, style} = props;
    return (
        <>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                <TextField InputProps={{classes: {root: styles.rootLeft}}} style={{...style, width: "14ch"}} variant={variant} size={size} disabled={disabled} select name={prefixName || ""} value={prefixValue} defaultValue={prefixDefaultValue} label="Prefisso">
                    {
                        internationalPrefixes.map(prefix => (
                            <MenuItem value={prefix.value}>{prefix.prefix}</MenuItem>
                        ))
                    }
                </TextField>
                <TextField InputProps={{classes: {root: styles.rootRight}}} {...props} style={{borderTopLeftRadius: "0", borderBottomLeftRadius: "0", ...style}}/>
            </div>
        </>
    );
}

export default PhoneField;