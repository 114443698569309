import { Box, Button, makeStyles, Paper } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    paper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "50%",
        minHeight: "50%",
        margin: "2rem",
        padding: "2rem",
        "& > * + *": {
            marginTop: "1rem"
        },
        [theme.breakpoints.down('sm')]: {
            width: "unset"
        }
    }
}));

const PaymentSuccess = props => {
    const styles = useStyles();
    const history = useHistory();
    return (
        <Box height="100%" display="flex" justifyContent="center" alignItems="center">
            <Paper className={styles.paper} width="100%">
                <img style={{ width: "30%" }} src="/logo_transparent_psycare.png" />
                <h2>Pagamento preso in carico con successo!</h2>
                <span>Il pagamento è stato preso in carico con successo. Controlla lo stato del pagamento oppure attendi la mail di conferma.</span>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        history.push("/payment");
                    }}
                >Vai ai pagamenti</Button>
            </Paper>
        </Box>
    )
};
export default PaymentSuccess;