import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import useApi from '../hooks/useApi';
import { useUserManager } from '../contexts/UserContext';
import { Box, Paper, Typography } from '@material-ui/core';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const Register = props => {
    const location = useQuery();
    const token = location.get("patienttoken");
    const [verified, setVerified] = useState(false);
    const [loading, setLoading] = useState(true);
    const api = useApi();
    const userManager = useUserManager();
    useEffect(() => {
        async function verifyToken() {
            let jsonVerify = await api(null, `verifypatienttoken?patienttoken=${token}`, "GET");
            if (jsonVerify.ok) {
                userManager.signinRedirect({
                    extraQueryParams: {
                        patient_token: token,
                        isPatient: true,
                    }
                });
            }
            setVerified(jsonVerify.ok);
            setLoading(false);
        }
        verifyToken();
    }, []);
    return (
        <div>
            {!loading && !verified &&
                <Box p={12} textAlign="center">
                    <Paper>
                        <Box pt={3}>
                            <Typography variant="h4">
                                Token non valido
                            </Typography>
                        </Box>
                        <Box p={3}>
                            Il link non è valido. Si prega di contattare il proprio terapeuta per farsi invitare di nuovo.
                        </Box>
                    </Paper>
                </Box>
            }
        </div>
    );
};

export default Register;