import React from 'react';
import { Box, Typography, CircularProgress, Paper, makeStyles} from '@material-ui/core'

const useStyles=makeStyles( theme => ({
    paper: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "50%",
        minHeight: "50%",
        margin: "2rem",
        padding: "2rem",
        "& > * + *": {
            marginTop: "1rem"
        },
        [theme.breakpoints.down('sm')]: {
            width: "unset"
        }
    }
}));

const ErrorPopup = ({message, redirecting}) => {
    const styles=useStyles();
    return (
        <Box height="100%" display="flex" justifyContent="center" alignItems="center">
            <Paper className={styles.paper} width="100%">
                <img style={{ width: "30%" }} src="/logo_transparent_psycare.png" />
                <Typography>{message}</Typography>
                {
                    redirecting &&
                    <CircularProgress size="3rem" />}
            </Paper>
        </Box>
    )
};
export default ErrorPopup;