import { SvgIcon } from '@material-ui/core';
import React from 'react';

const DoubleArrowIcon = props => {
    return (
        <SvgIcon {...props}>
            <path d="m5.9999,6l-1.41,1.41l4.58,4.59l-4.58,4.59l1.41,1.41l6,-6l-6,-6z" />
            <path d="m13.41006,6l-1.41,1.41l4.58003,4.59l-4.58003,4.59l1.41,1.41l6.00003,-6l-6.00003,-6z" />
        </SvgIcon>
    );
};
export default DoubleArrowIcon;