import React, { useState, createContext, useContext } from 'react';

const useGlobalSnackbar = ()=>{
    return useContext(GlobalSnackbarContext)
};
const GlobalSnackbarContext = createContext(null);

const GlobalSnackbarContextProvider = ({ children }) => {
    const [globalSnackbar, setGlobalSnackbar] = useState({});
    return (
        <GlobalSnackbarContext.Provider value={[ globalSnackbar, setGlobalSnackbar ]}>
            {children}
        </GlobalSnackbarContext.Provider>
    )
}

export default GlobalSnackbarContextProvider;
export { useGlobalSnackbar };