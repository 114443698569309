import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import { useHistory } from "react-router-dom";
import { useUser } from "../contexts/UserContext";


const useStyles = makeStyles(theme => ({
    errorContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        gap: "1rem",
        boxSizing: "border-box",
        padding: "1rem",
        height: "100vh",
        "& img": {
            width: "clamp(9rem, 15vw, 15rem)",
        },
        "& h2": {
            fontSize: "2rem",
            lineHeight: "3rem",
        },
        "& h5": {
            fontSize: "1.5rem",
            lineHeight: "2rem",
        },
        "& :is(h2, h5)": {
            fontStyle: "normal",
            fontWeight: "normal",
            maxWidth: "85%",
            margin: 0,
        },
    },
}));


const SessionErrorPage = ({ errorTitle, errorDescription, style }) => {
    const styles = useStyles();
    const user = useUser();
    const history = useHistory();
    return (
        <div style={style} className={styles.errorContainer}>
            <img src="/logo_transparent_psycare.png" />
            <h3>{errorTitle}</h3>
            <h5>{errorDescription}</h5>
            {
                user &&
                <Button
                    onClick={() => {
                        history.push("/home");
                    }}
                    variant="contained"
                    color="primary">Vai alla home</Button>
            }
        </div>
    );
};

const NotIn = ({ style }) => (
    <SessionErrorPage
        style={style}
        errorTitle=" Qui si aprirà la sala d'attesa che visualizzerà il tuo paziente."
        //errorDescription="Controlla che il link sia esatto o chiedi al tuo terapeuta di inviarti il link corretto. "
    />
);

const NoRoom = ({ style }) => (
    <SessionErrorPage
        style={style}
        errorTitle="Questa stanza virtuale non esiste."
        errorDescription="Controlla che il link sia esatto o chiedi al tuo terapeuta di inviarti il link corretto. "
    />
);

export default SessionErrorPage;
export { NoRoom, NotIn };