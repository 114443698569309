
import { SvgIcon } from '@material-ui/core';
import React from 'react';
const AbortoSpontaneoIcon = props => {
        return (
                <SvgIcon {...props}>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0166 16L12.0166 2H11.0166L11.0166 16H11.5112C9.85438 16 8.51123 17.3431 8.51123 19C8.51123 20.6569 9.85438 22 11.5112 22C13.1681 22 14.5112 20.6569 14.5112 19C14.5112 17.3431 13.1681 16 11.5112 16H12.0166Z" />
                </SvgIcon>
        )
};
export default AbortoSpontaneoIcon;
