import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import AgoraRTC from "agora-rtc-sdk-ng";

type MediaEvents =
  | "cameraChanged"
  | "microphoneChanged"
  | "playbackDeviceChanged";

const callbacks = new Map<MediaEvents, Set<(info: any) => void>>();

const useAgoraContext = () => {
  const context = useContext(AgoraContext);
  if (!context) {
    throw new Error(
      "You have to be inside the Agora Context Provider to use this hook"
    );
  }
  return context;
};

export function useAgoraMediaListener(
  event: MediaEvents,
  fn: (info: any) => void
) {
  const { on } = useAgoraContext();
  useEffect(() => {
    return on(event, fn);
  }, []);
}

const engine = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

type AgoraContextType = {
  engine: typeof engine;
  on: (event: MediaEvents, fn: (props: any) => void) => () => void;
  currentMicrophone?: MediaDeviceInfo;
  setCurrentMicrophone: ReturnType<
    typeof useState<MediaDeviceInfo | undefined>
  >[1];
  setCurrentCamera: ReturnType<typeof useState<MediaDeviceInfo | undefined>>[1];
  currentCamera?: MediaDeviceInfo;
};

const AgoraContext = createContext<AgoraContextType | undefined>(undefined);

const AgoraContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentMicrophone, setCurrentMicrophone] = useState<
    MediaDeviceInfo | undefined
  >();
  const [currentCamera, setCurrentCamera] = useState<
    MediaDeviceInfo | undefined
  >();

  const on = useCallback((event, fn) => {
    if (!callbacks.has(event)) {
      callbacks.set(event, new Set());
    }
    const callbacksSet = callbacks.get(event);
    callbacksSet!.add(fn);
    return () => {
      callbacksSet!.delete(fn);
    };
  }, []);

  useEffect(() => {
    AgoraRTC.onCameraChanged = (info) => {
      const cameraChangedCallbacks = callbacks.get("cameraChanged");
      if (cameraChangedCallbacks) {
        cameraChangedCallbacks.forEach((callback) => {
          callback(info);
        });
      }
    };
    AgoraRTC.onMicrophoneChanged = (info) => {
      const microphoneChangedCallbacks = callbacks.get("microphoneChanged");
      if (microphoneChangedCallbacks) {
        microphoneChangedCallbacks.forEach((callback) => {
          callback(info);
        });
      }
    };
    AgoraRTC.onPlaybackDeviceChanged = (info) => {
      const playbackDevideChangedCallbacks = callbacks.get(
        "playbackDeviceChanged"
      );
      if (playbackDevideChangedCallbacks) {
        playbackDevideChangedCallbacks.forEach((callback) => {
          callback(info);
        });
      }
    };
    return () => {
      AgoraRTC.onCameraChanged = undefined;
      AgoraRTC.onMicrophoneChanged = undefined;
      AgoraRTC.onAudioAutoplayFailed = undefined;
    };
  }, []);

  return (
    // @ts-ignore problem with react types
    <AgoraContext.Provider
      value={{
        engine,
        on,
        currentMicrophone,
        setCurrentMicrophone,
        currentCamera,
        setCurrentCamera,
      }}
    >
      {children}
    </AgoraContext.Provider>
  );
};

export default AgoraContextProvider;
export { useAgoraContext };
