import { useEffect } from "react";

/**
 * @callback VisibilityCallback
 * @param {boolean} hidden Specifies if the document is hidden or not
 */

/**
 * Hook to run a function on visibility change.
 * @param {VisibilityCallback} fn The callback to be called on visibility change
 * @param {any[]} deps The array of dependencies of the callback
 */
const useVisibility = (fn, deps) => {
    useEffect(() => {
        const listener = () => {
            fn(document.hidden);
        };
        document.addEventListener("visibilitychange", listener);
        return () => document.removeEventListener("visibilitychange", listener);
    }, deps);
};

export default useVisibility;