
import { SvgIcon } from '@material-ui/core';
import React from 'react';
const AmiciziaIcon = props => {
        return (
                <SvgIcon {...props}>
                        <path d="M2 13H3.73913" stroke="currentColor" />
                        <path d="M9.82611 13H11.5652" stroke="currentColor" />
                        <path d="M17.6522 13H19.3913" stroke="currentColor" />
                        <path d="M4.6087 13H6.34783" stroke="currentColor" />
                        <path d="M12.4348 13H14.1739" stroke="currentColor" />
                        <path d="M20.2611 13L21.1306 13.0111" stroke="currentColor" />
                        <path d="M21.1304 13.0112L21.9999 13.0223" stroke="currentColor" />
                        <path d="M7.21741 13H8.95654" stroke="currentColor" />
                        <path d="M15.0435 13H16.7826" stroke="currentColor" />
                        <path d="M2 11H3.73913" stroke="currentColor" />
                        <path d="M9.82611 11H11.5652" stroke="currentColor" />
                        <path d="M17.6522 11H19.3913" stroke="currentColor" />
                        <path d="M4.6087 11H6.34783" stroke="currentColor" />
                        <path d="M12.4348 11H14.1739" stroke="currentColor" />
                        <path d="M20.2611 11L21.1306 11.0111" stroke="currentColor" />
                        <path d="M21.1304 11.0112L21.9999 11.0223" stroke="currentColor" />
                        <path d="M7.21741 11H8.95654" stroke="currentColor" />
                        <path d="M15.0435 11H16.7826" stroke="currentColor" />
                </SvgIcon>
        )
};
export default AmiciziaIcon;
