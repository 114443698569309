import React from "react";

const withHooks = (Component, ...hooks) => (props) => {
    const hooksProps = hooks.reduce((acc, hook) => {
        if (typeof hook.hook !== "function") return acc;
        if (hook.hasToSpread) {
            acc[hook.name] = hook?.hook(...hook?.props);
        } else {
            acc[hook.name] = hook?.hook(hook?.props);
        }
        return acc;
    }, {});
    return <Component {...hooksProps} {...props} />;
};

export default withHooks;
