import { SvgIcon } from '@material-ui/core';
import React from 'react';

const OpenWhiteboardIcon = props => {
    return (
        <SvgIcon {...props}>
            <g>
                <path d="M14.9228 14.7655C13.9845 14.9961 13.4133 15.9396 13.6439 16.8778C13.8259 17.6182 13.2661 18.1694 12.7914 18.2861C13.4808 18.8478 14.4765 19.0706 15.33 18.8608C16.5791 18.5538 17.3421 17.2934 17.0351 16.0443C16.8045 15.1061 15.861 14.5349 14.9228 14.7655ZM21.37 7.56503L20.4265 6.99381C20.1519 6.82756 19.7958 6.91507 19.6296 7.18968L15.8101 13.4985L17.7464 14.6708L21.5659 8.36196C21.7321 8.08735 21.6446 7.73128 21.37 7.56503V7.56503Z" />
                <path d="M7.27525 4.19995C3.26659 4.19995 -7.97475e-10 7.46654 -1.77611e-09 11.4752C-2.75475e-09 15.4839 3.26659 18.7505 7.27525 18.7505C8.27924 18.7505 9.09407 17.9356 9.09407 16.9316C9.09407 16.4879 8.92674 16.0586 8.62845 15.7167C8.57025 15.6439 8.53387 15.5639 8.53387 15.4766C8.53387 15.2729 8.69393 15.1128 8.89763 15.1128L10.1854 15.1128C12.5935 15.1128 14.5505 13.1558 14.5505 10.7477C14.5505 7.13915 11.2839 4.19995 7.27525 4.19995ZM11.2766 12.2027C10.6728 12.2027 10.1854 11.7153 10.1854 11.1114C10.1854 10.5076 10.6728 10.0202 11.2766 10.0202C11.8805 10.0202 12.3679 10.5076 12.3679 11.1114C12.3679 11.7153 11.8805 12.2027 11.2766 12.2027ZM9.09407 9.29263C8.49022 9.29263 8.00278 8.80519 8.00278 8.20134C8.00278 7.5975 8.49022 7.11005 9.09407 7.11005C9.69791 7.11005 10.1854 7.5975 10.1854 8.20134C10.1854 8.80519 9.69791 9.29263 9.09407 9.29263ZM2.18258 11.1114C2.18258 10.5076 2.67002 10.0202 3.27386 10.0202C3.87771 10.0202 4.36515 10.5076 4.36515 11.1114C4.36515 11.7153 3.87771 12.2027 3.27386 12.2027C2.67002 12.2027 2.18258 11.7153 2.18258 11.1114ZM6.54773 8.20134C6.54773 8.80519 6.06029 9.29263 5.45644 9.29263C4.85259 9.29263 4.36515 8.80519 4.36515 8.20134C4.36515 7.5975 4.85259 7.11005 5.45644 7.11005C6.06029 7.11005 6.54773 7.5975 6.54773 8.20134Z" />
            </g>
        </SvgIcon>
    )
};
export default OpenWhiteboardIcon;