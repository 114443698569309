import React, { Component } from "react";
import { useUser } from "../contexts/UserContext";
import withHooks from "../HOC/withHooks";
import GeneralError from "./GeneralError";
class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        fetch("/api/UnautenticatedNotification/sendmail", {
            method: "POST",
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify({
                idUSer: this?.props?.user?.profile?.sub || "00000000-0000-0000-0000-000000000000",
                name: this?.props?.user?.profile?.name || "",
                lastName: this?.props?.user?.profile?.last_name || "",
                email: this?.props?.user?.profile?.email || "",
                messageType: 2,
                sendTo: [2],
                message: `${error.message} ${error.stack}`,
            })
        });
    }

    render() {
        if (this.state.hasError) {
            return <GeneralError />;
        }

        return this.props.children;
    }
}

export default withHooks(ErrorBoundary, {
    name: "user",
    hook: useUser,
});