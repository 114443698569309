import React from 'react';
import { Tooltip, IconButton, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    callButton: {
        color: "#fff",
        "&.pressed": {
            backgroundColor: "#FFFFFF33"
        },
        "&$disabledButton": {
            color: "#FFFFFF87",
        },
    },
    disabledButton: {},
}));

const VideoCallButton = ({ disabled, pressed, pressedTooltip, nonPressedTooltip, PressedIcon, NonPressedIcon, onClick, style, size, iconSize }) => {
    const styles = useStyles();
    return (
        <Tooltip title={pressed ? pressedTooltip : nonPressedTooltip}>
            <span>
                <IconButton size={["medium", "small"].includes(size) ? size : "medium"} disabled={disabled} style={style} className={`${styles.callButton} ${pressed ? "pressed" : ""} ${disabled ? styles.disabledButton : ""}`} onClick={onClick}>
                    {
                        pressed ?
                            <PressedIcon style={{ fontSize: iconSize || null }} /> :
                            <NonPressedIcon style={{ fontSize: iconSize || null }} />
                    }
                </IconButton>
            </span>
        </Tooltip>
    );
};
export default VideoCallButton;