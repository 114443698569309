import { makeStyles } from '@material-ui/core';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        flexDirection: "column",
    },
    button: {
        padding: '.4rem',
        width: "100%",
        backgroundColor: "transparent",
        border: "none",
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
        ["@media (max-height: 590px)"]: {
            fontSize: ".6rem",
        },

    },
    arrow: {
        width: ".6rem",
    },
    icon: {
        fontSize: "2rem",
        "&$small": {
            fontSize: "1.5rem",
        },
        ["@media (max-height: 590px)"]: {
            fontSize: "1.5rem",
            "&$small": {
                fontSize: "1rem",
            },
        }
    },
    subMenu: {
        position: "absolute",
        top: "0",
        backgroundColor: "white",
        transition: "transform 250ms",
        whiteSpace: "nowrap",
    },
    left: {
        left: 0,
        transform: "translateX(-100%)",
        transformOrigin: "right",
        boxShadow: "-0.5rem 0 1rem 0 rgb(0, 0, 0, .15)",
        "&$hiddenSubMenu": {
            transform: "translateX(-100%) scaleX(0)"
        },
        "&$up": {
            transform: "translate(-100%, calc(-100% + 3rem))",
        },
        "&$up$hiddenSubMenu": {
            transform: "translate(-100%, calc(-100% + 3rem)) scaleX(0)",
        }
    },
    right: {
        right: 0,
        transformOrigin: "left",
        transform: "translateX(100%)",
        boxShadow: "0.5rem 0 1rem 0 rgb(0, 0, 0, .15)",
        "&$hiddenSubMenu": {
            transform: "translateX(100%) scaleX(0)"
        },
        "&$up": {
            transform: "translate(100%, calc(-100% + 3rem))",
        },
        "&$up$hiddenSubMenu": {
            transform: "translate(100%, calc(-100% + 3rem)) scaleX(0)",
        }
    },
    up: {},
    hiddenSubMenu: {},
    small: {},
    tract: {
        width: "50%",
        height: ".1rem",
        ["@media (max-height: 590px)"]: {
            display: "none",
        },
    },
    activeIcon: {
        "& > $button": {
            color: theme.palette.primary.main,
        }
    },
}));

const WhiteboardButton = ({ Icon, Spacing, colore, active, subMenu, ySubMenu, onClick, subMenuRender, disabled, size, description, onSubMenuOpen, disableColor }, ref) => {
    const styles = useStyles();
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const isSubMenu = useMemo(() => ["left", "right"].includes(subMenu) && subMenuRender && typeof subMenuRender === "function", [subMenu, subMenuRender]);

    useImperativeHandle(ref, () => ({
        setIsSubMenuOpen,
    }), [setIsSubMenuOpen]);

    const onClicklHandler = useCallback((e) => {
        if (isSubMenu) {
            setIsSubMenuOpen(prev => !prev);
            if (typeof onSubMenuOpen === "function" && !isSubMenuOpen) {
                onSubMenuOpen(setIsSubMenuOpen);
            }
        }
        if (onClick) {
            onClick(e);
        }
    }, [isSubMenu, onClick, setIsSubMenuOpen, subMenu, isSubMenuOpen]);

    return (
        <div className={`${styles.container} ${Spacing} ${active || isSubMenuOpen || disableColor ? styles.activeIcon : ""}`}>
            <button disabled={disabled} onClick={onClicklHandler} className={styles.button}>
                <Icon style={{ color: colore }} className={`${styles.icon} ${size === "small" ? styles.small : ''}`} />
                {description}
            </button>
            {
                isSubMenu &&
                <div className={`${styles.subMenu} ${styles[subMenu]} ${styles[ySubMenu]} ${!isSubMenuOpen ? styles.hiddenSubMenu : ''}`}>
                    {subMenuRender(setIsSubMenuOpen)}
                </div>
            }
            <Divider className={styles.tract} variant="middle" />
        </div>
    );
};
export default forwardRef(WhiteboardButton);