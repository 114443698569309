import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
    finishContainer: {
        backgroundColor: "white",
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        "& > *": {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "3rem",
            maxWidth: "30%",
            textAlign: "center",
            margin: "auto"
        },
        [theme.breakpoints.down('sm')]: {
            "& > *": {
                maxWidth: "80%",
            }
        },
        "& img": {
            maxWidth: "100%",
        }

    },
}));

const SessionEnded = props => {
    const styles = useStyles();
    return <>
        <Box className={styles.finishContainer}>
            <Box>
                <Typography variant="h4">
                    Grazie per aver usato <span style={{ fontWeight: "bold" }}>PsyCare.</span>
                </Typography>
                <img src="/end.png" />
            </Box>
        </Box>
    </>;
};
export default SessionEnded;