
import { SvgIcon } from '@material-ui/core';
import React from 'react';
const MatrimonioIcon = props => {
        return (
                <SvgIcon {...props}>
                        <line x1="2" y1="14.5" x2="22" y2="14.5" stroke="currentColor" />
                        <line x1="22.3333" y1="9" x2="22.3333" y2="15" stroke="currentColor" />
                        <line x1="2.5" y1="9" x2="2.5" y2="15" stroke="currentColor" />
                </SvgIcon>
        )
};
export default MatrimonioIcon;
