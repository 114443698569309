import {
  Box,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Popper,
  Slide,
  Snackbar,
  useMediaQuery,
} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import {
  createMuiTheme,
  makeStyles,
  responsiveFontSizes,
  ThemeProvider,
  useTheme,
} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { ContactSupport, Gavel, Videocam } from "@material-ui/icons";
import DescriptionIcon from "@material-ui/icons/Description";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import GroupIcon from "@material-ui/icons/Group";
import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import PersonIcon from "@material-ui/icons/Person";
import { Alert } from "@material-ui/lab";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import "./App.css";
import PageLayout from "./components/PageLayout";
import VideoCall from "./views/Videocall";
import WhiteboardPopup from "./components/WhiteboardPopup";
import appsettings from "./config/appsettings.json";
import environment from "./config/environment.json";
import { useGlobalSnackbar } from "./contexts/GlobalSnackbarContext";
import {
  LastClickedContext,
  useLocalHistory,
} from "./contexts/LastClickedContext";
import { useNotificationApi } from "./contexts/NotificationContext";
import {
  useSetUserProfile,
  useUser,
  useUserManager,
  useUserProfile,
} from "./contexts/UserContext";
import {
  useIsVideoCallModalOpened,
  useSetIsVideoCallModalOpened,
  useSetVideoCallSession,
  useVideoCallSession,
} from "./contexts/VideoCallModalContext";
import useApi from "./hooks/useApi";
import {
  CompleteRegistration,
  Home,
  Documents,
  ErrorPage,
  Welcome,
  JoinSession,
  Norms,
  NotFound,
  Profilo,
  RedirectToIdentity,
  Register,
  Sessions,
  SigninOidc,
  SignoutOidc,
  Therapist,
  VirtualRoom,
  PaymentSuccess,
  GroupSession,
} from "./views";
import GuestSession from "./views/GuestSession";
import Payment from "./views/Payment";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import ErrorWebcamOrMicrDialog from "./components/ErrorWebcamOrMicrDialog";
import GroupSessionRedirect from "./views/GroupSessionRedirect";
import { Whiteboard } from "./views/Whiteboard";

const tema = responsiveFontSizes(
  createMuiTheme({
    props: {
      MuiButton: {
        disableElevation: true,
        disableRipple: true,
        size: "small",
      },
      MuiCheckbox: {
        disableRipple: true,
      },
      MuiRadio: {
        color:"primary",
    }
    },
    typography: {
      fontFamily: [
        '"Open Sans"',
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
    },
    palette: {
      common: {
        black: "#000",
        white: "#fff",
      },
      // "background": {
      //     "paper": "#fff",
      //     "default": "#fafafa"
      // },
      primary: {
        light: "#00c8ab",
        main: "#00858B", //"#474B6A",
        dark: "#006166",
        contrastText: "#fff",
      },
      secondary: {
        light: "rgba(184, 122, 159, 1)",
        main: "rgba(162, 87, 132, 1)",
        dark: "rgba(116, 62, 95, 1)",
        contrastText: "#fff",
      },
      error: {
        light: "#e57373",
        main: "#f44336",
        dark: "#d32f2f",
        contrastText: "#fff",
      },
      text: {
        primary: "rgba(0, 0, 0, 0.87)",
        secondary: "rgba(0, 0, 0, 0.54)",
        disabled: "rgba(0, 0, 0, 0.38)",
        hint: "rgba(0, 0, 0, 0.38)",
      },
    },
  })
);

//component to let the snackbar slide in
const SlideTransition = (props) => {
  return <Slide {...props} direction="up" />;
};

const drawerWidth = 240;
const drawerWidthMobile = "75%";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    maxHeight: "100%",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    textAlign: "center",
    textShadow: "0px 1px 1px rgba(0, 0, 0, 0.25)",
    boxShadow: "unset",
    backgroundColor: "#EEF6F4",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: `${theme.palette.primary.main}`,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    borderRight: "none",
    overflowY: "unset",
    width: drawerWidthMobile,
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
    paddingTop: 70,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingTop: 35,
    },
  },
  flexGrow: {
    flexGrow: 1,
  },
  title: {
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    color: `${theme.palette.primary.main}`,
    fontWeight: "600",
    [theme.breakpoints.up("sm")]: {
      color: "white",
    },
  },
  rippleButton: {
    position: "relative",
    overflow: "unset",
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      borderRadius: "100%",
      animation: "$ripple 1s ease-in 5",
      backgroundColor: "white",
      zIndex: "-1",
    },
  },
  "@keyframes ripple": {
    "0%": {
      opacity: 1,
      transform: "scale(1)",
    },
    "100%": {
      opacity: 0,
      transform: "scale(1.5)",
    },
  },
  listItemText: {
    fontWeight: "400",
    fontSize: "1.125rem",
  },
  navListIcon: {
    color: "black",
  },
  boxLogoPsyCare: {
    width: "100%",
    display: "grid",
    marginTop: "1rem",
    gridTemplateRows: "5rem max-content",
    alignItems: "center",
    gap: "1rem",
    padding: "1rem",
  },
  boxAvatar: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: "2rem",
    paddingLeft: "0.625rem",
  },
  avatarBox: {
    display: "flex",
    alignItems: "center",
    padding: "0rem 0.625rem",
  },
  avatarLarge: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    border: "3px solid green",
    color: "black",
    backgroundColor: "white",
    fontWeight: "600",
  },
  terapistBox: {
    flex: 2,
  },
  terapistBoxText: {
    color: "black",
    fontWeight: "400",
  },
  discoverText: {
    color: `${theme.palette.primary.main}`,
    textDecoration: "underline",
    fontSize: "0.875rem",
    textTransform: "none",
    padding: "0px 0px",
    textAlign: "start",
  },
  imgLogoPsyCare: {
    position: "relative",
    width: "100%",
    height: "100%",
    "&>img": {
      position: "absolute",
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
  textPsyCare: {
    fontSize: "1.4rem",
    textAlign: "center",
  },
  listMenu: {
    overflowY: "auto",
    scrollbarWidth: "thin",
    scrollbarColor: `#ADBCBA transparent`,
    "&::-webkit-scrollbar": {
      width: "0.3125rem",
      backgroundColor: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ADBCBA",
      borderRadius: "1rem",
    },
  },
  listItemSelected: {
    color: "white",
    borderRadius: "0.625rem",
    textShadow: "1px 1px 1px rgba(0, 0, 0, 0.25)",
    width: "auto",
    margin: ".5rem",
    backgroundColor: `${theme.palette.primary.main} !important`,
    "& .MuiSvgIcon-root": {
      color: "white",
      filter: "drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.25))",
    },
  },
  toolbar: {
    borderTopLeftRadius: "0rem",
    backgroundColor: "unset",
    justifyContent: "space-between",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-end",
      borderTopLeftRadius: "1.125rem",
      backgroundColor: `${theme.palette.primary.main}`,
    },
  },
  paperDrawer: {
    //width: '75%',
  },
  toolBarContainer: {
    backgroundColor: "unset",
    [theme.breakpoints.up("sm")]: {
      backgroundColor: "#CFE2DF",
    },
  },
  exitButton: {
    color: `${theme.palette.primary.main}`,
    [theme.breakpoints.up("sm")]: {
      color: "white",
    },
  },
  listItemButton: {
    transition: "unset",
  },
  appBarAvatar: {
    background: "white",
    border: `3px solid ${theme.palette.primary.main}`,
    color: "black",
    cursor: "pointer",
    fontWeight: "600",
    width: theme.spacing(7),
    height: theme.spacing(7),
    zIndex: 2,
  },
  appBarAvatarContainer: {
    transform: "translateY(50%)",
    border: "0.375rem solid white",
    borderRadius: "100%",
  },
  appBarMenuItem: {
    margin: "0.625rem 0rem",
  },
  divider: {
    backgroundColor: `${theme.palette.primary.main}`,
  },
}));

const ConditionalRoute = (props) => {
  let { condition, path, render, elseRender } = props;
  return <Route path={path} exact render={condition ? render : elseRender} />;
};

const Layout = (props) => {
  const { children } = props;
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const { lastClicked, setLastClicked } = useContext(LastClickedContext);
  const user = useUser();
  const userManager = useUserManager();
  const userProfile = useUserProfile();
  const setVideoCallSession = useSetVideoCallSession();
  const setIsVideoCallModalOpened = useSetIsVideoCallModalOpened();
  const api = useApi();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const signoutRedirect = () => {
    userManager.clearStaleState();
    userManager.removeUser();
    userManager.signoutRedirect({ id_token_hint: user.id_token });
  };

  let avatarInitialsName = useMemo(() => {
    if (!userProfile) return null; //You can't get name and surname
    const initialName = userProfile.firstName
      ? userProfile.firstName.charAt(0)
      : "";
    const initialSurname = userProfile.lastName
      ? userProfile.lastName.charAt(0)
      : "";
    return `${initialName}${initialSurname}`;
  }, [userProfile?.firstName, userProfile?.lastName]);

  const refreshPage = () => {
    history.push("/to-refresh");
    history.goBack();
  };

  const drawer = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        overflowY: matches ? "" : "auto",
      }}
    >
      <div
        style={{
          height: "100vh",
          display: "grid",
          gridTemplateRows: "min-content 1fr",
        }}
      >
        {matches && (
          <Box className={classes.boxLogoPsyCare}>
            <div className={classes.imgLogoPsyCare}>
              <img src="/logo_sfondo_bianco.png" />
            </div>
            <Typography className={classes.textPsyCare}>
              <b>P S Y </b>C A R E
            </Typography>
          </Box>
        )}
        {!matches && (
          <Box className={classes.boxAvatar}>
            <Box className={classes.avatarBox}>
              <Avatar className={classes.avatarLarge}>
                {avatarInitialsName || <PersonIcon />}
              </Avatar>
            </Box>
            <Box className={classes.terapistBox}>
              <Typography className={classes.terapistBoxText}>{`${
                !userProfile ? "" : userProfile.firstName
              }`}</Typography>
              <Typography className={classes.terapistBoxText}>{`${
                !userProfile ? "" : userProfile.lastName
              }`}</Typography>
              <Button
                className={classes.discoverText}
                color="primary"
                onClick={() => {
                  if (location.pathname !== "/profilo") {
                    history.push("/profilo");
                    setMobileOpen(false);
                  }
                }}
              >
                Vedi profilo
              </Button>
            </Box>
          </Box>
        )}
        <List className={classes.listMenu}>
          <ListItem
            classes={{
              selected: classes.listItemSelected,
              button: classes.listItemButton,
            }}
            selected={lastClicked == "home"}
            button
            onClick={() => {
              if (location.pathname !== "/home") {
                history.push("/home");
              } else {
                refreshPage();
              }
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>
              <HomeIcon className={classes.navListIcon} />
            </ListItemIcon>
            <ListItemText className={classes.listItemText} primary={"Home"} />
          </ListItem>

          <ListItem
            classes={{
              selected: classes.listItemSelected,
              button: classes.listItemButton,
            }}
            selected={lastClicked == "therapist"}
            button
            onClick={() => {
              if (location.pathname !== "/therapist") {
                history.push("/therapist");
              } else {
                refreshPage();
              }
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>
              <GroupIcon className={classes.navListIcon} />
            </ListItemIcon>
            <ListItemText
              className={classes.listItemText}
              primary={"Il mio terapeuta"}
            />
          </ListItem>

          <ListItem
            classes={{
              selected: classes.listItemSelected,
              button: classes.listItemButton,
            }}
            selected={lastClicked == "sessions"}
            button
            onClick={() => {
              if (location.pathname !== "/sessions") {
                history.push("/sessions");
              } else {
                refreshPage();
              }
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>
              <Videocam className={classes.navListIcon} />
            </ListItemIcon>
            <ListItemText
              className={classes.listItemText}
              primary={"Sedute online"}
            />
          </ListItem>

          <ListItem
            classes={{
              selected: classes.listItemSelected,
              button: classes.listItemButton,
            }}
            selected={lastClicked == "documents"}
            button
            onClick={() => {
              if (location.pathname !== "/documents") {
                history.push("/documents");
              } else {
                refreshPage();
              }
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>
              <DescriptionIcon className={classes.navListIcon} />
            </ListItemIcon>
            <ListItemText
              className={classes.listItemText}
              primary={"Documenti"}
            />
          </ListItem>
          <ListItem
            classes={{
              selected: classes.listItemSelected,
              button: classes.listItemButton,
            }}
            selected={lastClicked == "payment"}
            button
            onClick={() => {
              if (location.pathname !== "/payment") {
                history.push("/payment");
              } else {
                refreshPage();
              }
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>
              <AccountBalanceWalletIcon className={classes.navListIcon} />
            </ListItemIcon>
            <ListItemText
              className={classes.listItemText}
              primary={"Pagamenti"}
            />
          </ListItem>
        </List>
      </div>
      {!matches && (
        <div>
          <ListItem
            button
            onClick={() => window.open("https://psycare.it/supporto", "_blank")}
          >
            <ListItemIcon>
              <ContactSupport className={classes.navListIcon} />
            </ListItemIcon>
            <ListItemText
              className={classes.listItemText}
              primary={"Supporto"}
            />
          </ListItem>

          <ListItem
            classes={{
              selected: classes.listItemSelected,
              button: classes.listItemButton,
            }}
            selected={lastClicked == "norms"}
            button
            onClick={() => {
              if (location.pathname !== "/norms") {
                history.push("/norms");
              }
              setMobileOpen(false);
            }}
          >
            <ListItemIcon>
              <Gavel className={classes.navListIcon} />
            </ListItemIcon>
            <ListItemText
              className={classes.listItemText}
              primary={"Normative"}
            />
          </ListItem>
          <ListItem button onClick={signoutRedirect}>
            <ListItemIcon>
              <ExitToAppIcon className={classes.navListIcon} />
            </ListItemIcon>
            <ListItemText className={classes.listItemText} primary={"Esci"} />
          </ListItem>
        </div>
      )}
    </div>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  const history = useHistory();
  const [, setLocalHistory] = useLocalHistory();
  useEffect(() => {
    //Select the first content in the url -> /content/.../
    const regex = /\/([a-zA-Z-]+)\/?.*/;
    const [_, lc] = history?.location.pathname.match(regex) || [];
    if (history?.location.pathname) {
      //add the current pathname to the list of visited pathnames
      setLocalHistory((prev) => [...prev, history?.location.pathname]);
    }
    if (!lc) return;
    setLastClicked(lc);

    return history?.listen((location) => {
      //add the current pathname to the list of visited pathnames
      setLocalHistory((prev) => [...prev, location.pathname]);
      const [_, lc] = location.pathname.match(regex) || [];
      if (!lc) return;
      setLastClicked(lc);
    });
  }, [history]);

  const videoCallSession = useVideoCallSession();
  const isVideoCallModalOpened = useIsVideoCallModalOpened();
  const [globalSnackbar, setGlobalSnackbar] = useGlobalSnackbar();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      {user && (
        <div>
          <CssBaseline />
          <AppBar position="fixed" className={classes.appBar}>
            <div className={classes.toolBarContainer}>
              <Toolbar className={classes.toolbar}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  PsyCare
                </Typography>

                {/* <IconButton aria-label="show 11 new notifications" color="inherit">
                                <Badge badgeContent={3} color="secondary" style={{ marginRight: 8 }}>
                                    <NotificationsIcon />
                                </Badge>
                            </IconButton> */}
                {matches && (
                  <div className={classes.appBarAvatarContainer}>
                    <Avatar
                      className={classes.appBarAvatar}
                      onClick={handleToggle}
                    >
                      {avatarInitialsName || <PersonIcon />}
                    </Avatar>
                    <Popper
                      style={{ top: "60%", right: "0px", left: "unset" }}
                      open={open}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom"
                                ? "center top"
                                : "center bottom",
                          }}
                        >
                          <Paper
                            style={{
                              boxShadow: "unset",
                              borderRadius: "16px 0px 16px 16px",
                              backgroundColor: "#CFE2DF",
                              padding: "0rem 0.625rem",
                            }}
                          >
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList id="menu-list-grow">
                                <MenuItem
                                  className={classes.appBarMenuItem}
                                  onClick={() => {
                                    setOpen(false);
                                    if (location.pathname !== "/profilo") {
                                      if (location.pathname !== "/profilo") {
                                        history.push("/profilo");
                                      }
                                    }
                                  }}
                                >
                                  <PersonIcon style={{ marginRight: "15px" }} />
                                  Profilo personale
                                </MenuItem>
                                <Divider className={classes.divider} />
                                <MenuItem
                                  className={classes.appBarMenuItem}
                                  onClick={() =>
                                    window.open(
                                      "https://psycare.it/supporto",
                                      "_blank"
                                    )
                                  }
                                >
                                  <ContactSupport
                                    style={{ marginRight: "15px" }}
                                  />
                                  Supporto
                                </MenuItem>
                                <Divider className={classes.divider} />
                                <MenuItem
                                  className={classes.appBarMenuItem}
                                  onClick={() => {
                                    if (location.pathname !== "/norms")
                                      history.push("/norms");
                                    handleClose();
                                  }}
                                >
                                  <Gavel style={{ marginRight: "15px" }} />
                                  Normative
                                </MenuItem>
                                <Divider className={classes.divider} />
                                <MenuItem
                                  className={classes.appBarMenuItem}
                                  onClick={signoutRedirect}
                                >
                                  {" "}
                                  <ExitToAppIcon
                                    style={{ marginRight: "15px" }}
                                  />
                                  Esci
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                )}
              </Toolbar>
            </div>
          </AppBar>
          <nav className={classes.drawer} aria-label="mailbox folders">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                PaperProps={{
                  classes: { root: classes.paperDrawer },
                }}
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                elevation={16}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
        </div>
      )}
      <main className={classes.content}>
        <div style={{ height: "100%" }}>{children}</div>
      </main>
    </div>
  );
};

let session;
let stream;

const useModalStyles = makeStyles({
  paperWidthFalse: {
    width: "97%",
    height: "97%",
    maxWidth: "unset",
    maxHeight: "unset",
    margin: "unset",
  },
  action: {
    alignItems: "flex-start",
  },
  callButton: {
    border: `1px solid #006166`,
    boxShadow: "0 0 .5rem 0 #006166",
    color: "#006166",
    "&.pressed": {
      boxShadow: "inset 0 0 .2rem 0 #006166",
      backgroundColor: "#00616633",
    },
  },
});

const useModalContentStyles = makeStyles({
  root: {
    padding: "0",
    paddingTop: "0 !important",
    height: "100%",
  },
  lateralMenu: {
    position: "absolute",
    minHeight: "30%",
    backgroundColor: "white",
    right: 0,
    top: "50%",
    transform: "translateY(-50%)",
    boxShadow: "-.5rem 0 1rem 0 rgb(0, 0, 0, .15)",
  },
});

const App = (props) => {
  const user = useUser();
  const userProfile = useUserProfile();
  const videoCallSession = useVideoCallSession();
  //recover the boolean to show the video call modal from context
  const isVideoCallModalOpened = useIsVideoCallModalOpened();
  //recover the setter for the boolean to show the video call modal from context
  const setIsVideoCallModalOpened = useSetIsVideoCallModalOpened();
  //recover the eventual video call session from context
  const [snackbar, setSnackbar] = useState({ open: false, message: "" });
  const modalStyles = useModalStyles();
  const [isSpeedTestAvailable, setIsSpeedTestAvailable] = useState(false);
  const [patientSTResults, setPatientSTResults] = useState(null);
  const [isPatientSTRunning, setIsPatientSTRunning] = useState(false);
  const [globalSnackbar, setGlobalSnackbar] = useGlobalSnackbar();
  const [connectionMessage, setConnectionMessage] = useState(null);

  const videocallPopupRef = useRef();

  useEffect(() => {
    const speedOfMeScript = document.createElement("script");
    speedOfMeScript.src = "https://speedof.me/api/api.js";
    speedOfMeScript.onload = () => {
      try {
        //when the speedofme is loaded setup account information
        window.SomApi.account =
          appsettings[environment.Environment].speedOfMe.apiKey; //your API Key here
        window.SomApi.domainName =
          appsettings[environment.Environment].speedOfMe.host; //your domain or sub-domain here
        window.SomApi.config.sustainTime = 3;
        window.SomApi.config.userInfoEnabled = false;
        window.SomApi.config.latencyTestEnabled = false;
        window.SomApi.config.progress.enabled = false;
        window.SomApi.config.progress.verbose = false;
        /**
         * when the test is completed we get access to download and upload speed
         * since this is a non-reactive event we can't access reactive functions
         * to circumvent this we simply set the result on a reactive property that
         * trigger a rerun on an useEffect.
         */
        window.SomApi.onTestCompleted = ({ download, upload }) => {
          try {
            const min = Math.min(download, upload);
            setPatientSTResults({ download, upload, min });
            if (min < 1) {
              setConnectionMessage({
                message: "Qualità scarsa",
                link: true,
              });
            } else if (min < 6) {
              setConnectionMessage({
                message: "Qualità buona",
                link: false,
              });
            } else {
              setConnectionMessage({
                message: "Qualità ottima",
                link: false,
              });
            }
            setIsPatientSTRunning(false);
          } catch (e) {}
        };
        //set speed test available
        setIsSpeedTestAvailable(true);
      } catch (e) {}
    };
    document.head.append(speedOfMeScript);
  }, []);

  return (
    <ThemeProvider theme={tema}>
      <Router>
        {
          <Switch>
            <Redirect exact from="/" to="/home" />
            <Redirect from="/dashboard" exact to="/home" />
            <Route path="/accedi" component={RedirectToIdentity} />
            <ConditionalRoute
              condition={window.ReactNativeWebView}
              path="/whiteboard"
              render={() => <Whiteboard />}
              elseRender={() => <Welcome />}
            />
            <Route path="/payment-success" component={PaymentSuccess} />
            <Route path="/error" component={ErrorPage} />
            <Route path="/not-found" component={NotFound} />
            <Route path="/signin-oidc" exact component={SigninOidc} />
            <Route path="/signout-oidc" exact component={SignoutOidc} />
            <Route path="/register" exact component={Register} />
            <Route
              path="/therapist/room/:sessionid"
              exact
              render={(props) => (
                <GuestSession
                  connectionMessage={connectionMessage}
                  setConnectionMessage={setConnectionMessage}
                  isSpeedTestAvailable={isSpeedTestAvailable}
                  setSnackbar={setSnackbar}
                  patientSTResults={patientSTResults}
                  setPatientSTResults={setPatientSTResults}
                  isPatientSTRunning={isPatientSTRunning}
                  setIsPatientSTRunning={setIsPatientSTRunning}
                  {...props}
                />
              )}
            />
            <ConditionalRoute
              condition={user != null}
              render={() => <JoinSession />}
              elseRender={() => <Welcome />}
              path="/joinsession"
            />
            <ConditionalRoute
              condition={user != null}
              render={(props) => <GroupSessionRedirect {...props} />}
              elseRender={() => <Welcome isOutsideLayout />}
              exact
              path="/groupsession/:id"
            />
            <Route
              render={(props) => (
                <GroupSession
                  {...props}
                  isSpeedTestAvailable={isSpeedTestAvailable}
                  patientSTResults={patientSTResults}
                  isPatientSTRunning={isPatientSTRunning}
                  setIsPatientSTRunning={setIsPatientSTRunning}
                  connectionMessage={connectionMessage}
                />
              )}
              exact
              path="/groupsession/:id/:nanoId"
            />
            <Route
              render={(props) => (
                <VideoCall
                  ref={videocallPopupRef}
                  connectionMessage={connectionMessage}
                  setConnectionMessage={setConnectionMessage}
                  isSpeedTestAvailable={isSpeedTestAvailable}
                  setSnackbar={setSnackbar}
                  patientSTResults={patientSTResults}
                  setPatientSTResults={setPatientSTResults}
                  isPatientSTRunning={isPatientSTRunning}
                  setIsPatientSTRunning={setIsPatientSTRunning}
                />
              )}
              path="/session/:nanoId"
            />
            <Layout>
              <Route path="/welcome" exact component={Welcome} />
              <ConditionalRoute
                condition={user != null}
                render={(props) => <VirtualRoom {...props} />}
                elseRender={() => <Welcome />}
                path="/virtualRoom/:therapist"
              />
              <ConditionalRoute
                condition={user != null}
                render={() => <PageLayout Component={CompleteRegistration} />}
                elseRender={() => <Welcome />}
                path="/complete-registration"
                exact
              />
              <ConditionalRoute
                condition={user != null}
                render={() => <PageLayout Component={Home} />}
                elseRender={() => <Welcome />}
                path="/home"
              />
              <ConditionalRoute
                condition={user != null}
                render={(props) => (
                  <PageLayout Component={Therapist} ComponentProps={props} />
                )}
                elseRender={() => <Welcome />}
                path="/therapist"
              />
              <ConditionalRoute
                condition={user != null}
                render={() => <PageLayout Component={Sessions} />}
                elseRender={() => <Welcome />}
                path="/sessions"
                exact
              />
              <ConditionalRoute
                condition={user != null}
                render={() => <PageLayout Component={Profilo} />}
                elseRender={() => <Welcome />}
                path="/profilo"
                exact
              />
              <ConditionalRoute
                condition={user != null}
                render={() => <PageLayout Component={Documents} />}
                elseRender={() => <Welcome />}
                path="/documents"
                exact
              />
              <ConditionalRoute
                condition={user != null}
                render={() => <PageLayout Component={Norms} />}
                elseRender={() => <Welcome />}
                path="/norms"
                exact
              />
              <ConditionalRoute
                condition={user != null}
                render={() => <PageLayout Component={Payment} />}
                elseRender={() => <Welcome />}
                path="/payment"
                exact
              />
            </Layout>
            <Redirect to="/not-found" />
          </Switch>
        }
        <ErrorWebcamOrMicrDialog />
      </Router>
      <Snackbar
        open={snackbar.open && !isVideoCallModalOpened}
        message={snackbar.message}
        TransitionComponent={SlideTransition}
        onClose={() => {
          setSnackbar((prevSnack) => ({ ...prevSnack, open: false }));
        }}
      >
        <Alert
          severity="info"
          classes={{
            action: modalStyles.action,
          }}
          onClose={() => {
            setSnackbar((prevSnack) => ({ ...prevSnack, open: false }));
          }}
        >
          <Box
            style={{ gap: ".7rem" }}
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
          >
            {snackbar.message}
            {snackbar.isToJoin ? (
              <Button
                onClick={() => {
                  setIsVideoCallModalOpened(true);
                  setSnackbar((prevSnack) => ({ ...prevSnack, open: false }));
                }}
                color="primary"
                variant="contained"
                size="small"
              >
                INIZIA
              </Button>
            ) : null}
          </Box>
        </Alert>
      </Snackbar>
      <Snackbar
        open={globalSnackbar.open}
        message={globalSnackbar.message}
        TransitionComponent={SlideTransition}
        onClose={() => {
          //if there's an onclose event call it
          if (globalSnackbar.onClose) {
            globalSnackbar.onClose();
          }
          setGlobalSnackbar((prevSnack) => ({ ...prevSnack, open: false }));
        }}
      >
        <Alert
          severity={globalSnackbar.severity}
          classes={{
            action: modalStyles.action,
          }}
          onClose={() => {
            //if there's an onclose event call it
            if (globalSnackbar.onClose) {
              globalSnackbar.onClose();
            }
            setGlobalSnackbar((prevSnack) => ({ ...prevSnack, open: false }));
          }}
        >
          {typeof globalSnackbar.message === "function"
            ? globalSnackbar.message()
            : globalSnackbar.message}
        </Alert>
      </Snackbar>

      <WhiteboardPopup />

      {/* <Typography variant="body2" color="textSecondary" align="center">Punto.EXE Srl © 2020</Typography> */}
    </ThemeProvider>
  );
};

export default App;
