import {
  Button,
  makeStyles,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Settings, Videocam, VolumeUp } from "@material-ui/icons";
import React, { useMemo, useState } from "react";
import { useUserProfile } from "../contexts/UserContext";
import ParticipantRow from "./GroupSessions/ParticipantRow";
import GroupTherapyIcon from "./icons/GroupTherapyIcon";
import useMediaDevice from "../hooks/useMediaDevice";
import { useAgoraContext } from "../contexts/AgoraContext";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "min-content 1fr",
  },
  tabs: {
    borderRight: "0.125rem solid rgba(255,255,255,.25)",
  },
  tabIcon: {
    "--size": "2.5rem",
    width: "var(--size)",
    height: "var(--size)",
    border: 0,
    outline: 0,
    color: "rgba(255,255,255,.4)",
    cursor: "pointer",
    background: "transparent",
  },
  active: {
    color: "white",
    boxShadow: "inset 0 0.25rem 0.625rem rgba(255, 255, 255, 0.2)",
    position: "relative",
    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      width: "0.125rem",
      background: "white",
    },
  },
  innerPanes: {
    padding: "1rem",
  },
  participantsContainer: {
    display: "grid",
    gridTemplateRows: "1fr min-content",
    height: "100%",
  },
  noWaitingRoom: {
    fontStyle: "italic",
    fontWeight: "normal",
    fontSize: "0.75rem",
  },
  waitingRoom: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  waitingRoomElement: {
    padding: "1rem 0",
    display: "grid",
    gridTemplateColumns: "max-content 1fr max-content",
    alignItems: "center",
    fontSize: "1rem",
    gap: "1rem",
  },
  waitingRoomAvatar: {
    fontSize: "0.625rem",
    width: "1.5rem",
    height: "1.5rem",
    backgroundColor: "white",
    color: "black",
    fontWeight: 600,
    border: `0.125rem solid ${theme.palette.primary.main}`,
    boxShadow: "0px 0.25rem 0.25rem 0 rgb(0, 0, 0, .25)",
  },
  admit: {
    cursor: "pointer",
  },
  admitLoader: {
    position: "absolute",
    display: "grid",
    placeItems: "center",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0.25rem",
  },
  divider: {
    backgroundColor: "white",
    marginTop: "1rem",
  },
  participants: {
    marginTop: "2rem",
  },
  participant: {
    display: "grid",
    gridTemplateColumns: "1fr min-content min-content",
    gridAutoFlow: "dense",
    gap: ".5rem",
  },
  footerParticipants: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
    padding: "1rem",
    "& > a": {
      color: "white",
      fontSize: "0.75rem",
    },
  },
  title: {
    fontSize: "18px",
    fontWeight: 600,
    margin: "0",
    textAlign: "center",
    marginBottom: "1.5rem",
  },
  subtitle: {
    fontSize: "16px",
    fontWeight: 600,
    margin: "0",
    marginBottom: "1.5rem",
  },
  select: {
    display: "block",
    maxWidth: "12rem",
    color: "white",
    background: "rgba(255,255,255,.1)",
  },
  adornment: {
    marginRight: "1rem",
  },
  iconOutlinedStyles: {
    color: "white !important",
  },
  outlinedStyles: {
    color: "white !important",
    fontSize: "0.875rem",
  },
  labelStyles: {
    color: "white !important",
  },
  notchedOutlineStyles: {
    borderColor: "white !important",
  },
  hidden: {
    opacity: 0,
    pointerEvents: "none",
    height: 0,
  },
  spaced: {
    marginTop: "1rem",
  },
  blankButton: {
    border: 0,
    margin: 0,
    padding: 0,
    background: "transparent",
    cursor: "pointer",
    color: "white",
    gridColumn: "-1",
  },
  drawerTitle: {
    "&:not(:first-child)": {
      marginTop: "1.5rem",
    }
  },
  drawerSubtitle: {
    fontSize: ".75rem",
    marginTop: ".5rem",
    marginBottom: "1.5rem",
  },
}));

const tabs = [GroupTherapyIcon, Settings];

const GroupVideocallSidebar = ({
  groupSession,
  networkConditions,
  onlineParticipants,
  changeVideoCallSettings,
  owner,
  setSavedCamera,
}) => {
  const { cameraList, microphoneList } = useMediaDevice();
  const {
    currentMicrophone,
    setCurrentMicrophone,
    currentCamera,
    setCurrentCamera,
  } = useAgoraContext();

  const styles = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesBelow1000px = useMediaQuery("@media (max-width:62.5rem)");

  const allPatients = useMemo(
    () =>
      groupSession?.participants?.filter(
        (participant) =>
          participant.typology === 1 ) || [],
    [onlineParticipants, groupSession]
  );
   
  const allCotherapists = useMemo(
    () =>
      groupSession?.participants?.filter(
        (participant) =>
          participant.typology === 0 && participant.id !== groupSession?.idOwner
      ) || [],
    [groupSession]
  );

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        {tabs.map((TabIcon, id) => (
          <button
            onClick={() => setActiveTab(id)}
            className={`${styles.tabIcon} ${
              activeTab === id ? styles.active : ""
            }`}
            key={id}
          >
            <TabIcon />
          </button>
        ))}
      </div>
      <div className={styles.innerPanes}>
        <div
          className={`${styles.participantsContainer} ${
            activeTab !== 0 ? styles.hidden : ""
          }`}
        >
          <div className={styles.drawerList}>
            <div className={styles.drawerTitle}>Pazienti :</div>
            {allPatients.map(
              (participant) =>(
                  <ParticipantRow
                    key={participant.id}
                    participant={participant}
                    isOnline={onlineParticipants.includes(participant.id)}
                  />
                )
            )}
            <div className={styles.drawerTitle}>Co-terapeuti (
              {allCotherapists.some((cotherapist) => (onlineParticipants.includes(cotherapist.id)))? 1 : 0}
              
              ):</div>
            {allCotherapists.map(
              (participant) => (
                onlineParticipants.includes(participant.id) ? 
                  <ParticipantRow
                    key={participant.id}
                    participant={participant}
                    isOnline={onlineParticipants.includes(participant.id)}
                    isTherapist
                  /> :
                  <div className={styles.drawerSubtitle}>Nessun co-terapeuta presente.</div>

                  
                )
            )}
            <div className={styles.drawerTitle}>Terapeuta (1):</div>
            <ParticipantRow
              participant={owner}
              isOnline={onlineParticipants.includes(owner?.id)}
              isTherapist
            />
          </div>
          <div className={styles.footerParticipants}>
            {matchesBelow1000px && networkConditions()}
            <a
              href="https://www.psycare.it/risoluzione-problemi-paziente/"
              target="_blank"
            >
              Problemi audio/video?
            </a>
          </div>
        </div>
        <div className={activeTab !== 1 && styles.hidden}>
          <h2 className={styles.title}>Impostazioni</h2>
          <div>
            <h3 className={styles.subtitle}>Audio/Video</h3>
            <TextField
              SelectProps={{
                classes: {
                  outlined: styles.outlinedStyles,
                  iconOutlined: styles.iconOutlinedStyles,
                },
              }}
              InputProps={{
                classes: {
                  notchedOutline: styles.notchedOutlineStyles,
                  focused: styles.notchedOutlineStyles,
                  adornedStart: styles.outlinedStyles,
                },
                startAdornment: <Videocam className={styles.adornment} />,
              }}
              InputLabelProps={{
                classes: {
                  root: styles.labelStyles,
                },
              }}
              margin="dense"
              variant="outlined"
              className={styles.select}
              label="Fotocamera"
              select
              fullWidth
              value={currentCamera?.deviceId}
              key={`currentCamera-${!!currentCamera?.deviceId}`}
              onChange={(e) => {
                const newCamera = cameraList.find(
                  (camera) => camera.deviceId === e.target.value
                );
                if (!newCamera) return;
                setSavedCamera(currentCamera)
                setCurrentCamera(newCamera);
                changeVideoCallSettings(newCamera, "video");
              }}
            >
              {cameraList.map((videoInput) => (
                <MenuItem key={videoInput.deviceId} value={videoInput.deviceId}>
                  {videoInput.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              SelectProps={{
                classes: {
                  outlined: styles.outlinedStyles,
                  iconOutlined: styles.iconOutlinedStyles,
                },
              }}
              InputProps={{
                classes: {
                  notchedOutline: styles.notchedOutlineStyles,
                  focused: styles.notchedOutlineStyles,
                  adornedStart: styles.outlinedStyles,
                },
                startAdornment: <VolumeUp className={styles.adornment} />,
              }}
              InputLabelProps={{
                classes: {
                  root: styles.labelStyles,
                },
              }}
              margin="dense"
              variant="outlined"
              className={styles.select}
              label="Microfono"
              select
              fullWidth
              value={currentMicrophone?.deviceId}
              key={`currentMicrophone-${!!currentMicrophone?.deviceId}`}
              onChange={(e) => {
                const newMic = microphoneList.find(
                  (mic) => mic.deviceId === e.target.value
                );
                if (!newMic) return;
                setCurrentMicrophone(newMic);
                changeVideoCallSettings(newMic, "audio");
              }}
            >
              {microphoneList.map((audioInput) => (
                <MenuItem key={audioInput.deviceId} value={audioInput.deviceId}>
                  {audioInput.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GroupVideocallSidebar;
