import { Box, Link, makeStyles, Paper, Tab, Typography } from '@material-ui/core';
import { VpnKey } from '@material-ui/icons';
import PersonIcon from '@material-ui/icons/Person';
import React, { useEffect, useState } from 'react';
import PsyTabs from '../components/PsyTabs';
import appsettings from '../config/appsettings.json';
import environment from '../config/environment.json';
import { useUser } from '../contexts/UserContext';
import useApi from '../hooks/useApi';
import Profilo_DatiGenerali from './Profilo_DatiGenerali';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            <Box p={3}>
                <Typography>{children}</Typography>
            </Box>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    light: {
        borderBottom: ".5px solid " + theme.palette.primary.light
    }
}));

const Profilo = (props) => {
    let [activeTab, setActiveTab] = useState(0);
    let [published, setPublished] = useState(true);
    const handleChange = (_, value) => {
        setActiveTab(value);
    };
    const togglePublished = () => {
        setPublished(!published);
    };
    const styles = useStyles();
    const api = useApi();
    const user = useUser();
    const [userProfile, setUserProfile] = useState(null);
    useEffect(() => {
        const getUserAsync = async () => {
            if (user?.profile) {
                let userResponse = await api(user, `patients/${user.profile.sub}`, "GET");
                if (userResponse) {
                    setUserProfile(userResponse);
                }
            }
        };
        getUserAsync();
    }, [user]);

    useEffect(() => {
        if (props.layoutOptions) {
            props.layoutOptions({
                title: "Profilo",
                disabled: false,
                Icon: PersonIcon,
            });
        }
    }, []);

    return (
        <React.Fragment>
            <>
                <PsyTabs
                    style={{ marginBottom: "1rem" }}
                    value={activeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    onChange={handleChange}
                >
                    <Tab
                        icon={<PersonIcon />}
                        label="Dati personali" />
                    <Tab
                        icon={<VpnKey />}
                        label="Credenziali Account" />
                </PsyTabs>
                <Paper>
                    <TabPanel value={activeTab} index={0}>
                        <Profilo_DatiGenerali userProfile={userProfile} />
                    </TabPanel>
                    <TabPanel value={activeTab} index={1}>
                        <ul>
                            <li><Link target="_blank" href={`${appsettings[environment.Environment].user_manager_config.authority}/Account/patient/ForgotPassword?email=${user.profile.email}`}>Resetta la tua password</Link></li>
                            <li><Link target="_blank" href={`${appsettings[environment.Environment].user_manager_config.authority}/Account/RequestData`}>Richiedi i tuoi dati</Link></li>
                            <li><Link target="_blank" href={`${appsettings[environment.Environment].user_manager_config.authority}/Account/DeleteAccount`}>Richiedi cancellazione account</Link></li>
                        </ul>
                    </TabPanel>
                </Paper>
            </>
        </React.Fragment>
    );
};

export default Profilo;