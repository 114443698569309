import { makeStyles, Tabs, useMediaQuery, useTheme } from '@material-ui/core';
import React, { Children, cloneElement, useMemo } from 'react';

const useStyle = makeStyles(theme => ({
    tabActived: {},
    tabDeactive: {
        backgroundColor: '#CFE2DF',
        color: '#505050'
    },
    tabFlexContainer: {
        justifyContent: 'center',
        "& > *": {
            backgroundColor: "#CFE2DF",
            borderRadius: 0,
        },
        "& > :first-child": {
            borderTopLeftRadius: '0.5rem',
            borderBottomLeftRadius: '0.5rem'
        },
        "& > :last-child": {
            borderTopRightRadius: '0.5rem',
            borderBottomRightRadius: '0.5rem',
        },
        "& $tabActived": {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
        },
    },
    tabIndicator: {
        backgroundColor: 'unset',
    },
    subTitle: {
        fontWeight: 600,
        fontSize: "1rem",
        lineHeight: "1rem",
        marginTop: "1rem",
    },
}));

const PsyTabs = ({ children, ...props }) => {
    const classes = useStyle();
    const theme = useTheme();
    const matchesUpSm = useMediaQuery(theme.breakpoints.up("sm"));
    const activeLabel = useMemo(() => {
        const childrens = Children.toArray(children);
        return <div className={classes.subTitle}>{childrens[props.value].props.label || ""}</div>
    }, [props.value, children])
    return (
        <>
            <Tabs
                {...props}
                classes={{
                    flexContainer: !matchesUpSm ? classes.tabFlexContainer : null,
                    indicator: !matchesUpSm ? classes.tabIndicator : null,
                }}
            >
                {Children.map(children, (child) => (
                    cloneElement(child, {
                        classes: {
                            disabled: !matchesUpSm ? classes.tabDeactive : null,
                            selected: !matchesUpSm ? classes.tabActived : null,
                        },
                        icon: !matchesUpSm ? child?.props?.icon : null,
                        label: matchesUpSm ? child?.props?.label : null,
                    })
                ))}
            </Tabs>
            {!matchesUpSm && activeLabel}
        </>
    )
};
export default PsyTabs;