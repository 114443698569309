import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Close as CloseIcon } from '@material-ui/icons';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, useTheme, useMediaQuery, Link } from '@material-ui/core';
import { usePopups } from '../contexts/PopupContext';
import { MoreVert } from '@material-ui/icons';


const useStyles = makeStyles(theme => ({
    paper: {
        margin: "2rem 1rem 2rem 1rem",
        [theme.breakpoints.up("sm")]: {
            margin: "2rem",
        }
    },
    widthFalse: {
        maxWidth: "100%",
    },
    titleRoot: {
        paddingLeft: "1rem",
        paddingRight: "1rem",
        [theme.breakpoints.up("sm")]: {
            paddingTop: "1.5rem",
            paddingBottom: "1.5rem",
        },
        "& h2": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
        },
    },
    closeIcon: {
        alignSelf: "flex-start",
        color: theme.palette.common.black,
        fontSize: ".875rem",
        padding: 0,

    },
    title: {
        fontSize: "1rem",
        fontWeight: "600",
        lineHeight: "1.357",
        color: theme.palette.common.black,
        paddingRight: ".5rem",
        [theme.breakpoints.up("sm")]: {
            fontSize: "1.125rem",
        },
    },

    content: {
        paddingLeft: "1rem",
        paddingRight: "1rem",
        fontSize: ".875rem",
        color: theme.palette.common.black,
    },
    moreIcon: {
        borderRadius: "50%",
        border: `solid 2px ${theme.palette.primary.main}`,
        fontSize: "1.25rem",
        verticalAlign: "sub",
    },
    text: {
        marginBottom: "1rem",
    },
    link: {
        display: "block",
        color: "#000AFF",
    },
    wrapperBtn: {
        display: "flex",
        flexDirection: "column",
        paddingBottom: "1rem",
        [theme.breakpoints.up("sm")]: {
            flexDirection: "row",
            padding: "1rem",
            paddingTop: "1.5rem",
        }
    },
    btn: {
        fontSize: "1rem",
        fontWeight: "600",
        textShadow: "1px 1px 1px rgba(0, 0, 0, 0.25)",
    }
}));

const ErrorWebcamOrMicrDialog = () => {
    const theme = useTheme();
    const styles = useStyles();
    const matches = useMediaQuery(theme.breakpoints.up("sm"));
    const [{ errorWebcamOrMicrPopup }, setPopups] = usePopups();
    return (
        <Dialog open={errorWebcamOrMicrPopup?.open} classes={{ paper: styles.paper, paperWidthFalse: styles.widthFalse }} scroll='body' maxWidth={matches ? 'sm' : false} >
            <DialogTitle classes={{ root: styles.titleRoot }} >
                <div className={styles.title}>{errorWebcamOrMicrPopup?.props?.title || "Errore con webcam o microfono"}</div>
                <IconButton aria-label="close"
                    onClick={() => {
                        setPopups(prev => ({
                            ...prev,
                            errorWebcamOrMicrPopup: {
                                open: false,
                            }
                        }));

                    }}
                    className={styles.closeIcon}
                >
                    <CloseIcon />
                </IconButton>

            </DialogTitle>
            <DialogContent classes={{ root: styles.content }}>
                <div className={styles.text}>{errorWebcamOrMicrPopup?.props?.message}</div>
                {/* <Link className={styles.link} underline="always" target="_blank" href="https://www.psycare.it/risoluzione-problemi-paziente/">Se hai ancora dei problemi, clicca qui.</Link> */}
            </DialogContent>
            <DialogActions classes={{ root: styles.wrapperBtn }}>
                <Button
                    color="primary"
                    variant="contained"
                    className={styles.btn}
                    onClick={() => {
                        setPopups(prev => ({
                            ...prev,
                            errorWebcamOrMicrPopup: {
                                open: false,
                            }
                        }));

                    }}
                >
                    ok, grazie
                </Button>
            </DialogActions>
        </Dialog>);
};
export default ErrorWebcamOrMicrDialog;