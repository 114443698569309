import React, { useState, createContext, useContext } from 'react';

const useGroupSession = () => {
    return useContext(GroupCallContext)
};
const GroupCallContext = createContext(null);

const GroupCallContextProvider = ({ children }) => {
    const [groupSession, setGroupSession] = useState(null);
    return (
        <GroupCallContext.Provider value={[groupSession, setGroupSession]}>
            {children}
        </GroupCallContext.Provider>
    )
}

export default GroupCallContextProvider;
export { useGroupSession };