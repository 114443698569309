import React, { useCallback, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { dateFormatter, downloadFile } from "../utils/UtilsFunction";
import useApi from "../hooks/useApi";
import { useUser } from "../contexts/UserContext";
import { useGlobalSnackbar } from "../contexts/GlobalSnackbarContext";
import { GetApp } from "@material-ui/icons";

const useStyle = makeStyles((theme) => ({
  wrapperTitle: {
    "&>h2": {
      display: "flex",
      gap: "2rem",
      justifyContent: "space-between",
      alignItems: "center",
      color: theme.palette.common.black,
    },
  },
  exitButton: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    margin: 0,
    backgroundColor: "transparent",
    border: 0,
    outline: 0,
    cursor: "pointer",
  },
  dialogAction: {
    padding: "1rem 1.5rem",
  },
}));

const ChoseDownloadTypePopup = ({
  onClose,
  isOpen,
  patientName,
  patientSurname,
  idDocument,
  date,
  isManual,
  whiteboardId,
}) => {
  const style = useStyle();
  const api = useApi();
  const [selectedFormat, setSelectedFormat] = useState("png");
  const [loading, setLoading] = useState(false);

  const handleFormat = (e) => {
    setSelectedFormat(e.target.value);
  };

  const closeModal = () =>{
    setSelectedFormat("png")
    onClose()
  }

  const user = useUser();
  const theme = useTheme();
  const [globalSnackbar, setGlobalSnackbar] = useGlobalSnackbar();

  const downloadPdf = useCallback(async () => {
    setLoading(true);
    let response;
    if (!isManual) {
      response = await api(
        user,
        `sessions/${idDocument}/whiteboard/pdf`,
        "GET"
      );
    } else {
      response = await api(
        user,
        `sessions/${idDocument}/whiteboard/${whiteboardId}/pdf`,
        "GET"
      );
    }
    if (response.ok != false) {
      setGlobalSnackbar({
        severity: "success",
        open: true,
        message: "Il file verrà scaricato a breve.",
      });
      downloadFile({
        base64File: response?.base64File,
        fileName: `${patientName} ${patientSurname} ${date} .${selectedFormat}`,
      });
      setLoading(false);
      closeModal();
    } else {
      setGlobalSnackbar({
        severity: "error",
        open: true,
        message: "Non è possibile scaricare il file",
      });
      setLoading(false);
      closeModal();
    }
  }, [idDocument, selectedFormat, whiteboardId]);

  const downloadImage = useCallback(
    async (selectedFormat) => {
      setLoading(true);
      let imageResponse;
      if (!isManual) {
        imageResponse = await api(
          user,
          `sessionsimage/${idDocument}/whiteboard/image?type=${selectedFormat}`,
          "GET"
        );
      } else {
        imageResponse = await api(
          user,
          `sessions/${idDocument}/whiteboard/${whiteboardId}/image?type=${selectedFormat}`,
          "GET"
        );
      }
      if (imageResponse.ok !== false) {
        setGlobalSnackbar({
          severity: "success",
          open: true,
          message: "Il file verrà scaricato a breve.",
        });
        downloadFile({
          base64File: imageResponse?.image?.replace(
            `data:image/${selectedFormat};base64,`,
            ""
          ),
          fileName: `${patientName} ${patientSurname} ${date} .${selectedFormat}`,
        });
        setLoading(false);
        closeModal();
      } else {
        setLoading(false);
        closeModal();
        setGlobalSnackbar({
          severity: "error",
          open: true,
          message: "Non è possibile scaricare il file",
        });
      }
    },
    [idDocument, selectedFormat, whiteboardId]
  );

  return (
    <Dialog open={isOpen}>
      <DialogTitle className={style.wrapperTitle}>
        <Button style={{ fontSize: "1rem" }}>
          Lavagna di {patientName} {patientSurname} del {date != null && date}
        </Button>
        <button className={style.exitButton} onClick={onClose}>
          <CloseIcon />
        </button>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          In che formato preferisci scaricare il file?
        </DialogContentText>
        <RadioGroup onChange={handleFormat} value={selectedFormat}>
          <FormControlLabel value="png" control={<Radio />} label=".png" />
          <FormControlLabel value="pdf" control={<Radio />} label=".pdf" />
          <FormControlLabel value="jpeg" control={<Radio />} label=".jpeg" />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={onClose}>
          Annulla
        </Button>
        <Button
          disabled={loading}
          variant="contained"
          color="primary"
          onClick={() => {
            switch (selectedFormat) {
              default:
                downloadImage("png");
                break;
              case "pdf":
                downloadPdf();
                break;
              case "jpeg":
                downloadImage("jpeg");
                break;
            }
          }}
          startIcon={
            loading ? (
              <CircularProgress
                color={theme.palette.primary.contrastText}
                size="1rem"
              />
            ) : (
              <GetApp />
            )
          }
        >
          Scarica
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChoseDownloadTypePopup;
