import { Box, Tab, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import { useTheme } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import PaymentHistoryIcon from '../components/icons/PaymentHistoryIcon';
import PaymentToPayIcon from '../components/icons/PaymentToPayIcon';
import PaymentHistory from '../components/Payments/PaymentHistory';
import PaymentToPay from '../components/Payments/PaymentToPay';
import PsyTabs from '../components/PsyTabs';

const Payment = (props) => {

    const classes = useStyles();
    const theme = useTheme();
    const matchesUpSm = useMediaQuery(theme.breakpoints.up('sm'));
    const [activeTab, setActiveTab] = useState(0);

    //Use effect for customize layout of page
    useEffect(() => {
        if (props.layoutOptions) {
            props.layoutOptions({
                title: "Pagamenti",
                disabled: false,
                Icon: AccountBalanceWalletIcon,
            });
        }
    }, []);

    return (
        <div>
            <PsyTabs value={activeTab}
                onChange={(event, newValue) => { setActiveTab(newValue); }}
                aria-label="Tab di selezione dei pagamenti"
                variant='scrollable'
                indicatorColor="primary"
            >
                <Tab
                    icon={<PaymentToPayIcon />}
                    label="Pagamenti da effettuare"
                />
                <Tab
                    icon={<PaymentHistoryIcon />}
                    label="Storico dei pagamenti"
                />
            </PsyTabs>
            <Box hidden={activeTab !== 0}><PaymentToPay refresh={activeTab == 0} /></Box>
            <Box hidden={activeTab !== 1}><PaymentHistory refresh={activeTab == 1} /></Box>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    tabActived: {},
    tabDeactive: {
        backgroundColor: '#CFE2DF',
        color: '#505050'
    },
    tabFlexContainer: {
        justifyContent: 'center',
        "& > *": {
            backgroundColor: "#CFE2DF",
            borderRadius: 0,
        },
        "& > :first-child": {
            borderTopLeftRadius: '0.5rem',
            borderBottomLeftRadius: '0.5rem'
        },
        "& > :last-child": {
            borderTopRightRadius: '0.5rem',
            borderBottomRightRadius: '0.5rem',
        },
        "& $tabActived": {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
        },
    },
    tabIndicator: {
        backgroundColor: 'unset',
    }
}));

export default Payment