import React, { useEffect, useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Button, DialogTitle, Dialog, DialogContent, DialogActions, DialogContentText, useMediaQuery, IconButton, Tooltip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TextField } from '@material-ui/core';
import { useGlobalSnackbar } from '../../contexts/GlobalSnackbarContext';
import { useTheme } from '@material-ui/styles';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';

//Default data for every paymentItems
const defaultData = {
    bonifico: {
        owner: "",
        iban: "",
        bic: "",
    }
}

const PaymentItem = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const matchesUpSm = useMediaQuery(theme.breakpoints.up('sm'));
    const [expanded, setExpanded] = useState(props?.create ? props?.create : false); //For see if accordion is open or not
    //Data for PaymentItem
    const [data, setData] = useState(props?.data || defaultData[props?.type]);
    const [globalSnackbar, setGlobalSnackbar] = useGlobalSnackbar();

    useEffect(() => {
        setData(props?.data || defaultData[props?.type])
    }, [props?.data])

    const copyToClipboard = useCallback((valueToCopy) => {
        try {
            navigator.clipboard.writeText(valueToCopy)

            setGlobalSnackbar({ open: true, severity: "info", message: "Elemento copiato" });
        }catch(error){
            setGlobalSnackbar({ open: true, severity: "error", message: "Elemento non copiato" });
        }
    }, []);


    //Here you can add template for othere payment type
    const template = {
        bonifico: <Accordion
            expanded={expanded}
            onChange={(event, isExpanded) => { setExpanded(isExpanded ? true : false); }}
            classes={{ root: classes.accordion }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id="additional-actions1-header"
                classes={{ content: classes.accordionSummaryContent }}
            >
                <div className={classes.accordionSummaryDiv}>
                    <div className={classes.accordionSummaryTitle}>
                        <Typography className={classes.heading}>{`${matchesUpSm ? 'Bonifico su conto corrente' : 'Bonifico su C/C'}`}</Typography>
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails classes={{ root: classes.accordionDetails }}>
                <div className={classes.bonificoDetailDiv}>
                    <div className={classes.inputDiv}>
                        <Typography className={classes.inputTitle}>Intestatario del conto corrente:</Typography>
                        <TextField
                            id="outlined-helperText"
                            value={data?.owner}
                            variant="outlined"
                            readOnly
                            InputProps={{
                                endAdornment: <Tooltip title="Copia" ><FileCopyOutlinedIcon classes={{ root: classes.copyToClipboard }} onClick={() => { copyToClipboard(data?.owner) }} /></Tooltip>
                            }}
                            inputProps={{className: classes.inputText}}
                        />
                    </div>
                    <div className={classes.inputDiv}>
                        <Typography className={classes.inputTitle}>Codice IBAN:</Typography>
                        <TextField
                            readOnly
                            id="outlined-helperText"
                            value={data?.iban}
                            variant="outlined"
                            InputProps={{
                                endAdornment: <Tooltip title="Copia" ><FileCopyOutlinedIcon classes={{ root: classes.copyToClipboard }} onClick={() => { copyToClipboard(data?.iban) }} /></Tooltip>
                            }}
                            inputProps={{className: classes.ibanTextField}}
                        />
                    </div>
                    <div className={classes.inputDiv}>
                        <Typography className={classes.inputTitle}>Codice BIC:</Typography>
                        <TextField
                            classes={{ root: classes.bicTextField }}
                            readOnly
                            id="outlined-helperText"
                            value={data?.bic}
                            variant="outlined"
                            InputProps={{
                                endAdornment: <Tooltip title="Copia" ><FileCopyOutlinedIcon classes={{ root: classes.copyToClipboard }} onClick={() => { copyToClipboard(data?.bic) }} /></Tooltip>
                            }}
                        />
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>,
    }

    return (
        <div>
            <div className={classes.paymentItemDiv}>{template[props?.type]}</div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: { width: '100%' },

    copyToClipboard: { cursor: 'pointer', color: 'black' },

    heading: { fontSize: '1.125rem', fontWeight: '600', },

    paymentItemDiv: { marginBottom: '0.625rem', },

    accordion: { backgroundColor: '#CFE2DF', borderRadius: '20px', boxShadow: 'unset', },

    accordionSummaryDiv: { display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center', },

    accordionSummaryTitle: { display: 'flex', flexDirection: 'row' },

    accordionSummaryContent: { margin: '0.5rem 0rem' },

    accordionDetails: { backgroundColor: 'white', padding: '8px 0rem', },

    bonificoDetailDiv: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        }
    },

    inputDiv: {
        display: 'flex', flexDirection: 'column', marginLeft: '8px',
        [theme.breakpoints.down('md')]: {
            marginBottom: '8px'
        }
    },

    inputTitle: { color: '#505050', fontSize: '1rem', whiteSpace:'nowrap' },

    ibanTextField: {
        minWidth: '28ch',
        [theme.breakpoints.down('md')]: {
            minWidth: 'unset',
        }
    },
    bicTextField: {
        minWidth: '14rem',
        [theme.breakpoints.down('md')]: {
            minWidth: 'unset',
        }
    },

    inputText:{overflow:'hidden',textOverflow:'ellipsis'},

}));

export default PaymentItem