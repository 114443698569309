import { SvgIcon } from '@material-ui/core';
import React from 'react'

/*
This svg component rappresent videocallgroup icon
*/

const GroupCallIcon = props => {
    return (
        <SvgIcon {...props}>
            <path d="M19.2593 7.3335V5.77794C19.2593 5.5335 19.0259 5.3335 18.7407 5.3335H12.5185C12.2333 5.3335 12 5.5335 12 5.77794V10.2224C12 10.4668 12.2333 10.6668 12.5185 10.6668H18.7407C19.0259 10.6668 19.2593 10.4668 19.2593 10.2224V8.66683L21.3333 10.4446V5.55572L19.2593 7.3335Z" />
            <path d="M10.667 16.5C11.7537 16.5 12.7137 16.76 13.4937 17.1C14.2137 17.42 14.667 18.14 14.667 18.92V20H6.66699V18.9267C6.66699 18.14 7.12033 17.42 7.84033 17.1067C8.62033 16.76 9.58033 16.5 10.667 16.5ZM5.33366 16.6667C6.06699 16.6667 6.66699 16.0667 6.66699 15.3333C6.66699 14.6 6.06699 14 5.33366 14C4.60033 14 4.00033 14.6 4.00033 15.3333C4.00033 16.0667 4.60033 16.6667 5.33366 16.6667ZM6.08699 17.4C5.84033 17.36 5.59366 17.3333 5.33366 17.3333C4.67366 17.3333 4.04699 17.4733 3.48033 17.72C2.98699 17.9333 2.66699 18.4133 2.66699 18.9533V20H5.66699V18.9267C5.66699 18.3733 5.82033 17.8533 6.08699 17.4ZM16.0003 16.6667C16.7337 16.6667 17.3337 16.0667 17.3337 15.3333C17.3337 14.6 16.7337 14 16.0003 14C15.267 14 14.667 14.6 14.667 15.3333C14.667 16.0667 15.267 16.6667 16.0003 16.6667ZM18.667 18.9533C18.667 18.4133 18.347 17.9333 17.8537 17.72C17.287 17.4733 16.6603 17.3333 16.0003 17.3333C15.7403 17.3333 15.4937 17.36 15.247 17.4C15.5137 17.8533 15.667 18.3733 15.667 18.9267V20H18.667V18.9533ZM10.667 12C11.7737 12 12.667 12.8933 12.667 14C12.667 15.1067 11.7737 16 10.667 16C9.56033 16 8.66699 15.1067 8.66699 14C8.66699 12.8933 9.56033 12 10.667 12Z" />
        </SvgIcon>
    )
}

export default GroupCallIcon
