
import { SvgIcon } from '@material-ui/core';
import React from 'react';
const TransgenderUDIcon = props => {
        return (
                <SvgIcon {...props}>
                        <rect x="5.83331" y="5.83333" width="12.3333" height="12.3333" fill="none" stroke="currentColor" />
                        <circle cx="12" cy="12" r="9.5"  fill="none" stroke="currentColor" />
                </SvgIcon>
        )
};
export default TransgenderUDIcon;
