import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Slide,
  IconButton,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  CallEnd,
  Close,
  MeetingRoom,
  Mic,
  MicOff,
  MoreVert,
  NetworkCheck,
  PresentToAll,
  Remove,
  SignalWifi0Bar,
  Stop,
  Videocam,
  VideocamOff,
  VolumeUp,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import appsettings from "../config/appsettings.json";
import environment from "../config/environment.json";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import AlertDialog from "../components/AlertDialog";
import ParticipantBox from "../components/GroupSessions/ParticipantBox";
import VideoCallButton from "../components/VideoCallButton";
import { useGlobalSnackbar } from "../contexts/GlobalSnackbarContext";
import { useGroupSession } from "../contexts/GroupCallContext";
import { useLocalHistory } from "../contexts/LastClickedContext";
import { usePopups } from "../contexts/PopupContext";
import { useUser, useUserProfile } from "../contexts/UserContext";
import useApi from "../hooks/useApi";
import { FRAME_RATE, grayLog, lerpArray } from "../utils/UtilsFunction";
import logoHorizontalWhite from "../assets/various/logo_horizontal_white.png";
import useOnUnmount from "../hooks/useOnUnmount";
import GroupVideocallSidebar from "../components/GroupVideocallSidebar";
import PsyTimer from "../components/PsyTimer";
import NetworkResults from "../components/NetworkResults";
import MicLevels from "../components/MicLevels";
import DoubleArrowIcon from "../components/icons/DoubleArrowIcon";
import OpenWhiteboardIcon from "../components/icons/OpenWhiteboardIcon";
import { NoRoom, NotIn } from "../components/SessionErrorPage";
import { useAnonUser } from "../hooks/useAnonUser";
import SessionEnded from "../components/SessionEnded";
import AgoraRTC, {
  ConnectionDisconnectedReason,
  IAgoraRTCRemoteUser,
  ILocalAudioTrack,
  ILocalVideoTrack,
  ITrack,
} from "agora-rtc-sdk-ng";
import {
  SignalingEvent,
  SignalingEventsMap,
  useSignaling,
  useSignalingListener,
} from "../contexts/SignalingContext";
import { useEventEffect } from "../hooks/useEventEffect";
import useMediaDevice from "../hooks/useMediaDevice";
import { useAgoraContext } from "../contexts/AgoraContext";
import WarningIcon from "@material-ui/icons/Warning";
import { AlertTitle } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";

const below1000pxMediaQuery = "@media (max-width:62.5rem)";
const below1200pxMediaQuery = "@media (max-width:75rem)";
const over1200pxMediaQuery = "@media (min-width:75rem)";
const below600pxMediaQuery = "@media (max-width:37.5rem)";

//function to stop the stream
const stopStream = (stream) =>
  stream?.getTracks?.().forEach((track) => track.stop());

const getVideoIdFromStream = (stream) =>
  stream?.getVideoTracks?.()?.[0]?.getSettings?.()?.deviceId;
const getAudioIdFromStream = (stream) =>
  stream?.getAudioTracks?.()?.[0]?.getSettings?.()?.deviceId;

function hasVideoOrAudio(uid: string, map: Map<string, ITrack | undefined>) {
  const video = map.has(`${uid}-video`);
  const audio = map.has(`${uid}-audio`);
  return video || audio;
}

function attachToElement(videoElement: string, track: ITrack, mirror = false) {
  if (!videoElement || !track) return;
  // we do the casting here to get access to the config
  // intellisense. We don't care if it's a local audio because
  // that will not take any argument and this allows us to
  // specify the config for the video without doing an explicit
  // and non useful check
  (track as ILocalVideoTrack).play(videoElement, {
    mirror,
    fit: "cover",
  });
}

//mapping from number of participant to grid columns in mobile
const mobileGrids = [
  {
    key: [3, 4],
    value: {
      rows: 2,
      cols: 2,
    },
  },
  {
    key: [5, 6],
    value: {
      rows: 3,
      cols: 2,
    },
  },
  {
    key: [7, 8],
    value: {
      rows: 4,
      cols: 2,
    },
  },
  {
    key: [9],
    value: {
      rows: 3,
      cols: 3,
    },
  },
  {
    key: [10, 11, 12],
    value: {
      rows: 4,
      cols: 3,
    },
  },
  {
    key: [13, 14, 15],
    value: {
      rows: 5,
      cols: 3,
    },
  },
  {
    key: [16],
    value: {
      rows: 4,
      cols: 4,
    },
  },
  {
    key: [17, 18, 19, 20],
    value: {
      rows: 5,
      cols: 4,
    },
  },
  {
    key: [21, 22],
    value: {
      rows: 6,
      cols: 4,
    },
  },
];
//mapping from number of participant to grid columns in desktop
const desktopGrids = [
  {
    key: [3, 4],
    value: {
      rows: 2,
      cols: 2,
    },
  },
  {
    key: [5, 6],
    value: {
      rows: 2,
      cols: 3,
    },
  },
  {
    key: [7, 9],
    value: {
      rows: 3,
      cols: 3,
    },
  },
  {
    key: [8],
    value: {
      rows: 2,
      cols: 4,
    },
  },
  {
    key: [10],
    value: {
      rows: 2,
      cols: 5,
    },
  },
  {
    key: [11, 12],
    value: {
      rows: 3,
      cols: 4,
    },
  },
  {
    key: [13, 14, 15],
    value: {
      rows: 3,
      cols: 5,
    },
  },
  {
    key: [16],
    value: {
      rows: 4,
      cols: 4,
    },
  },
  {
    key: [17, 18, 19, 20],
    value: {
      rows: 4,
      cols: 5,
    },
  },
  {
    key: [21, 22],
    value: {
      rows: 4,
      cols: 6,
    },
  },
];

// @ts-ignore
const useStyles = makeStyles((theme) => ({
  snackBarActions: {
    alignItems: "flex-start",
  },
  loadingBox: {
    width: "100%",
    height: "100%",
    display: "grid",
    placeItems: "center",
  },
  container: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#444",
    overflow: "auto",
    color: "white",
    position: "relative",
  },
  logoWaitingRoom: {
    position: "absolute",
    margin: "1rem",
    maxWidth: "clamp(10rem, 35vw, 17rem)",
    top: 0,
    left: 0,
  },
  alertWaitingRoom: {
    placeSelf: "end",
    maxWidth: "max(25vw,487px)",
    margin: "1rem",
  },
  mediaPreviewAndSelect: {
    minWidth: "50%",
    maxWidth: "75%",
    justifySelf: "center",
    justifyContent: "center",
    display: "grid",
    gap: "24px",
    marginBlock: "48px",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr",
    width: "90%",
    [over1200pxMediaQuery]: {
      gridTemplateColumns: "min-content 25%",
      gridTemplateRows: "unset",
    },
  },
  mediaPreview: {
    position: "relative",
    height: "unset",
    aspectRatio: "3 / 2",
    justifySelf: "end",
    border: "1px solid #cccccc",
    background: "#333",
    boxSizing: "border-box",
    width: "100%",
    [over1200pxMediaQuery]: {
      width: "unset",
      height: "40vh",
      gridTemplateColumns: "min-content 25%",
      gridTemplateRows: "unset",
    },
    "& > video": {
      width: "100%",
      height: "100%",
      objectFit: "contain",
    },
  },
  videoMuted: {
    filter: "brightness(0)",
  },
  previewActions: {
    position: "absolute",
    width: "100%",
    display: "grid",
    justifyContent: "center",
    gap: "1rem",
    bottom: "1rem",
    gridAutoFlow: "column",
    "& > button": {
      border: 0,
      outline: 0,
      backgroundColor: "hsla(0, 0%, 20%, 0.9)",
      color: "white",
      padding: "0.25rem",
      aspectRatio: "1/1",
      margin: 0,
      cursor: "pointer",
      borderRadius: ".5rem",
      display: "grid",
    },
  },
  mediaSelect: {
    display: "grid",
    padding: "1rem",
    background: "#333",
    borderRadius: "1rem",
    gridAutoRows: "min-content",
    gap: ".3rem",
    "& > button": {
      width: "fit-content",
      background: "transparent",
      color: "white",
      margin: "0",
      padding: "0",
      border: "0",
      outline: "0",
      textDecoration: "underline",
      cursor: "pointer",
      placeSelf: "end",
      marginBottom: "-0.5rem",
      marginTop: "1rem",
    },
    "& > *:not(span)": {
      maxWidth: "100%",
    },
    "& > span": {
      width: "100%",
      textAlign: "center",
      fontSize: "0.875rem",
    },
  },
  checkConnection: {
    border: "1px solid white",
    padding: ".3rem",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: ".25rem",
    gap: ".5rem",
  },
  select: {
    display: "block",
    maxWidth: "12rem",
    color: "white",
    background: "rgba(255,255,255,.1)",
  },
  adornment: {
    marginRight: "1rem",
  },
  iconOutlinedStyles: {
    color: "white !important",
  },
  outlinedStyles: {
    color: "white !important",
    fontSize: "0.875rem",
  },
  labelStyles: {
    color: "white !important",
  },
  notchedOutlineStyles: {
    borderColor: "white !important",
  },
  actionContainer: {
    boxSizing: "border-box",
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    padding: "2rem 2rem 0 0",
    "& > svg": {
      cursor: "pointer",
    },
    "& > * + *": {
      marginLeft: "1rem",
    },
  },
  linksContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      margin: "0 8.5rem",
    },
  },
  conferenceContainer: {
    backgroundColor: "#222",
    height: "100%",
    position: "relative",
    overflow: "hidden",
  },
  drawer: {
    position: "absolute",
    right: "0px",
    color: "white",
    backgroundColor: "#333",
    height: "100%",
    transition: "transform 250ms ease-in-out",
    transform: "translateX(100%)",
    zIndex: 1000,
    borderLeft: "1px solid rgba(255,255,255, .3)",
    "& svg": {
      transition: "transform 250ms ease-in-out",
      transform: "rotate(180deg)",
    },
  },
  drawerOpen: {
    transform: "translateX(0)",
    "& svg": {
      transform: "rotate(0deg)",
    },
  },
  drawerOpener: {
    position: "absolute",
    top: "1rem",
    transform: "translateX(-100%)",
    background: "#333",
    cursor: "pointer",
    padding: ".5rem",
    display: "flex",
    border: "1px solid rgba(255,255,255, .3)",
    borderRight: 0,
  },
  drawerTitle: {
    fontWeight: "600",
  },
  drawerList: {
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: "calc(100% - 2rem)",
    margin: "1rem 0",
    display: "grid",
    gridTemplateColumns: "max-content",
    [theme.breakpoints.up("sm")]: {
      scrollbarWidth: "thin",
      "&::-webkit-scrollbar": {
        backgroundColor: "rgba(255,255,255, .5)",
        borderRadius: "100vw",
        width: ".5rem",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(255,255,255)",
        borderRadius: "100vw",
      },
    },
  },
  conferenceParticipants: ({ rows, cols }) => ({
    width: "100%",
    height: "100%",
    display: "grid",
    gap: ".5rem",
    //to accomodate for different number of participants
    //we pass informations about rows and cols it defaults to 5x5
    gridTemplateColumns: `repeat(${cols || 5}, 1fr)`,
    gridTemplateRows: `repeat(${rows || 5}, 1fr)`,
    "& > *": {
      color: "white",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      fontWeight: 600,
      padding: ".125rem",
      "& > video": {
        position: "absolute",
        width: "100%",
        maxHeight: "100%",
        height: "100%",
        objectFit: "cover",
        margin: 0,
      },
      "& > * + *": {
        marginTop: "1rem",
        textAlign: "center",
        fontSize: ".7rem",
        [theme.breakpoints.up("sm")]: {
          fontSize: "1rem",
        },
      },
    },
  }),
  patientDialogAction: {
    paddingRight: "1.5rem",
    paddingLeft: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  patientNotShowBtn: {
    textShadow: "1px 1px 1px rgb(0 0 0 / 25%)",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  patientShowBtn: {
    textShadow: "1px 1px 1px rgb(0 0 0 / 25%)",
    flex: 1,
    [theme.breakpoints.down("sm")]: {
      marginTop: "0.625rem",
      width: "100%",
    },
  },
  desktopPatetientIcon: {
    width: "auto",
    height: "1.563rem",
  },
  hidden: {
    display: "none",
  },
  loggedUser: {
    gridRowEnd: -1,
    gridColumnEnd: -1,
    "& video": {
      transform: "scaleX(-1)",
    },
  },
  logoAndQoS: {
    display: "flex",
    color: "white",
    alignItems: "center",
    maxWidth: "100%",
    maxHeight: "3rem",
    padding: "0.5rem",
    "& > img": {
      maxHeight: "2rem",
      maxWidth: "100%",
    },
    [below1000pxMediaQuery]: {
      opacity: 0,
    },
  },
  therapistQoS: {
    textAlign: "center",
  },
  timerAndClose: {
    placeSelf: "end",
    display: "grid",
    gridTemplateColumns: "min-content",
    gap: "1rem",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "min-content min-content",
    },
  },
}));

let callStatsArray = [];
const streams = [];
let online = [];
let streamsQueue = [];
let entering;

const GroupSession = ({
  setIsNotifyJoin,
  isSpeedTestAvailable,
  availableDevices,
  setMediaDeviceChanged,
  inUseMichrophone,
  inUseWebcam,
  setInUseMichrophone,
  setInUseWebcam,
  isPatientSTRunning,
  patientSTResults,
  connectionMessage,
  setIsPatientSTRunning,
}) => {
  const signaling = useSignaling();
  const sendToGroup = useEventEffect(
    <T extends SignalingEvent>(
      event: T,
      data: SignalingEventsMap[T],
      needResponse?: boolean
    ) => {
      groupSession?.participants?.forEach((participant) => {
        signaling.send(participant.id, event, data, needResponse);
      });
    }
  );
  const audioStream = useRef<ILocalAudioTrack | undefined>();
  const videoStream = useRef<ILocalVideoTrack | undefined>();

  const { cameraList, microphoneList } = useMediaDevice();

  const {
    currentMicrophone,
    setCurrentMicrophone,
    currentCamera,
    setCurrentCamera,
    engine,
  } = useAgoraContext();

  const { nanoId } = useParams();
  const { user, userProfile, error, sessionIds, isAnon } = useAnonUser(nanoId);
  const [patientId, setPatientId] = useState();
  const [isEndedForAnonUser, setIsEndedForAnonUser] = useState(false);
  const history = useHistory();
  const [groupSession, setGroupSession] = useGroupSession();
  const [onlineParticipants, setOnlineParticipants] = useState([]);
  const [speakingParticipants, setSpeakingParticipants] = useState([]);
  const [conference, setConference] = useState();
  const [session, setSession] = useState(null);
  const [sessionMessage, setSessionMessage] = useState("");
  const [isClickedOnJoin, setIsClickedOnJoin] = useState(false);
  const [isConferenceJoined, setIsConferenceJoined] = useState(false);
  const [isConsentGiven, setIsConsentGiven] = useState(true);
  const [isAudioActive, setIsAudioActive] = useState(true);
  const [isVideoActive, setIsVideoActive] = useState(true);
  const [isVideoSettingsShowing, setIsVideoSettingsShowing] = useState(false);
  const [isClosingAlertOpen, setIsClosingAlertOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [noRoom, setNoRoom] = useState(false);
  const [notIn, setNotIn] = useState(false);
  const groupId = useParams().id;
  const [stream, setStream] = useState();

  const [joinStreamOptions, setJoinStreamOptions] = useState({
    videoMuted: false,
    audioMuted: false,
  });

  const owner = useMemo(
    () =>
      groupSession?.participants?.find(
        (participant) => participant.id === groupSession?.idOwner
      ),
    [groupSession]
  );
  const [hideWRAlert, setHideWRAlert] = useState(false);
  const [hideAlert, setHideAlert] = useState(false);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const [, setPopups] = usePopups();
  const [, setGlobalSnackbar] = useGlobalSnackbar();
  const [snackbar, setSnackbar] = useState({
    open: false,
  });

  const [localHistory] = useLocalHistory();

  const api = useApi();

  const [namesVisibility, setNameVisibility] = useState(false);
  const [timeoutToCancel, setTimeoutToCancel] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [settingPatient, setSettingPatient] = useState();

  const defaultWebcam = useRef(null);

  const patientSetting = useCallback(async () => {
    let settingsResponse = await api(
      user,
      `patients/${user?.profile?.sub}/${userProfile?.idTherapist}/settings`,
      "GET"
    );
    if (settingsResponse.ok === false) {
      setGlobalSnackbar({
        open: true,
        severity: "error",
        message: "Impossibile avviare la videoseduta",
      });
      return;
    } else {
      setSettingPatient(settingsResponse);
      if (settingsResponse.isArchived) {
        setGlobalSnackbar({
          open: true,
          severity: "error",
          message:
            "Impossibile avviare la videoseduta. Contatta il tuo terapeuta.",
        });
      }
    }
  }, [user]);

  const gridProps = useMemo(() => {
    return (matches ? desktopGrids : mobileGrids).find((elem) =>
      elem.key.includes(groupSession?.participants?.length)
    )?.value;
  }, [groupSession, matches]);

  const classes = useStyles(gridProps);

  const [patientQoS, setPatientQoS] = useState(-1);
  const therapistQoSSentence = useMemo(() => {
    if (patientQoS < 0) {
      return "N.D.";
    } else {
      return lerpArray(["Scarsa", "Buona", "Ottima"], 0, 4, patientQoS);
    }
  }, [patientQoS]);

  const networkConditions = useCallback(() => {
    return (
      <div className={classes.therapistQoS}>
        {/* <div>Qualità video</div>
        <span>{therapistQoSSentence}</span> */}
      </div>
    );
  }, [patientQoS, therapistQoSSentence, classes]);

  const resetAndChangeRoute = useCallback(() => {
    if (isAnon.current) {
      setIsEndedForAnonUser(true);
      return;
    }
    //if there's a previous page return there, otherwise go to home
    if (localHistory[localHistory.length - 2]) {
      history.push(localHistory[localHistory.length - 2]);
    } else {
      history.push("/home");
    }
  }, [localHistory, history]);

  const leaveConference = useEventEffect(async (isClosing = true) => {
    //close the session only if the isClosing param is true
    //to avoid closing the session in the database if the only thing
    //to do is change route and release the stream
    if (isClosing) {
      const closedSession = await api(
        user,
        `grouptherapies/${groupId}/end`,
        "PATCH",
        {
          idGroup: groupId,
          idPartecipant: user?.profile?.sub,
          idGroupHistory: groupSession?.idGroupHistory,
        }
      );
    }
    try {
      engine.leave();
      if (videoStream.current) {
        videoStream.current.close();
      }
      if (audioStream.current) {
        audioStream.current.close();
      }
    } catch (e) {}
    resetAndChangeRoute();
    defaultWebcam.current=null;
    setCurrentCamera(cameraList[0]);
  });

  /*
   * This method create and publish the stream of the user, join the conference on Agora
   * if the videosession in memory doesn't have a idhost, sessionstart and isTherapist
   * calls the api to start the session.
   */
  const joinConference = async () => {
    //try to join the group session
    const joiningRequest = await api(
      user,
      `grouptherapies/${groupId}/join`,
      "POST",
      {
        idGroup: groupId,
        idPartecipant: user?.profile?.sub,
        idGroupHistory: groupSession?.idGroupHistory,
      }
    );
    //if there are problems show the snackbar
    if (joiningRequest.ok === false) {
      setSnackbar({
        open: true,
        severity: "error",
        message:
          "Si è verificato un errore nel recupero della seduta di gruppo. Prova ad aggiornare la pagina.",
      });
      return;
    }
    setGroupSession((prev) => ({
      ...prev,
      idGroupHistory: joiningRequest?.idGroupHistory,
    }));
    //if canJoin is false this means the user is not the owner and the owner have not yet started the session
    if (joiningRequest.canJoin === false) {
      setGlobalSnackbar({
        severity: "info",
        open: true,
        message:
          " Il terapeuta non ha ancora iniziato la seduta di gruppo. Riprova più tardi.",
      });
      return;
    }
    setIsAudioActive(true);
    setIsVideoActive(true);
    setIsVideoSettingsShowing(false);
    setIsClickedOnJoin(true);
    try {
      let localVideoStream = null;
      let localAudioStream = null;
      testMicrophoneRef.current?.stop();
      const videoTrack = previewStream?.getVideoTracks()[0];
      const audioTrack = previewStream?.getAudioTracks()[0];
      if (!videoTrack || !audioTrack) {
        //TO DO SHOW ERROR
        return;
      }

      const tokenResponse = await api(
        user,
        // @ts-ignore
        // this will send a request to the normal rtc group...in this case groupsession is
        // treated as the "patientId" and the channel is passed to make the room id
        // be the id of the group session...on the patient project you should pass the
        // patient profile as the first parameter of the route, groupsession as the second
        // and groupid as the channel eg. `agoratoken/rtc/[patientid]/groupsession?channel=[idgroup]`
        `agoratoken/rtc/${user.profile.sub}/groupsession?channel=${groupSession?.idGroup}`
      );
      if (tokenResponse.ok === false) {
        // show error
        setGlobalSnackbar({
          open: true,
          severity: "error",
          message: "Impossibile avviare la videochiamata, riprova.",
        });
        setIsClickedOnJoin(false);
        return;
      }
      await engine.join(
        // @ts-ignore
        appsettings[environment.Environment].agora.appId,
        tokenResponse.roomId,
        tokenResponse.accessToken,
        // @ts-ignore
        user.profile.sub
      );

      setOnlineParticipants((prev) => [...prev, user.profile.sub]);

      try {
        localVideoStream = AgoraRTC.createCustomVideoTrack({
          mediaStreamTrack: videoTrack,
        });
        localAudioStream = AgoraRTC.createCustomAudioTrack({
          mediaStreamTrack: audioTrack,
        });
        videoStream.current = localVideoStream;
        audioStream.current = localAudioStream;
      } catch (e) {
        switch (e.name) {
          case "NotAllowedError":
          case "PermissionDeniedError":
            setPopups((prev) => ({
              ...prev,
              errorWebcamOrMicrPopup: {
                open: true,
                props: {
                  title:
                    "Il browser non ha l’autorizzazione per accedere alla webcam o al microfono",
                  message: (
                    <>
                      <a
                        href="https://www.psycare.it/risoluzione-problemi-permessi-e-consensi-webcam-microfono/"
                        target="_blank"
                      >
                        Clicca qui
                      </a>{" "}
                      e scopri come permettere al tuo browser di accedere a
                      webcam e microfono in base al dispositivo che stai
                      utilizzando.
                    </>
                  ),
                },
              },
            }));
            break;
          case "TrackStartError": //chrome
          case "NotReadableError":
            setCurrentCamera(defaultWebcam.current);
            setPopups((prev) => ({
              ...prev,
              errorWebcamOrMicrPopup: {
                open: true,
                props: {
                  title:
                    "La webcam o il microfono risultano essere in uso da un altro software",
                  message:
                    "Chiudi gli altri programmi in uso e riaggiorna la pagina. In alternativa, seleziona un dispositivo diverso dal menu a destra.",
                },
              },
            }));
            break;
          case "DevicesNotFoundError": //chrome
          case "NotFoundError":
            setPopups((prev) => ({
              ...prev,
              errorWebcamOrMicrPopup: {
                open: true,
                props: {
                  title: "Non è stata trovata alcuna webcam o microfono",
                  message: (
                    <>
                      Assicurati di avere collegato correttamente webcam o
                      microfono. In alternativa,{" "}
                      <a
                        href="https://www.psycare.it/risoluzione-problemi-paziente/"
                        target="_blank"
                      >
                        clicca qui
                      </a>{" "}
                      per scoprire quali possono essere le cause e come
                      risolvere.
                    </>
                  ),
                },
              },
            }));
            break;
          case "OverconstrainedError":
          case "ConstraintNotSatisfiedError":
            setPopups((prev) => ({
              ...prev,
              errorWebcamOrMicrPopup: {
                open: true,
                props: {
                  title:
                    "Non è stata trovato alcun dispositivo valido per effettuare la videoseduta",
                  message: (
                    <>
                      Assicurati di avere collegato correttamente webcam o
                      microfono. In alternativa,{" "}
                      <a
                        href="https://www.psycare.it/risoluzione-problemi-paziente/"
                        target="_blank"
                      >
                        clicca qui
                      </a>{" "}
                      per scoprire quali possono essere le cause e come
                      risolvere.
                    </>
                  ),
                },
              },
            }));
            break;
          case "TypeError":
            setPopups((prev) => ({
              ...prev,
              errorWebcamOrMicrPopup: {
                open: true,
                props: {
                  title: "C’è un problema con la webcam o il microfono",
                  message: (
                    <>
                      <a
                        href="https://www.psycare.it/risoluzione-problemi-paziente/"
                        target="_blank"
                      >
                        Clicca qui
                      </a>{" "}
                      per scoprire quali possono essere le cause e come
                      risolvere.
                    </>
                  ),
                },
              },
            }));
            break;
          default:
            setPopups((prev) => ({
              ...prev,
              errorWebcamOrMicrPopup: {
                open: true,
                props: {
                  title: "C’è un problema con la webcam o il microfono",
                  message: (
                    <>
                      <a
                        href="https://www.psycare.it/risoluzione-problemi-paziente/"
                        target="_blank"
                      >
                        Clicca qui
                      </a>{" "}
                      per scoprire quali possono essere le cause e come
                      risolvere.
                    </>
                  ),
                },
              },
            }));
            break;
        }
        return;
      }
      setIsClickedOnJoin(true);
      //setPreviewStream(null);}

      await engine?.publish([localVideoStream, localAudioStream]);
      if (joinStreamOptions?.videoMuted) {
        //localStream.muteVideo();
        //localVideoStream.setEnabled(false);
        localVideoStream.setMuted(true);
        setIsVideoActive(false);
      }
      if (joinStreamOptions?.audioMuted) {
        //localStream.muteAudio();
        //localAudioStream.setEnabled(false);
        localAudioStream.setMuted(true);
        setIsAudioActive(false);
      }
      attachToElement(user.profile.sub, localVideoStream, true);
    } catch (e) {}
  };

  /**
   * Toggle the audio of the active stream
   */
  const toggleAudio = useCallback(() => {
    if (!audioStream.current) return;
    if (isAudioActive) {
      //audioStream.current.setEnabled(false);
      audioStream.current.setMuted(true);
      if (!isVideoActive) {
        setHideAlert(false);
      }
    } else {
      setHideAlert(true);
      //audioStream.current.setEnabled(true);
      audioStream.current.setMuted(false);
    }
    setIsAudioActive((audioActive) => !audioActive);
  }, [isAudioActive]);

  const toggleVideo = useCallback(() => {
    if (!videoStream.current) return;
    if (isVideoActive) {
      //videoStream.current.setEnabled(false);
      videoStream.current.setMuted(true);
      if (!isAudioActive) {
        setHideAlert(false);
      }
    } else {
      setHideAlert(true);
      //videoStream.current.setEnabled(true);
      videoStream.current.setMuted(false);
    }
    setIsVideoActive((videoActive) => !videoActive);
  }, [isVideoActive]);

  /**
   * Close the session
   */
  const closeSession = useCallback(() => {
    //Open whiteboard usage dialog
    setIsClosingAlertOpen(true);
    callStatsArray = []; // Clear list of callId
  }, []);

  /**
   * Change the settings for the webcam and the microphone
   */
  const changeVideoCallSettings = async (
    toChange: MediaDeviceInfo,
    whatToChange: "audio" | "video"
  ) => {
    if (!videoStream.current || !audioStream.current) return;
    try {
      //create a new stream with the selected microphone and webcam

      // if what to change is audio we create the options for that
      // audio device otherwise we leave it undefined
      const audio =
        whatToChange === "audio"
          ? {
              deviceId: { exact: toChange?.deviceId },
            }
          : undefined;

      // if whatToChange is video we create the options for that
      // video device otherwise we leave it undefined
      const video =
        whatToChange === "video"
          ? {
              deviceId: { exact: toChange?.deviceId },
              width: {
                min: 320,
                ideal: 640,
                max: 1024,
              },
              height: {
                min: 240,
                ideal: 480,
                max: 768,
              },
            }
          : undefined;

      const localMediaStream = await navigator?.mediaDevices?.getUserMedia({
        audio,
        video,
      });

      const track =
        whatToChange === "audio"
          ? localMediaStream?.getAudioTracks()[0]
          : localMediaStream?.getVideoTracks()[0];

      const createFunction =
        whatToChange === "audio"
          ? AgoraRTC.createCustomAudioTrack
          : AgoraRTC.createCustomVideoTrack;

      const localStream = createFunction({
        mediaStreamTrack: track,
      });

      const toUnpublish = whatToChange === "audio" ? audioStream : videoStream;

      //unpublish the current stream
      await engine.unpublish(toUnpublish.current);
      //release the webcam
      toUnpublish.current!.close();

      toUnpublish.current = localStream;

      if (whatToChange === "video") {
        // reset my own video srcObject to than add the new
        // stream to a fresh MediaStream
        //attach the new stream to the therapist video element
        localStream.play(user.profile.sub, {
          mirror: false,
          fit: "cover",
        });
      }
      //publish the stream
      await engine.publish(localStream);
      setIsAudioActive(true);
      setIsVideoActive(true);
    } catch (e) {
      grayLog({
        message: e.message,
        type: e.name,
        stack: e.stack,
        userId: user?.profile?.sub || "Uknown user",
      });

      switch (e.name) {
        case "NotAllowedError":
        case "PermissionDeniedError":
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title:
                  "Il browser non ha l’autorizzazione per accedere alla webcam o al microfono",
                message: (
                  <>
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-permessi-e-consensi-webcam-microfono/"
                      target="_blank"
                    >
                      Clicca qui
                    </a>{" "}
                    e scopri come permettere al tuo browser di accedere a webcam
                    e microfono in base al dispositivo che stai utilizzando.
                  </>
                ),
              },
            },
          }));
          break;
        case "TrackStartError": //chrome
        case "NotReadableError":
          setCurrentCamera(defaultWebcam.current);
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title:
                  "La webcam o il microfono risultano essere in uso da un altro software",
                message:
                  "Chiudi gli altri programmi in uso e riaggiorna la pagina. In alternativa, seleziona un dispositivo diverso dal menu a destra.",
              },
            },
          }));
          break;
        case "DevicesNotFoundError": //chrome
        case "NotFoundError":
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title: "Non è stata trovata alcuna webcam o microfono",
                message: (
                  <>
                    Assicurati di avere collegato correttamente webcam o
                    microfono. In alternativa,{" "}
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-paziente/"
                      target="_blank"
                    >
                      clicca qui
                    </a>{" "}
                    per scoprire quali possono essere le cause e come risolvere.
                  </>
                ),
              },
            },
          }));
          break;
        case "OverconstrainedError":
        case "ConstraintNotSatisfiedError":
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title:
                  "Non è stata trovato alcun dispositivo valido per effettuare la videoseduta",
                message: (
                  <>
                    Assicurati di avere collegato correttamente webcam o
                    microfono. In alternativa,{" "}
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-paziente/"
                      target="_blank"
                    >
                      clicca qui
                    </a>{" "}
                    per scoprire quali possono essere le cause e come risolvere.
                  </>
                ),
              },
            },
          }));
          break;
        case "TypeError":
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title: "C’è un problema con la webcam o il microfono",
                message: (
                  <>
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-paziente/"
                      target="_blank"
                    >
                      Clicca qui
                    </a>{" "}
                    per scoprire quali possono essere le cause e come risolvere.
                  </>
                ),
              },
            },
          }));
          break;
        default:
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title: "C’è un problema con la webcam o il microfono",
                message: (
                  <>
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-paziente/"
                      target="_blank"
                    >
                      Clicca qui
                    </a>{" "}
                    per scoprire quali possono essere le cause e come risolvere.
                  </>
                ),
              },
            },
          }));
          break;
      }
    }
    //close the popup
    setIsVideoSettingsShowing(false);
  };

  useSignalingListener(
    "groupvideocall:toggle-audio",
    () => {
      toggleAudio();
    },
    [toggleAudio]
  );

  useSignalingListener(
    "groupvideocall:closing",
    () => {
      leaveConference();
    },
    [leaveConference]
  );

  const [previewStream, setPreviewStream] = useState(null);
  const videoPreviewRef = useRef();
  const testMicrophoneRef = useRef();

  const handleVideoPreview = async (
    inUseMichrophone?: MediaDeviceInfo,
    inUseWebcam?: MediaDeviceInfo
  ) => {
    if (isJoined.current) return;
    if (!inUseMichrophone || !inUseWebcam) return;

    try {
      const previewStreamLocal = await navigator?.mediaDevices?.getUserMedia({
        audio: inUseMichrophone
          ? {
              deviceId: { exact: inUseMichrophone.deviceId },
            }
          : true,
        video: inUseWebcam
          ? {
              deviceId: { exact: inUseWebcam.deviceId },
              width: {
                min: 320,
                ideal: 640,
                max: 1024,
              },
              height: {
                min: 240,
                ideal: 480,
                max: 768,
              },
            }
          : {
              width: {
                min: 320,
                ideal: 640,
                max: 1024,
              },
              height: {
                min: 240,
                ideal: 480,
                max: 768,
              },
            },
      });
      setPreviewStream((prev) => {
        stopStream(prev);
        return previewStreamLocal;
      });
      return previewStreamLocal;
    } catch (e) {
      grayLog({
        message: e.message,
        type: e.name,
        stack: e.stack,
        userId: user?.profile?.sub || "Uknown user",
      });
      switch (e.name) {
        case "NotAllowedError":
        case "PermissionDeniedError":
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title:
                  "Il browser non ha l’autorizzazione per accedere alla webcam o al microfono",
                message: (
                  <>
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-permessi-e-consensi-webcam-microfono/"
                      target="_blank"
                    >
                      Clicca qui
                    </a>{" "}
                    e scopri come permettere al tuo browser di accedere a webcam
                    e microfono in base al dispositivo che stai utilizzando.
                  </>
                ),
              },
            },
          }));
          break;
        case "TrackStartError": //chrome
        case "NotReadableError":
          setCurrentCamera(defaultWebcam.current);
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title:
                  "La webcam o il microfono risultano essere in uso da un altro software",
                message:
                  "Chiudi gli altri programmi in uso e riaggiorna la pagina. In alternativa, seleziona un dispositivo diverso dal menu a destra.",
              },
            },
          }));
          break;
        case "DevicesNotFoundError": //chrome
        case "NotFoundError":
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title: "Non è stata trovata alcuna webcam o microfono",
                message: (
                  <>
                    Assicurati di avere collegato correttamente webcam o
                    microfono. In alternativa,{" "}
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-paziente/"
                      target="_blank"
                    >
                      clicca qui
                    </a>{" "}
                    per scoprire quali possono essere le cause e come risolvere.
                  </>
                ),
              },
            },
          }));
          break;
        case "OverconstrainedError":
        case "ConstraintNotSatisfiedError":
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title:
                  "Non è stata trovato alcun dispositivo valido per effettuare la videoseduta",
                message: (
                  <>
                    Assicurati di avere collegato correttamente webcam o
                    microfono. In alternativa,{" "}
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-paziente/"
                      target="_blank"
                    >
                      clicca qui
                    </a>{" "}
                    per scoprire quali possono essere le cause e come risolvere.
                  </>
                ),
              },
            },
          }));
          break;
        case "TypeError":
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title: "C’è un problema con la webcam o il microfono",
                message: (
                  <>
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-paziente/"
                      target="_blank"
                    >
                      Clicca qui
                    </a>{" "}
                    per scoprire quali possono essere le cause e come risolvere.
                  </>
                ),
              },
            },
          }));
          break;
        default:
          setPopups((prev) => ({
            ...prev,
            errorWebcamOrMicrPopup: {
              open: true,
              props: {
                title: "C’è un problema con la webcam o il microfono",
                message: (
                  <>
                    <a
                      href="https://www.psycare.it/risoluzione-problemi-paziente/"
                      target="_blank"
                    >
                      Clicca qui
                    </a>{" "}
                    per scoprire quali possono essere le cause e come risolvere.
                  </>
                ),
              },
            },
          }));
          break;
      }
      return false;
    }
  };

  //everytime the preview video change we change the video in
  //the video element
  useEffect(() => {
    if (!videoPreviewRef.current) return;
    videoPreviewRef.current.srcObject = previewStream;
  }, [previewStream]);

  useEffect(() => {
    if (isLoading) return;
    handleVideoPreview(currentMicrophone, currentCamera);
  }, [currentMicrophone, currentCamera, isLoading]);

  useOnUnmount(() => {
    if (previewStream) {
      stopStream(previewStream);
    }
  }, [previewStream]);

  const trackToUserIdMap = useRef(new Map<string, ITrack | undefined>());
  const isJoined = useRef(false);

  useEffect(() => {
    engine.on("user-joined", (userPublished : IAgoraRTCRemoteUser) => {
      const uid = userPublished.uid.toString();
      setOnlineParticipants((prev) => [...prev, uid]);
    });

    engine.on("user-left", (userPublished :IAgoraRTCRemoteUser, reason : string) => {
      
      if(reason != "Quit") return;
      
      const uid = userPublished.uid.toString();
      //if (!hasVideoOrAudio(uid, trackToUserIdMap.current)) {
          setOnlineParticipants((prev) =>
            prev.filter((participantId) => participantId !== uid)
          );
        //}
    });
    //if we want implement stats we can use AgoraEngine getRemoteVideoStats getRTCStats
    engine.on("user-published", async (userPublished, mediaType) => {
      const uid = userPublished.uid.toString();
      await engine.subscribe(userPublished, mediaType);

      // if (!hasVideoOrAudio(uid, trackToUserIdMap.current)) {
      //   setOnlineParticipants((prev) => [...prev, uid]);
      // }

      trackToUserIdMap.current.set(
        `${uid}-${mediaType}`,
        mediaType === "video"
          ? userPublished.videoTrack
          : userPublished.audioTrack
      );

      if (mediaType === "video") {
        if (!userPublished.videoTrack) {
          return;
        }
        attachToElement(uid, userPublished.videoTrack);
      }
      if (mediaType === "audio") {
        if (!userPublished.audioTrack) {
          return;
        }
        attachToElement(uid, userPublished.audioTrack);
      }
    });
    engine.on("user-unpublished", async (userUnpublished, mediaType) => {
      const uid = userUnpublished.uid.toString();
      const trackToDetach = trackToUserIdMap.current.get(`${uid}-${mediaType}`);
      trackToUserIdMap.current.delete(`${uid}-${mediaType}`);
      // if (!hasVideoOrAudio(uid, trackToUserIdMap.current)) {
      //   setOnlineParticipants((prev) =>
      //     prev.filter((participantId) => participantId !== uid)
      //   );
      // }
      if (trackToDetach) {
        trackToDetach.stop();
      }
    });

    engine.on("connection-state-change", (curState, prevState, reason) => {
      if (curState === "CONNECTED") {
        isJoined.current = true;
        setIsConferenceJoined(true);
        setIsClickedOnJoin(false);
      } else if (
        curState === "DISCONNECTED" &&
        prevState === "CONNECTED" &&
        reason === ConnectionDisconnectedReason.UID_BANNED
      ) {
        leaveConference(false);
        setGlobalSnackbar({
          open: true,
          severity: "info",
          message:
            "Hai effettuato l'accesso da un altro dispositivo. Continua da lì la tua seduta.",
        });
      }
    });
    engine.enableAudioVolumeIndicator();
    engine.on("volume-indicator", (result) => {
      setSpeakingParticipants(
        result
          .filter((volume) => {
            // 60 is the suggested volume level from agora
            // to consider a member speaking
            // https://api-ref.agora.io/en/video-sdk/web/4.x/interfaces/iagorartcclient.html#event_volume_indicator
            return volume.level > 60;
          })
          .map((volume) => {
            // we get the uids of every participant remaining in the array
            return volume.uid.toString();
          })
      );
    });

    return () => {
      engine.removeAllListeners();
    };
  }, [engine]);

  useEffect(() => {
    if (!user || !groupId) return;
    patientSetting();
    const initSession = async () => {
      setIsLoading(true);
      //recover the group session
      const groupSessionLocal = await api(user, `grouptherapies/${groupId}`);
      if (groupSessionLocal.ok === false) {
        setNoRoom(true);
        setIsLoading(false);
        return;
      }
      //check if the current user is in the list of participants, if it's not show an
      //error page
      const imIn = groupSessionLocal.participants.some(
        (elem) => elem.id.toLowerCase() === user.profile.sub.toLowerCase()
      );
      if (!imIn) {
        setNotIn(true);
        setIsLoading(false);
        return;
      }
      const joiningRequest = await api(
        user,
        `grouptherapies/${groupId}/joinvirtualroom`,
        "POST",
        {
          idGroup: groupId,
          idPartecipant: user?.profile?.sub,
        }
      );

      if (joiningRequest.ok !== false) {
        groupSessionLocal.idGroupHistory = joiningRequest.idGroupHistory;
        groupSessionLocal.canJoin = joiningRequest.canJoin;
        groupSessionLocal.inQueue = joiningRequest.inQueue;
      } else {
        setSnackbar({
          open: true,
          severity: "error",
          message:
            "Si è verificato un errore nel recupero della seduta di gruppo. Prova ad aggiornare la pagina.",
        });
        return;
      }
      setGroupSession(groupSessionLocal);
      setIsLoading(false);
    };
    initSession();
  }, [user]);

  const toCloseOnBackRef = useRef();

  const hasSignalingLoggedIn = useRef(false);

  if (isAnon.current && !hasSignalingLoggedIn.current && user) {
    try {
      signaling.getTokenAndLogin(user).catch(() => {
        hasSignalingLoggedIn.current = false;
      });
      hasSignalingLoggedIn.current = true;
    } catch (e) {}
  }

  useEffect(() => {
    toCloseOnBackRef.current = {
      conference,
      leaveConference,
    };
  }, [conference, leaveConference]);

  useEffect(
    () => () => {
      if (toCloseOnBackRef?.current?.conference?.isJoined()) {
        toCloseOnBackRef?.current?.leaveConference &&
          toCloseOnBackRef.current.leaveConference();
      }
    },
    []
  );

  if (isEndedForAnonUser) return <SessionEnded />;

  return (
    <>
      <div className={classes.container}>
        <div
          className={`${classes.actionContainer} ${
            isConferenceJoined ? classes.hidden : null
          }`}
        >
          <IconButton
          disabled={isClickedOnJoin}
          style={{color:"#fff"}}
            onClick={async () => {
              setGroupSession(null);
              resetAndChangeRoute();
              setCurrentCamera(cameraList[0]);
              defaultWebcam.current = null;
            }}
          >
            <Close />
          </IconButton>
        </div>
        <Box flexGrow="1">
          {isLoading ? (
            <div className={classes.loadingBox}>
              <CircularProgress />
            </div>
          ) : notIn || noRoom ? (
            notIn ? (
              <NotIn />
            ) : (
              <NoRoom />
            )
          ) : (
            <>
              <div
                onTouchStart={() => {
                  if (timeoutToCancel) clearTimeout(timeoutToCancel);
                  const toCancel = setTimeout(() => {
                    setNameVisibility(false);
                  }, 5000);
                  setTimeoutToCancel(toCancel);
                  setNameVisibility(true);
                }}
                className={`${classes.conferenceContainer} ${
                  isConferenceJoined ? null : classes.hidden
                }`}
              >
                <div
                  className={`${classes.drawer} ${
                    isDrawerOpen ? classes.drawerOpen : null
                  }`}
                >
                  <div
                    onClick={() => {
                      setIsDrawerOpen((prev) => !prev);
                    }}
                    className={classes.drawerOpener}
                  >
                    <DoubleArrowIcon />
                  </div>
                  <GroupVideocallSidebar
                    changeVideoCallSettings={changeVideoCallSettings}
                    groupSession={groupSession}
                    onlineParticipants={onlineParticipants}
                    owner={owner}
                    networkConditions={networkConditions}
                    setSavedCamera={(data) => (defaultWebcam.current = data)}
                  />
                </div>
                <div className={classes.conferenceParticipants}>
                  <ParticipantBox
                    participant={owner}
                    isOnline={onlineParticipants.includes(owner?.id)}
                    isSpeaking={speakingParticipants.includes(owner?.id)}
                    isTherapist
                    namesVisibility={namesVisibility}
                  />
                  {groupSession?.participants?.map(
                    (participant) =>
                      participant.typology === 0 &&
                      participant.id !== groupSession?.idOwner && (
                        <ParticipantBox
                          key={participant.id}
                          participant={participant}
                          isOnline={onlineParticipants.includes(participant.id)}
                          isTherapist
                          isSpeaking={speakingParticipants.includes(
                            participant.id
                          )}
                          namesVisibility={namesVisibility}
                        />
                      )
                  )}
                  {groupSession?.participants?.map(
                    (participant) =>
                      participant.typology === 1 &&
                      participant.id !== user?.profile?.sub && (
                        <ParticipantBox
                          key={participant.id}
                          participant={participant}
                          isOnline={onlineParticipants.includes(participant.id)}
                          isSpeaking={speakingParticipants.includes(
                            participant.id
                          )}
                          namesVisibility={namesVisibility}
                        />
                      )
                  )}
                  <ParticipantBox
                    className={classes.loggedUser}
                    participant={groupSession?.participants?.find(
                      (participant) => participant.id === user?.profile?.sub
                    )}
                    isOnline={onlineParticipants.includes(user?.profile?.sub)}
                    isSpeaking={speakingParticipants.includes(
                      user?.profile?.sub
                    )}
                    namesVisibility={namesVisibility}
                  />
                </div>
              </div>
              <Box
                style={{ display: isConferenceJoined ? "none" : "" }}
                width="100%"
                display="flex"
                alignItems="center"
                flexDirection="column"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  width="100%"
                >
                  <img
                    src={logoHorizontalWhite}
                    className={classes.logoWaitingRoom}
                  />
                  <Box margin="2rem" width="90%" textAlign="center">
                    <Typography variant="h4" style={{ marginTop: "3rem" }}>
                      Benvenuto nella sala d'attesa virtuale del gruppo{" "}
                      {groupSession?.name}.
                    </Typography>
                    <Typography
                      style={{ fontSize: "1.25rem", paddingTop: ".3rem" }}
                    >
                      Sei in attesa che il terapeuta si colleghi alla stanza.
                      Potrai accedere non appena inizierà la seduta.
                    </Typography>
                  </Box>
                  {isClickedOnJoin ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      style={{ marginTop: "2rem", fontSize: "1rem" }}
                      disabled={!previewStream || settingPatient?.isArchived}
                      color="primary"
                      variant="contained"
                      size="small"
                      startIcon={<MeetingRoom />}
                      onClick={joinConference}
                    >
                      ENTRA
                    </Button>
                  )}
                </Box>
                {/* {!isConferenceJoined &&
                !hideWRAlert &&
                joinStreamOptions?.audioMuted &&
                joinStreamOptions?.videoMuted ? (
                  <div style={{ paddingTop: "1.5rem" }}>
                    <Alert
                      style={{
                        color: "black",
                        backgroundColor: "#FDE5D7",
                        display: "flex",
                        alignItems: "center",
                      }}
                      severity="warning"
                      icon={<WarningIcon fontSize="inherit" />}
                    >
                      <Box display="flex" flexDirection="column">
                        <div
                          style={{
                            gap: "1rem",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <AlertTitle
                            style={{
                              fontSize: ".75rem",
                              margin: 0,
                              lineHeight: 1,
                            }}
                          >
                            <b>Attenzione:</b> disabilitando contemporaneamente
                            sia microfono che fotocamera, risulterai come
                            assente. Attivane almeno uno tra i due per far
                            sapere al tuo terapeuta che stai partecipando alla
                            seduta.
                          </AlertTitle>
                          <IconButton>
                            <CloseIcon onClick={() => setHideWRAlert(true)} />
                          </IconButton>
                        </div>
                      </Box>
                    </Alert>
                  </div>
                ) : (
                  ""
                )} */}
                <div className={classes.mediaPreviewAndSelect}>
                  <div className={classes.mediaPreview}>
                    <video
                      className={`flipped ${
                        joinStreamOptions?.videoMuted
                          ? classes.videoMuted
                          : null
                      }`}
                      ref={videoPreviewRef}
                      autoPlay
                      playsInline
                      muted
                      poster="./wait_flipped.png"
                    />
                    <div className={classes.previewActions}>
                      <button
                        onClick={() => {
                          setJoinStreamOptions((prev) => ({
                            ...prev,
                            audioMuted: !prev.audioMuted,
                          }));
                          if (!joinStreamOptions.videoMuted) {
                            setHideWRAlert(true);
                          } else {
                            setHideWRAlert(false);
                          }
                        }}
                      >
                        {joinStreamOptions?.audioMuted ? <MicOff /> : <Mic />}
                      </button>
                      <button
                        onClick={() => {
                          setJoinStreamOptions((prev) => ({
                            ...prev,
                            videoMuted: !prev.videoMuted,
                          }));
                          if (!joinStreamOptions.audioMuted) {
                            setHideWRAlert(true);
                          } else {
                            setHideWRAlert(false);
                          }
                        }}
                      >
                        {joinStreamOptions?.videoMuted ? (
                          <VideocamOff />
                        ) : (
                          <Videocam />
                        )}
                      </button>
                    </div>
                  </div>
                  <div className={classes.mediaSelect}>
                    <span>Seleziona il dispositivo che vuoi utilizzare</span>
                    <div
                      onClick={
                        isPatientSTRunning
                          ? null
                          : () => {
                              window.SomApi.startTest();
                              setIsPatientSTRunning(true);
                            }
                      }
                      className={classes.checkConnection}
                    >
                      {isPatientSTRunning ? (
                        <CircularProgress color="inherit" size="1.5rem" />
                      ) : !patientSTResults ? (
                        <NetworkCheck />
                      ) : (
                        <NetworkResults
                          size="1.5rem"
                          value={
                            Math.min(
                              patientSTResults?.download,
                              patientSTResults?.upload
                            ) || 0
                          }
                          min={0}
                          max={12}
                        />
                      )}
                      <span>
                        {connectionMessage?.message || "Test connessione"}
                      </span>
                    </div>
                    <button
                      disabled={!previewStream}
                      onClick={() => {
                        testMicrophoneRef.current.start();
                      }}
                    >
                      Test
                    </button>
                    <TextField
                      SelectProps={{
                        classes: {
                          outlined: classes.outlinedStyles,
                          iconOutlined: classes.iconOutlinedStyles,
                        },
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutlineStyles,
                          focused: classes.notchedOutlineStyles,
                          adornedStart: classes.outlinedStyles,
                        },
                        startAdornment: (
                          <VolumeUp className={classes.adornment} />
                        ),
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelStyles,
                        },
                      }}
                      margin="dense"
                      variant="outlined"
                      label="Microfono"
                      select
                      fullWidth
                      value={currentMicrophone?.deviceId}
                      key={`currentMicrophone-${!!currentMicrophone?.deviceId}`}
                      onChange={(e) => {
                        const newMic = microphoneList.find(
                          (mic) => mic.deviceId === e.target.value
                        );
                        if (!newMic) return;
                        setCurrentMicrophone(newMic);
                      }}
                    >
                      {microphoneList.map((audioInput) => (
                        <MenuItem
                          key={audioInput.deviceId}
                          value={audioInput.deviceId}
                        >
                          {audioInput.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <MicLevels
                      ref={testMicrophoneRef}
                      bars={7}
                      color="#444"
                      progressColor="white"
                      width="100%"
                      height="2rem"
                      stream={previewStream}
                    />
                    <TextField
                      SelectProps={{
                        classes: {
                          outlined: classes.outlinedStyles,
                          iconOutlined: classes.iconOutlinedStyles,
                        },
                      }}
                      InputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutlineStyles,
                          focused: classes.notchedOutlineStyles,
                          adornedStart: classes.outlinedStyles,
                        },
                        startAdornment: (
                          <Videocam className={classes.adornment} />
                        ),
                      }}
                      InputLabelProps={{
                        classes: {
                          root: classes.labelStyles,
                        },
                      }}
                      margin="dense"
                      variant="outlined"
                      label="Fotocamera"
                      select
                      fullWidth
                      value={currentCamera?.deviceId}
                      key={`currentCamera-${!!currentCamera?.deviceId}`}
                      onChange={(e) => {
                        const newCamera = cameraList.find(
                          (camera) => camera.deviceId === e.target.value
                        );
                        if (!newCamera) return;
                        defaultWebcam.current = currentCamera;
                        setCurrentCamera(newCamera);
                      }}
                    >
                      {cameraList.map((videoInput) => (
                        <MenuItem
                          key={videoInput.deviceId}
                          value={videoInput.deviceId}
                        >
                          {videoInput.label}
                        </MenuItem>
                      ))}
                    </TextField>
                    <a
                      style={{ color: "white", justifySelf: "center" }}
                      href="https://www.psycare.it/risoluzione-problemi-paziente/"
                      target="_blank"
                    >
                      Problemi audio/video?
                    </a>
                  </div>
                </div>
                <Box
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                  width="100%"
                  marginTop={matches ? "4rem" : ""}
                >
                  {!isConsentGiven && (
                    <Box fontSize=".9em" margin="1rem" color="red">
                      Non è possibile effettuare l'accesso senza dare il
                      consenso all'utilizzo di webcam e microfono.
                    </Box>
                  )}
                </Box>
              </Box>
            </>
          )}
        </Box>
        {/* {isConferenceJoined &&
        !hideAlert &&
        !isVideoActive &&
        !isAudioActive ? (
          <div>
            <Alert
              style={{
                height: "1rem",
                color: "black",
                backgroundColor: "#FDE5D7",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              severity="warning"
              icon={<WarningIcon fontSize="inherit" />}
            >
              <Box display="flex" flexDirection="column">
                <div
                  style={{
                    gap: "1rem",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <AlertTitle
                    style={{
                      fontSize: ".75rem",
                      margin: 0,
                      lineHeight: 1,
                    }}
                  >
                    <b>Attenzione:</b> disabilitando contemporaneamente sia
                    microfono che fotocamera, risulterai come assente. Attivane
                    almeno uno tra i due per far sapere al tuo terapeuta che
                    stai partecipando alla seduta.
                  </AlertTitle>
                  <IconButton>
                    <CloseIcon onClick={() => setHideAlert(true)} />
                  </IconButton>
                </div>
              </Box>
            </Alert>
          </div>
        ) : (
          ""
        )} */}
        {isConferenceJoined && (
          <Box
            style={{ backgroundColor: "#333" }}
            boxSizing="border-box"
            padding="1rem"
            width="100%"
            position="relative"
            display="grid"
            gridTemplateColumns="20% 1fr 20%"
            alignItems="center"
          >
            <div className={classes.logoAndQoS}>
              <img style={{ maxWidth: "100%" }} src={logoHorizontalWhite} />
              {networkConditions()}
            </div>
            <Box
              style={{ gap: "1.5rem" }}
              width="100%"
              display="flex"
              justifyContent="center"
            >
              <VideoCallButton
                size="small"
                iconSize={matches ? "2rem" : "1.5rem"}
                onClick={() => {
                  setGlobalSnackbar({
                    open: true,
                    severity: "info",
                    message:
                      "Funzionalità non disponibile nella seduta di gruppo",
                  });
                }}
                nonPressedTooltip="Condividi schermo"
                NonPressedIcon={PresentToAll}
              />
              <VideoCallButton
                size="small"
                iconSize={matches ? "2rem" : "1.5rem"}
                onClick={() => {
                  setGlobalSnackbar({
                    open: true,
                    severity: "info",
                    message:
                      "Funzionalità non disponibile nella seduta di gruppo",
                  });
                }}
                nonPressedTooltip="Lavagna virtuale"
                NonPressedIcon={OpenWhiteboardIcon}
              />
              <VideoCallButton
                size="small"
                iconSize={matches ? "2rem" : "1.5rem"}
                pressed={!isAudioActive}
                pressedTooltip="Attiva audio"
                nonPressedTooltip="Disattiva audio"
                PressedIcon={MicOff}
                NonPressedIcon={Mic}
                onClick={toggleAudio}
              />
              <VideoCallButton
                size="small"
                iconSize={matches ? "2rem" : "1.5rem"}
                pressed={!isVideoActive}
                pressedTooltip="Attiva video"
                nonPressedTooltip="Disattiva video"
                PressedIcon={VideocamOff}
                NonPressedIcon={Videocam}
                onClick={toggleVideo}
              />
            </Box>
            <div className={classes.timerAndClose}>
              <VideoCallButton
                style={{
                  backgroundColor: "rgb(196,49,75)",
                  border: "none",
                  color: "white",
                }}
                nonPressedTooltip="Concludi Sessione"
                NonPressedIcon={CallEnd}
                onClick={closeSession}
              />
            </div>
          </Box>
        )}
      </div>
      {snackbar.open && (
        <Snackbar
          open={snackbar.open}
          message={snackbar.message}
          TransitionComponent={(props) => <Slide {...props} direction="up" />}
          onClose={() => {
            //if there's an onclose event call it
            if (snackbar.onClose) {
              snackbar.onClose();
            }
            setSnackbar((prevSnack) => ({
              ...prevSnack,
              open: false,
              onClose: null,
            }));
          }}
        >
          <Alert
            severity={snackbar.severity}
            classes={{
              action: classes.snackBarActions,
            }}
            onClose={() => {
              //if there's an onclose event call it
              if (snackbar.onClose) {
                snackbar.onClose();
              }
              setSnackbar((prevSnack) => ({
                ...prevSnack,
                open: false,
                onClose: null,
              }));
            }}
          >
            {typeof snackbar.message === "function"
              ? snackbar.message()
              : snackbar.message}
          </Alert>
        </Snackbar>
      )}
      <AlertDialog
        open={isClosingAlertOpen}
        title="Confermi di voler chiudere la seduta di gruppo?"
        onClose={(result) => {
          if (!result) {
            setIsClosingAlertOpen(false);
            return;
          }
          leaveConference();
        }}
      />
    </>
  );
};
export default GroupSession;
