import React, { useState, createContext, useContext, useEffect, useCallback } from 'react';
import appsettings from "../config/appsettings.json";
import environment from "../config/environment.json";
import { useUser } from './UserContext';

const useNotificationApi = () => {
    return useContext(NotificationApiContext);
};

const useNotifications = () => {
    return useContext(NotificationContext);
};

const NotificationContext = createContext(null);
const NotificationApiContext = createContext(null);

const NotificationContextProvider = ({ children }) => {
    const [startingConnecting, setStartingConnecting] = useState(false);
    const [notificationSocket, setNotificationSocket] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const [notificationQueue, setNotificationQueue] = useState([]);
    const user = useUser();
    /**
     * This reruns every time the user changes.
     */
    useEffect(() => {
        //if there's no user just return or we already started a connection just return
        if (!user || startingConnecting) return;
        /**
         * Method to connect to the socket asyncronously
         */
        const connectSocketAsync = async () => {
            //create a new connection with the url from the appsettings
            //using the access token from the current user.
            const connection = new window.signalR.HubConnectionBuilder()
                .withUrl(appsettings[environment.Environment].notificationUrl, {
                    accessTokenFactory: () => user.access_token
                })
                .withAutomaticReconnect()
                .configureLogging(window.signalR.LogLevel.None)
                .build();
            //if there's a connectioon element and it has the function start call it
            if (!connection || !connection.start) return;
            setStartingConnecting(true);
            await connection.start();
            //invoke the method Connect passing the patient id
            connection.invoke("Connect", user.profile.sub);
            connection.on("AdmitVideoSession", (message) => {
                let data;
                try {
                    data = JSON.parse(message);
                } catch (e) {
                    data = message;
                }
                setNotifications(notificationsList => [...notificationsList, {
                    unread: true,
                    type: "AdmitVideoSession",
                    kind: "action",
                    data
                }]);
            });
            setNotificationSocket(connection);
        };
        connectSocketAsync();
    }, [user]);


    /**
     * Method to call invoke the ReloadWaitingRoom method on the WS
     * @param therapistId - The id of the therapist
     */
    const reloadWaitingRoom = useCallback(async (therapistId) => {
        //check to see if invoke is a function of notificationSocket
        //if not this means the socket is not ready yet so we enqueue the notification
        if (!notificationSocket?.invoke) {
            enqueueNotification("reloadWaitingRoom", therapistId);
            return;
        }
        //invoke the method to notify the therapist
        notificationSocket.invoke("ReloadWaitingRoom", [therapistId]);
    }, [notificationSocket]);

    /**
     * Method to call invoke the NotifyTherapist method on the WS
     * @param therapistId - The id of the therapist
     * @param session - The session object that the patient is in the waiting room
     */
    const notifyTherapist = useCallback(async (therapistId, session) => {
        //check to see if invoke is a function of notificationSocket
        //if not this means the socket is not ready yet so we enqueue the notification
        if (!notificationSocket?.invoke) {
            enqueueNotification("notifyTherapist", therapistId, session);
            return;
        }
        //invoke the method to notify the therapist
        notificationSocket.invoke("NotifyTherapist", therapistId, JSON.stringify(session));
    }, [notificationSocket]);

    /**
     * Method to call invoke the NotifyTherapist method on the WS
     * @param therapistId - The id of the therapist
     * @param session - The session object that the patient is in the waiting room
     */
    const patientQuit = useCallback(async (therapistId, session) => {
        //check to see if invoke is a function of notificationSocket
        //if not this means the socket is not ready yet so we enqueue the notification
        if (!notificationSocket?.invoke) {
            enqueueNotification("patientQuit", therapistId, session);
            return;
        }
        //invoke the method to notify the therapist
        notificationSocket.invoke("PatientQuit", therapistId, JSON.stringify(session));
    }, [notificationSocket]);

    /**
     * Method to enqueue a notification. It saves the method and the args
     * in the notification queue.
     * @param {string} method - The method that will be called on the dequeueing
     * @param  {...any} args -  The args that will be passed to the method 
     */
    const enqueueNotification = (method, ...args) => {
        setNotificationQueue(queue => [...queue, {
            method,
            args
        }]);
    };

    /**
     * Method that will dequeue the notification queue.
     * N.b. this methods has to have a dependency on every method we expose in
     * the NotificationApi context,
     */
    const dequeueNotification = useCallback(() => {
        //get the first element of notificationQueue (this is safe since this method
        //get's called by the use effect after checking the lenght is not 0)
        const toCall = notificationQueue[0];
        //switch on the method name
        switch (toCall.method) {
            case "notifyTherapist":
                //in case is notifyTherapist we call the method notifyTherapist
                //passing the args
                notifyTherapist(...toCall.args);
                break;
            case "patientQuit":
                //in case is patientQuit we call the method patientQuit
                //passing the args
                patientQuit(...toCall.args);
                break;
            case "reloadWaitingRoom":
                //in case is reloadWaitingRoom we call the method reloadWaitingRoom
                //passing the args
                reloadWaitingRoom(...toCall.args);
                break;
        }
        //dequeue the notification
        setNotificationQueue(queue => queue.filter(elem => elem !== toCall));
    }, [notificationQueue, notifyTherapist, reloadWaitingRoom, patientQuit]);

    /**
     * This gets rerunned everytime the notification queue or the notificationSocket
     * changes.
     */
    useEffect(() => {
        //if the notificationSocket is still null or the queue has no
        //elements inside just return
        if (!notificationSocket || notificationQueue.length === 0) return;
        //otherwise call the dequeue function
        dequeueNotification();
    }, [notificationQueue, notificationSocket]);

    return (
        <NotificationApiContext.Provider value={{ notifyTherapist, reloadWaitingRoom, patientQuit }}>
            <NotificationContext.Provider value={[notifications, setNotifications]}>
                {children}
            </NotificationContext.Provider>
        </NotificationApiContext.Provider>
    );
};

export default NotificationContextProvider;
export { useNotificationApi, useNotifications };