
import { SvgIcon } from '@material-ui/core';
import React from 'react';
const GemelliIcon = props => {
        return (
                <SvgIcon {...props}>
                        <line y1="-0.5" x2="18.1765" y2="-0.5" transform="matrix(-0.433132 0.901331 0.699975 0.714168 12.8953 3.00003)" stroke="currentColor" />
                        <line y1="-0.5" x2="18.1765" y2="-0.5" transform="matrix(0.433132 0.901331 -0.699975 0.714168 11.8855 3.00003)" stroke="currentColor" />
                </SvgIcon>
        )
};
export default GemelliIcon;
