
import { SvgIcon } from '@material-ui/core';
import React from 'react';
const NormaleIcon = props => {
        return (
                <SvgIcon {...props}>
                        <line x1="2" y1="11.5" x2="22" y2="11.5" stroke="currentColor" />
                </SvgIcon>
        )
};
export default NormaleIcon;
