
import { SvgIcon } from '@material-ui/core';
import React from 'react';
const ConvivenzaIcon = props => {
        return (
                <SvgIcon {...props}>
                        <line x1="2.5" y1="7" x2="2.5" y2="9" stroke="currentColor" />
                        <line x1="2.5" y1="10" x2="2.5" y2="12" stroke="currentColor" />
                        <path d="M2 13H3.73914" stroke="currentColor" />
                        <path d="M9.82617 13H11.5653" stroke="currentColor" />
                        <path d="M17.6522 13H19.3914" stroke="currentColor" />
                        <path d="M4.60876 13H6.3479" stroke="currentColor" />
                        <path d="M12.4348 13H14.174" stroke="currentColor" />
                        <path d="M20.261 13L21.1305 13.0111" stroke="currentColor" />
                        <path d="M21.1305 13.0111L22 13.0223" stroke="currentColor" />
                        <path d="M7.21741 13H8.95655" stroke="currentColor" />
                        <path d="M15.0436 13H16.7827" stroke="currentColor" />
                        <path d="M21.5653 12V10" stroke="currentColor" />
                        <path d="M21.5653 9V7" stroke="currentColor" />
                        <mask id="path-14-inside-1" fill="white">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17 12.9211L12 7L7 12.9211H8.53586V17H15.4641V12.9211H17Z" />
                        </mask>
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17 12.9211L12 7L7 12.9211H8.53586V17H15.4641V12.9211H17Z" fill="white" />
                        <path d="M12 7L12.764 6.35482L12 5.45005L11.236 6.35482L12 7ZM17 12.9211V13.9211H19.1533L17.764 12.2759L17 12.9211ZM7 12.9211L6.23597 12.2759L4.84671 13.9211H7V12.9211ZM8.53586 12.9211H9.53586V11.9211H8.53586V12.9211ZM8.53586 17H7.53586V18H8.53586V17ZM15.4641 17V18H16.4641V17H15.4641ZM15.4641 12.9211V11.9211H14.4641V12.9211H15.4641ZM11.236 7.64518L16.236 13.5662L17.764 12.2759L12.764 6.35482L11.236 7.64518ZM7.76403 13.5662L12.764 7.64518L11.236 6.35482L6.23597 12.2759L7.76403 13.5662ZM8.53586 11.9211H7V13.9211H8.53586V11.9211ZM9.53586 17V12.9211H7.53586V17H9.53586ZM15.4641 16H8.53586V18H15.4641V16ZM14.4641 12.9211V17H16.4641V12.9211H14.4641ZM17 11.9211H15.4641V13.9211H17V11.9211Z" mask="url(#path-14-inside-1)" />
                </SvgIcon>
        )
};
export default ConvivenzaIcon;
