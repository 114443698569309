import React, { useState, useRef } from 'react';
import { Typography, Button, TextField, makeStyles, Grid, Box} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { verify } from 'crypto';

const useStyles = makeStyles(theme => ({
    button: {
        boxShadow: `0 0 .3rem .1rem ${theme.palette.primary.main}`,
        width: "6rem"
    },
    warningTip: {
        width: "10%",
        maxWidth: "4rem"
    },
    checkValidation: {
        position: "absolute",
        left: "calc(80% - 24px)",
        top: "calc(100% - 26px)",
        fill: theme.palette.primary.main,
        transform: "scale(0)",
        transformOrigin: "center",
        transition: "transform 250ms ease-out"
    },
    verified: {
        transform: "scale(1)"
    }
}));

const TwoFactorAutentication = (props) => {
    let { onCodeValid, error } = props;
    const styles = useStyles();
    const codeRef = useRef();
    const [phoneInserted, setPhoneInserted] = useState(false);
    const [messageSent, setMessageSent] = useState(false);
    const [codeSent, setCodeSent] = useState(-1);
    const [codeValidated, setCodeValidated] = useState(false);
    const [codeVerified, setCodeVerified] = useState(false);
    const [verificationError, setVerificationError] = useState("");

    const validatePhone = ({ target }) => {
        setPhoneInserted(/^\+?\d{10,12}$/.test(target.value));
    }

    const validateCode = ({ target }) => {
        setCodeValidated(/^\d{4}$/.test(target.value));
    }

    const sendMessage = () => {
        let code = (Math.floor(1000 + Math.random() * 8999));
        console.log({ code });
        setCodeSent(code);
        setMessageSent(true);
    }

    const verifyCode = () => {
        console.log(codeRef.current.value, codeSent)
        if (codeRef.current.value == codeSent) {
            setVerificationError("");
            setCodeVerified(true);
            onCodeValid();
        } else {
            setCodeVerified(false);
            setVerificationError("Il codice non corrisponde");
        }
    }
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography variant="error" style={{ color: "red", textAlign: "center", width: "100%", display: "block" }}>
                    {error}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography>
                    Per poter procedere con l'attivazione dell'account è necessario verificare l'identità del titolare. Verrà inviato un SMS al numero indicato qui sotto, contenente un codice OTP di verifica.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems="center" justify="center">
                    <Grid item xs={7}>
                        <TextField onChange={validatePhone} style={{ width: "80%" }} placeholder="Cellulare" />
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <Button disabled={!phoneInserted} onClick={sendMessage} className={styles.button}>Invia</Button>
                    </Grid>
                    <Grid item xs={11} md={2}>
                        <Typography className={styles.warningTip} variant="caption" align="center">Massimo 3 invii</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography>
                    Inserisci il codice di verifica che hai ricevuto al numero indicato in precedenza.
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems="center" justify="center">
                    <Grid item xs={7} alignItems="center" justify="center">
                        <Box position="relative">
                            <TextField inputRef={codeRef} onChange={validateCode} style={{ width: "80%" }} placeholder="Codice" error={verificationError != ""} helperText={verificationError} />
                            <CheckCircleIcon className={`${styles.checkValidation} ${codeVerified && styles.verified}`}/>
                        </Box>
                    </Grid>
                    <Grid item xs={4} md={2} alignItems="center" justify="center">
                        <Button onClick={verifyCode} disabled={!messageSent || !codeValidated} className={styles.button}>Verifica</Button>
                    </Grid>
                    <Grid item xs={11} md={2} alignItems="center" justify="center">
                        <Typography className={styles.warningTip} variant="caption" align="center">Massimo 3 tentativi</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );

}

export default TwoFactorAutentication;