import { SvgIcon } from '@material-ui/core';
import React from 'react';

const LineIcon = props => {
    return (
        <SvgIcon {...props}>
            <line x1="3.29289" y1="20.2634" x2="20.2635" y2="3.29279" stroke="currentColor" stroke-width="2"/>
        </SvgIcon>
    )
};
export default LineIcon;