import phoneValidator from "google-libphonenumber";

const qualifiche = [
  "Psicologo",
  "Psicologo Psicoterapeuta",
  "Medico Psicoterapeuta",
];

//method to validate a birthdate
const validateBirthDate = (birthDate) => {
  let minAge = 0;
  let millisInYear = 1000 * 60 * 60 * 24 * 365;
  let date = new Date(reformatDate(birthDate));
  let today = new Date();
  return today - date > minAge * millisInYear
    ? ""
    : "Il giorno di nascita non può essere nel futuro";
};

//method to convert a form in an object
function objectFromForm(form) {
  let retval = {};
  for (let input of form) {
    retval[`${form.id}-${input.name}`] =
      input.type == "file" ? input.files : input.value;
  }
  return retval;
}

//method to format a date
const formatDate = (dateString) => {
  if (!dateString) {
    return undefined;
  }
  let date = new Date(dateString);
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(date.getDate()).padStart(2, "0")}`;
};

//method to get the difference in minutes between two dates
const differenceInMinutes = (start, end) => {
  if (!start) {
    return 0;
  }
  //if the passed in element is not already a date i'll convert it in dates
  const startDate = start instanceof Date ? start : new Date(`${start}Z`);
  const endDate = end
    ? end instanceof Date
      ? end
      : new Date(`${end}Z`)
    : new Date();
  if (
    startDate instanceof Date &&
    !isNaN(startDate) &&
    endDate instanceof Date &&
    !isNaN(endDate)
  ) {
    return Math.floor((endDate - startDate) / 1000 / 60);
  }
  return 0;
};

const acceptedExtensions = [
  ".txt",
  ".pdf",
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
  ".jpg",
  ".png",
];

//date formatter to format a date into string
const dateFormatter = new Intl.DateTimeFormat("it-IT", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
});

//date formatter to format a date into string
const onlyDateFormatter = new Intl.DateTimeFormat("it-IT", {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
});

//date formatter to format a date into string
const hoursFormatter = new Intl.DateTimeFormat("it-IT", {
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
});

const REGEX_FC =
  /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i;

const validateFiles = (files) => {
  if (!(files instanceof FileList)) return "";
  if (!files) return "Il campo è obbligatorio";
  for (let file of files) {
    if (file.size / 1000000 > 8) {
      return "La dimensione dei file non deve superare 8mb.";
    }
    if (
      !acceptedExtensions.includes(
        file.name.substring(file.name.lastIndexOf(".")).toLowerCase()
      )
    ) {
      return "Uno dei formati dei file caricati non è supportato.";
    }
  }
  return "";
};

const validations = {
  firstName: (firstName) => {
    return /^[^0-9]+$/.test(firstName)
      ? ""
      : "Il nome non può contenere numeri";
  },
  lastName: (lastName) => {
    return /^[^0-9]+$/.test(lastName)
      ? ""
      : "Il cognome non può contenere numeri";
  },
  fiscalCode: (fiscalCode) => {
    return REGEX_FC.test(fiscalCode) ? "" : "Codice fiscale non valido";
  },
  birthDate: validateBirthDate,
  phoneNumber: (phoneNumber) => {
    //get an instance of phone validator from google-libphonenumber
    const validator = phoneValidator.PhoneNumberUtil.getInstance();
    let isValid = false;
    try {
      //try to parse the number
      const number = validator.parse(phoneNumber);
      //check to verify that is a + followed by numbers check if it's a possible number and a valid number
      isValid =
        /^\+\d+$/.test(phoneNumber) &&
        validator.isPossibleNumber(number) &&
        validator.isValidNumber(number);
    } catch (e) {}
    return isValid ? "" : "Numero di telefono non valido";
  },
  file: validateFiles,
  retro: validateFiles,
};

const genericAudioWebcamError = {
  open: true,
  severity: "warning",
  message:
    "Non è stato possibile utilizzare la webcam o il microfono. Si prega di selezionare un dispositivo.",
};

/**
 * Calculate the sha1 of a string message
 * @param {string} message
 * @returns The hashed message
 */
async function sha1(message) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest("SHA-1", msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray
    .map((b) => ("00" + b.toString(16)).slice(-2))
    .join("");
  return hashHex;
}

const reformatDate = (date) => {
  const match = date.match(/(\d{1,2})\/(\d{1,2})\/(\d{4,})/);
  if (match) {
    const [_, day, month, year] = match;
    return `${year}-${month}-${day}`;
  }
  return "";
};

/**
 * Enum for Whiteboard permissions
 * @enum {(-1|0|1|2)}
 * @property {-1} NOWHITEBOARD - There's no whiteboard available
 * @property {0} CANMODIFY - patient can modify whiteboard
 * @property {1} READONLY - patient can only view
 * @property {2} LOCKED - patient can't view or modify
 */
const WHITEBOARD_PERMISSIONS = {
  /**There's no whiteboard available*/
  NOWHITEBOARD: -1,
  /**Patient can modify the whiteboard */
  CANMODIFY: 0,
  /**Patient can only see the whiteboard*/
  READONLY: 1,
  /**Patient can't see the whiteboard*/
  LOCKED: 2,
};

/**
 * Enum for Payment permissions
 * @enum {(0|1|2|3|4)}
 * @property {0} TOPAY - NOT USED FOR NOW
 * @property {1} REQUESTED - The payment was created by the therapist
 * @property {2} PAYED - The payment was paid
 * @property {3} PENDING - The payment has been paid but is waiting for validation
 * @property {3} FAILED - The payment has been paid but failed
 */

const PAYMENT_STATE = {
  /**NOT USED FOR NOW*/
  TOPAY: 0,
  /**The payment was created by the therapist*/
  REQUESTED: 1,
  /**The payment was paid*/
  PAYED: 2,
  /**The payment has been paid but is waiting for validation*/
  PENDING: 3,
  /**The payment has been paid but failed*/
  FAILED: 4,
};

//method to start the download of a file upon receiving a RawDocumentDTO
let downloadFile = (rawFile) => {
  //converting the base64 string to a byt array
  const bytes = Uint8Array.from(atob(rawFile.base64File), (c) =>
    c.charCodeAt(0)
  );
  //creating the new blob
  let blob = new Blob([bytes]);
  //creating a ficticious a element that will never be added to the page
  let link = document.createElement("a");
  //setting the blob url as the href
  link.href = window.URL.createObjectURL(blob);
  //setting the download name
  link.download = rawFile.fileName;
  //programmatically clicking on it to start the download
  link.click();
};

//This function return couple name surname or one of them. In other case return alternativeStr
const nameAndSurname = (name, surname, alternativeStr) => {
  const nameAndSurname = `${name} ${surname}`;
  return !name && !surname ? alternativeStr : nameAndSurname;
};

const eurCurrency = (value) =>
  (Math.round((value || 0) * 100) / 100).toFixed(2).replace(".", ",");

const FRAME_RATE = 20;

/**
 * Function that given an array, a min, a max and a value returns the element of the array
 * at the correct position between min and max
 * @param {Array} array The array to linear interpolate
 * @param {number} min The mimal value assignable to value
 * @param {number} max The maximal value assignable to value
 * @param {number} value The value
 * @returns
 */
const lerpArray = (array, min, max, value) => {
  const index = Math.max(
    Math.min(
      Math.floor(((value - min) / (max - min)) * array.length),
      array.length - 1
    ),
    0
  );
  return array[index];
};

/**
 * Function to log to graylog
 * @param {Object} error The error to log in graylog
 * @param {string} error.type The type of the error
 * @param {string} error.message The message of the error
 * @param {string} error.stack The stack of the error
 * @param {string} error.userId The userId of the user
 */
const grayLog = (error) => {
  fetch("/api/logging", {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify(error),
  });
};

/**
 * A promise to load a file from the file system
 * @param {number} maxSize The max size one of the sides of the image can be, if null no transformation is applied
 * @returns The base64 data url of the selected image
 */
const loadImageAsDataUrl = (maxSize) => {
  return new Promise((resolve, reject) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.click();
    fileInput.addEventListener("change", (e) => {
      const file = e.target?.files[0];
      if (!file) return;
      const fr = new FileReader();
      fr.onerror = () => {
        reject();
      };
      fr.onload = ({ target: { result } }) => {
        const img = new Image();
        img.src = result;
        img.onerror = () => {
          reject();
        };
        img.onload = () => {
          if (!maxSize || (img.width <= maxSize && img.height <= maxSize)) {
            resolve(result);
            return;
          }
          const canvas = document.createElement("canvas");
          const MAX_DIMENSION = maxSize;
          let w = (MAX_DIMENSION * img.width) / img.height;
          let h = MAX_DIMENSION;
          if (img.width > img.height) {
            w = MAX_DIMENSION;
            h = (MAX_DIMENSION * img.height) / img.width;
          }
          canvas.width = w;
          canvas.height = h;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, w, h);
          resolve(canvas.toDataURL());
        };
      };
      fr.readAsDataURL(file);
    });
  });
};

export {
  dateFormatter,
  onlyDateFormatter,
  hoursFormatter,
  differenceInMinutes,
  formatDate,
  objectFromForm,
  validateBirthDate,
  qualifiche,
  validations,
  acceptedExtensions,
  sha1,
  reformatDate,
  WHITEBOARD_PERMISSIONS,
  downloadFile,
  nameAndSurname,
  eurCurrency,
  PAYMENT_STATE,
  FRAME_RATE,
  lerpArray,
  grayLog,
  loadImageAsDataUrl,
};
