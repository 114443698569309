import { Box, Button, CircularProgress, Grid, makeStyles, MenuItem, Paper, TextField, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useMemo, useState } from 'react';
import { Prompt, useHistory } from 'react-router-dom';
import { FileField, PhoneField, ProgressTabs } from '../components/';
import DateField from '../components/DateField';
import { useSetUserProfile, useUser, useUserProfile } from '../contexts/UserContext';
import useApi from '../hooks/useApi';
import { acceptedExtensions, formatDate, objectFromForm, reformatDate, validations } from '../utils/UtilsFunction';

const useStyles = makeStyles((theme) => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 800,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    buttonsMobile: {
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "column",
        gap: "1rem",
        padding: "1rem"
    },
    button: {
        boxSizing: "content-box",
        margin: theme.spacing(3),
        minWidth: "fit-content"
    },
    formBox: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: ".3rem",
        margin: "1em 0",
        width: "100%",
        padding: "1em",
        position: "relative",

        "&::before": {
            content: "attr(data-title)",
            position: "absolute",
            left: "1em",
            backgroundColor: "white",
            padding: "0 1em",
            transform: "translateY(calc(-50% - 1em))",
            fontStyle: "italic"
        }
    }
}));

function validateRequired(form) {
    let retval = {};
    for (let input of form) {
        retval[input.name] = !(input.required && !input.value.trim()) ? "" : "Campo obbligatorio";
        if (retval[input.name] == "") {
            //for the phone i need to send the phone prefix together with the number for the validation process
            if (input.name !== "phoneNumber") {
                retval[input.name] = validations[input.name] ? validations[input.name](input.type !== "file" ? input.value : input.files) : "";
            } else {
                retval.phoneNumber = validations.phoneNumber(`+${form["prefix"].value}${form["phoneNumber"].value}`);
            }
        }
    }
    return retval;
}


function CompleteRegistration(props) {
    const history = useHistory();
    const classes = useStyles();
    const [registrationCompleted, setRegistrationCompleted] = useState(false);
    const [formDatiAnagraficiErrors, setFormDatiAnagraficiErrors] = useState({});
    const [formDocumentoErrors, setFormDocumentoErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [userDTO, setUserDTO] = useState();
    const [isDocumentAlreadyUploaded, setIsDocumentAlreadyUploaded] = useState(null);
    const setUserProfile = useSetUserProfile();
    const tabs = useMemo(() => {
        if (userDTO?.isDocumentMandatory) {
            return [
                { id: 0, title: "Dati anagrafici", icon: "person" },
                { id: 2, title: "Documento di riconoscimento", icon: "assignment" },
                { id: 3, title: "Account attivato", icon: "check" },
            ];
        }
        return [
            { id: 0, title: "Dati anagrafici", icon: "person" },
            { id: 3, title: "Account attivato", icon: "check" },
        ];
    }, [userDTO]);
    const [activeTab, setActiveTab] = useState(Object.values(tabs)[0].id);
    const user = useUser();
    const userProfile = useUserProfile();
    const api = useApi();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        if (userProfile?.isCompleted) {
            history.replace("/");
        } else {
            async function initAsync() {
                setLoading(true)
                let response = await api(user, `patients/${user.profile.sub}`, "GET");
                if (response) {
                    setUserDTO(response);
                    console.log("userDTO", userDTO)
                    setLoading(false);
                } else {
                    history.push("/error");
                    setLoading(false);
                }
            }
            initAsync();
        }
    }, []);

    const handleChange = (value) => {
        setActiveTab(value);
    };

    const saveInformations = async () => {
        setLoading(true);
        let datiAnagrafici = "datiAnagraficiForm";
        let anagraficiObj = objectFromForm(document.forms[datiAnagrafici]);
        let objTosave = {
            "idpatient": user.profile.sub,
            "idtherapist": userDTO?.idTherapist,
            "fiscalCode": anagraficiObj[`${datiAnagrafici}-fiscalCode`],
            "birthdate": reformatDate(anagraficiObj[`${datiAnagrafici}-birthDate`]),
            "gender": anagraficiObj[`${datiAnagrafici}-gender`],
            "phoneNumber": anagraficiObj[`${datiAnagrafici}-phoneNumber`],
            "phonePrefix": anagraficiObj[`${datiAnagrafici}-prefix`],
            "isMarketingAccepted": userDTO?.isMarketingAccepted,
            "addresses": [
                {
                    "countryCode": anagraficiObj[`${datiAnagrafici}-stato`],
                    "region": anagraficiObj[`${datiAnagrafici}-regione`],
                    "district": anagraficiObj[`${datiAnagrafici}-provincia`],
                    "city": anagraficiObj[`${datiAnagrafici}-comune`],
                    "zipCode": anagraficiObj[`${datiAnagrafici}-CAP`],
                    "type": "birthplace"
                },
                {
                    "region": anagraficiObj[`${datiAnagrafici}-regioneResidenza`],
                    "district": anagraficiObj[`${datiAnagrafici}-provinciaResidenza`],
                    "city": anagraficiObj[`${datiAnagrafici}-comuneResidenza`],
                    "zipCode": anagraficiObj[`${datiAnagrafici}-CAPresidenza`],
                    "addressLine": anagraficiObj[`${datiAnagrafici}-via`],
                    "number": anagraficiObj[`${datiAnagrafici}-numeroCivico`],
                    "type": "residenceplace"
                }
            ]
        };
        let json = await api(user, "patient", "PUT", objTosave);
        return json;
    };

    async function nextStep() {
        if (activeTab == 0) {
            let formDatiAnagrafici = document.forms['datiAnagraficiForm'];
            let formErrors = validateRequired(formDatiAnagrafici);
            if (Object.values(formErrors).some(x => x != "")) {
                setFormDatiAnagraficiErrors(formErrors);
            } else {
                setFormDatiAnagraficiErrors({});
                await saveInformations();
                //check to see if the document is mandatory
                if (userDTO?.isDocumentMandatory === true) {
                    //if it's mandatory proceed to check if there are already uploaded documents
                    if (userDTO && user) {
                        const documents = await api(user, `documents/${userDTO.idTherapist}/${user.profile.sub}?isPersonal=true`, "GET");
                        if (Array.isArray(documents)) {
                            setIsDocumentAlreadyUploaded([...documents].length > 0);
                        }
                    }
                    handleChange(2);
                } else {
                    //if it's not mandatory complete the patient profile and just go to the last step
                    const response = await api(user, `patients/${user.profile.sub}/complete`, "PATCH");
                    if (response.isSuccessStatusCode) {
                        handleChange(3);
                        setUserProfile(prev => ({
                            ...prev,
                            isCompleted: true
                        }));
                        setRegistrationCompleted(true);
                    } else {
                        setFormDatiAnagraficiErrors({ generic: "Errore, il profilo non è stato completato, riprovare." });
                    }
                }
                setLoading(false);
            }
        } else if (activeTab == 2) {
            let formDocumento = document.forms['documentoForm'];
            let formErrors = validateRequired(formDocumento);
            if (Object.values(formErrors).some(x => x != "")) {
                setFormDocumentoErrors(formErrors);
            } else {
                setFormDocumentoErrors({});
                setLoading(true);
                if (formDocumento["file"][1].files.length == 0) {
                    const response = await api(user, `patients/${user.profile.sub}/complete`, "PATCH");
                    if (response.isSuccessStatusCode) {
                        handleChange(3);
                        setUserProfile(prev => ({
                            ...prev,
                            isCompleted: true
                        }));
                        setRegistrationCompleted(true);
                    } else {
                        setFormDocumentoErrors({ generic: "Errore, il profilo non è stato completato, riprovare." });
                    }
                } else {
                    let formDatiAnagrafici = document.forms['datiAnagraficiForm'];
                    let formData = new FormData();
                    formData.append("idtherapist", userDTO.idTherapist);
                    formData.append("idpatient", user.profile.sub);
                    formData.append("documentinfo[0].documenttype", formDocumento["type"].value);
                    formData.append("documentinfo[0].documentnumber", formDocumento["code"].value);
                    formData.append("documentinfo[0].attachmentfile", formDocumento["file"][1].files[0]);
                    formData.append("documentinfo[0].isfront", true);
                    formData.append("documentinfo[1].documenttype", formDocumento["type"].value);
                    formData.append("documentinfo[1].documentnumber", formDocumento["code"].value);
                    formData.append("documentinfo[1].attachmentfile", formDocumento["retro"][1].files[0]);
                    formData.append("documentinfo[1].isfront", false);
                    formData.append("FirstName", userProfile.firstName);
                    formData.append("LastName", userProfile.lastName);
                    formData.append("Email", user.profile.email);
                    formData.append("PhoneNumber", `+${formDatiAnagrafici["prefix"].value}${formDatiAnagrafici["phoneNumber"].value}`);
                    formData.append("IsPersonal", true);
                    const response = await api(user, "registerdocument", "POST", formData, false);
                    setLoading(false);
                    if (response.isSuccessStatusCode) {
                        handleChange(3);
                        setUserProfile(prev => ({
                            ...prev,
                            isCompleted: true
                        }));
                        setRegistrationCompleted(true);
                    } else {
                        setFormDocumentoErrors({ generic: "Errore nel caricamento del documento. Riprovare." });
                    }
                }
                setLoading(false);
            }
        } else {
        }

    }

    useEffect(() => {
        if (props.layoutOptions) {
            props.layoutOptions({
                title: "Informazioni per il terapeuta",
                disabled: false,
            });
        }
    }, []);

    //const styles = useStyles();
    return (
        //if there's no user logged in it shows an empty page
        !userDTO ? <></> :
            <Box>
                <Prompt when={!registrationCompleted} message="Uscire senza completare il processo di registrazione?" />
                <Paper style={{ position: "relative" }}>
                    <Box style={{ backgroundColor: "rgba(255,255,255,.7", zIndex: 1000, display: loading ? "" : "none" }} position="absolute" height="100%" width="100%" display="flex" justifyContent="center" alignItems="center" py={5}>
                        <CircularProgress size="3rem" />
                    </Box>
                    <ProgressTabs list={tabs} active={activeTab}
                        goToStep={handleChange}
                    />
                    <div
                        role="tabpanel"
                        hidden={activeTab !== 0}>
                        <Box px={matches ? 5 : 4}>
                            <form id="datiAnagraficiForm" noValidate autoComplete="off" spellCheck="false">

                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Box width="100%" textAlign="center" color="red">
                                            {formDatiAnagraficiErrors.generic}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <PhoneField prefixName="prefix" prefixDefaultValue={userDTO?.phonePrefix || "39"} name="phoneNumber" defaultValue={userDTO?.phoneNumber} label="Numero di telefono" InputLabelProps={{ shrink: true }} fullWidth required error={formDatiAnagraficiErrors.phoneNumber} helperText={formDatiAnagraficiErrors.phoneNumber} />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField name="fiscalCode" defaultValue={userDTO?.fiscalCode} label="Codice fiscale" InputLabelProps={{ shrink: true }} fullWidth required error={formDatiAnagraficiErrors.fiscalCode} helperText={formDatiAnagraficiErrors.fiscalCode} />
                                    </Grid>

                                    <Grid item xs={12} sm={3}>
                                        <DateField name="birthDate" defaultValue={formatDate(userDTO?.birthDate)} label="Data di nascita" fullWidth InputLabelProps={{ shrink: true }} required error={formDatiAnagraficiErrors.birthDate} helperText={formDatiAnagraficiErrors.birthDate} />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <TextField name="gender" label="Sesso" defaultValue={userDTO?.gender} select fullWidth InputLabelProps={{ shrink: true }} required error={formDatiAnagraficiErrors.gender} helperText={formDatiAnagraficiErrors.gender}>
                                            <MenuItem value="M">M</MenuItem>
                                            <MenuItem value="F">F</MenuItem>
                                            <MenuItem value="ND">Preferisco non specificarlo</MenuItem>
                                        </TextField>
                                    </Grid>
                                    <Box className={classes.formBox} data-title="Luogo di nascita">
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={2}>
                                                <TextField name="stato" label="Stato" defaultValue={userDTO?.addresses?.find(elem => elem.type === "birthplace")?.countryCode} fullWidth InputLabelProps={{ shrink: true }} required error={formDatiAnagraficiErrors.stato} helperText={formDatiAnagraficiErrors.stato}></TextField>
                                            </Grid>
                                            <Grid item xs={12} sm={5}>
                                                <TextField name="regione" label="Regione" defaultValue={userDTO?.addresses?.find(elem => elem.type === "birthplace")?.region} InputLabelProps={{ shrink: true }} fullWidth required error={formDatiAnagraficiErrors.regione} helperText={formDatiAnagraficiErrors.regione} />
                                            </Grid>
                                            <Grid item xs={12} sm={5}>
                                                <TextField name="provincia" label="Provincia" defaultValue={userDTO?.addresses?.find(elem => elem.type === "birthplace")?.district} InputLabelProps={{ shrink: true }} fullWidth required error={formDatiAnagraficiErrors.provincia} helperText={formDatiAnagraficiErrors.provincia} />
                                            </Grid>

                                            <Grid item xs={12} sm={7}>
                                                <TextField name="comune" label="Comune" defaultValue={userDTO?.addresses?.find(elem => elem.type === "birthplace")?.city} InputLabelProps={{ shrink: true }} fullWidth required error={formDatiAnagraficiErrors.comune} helperText={formDatiAnagraficiErrors.comune} />
                                            </Grid>
                                            <Grid item xs={12} sm={2}>
                                                <TextField name="CAP" label="CAP" defaultValue={userDTO?.addresses?.find(elem => elem.type === "birthplace")?.zipCode} InputLabelProps={{ shrink: true }} fullWidth required error={formDatiAnagraficiErrors.CAP} helperText={formDatiAnagraficiErrors.CAP} />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box className={classes.formBox} data-title="Luogo di residenza">
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={4}>
                                                <TextField name="regioneResidenza" defaultValue={userDTO?.addresses?.find(elem => elem.type === "residenceplace")?.region} label="Regione" fullWidth InputLabelProps={{ shrink: true }} required error={formDatiAnagraficiErrors.regioneResidenza} helperText={formDatiAnagraficiErrors.regioneResidenza} />
                                            </Grid>

                                            <Grid item xs={12} sm={4}>
                                                <TextField name="provinciaResidenza" defaultValue={userDTO?.addresses?.find(elem => elem.type === "residenceplace")?.district} label="Provincia" fullWidth InputLabelProps={{ shrink: true }} required error={formDatiAnagraficiErrors.provinciaResidenza} helperText={formDatiAnagraficiErrors.provinciaResidenza} />
                                            </Grid>
                                            <Grid item xs={12} sm={4}>
                                                <TextField name="comuneResidenza" defaultValue={userDTO?.addresses?.find(elem => elem.type === "residenceplace")?.city} label="Comune" fullWidth InputLabelProps={{ shrink: true }} required error={formDatiAnagraficiErrors.comuneResidenza} helperText={formDatiAnagraficiErrors.comuneResidenza} />
                                            </Grid>
                                            <Grid item xs={12} sm={7}>
                                                <TextField name="via" label="Via" defaultValue={userDTO?.addresses?.find(elem => elem.type === "residenceplace")?.addressLine} InputLabelProps={{ shrink: true }} fullWidth required error={formDatiAnagraficiErrors.via} helperText={formDatiAnagraficiErrors.via} />
                                            </Grid>
                                            <Grid item xs={12} sm={2}>
                                                <TextField name="numeroCivico" defaultValue={userDTO?.addresses?.find(elem => elem.type === "residenceplace")?.number} label="Numero" InputLabelProps={{ shrink: true }} fullWidth required error={formDatiAnagraficiErrors.numeroCivico} helperText={formDatiAnagraficiErrors.numeroCivico} />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <TextField name="CAPresidenza" label="CAP" defaultValue={userDTO?.addresses?.find(elem => elem.type === "residenceplace")?.zipCode} InputLabelProps={{ shrink: true }} fullWidth required error={formDatiAnagraficiErrors.CAPresidenza} helperText={formDatiAnagraficiErrors.CAPresidenza} />
                                            </Grid>
                                        </Grid>
                                    </Box>

                                </Grid>

                            </form>
                        </Box>
                    </div>
                    <div
                        role="tabpanel"
                        hidden={activeTab !== 2}>
                        <Box px={matches ? 5 : 4}>
                            <form id="documentoForm" noValidate autoComplete="off" spellCheck="false">
                                {
                                    isDocumentAlreadyUploaded &&
                                    <Alert severity="info"><Typography><span style={{ fontWeight: "bold" }}> Il suo terapeuta ha già caricato il suo documento di riconoscimento.</span> Procedendo senza selezionare alcun file accetterà il documento caricato dal suo terapeuta, caricando un nuovo file un nuovo documento sarà visualizzabile nella sua area personale.</Typography></Alert>
                                }
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Box width="100%" textAlign="center" color="red">
                                            {formDocumentoErrors.generic}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField name="type" label="Tipo" select fullWidth InputLabelProps={{ shrink: true }} required={!isDocumentAlreadyUploaded} error={formDocumentoErrors.type} helperText={formDocumentoErrors.type}>
                                            <MenuItem value="ID">Carta d'identità</MenuItem>
                                            <MenuItem value="DL">Patente di guida</MenuItem>
                                            <MenuItem value="PS">Passaporto</MenuItem>
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        <TextField name="code" label="Numero" InputLabelProps={{ shrink: true }} fullWidth required={!isDocumentAlreadyUploaded} error={formDocumentoErrors.code} helperText={formDocumentoErrors.code} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            Carica documento
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FileField
                                            accept={acceptedExtensions.join(",")}
                                            label="FRONTE" name="file" required={!isDocumentAlreadyUploaded} error={formDocumentoErrors.file} helperText={formDocumentoErrors.file} />
                                        {!matches && <small>È possibile caricare un documento o scattare una foto</small>}
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FileField
                                            accept={acceptedExtensions.join(",")}
                                            label="RETRO" name="retro" required={!isDocumentAlreadyUploaded} error={formDocumentoErrors.retro} helperText={formDocumentoErrors.retro} />
                                        {!matches && <small>È possibile caricare un documento o scattare una foto</small>}                                </Grid>
                                </Grid>

                            </form>
                        </Box>
                    </div>
                    <div
                        role="tabpanel"
                        hidden={activeTab !== 3}>
                        <Box px={matches ? 5 : 4}>
                            Il tuo account è stato verificato con successo, puoi iniziare ad utilizzare le funzionalità di Psy Care.
                        </Box>

                    </div>
                    <div className={matches ? classes.buttons : classes.buttonsMobile}>
                        <Box display="flex" alignItems="center" textAlign="left" width="100%" fontStyle="italic" fontSize=".8em" padding={matches ? "1rem 40px" : "1rem"}>
                            * Campo obbligatorio
                        </Box>
                        {
                            activeTab !== Object.values(tabs)[0].id && activeTab !== Object.values(tabs)[Object.values(tabs).length - 1].id &&
                            <Button onClick={() => { handleChange(Object.values(tabs).findIndex(tab => tab.id == activeTab) - 1); }} className={matches ? classes.button : null}>
                                Indietro
                            </Button>
                        }
                        {activeTab !== Object.values(tabs)[Object.values(tabs).length - 1].id ?
                            <Button variant="contained" color="primary" onClick={nextStep} className={matches ? classes.button : null}>Avanti</Button>
                            :
                            <Button variant="contained" color="primary" onClick={() => { history.push("/home"); }} className={matches ? classes.button : null}>Home</Button>
                        }
                    </div>
                </Paper>
            </Box>
    );
}

export default CompleteRegistration;
