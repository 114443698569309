
import { SvgIcon } from '@material-ui/core';
import React from 'react';
const RubberIcon = props => {
    return (
        <SvgIcon {...props}>
            <path d="M12.129 3.31049C12.6916 2.74808 13.4545 2.43213 14.25 2.43213C15.0455 2.43213 15.8084 2.74808 16.371 3.31049L22.1895 9.12899C22.7519 9.69157 23.0678 10.4545 23.0678 11.25C23.0678 12.0455 22.7519 12.8084 22.1895 13.371L13.9395 21.621C13.377 22.1836 12.6141 22.4998 11.8185 22.5H7.67999C6.88441 22.4998 6.12147 22.1836 5.55899 21.621L1.80899 17.871C1.24658 17.3084 0.930634 16.5455 0.930634 15.75C0.930634 14.9545 1.24658 14.1916 1.80899 13.629L12.1275 3.31049H12.129ZM15.3105 4.37099C15.0292 4.08978 14.6477 3.93181 14.25 3.93181C13.8522 3.93181 13.4708 4.08978 13.1895 4.37099L6.23999 11.3205L14.1795 19.26L21.129 12.3105C21.4102 12.0292 21.5682 11.6477 21.5682 11.25C21.5682 10.8522 21.4102 10.4708 21.129 10.1895L15.3105 4.37099ZM13.119 20.3205L5.17949 12.381L2.87099 14.6895C2.58979 14.9708 2.43181 15.3522 2.43181 15.75C2.43181 16.1477 2.58979 16.5292 2.87099 16.8105L6.62099 20.5605C6.90223 20.8418 7.2837 20.9999 7.68149 21H11.82C12.2178 20.9999 12.5993 20.8418 12.8805 20.5605L13.1205 20.3205H13.119Z" fill="currentColor" />
        </SvgIcon>
    );
};
export default RubberIcon;
