import { useCallback, useLayoutEffect, useRef } from "react";

export function useEventEffect<ArgsType extends any[], ReturnType>(
  event: (...args: ArgsType) => ReturnType
) {
  const eventRef = useRef(event);

  useLayoutEffect(() => {
    eventRef.current = event;
  });

  return useCallback((...args: ArgsType) => {
    if (typeof eventRef.current === "function") {
      return eventRef.current(...args);
    }
  }, []);
}
