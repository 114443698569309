
import { SvgIcon } from '@material-ui/core';
import React from 'react';
const ConflittualeIcon = props => {
        return (
                <SvgIcon {...props}>
                        <path d="M8.89576 15.533L5.3482 9.03304" stroke="currentColor" />
                        <path d="M2 15.4796L5.79163 9.03304" stroke="currentColor" />
                        <path d="M15.1041 15.033L11.5565 9.03304" stroke="currentColor" />
                        <path d="M8.43278 15.4796L11.9999 9.03304" stroke="currentColor" />
                        <path d="M22 15.4796L18.2082 9.03305" stroke="currentColor" />
                        <path d="M14.8658 15.4796L18.6516 9.03304" stroke="currentColor" />
                </SvgIcon>
        )
};
export default ConflittualeIcon;
