import React, { useMemo, useState } from 'react';
import { ExpandMore, ChevronRight } from '@material-ui/icons';
import { ListItem } from '@material-ui/core';
import AbortoSpontaneoIcon from './icons/AbortoSpontaneoIcon';
import AbortoVolontarioIcon from './icons/AbortoVolontarioIcon';
import AbusoFisicoIcon from './icons/AbusoFisicoIcon';
import AmiciziaIcon from './icons/AmiciziaIcon';
import ConflittualeIcon from './icons/ConflittualeIcon';
import ConvivenzaIcon from './icons/ConvivenzaIcon';
import DecessoIcon from './icons/DecessoIcon';
import DistanteIcon from './icons/DistanteIcon';
import DivorzioIcon from './icons/DivorzioIcon';
import DonnaIcon from './icons/DonnaIcon';
import FiglioAdottivoIcon from './icons/FiglioAdottivoIcon';
import GemelliIcon from './icons/GemelliIcon';
import GravidanzaIcon from './icons/GravidanzaIcon';
import FiglioIcon from './icons/FiglioIcon';
import InformazioneMancanteIcon from './icons/InformazioneMancanteIcon';
import MatrimonioIcon from './icons/MatrimonioIcon';
import MoltoViciniIcon from './icons/MoltoViciniIcon';
import NormaleIcon from './icons/NormaleIcon';
import RelazioneSignificativaIcon from './icons/RelazioneSignificativaIcon';
import SeparazioneIcon from './icons/SeparazioneIcon';
import TransgenderDUIcon from './icons/TransgenderDUIcon';
import TransgenderUDIcon from './icons/TransgenderUDIcon';
import UomoIcon from './icons/UomoIcon';
import { TreeItem, TreeView } from '@material-ui/lab';
import { Genograms } from "react-sketch-3.0";


const GenogramMenu = ({ onSelect }) => {
    const [expandedNodes, setExpandedNodes] = useState([]);
    const structure = useMemo(() => ([
        {
            name: "Genere",
            childrens: [
                {
                    name: "Uomo",
                    icon: <UomoIcon />,
                    onClick: () => {
                        if (onSelect && typeof onSelect === "function") {
                            onSelect(Genograms.Uomo);
                        }
                    }
                },
                {
                    name: "Donna",
                    icon: <DonnaIcon />,
                    onClick: () => {
                        if (onSelect && typeof onSelect === "function") {
                            onSelect(Genograms.Donna);
                        }
                    }
                },
                {
                    name: "Transgender",
                    subtitle: "(da donna a uomo)",
                    icon: <TransgenderDUIcon />,
                    onClick: () => {
                        if (onSelect && typeof onSelect === "function") {
                            onSelect(Genograms.TransgenderDu);
                        }
                    }
                },
                {
                    name: "Transgender",
                    subtitle: "(da uomo a donna)",
                    icon: <TransgenderUDIcon />,
                    onClick: () => {
                        if (onSelect && typeof onSelect === "function") {
                            onSelect(Genograms.TransgenderUd);
                        }
                    }
                }
            ]
        },
        {
            name: "Relazioni familiari",
            childrens: [
                {
                    name: "Matrimonio",
                    icon: <MatrimonioIcon />,
                    onClick: () => {
                        if (onSelect && typeof onSelect === "function") {
                            onSelect(Genograms.Matrimonio);
                        }
                    }
                },
                {
                    name: "Relazione significativa",
                    icon: <RelazioneSignificativaIcon />,
                    onClick: () => {
                        if (onSelect && typeof onSelect === "function") {
                            onSelect(Genograms.Relazione);
                        }
                    }
                },
                {
                    name: "Convivenza",
                    icon: <ConvivenzaIcon />,
                    onClick: () => {
                        if (onSelect && typeof onSelect === "function") {
                            onSelect(Genograms.Convivenza);
                        }
                    }
                },
                {
                    name: "Divorzio",
                    icon: <DivorzioIcon />,
                    onClick: () => {
                        if (onSelect && typeof onSelect === "function") {
                            onSelect(Genograms.Divorzio);
                        }
                    }
                },
                {
                    name: "Separazione",
                    icon: <SeparazioneIcon />,
                    onClick: () => {
                        if (onSelect && typeof onSelect === "function") {
                            onSelect(Genograms.Separazione);
                        }
                    }
                }
            ]
        },
        {
            name: "Relazioni emotive",
            childrens: [
                {
                    name: "Normale",
                    icon: <NormaleIcon />,
                    onClick: () => {
                        if (onSelect && typeof onSelect === "function") {
                            onSelect(Genograms.RelazioneNormale);
                        }
                    }
                },
                {
                    name: "Distante",
                    icon: <DistanteIcon />,
                    onClick: () => {
                        if (onSelect && typeof onSelect === "function") {
                            onSelect(Genograms.RelazioneDistante);
                        }
                    }
                },
                {
                    name: "Molto vicini",
                    icon: <MoltoViciniIcon />,
                    onClick: () => {
                        if (onSelect && typeof onSelect === "function") {
                            onSelect(Genograms.RelazioneMoltoVicini);
                        }
                    }
                },
                {
                    name: "Conflittuale",
                    icon: <ConflittualeIcon />,
                    onClick: () => {
                        if (onSelect && typeof onSelect === "function") {
                            onSelect(Genograms.RelazioneConflittuale);
                        }
                    }
                },
                {
                    name: "Amicizia",
                    icon: <AmiciziaIcon />,
                    onClick: () => {
                        if (onSelect && typeof onSelect === "function") {
                            onSelect(Genograms.RelazioneAmicizia);
                        }
                    }
                },
                {
                    name: "Incuria/Abuso",
                    icon: <AbusoFisicoIcon />,
                    onClick: () => {
                        if (onSelect && typeof onSelect === "function") {
                            onSelect(Genograms.RelazioneAbuso);
                        }
                    }
                }
            ]
        },
        {
            name: "Figli",
            childrens: [
                {
                    name: "Figlio/a",
                    icon: <FiglioIcon />,
                    onClick: () => {
                        if (onSelect && typeof onSelect === "function") {
                            onSelect(Genograms.Figlio);
                        }
                    }
                },
                {
                    name: "Figlio/a adottivo",
                    icon: <FiglioAdottivoIcon />,
                    onClick: () => {
                        if (onSelect && typeof onSelect === "function") {
                            onSelect(Genograms.FiglioAdottivo);
                        }
                    }
                },
                {
                    name: "Gemelli",
                    icon: <GemelliIcon />,
                    onClick: () => {
                        if (onSelect && typeof onSelect === "function") {
                            onSelect(Genograms.Gemelli);
                        }
                    }
                },
                {
                    name: "Gravidanza",
                    icon: <GravidanzaIcon />,
                    onClick: () => {
                        if (onSelect && typeof onSelect === "function") {
                            onSelect(Genograms.Gravidanza);
                        }
                    }
                },
                {
                    name: "Aborto spontaneo",
                    icon: <AbortoSpontaneoIcon />,
                    onClick: () => {
                        if (onSelect && typeof onSelect === "function") {
                            onSelect(Genograms.AbortoSpontaneo);
                        }
                    }
                },
                {
                    name: "Aborto volontario",
                    icon: <AbortoVolontarioIcon />,
                    onClick: () => {
                        if (onSelect && typeof onSelect === "function") {
                            onSelect(Genograms.AbortoVolontario);
                        }
                    }
                }
            ]
        },
        {
            name: "Altro",
            childrens: [
                {
                    name: "Decesso",
                    icon: <DecessoIcon />,
                    onClick: () => {
                        if (onSelect && typeof onSelect === "function") {
                            onSelect(Genograms.Decesso);
                        }
                    }
                },
                {
                    name: "Informazione mancante",
                    icon: <InformazioneMancanteIcon />,
                    onClick: () => {
                        if (onSelect && typeof onSelect === "function") {
                            onSelect(Genograms.InformazioneMancante);
                        }
                    }
                }
            ]
        }
    ]), [onSelect]);
    return (
        <TreeView
            defaultCollapseIcon={<ExpandMore />}
            defaultExpandIcon={<ChevronRight />}
            disableSelection
            onNodeToggle={(e, ids) => {
                setExpandedNodes(prev => ids.filter(id => !prev.includes(id)));
            }}
            selected={[]}
            expanded={expandedNodes}
        >
            {structure.map((topLevel, number) => (
                <ListItem>
                    <TreeItem nodeId={number} label={<div style={{ fontSize: ".8rem" }}>{topLevel.name} </div>}>
                        {topLevel.childrens.map((child, childNumber) => (
                            <ListItem>
                                <TreeItem
                                    icon={child.icon}
                                    nodeId={`${number}_${childNumber}`}
                                    label={<div style={{ lineHeight: 1, fontSize: ".75rem" }}>{child.name}<br />{child.subtitle && <span style={{ fontSize: ".65rem" }}>{child.subtitle}</span>}</div>}
                                    onLabelClick={() => {
                                        setExpandedNodes([]);
                                        child.onClick();
                                    }}

                                />
                            </ListItem>
                        ))}
                    </TreeItem>
                </ListItem>
            ))}
        </TreeView>
    );
};
export default GenogramMenu;