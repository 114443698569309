import { SvgIcon } from '@material-ui/core';
import React from 'react';

const VoidIcon = props => {
    return (
        <SvgIcon {...props}>
            <rect fill="none" height="24" width="24" />
        </SvgIcon>
    );
};
export default VoidIcon;