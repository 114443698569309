import React, { useState, createContext, useEffect, useContext } from 'react';
import { UserManager } from 'oidc-client';
import appsettings from "../config/appsettings.json"
import environment from '../config/environment.json'
import useApi from '../hooks/useApi';

const UserContext = createContext(null);
const UserUpdateContext = createContext(null);
const UserProfileContext = createContext(null);
const UserProfileUpdateContext = createContext(null);
const UserManagerContext = createContext(null);

const useUser = () => {
  return useContext(UserContext);
}

const useSetUser = () => {
  return useContext(UserUpdateContext);
}

const useUserProfile = () => {
  return useContext(UserProfileContext);
}

const useSetUserProfile = () => {
  return useContext(UserProfileUpdateContext);
}

const useUserManager = () => {
  return useContext(UserManagerContext);
}

const UserContextProvider = ({ children }) => {
  const api = useApi();
  const [userManager, setUserManager] = useState(new UserManager(appsettings[environment.Environment].user_manager_config));
  useEffect(() => {
    const onUserLoaded = (user) => {
      console.log(`user loaded`);
      setUser({ ...user });
    }

    const onUserUnloaded = () => {
      console.log(`user unloaded`)
    }

    const onAccessTokenExpiring = () => {
      console.log(`user token expiring`);
      userManager.signinSilent().then(user => {
        setUser(user);
      });
    }

    const onAccessTokenExpired = () => {
      console.log(`user token expired`);
    }

    const onUserSignedOut = () => {
      console.log(`user signed out`)
    }

    // events for user

    userManager.events.addUserLoaded(onUserLoaded)
    userManager.events.addUserUnloaded(onUserUnloaded)
    userManager.events.addAccessTokenExpiring(onAccessTokenExpiring)
    userManager.events.addAccessTokenExpired(onAccessTokenExpired)
    userManager.events.addUserSignedOut(onUserSignedOut)

    // Specify how to clean up after this effect:

    async function currentUserAsync() {
      let currentUser = await userManager.getUser();
      setUser(currentUser ? { ...currentUser } : null);
      if (currentUser) {
        //checking if the usertoken is already expired, if it is we log them out
        if (new Date(0).setUTCSeconds(currentUser.expires_at) < new Date()) {
          userManager.clearStaleState();
          userManager.removeUser();
          userManager.signoutRedirect({ 'id_token_hint': currentUser.id_token });
          return;
        }
        let res = await api(currentUser, `patients/${currentUser.profile.sub}`, "GET");
        let settingsResponse = await api(currentUser, `patients/${currentUser.profile.sub}/${res?.idTherapist}/settings`, "GET");

        setUserProfile({
          isCompleted: res?.isCompleted,
          idTherapist: res?.idTherapist,
          therapistName: res?.therapistFirstName,
          therapistLastName: res?.therapistLastName,
          firstName: res?.name,
          lastName: res?.lastName,
          isArchived: settingsResponse.ok === false || settingsResponse?.isArchived ,
        })

      }
      setLoading(false);
    }
    currentUserAsync();
    return function cleanup() {
      userManager.events.removeUserLoaded(onUserLoaded);
      userManager.events.removeUserUnloaded(onUserUnloaded);
      userManager.events.removeAccessTokenExpiring(onAccessTokenExpiring)
      userManager.events.removeAccessTokenExpired(onAccessTokenExpired)
      userManager.events.removeUserSignedOut(onUserSignedOut)
    };
  }, []);
  const [user, setUser] = useState(null);
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  return (
    <UserContext.Provider value={user}>
      <UserUpdateContext.Provider value={setUser}>
        <UserProfileContext.Provider value={userProfile}>
          <UserProfileUpdateContext.Provider value={setUserProfile}>
            <UserManagerContext.Provider value={userManager}>
              {!loading && children}
            </UserManagerContext.Provider>
          </UserProfileUpdateContext.Provider>
        </UserProfileContext.Provider>
      </UserUpdateContext.Provider>
    </UserContext.Provider>
  )
}

export default UserContextProvider;
export { UserContext, UserUpdateContext, UserManagerContext, useUser, useSetUser, useUserManager, useUserProfile, useSetUserProfile };