import { SvgIcon } from '@material-ui/core';
import React from 'react';

const TextIcon = props => {
    return (
        <SvgIcon {...props}>
            <path d="M17.25 20.25V11.25H13.5V9.75H22.5V11.25H18.75V20.25H17.25Z" fill="currentColor" />
            <path d="M8.25 20.25V6H1.5V4.5H16.5V6H9.75V20.25H8.25Z" fill="currentColor" />
        </SvgIcon>
    );
};
export default TextIcon;