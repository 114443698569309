import { SignalWifi1Bar, SignalWifi2Bar, SignalWifi3Bar } from "@material-ui/icons";
import React, { useMemo } from "react";
import { lerpArray } from "../utils/UtilsFunction";


const icons = [{
    color: "red",
    icon: SignalWifi1Bar
}, {
    color: "#fec821",
    icon: SignalWifi2Bar
}, {
    color: "green",
    icon: SignalWifi3Bar
}];


const NetworkResults = ({ value, size, min, max }) => {

    const Icon = useMemo(() => {
        return lerpArray(icons, min, max, value);
    }, [value, min, max, icons]);

    return (
        Icon ?
            <Icon.icon style={{ fontSize: size || "5rem", color: Icon.color }} /> : null
    );
};

export default NetworkResults;