import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import { Mic, MicOff, Visibility, VisibilityOff } from "@material-ui/icons";
import React, { useMemo } from "react";
import PsyAvatar from "../PsyAvatar";

const useStyles = makeStyles((theme) => ({
  generalContainer: {
    position: "relative",
    "& video": {
      zIndex: 2,
    },
  },
  avatarContainer: {
    width: "50%",
    [theme.breakpoints.up("sm")]: {
      width: "25%",
    },
    "& > div": {
      width: "100%",
      //this is used to mantain a 1/1 aspect-ratio
      paddingBottom: "100%",
      position: "relative",
    },
  },
  participantAvatar: {
    fontWeight: 600,
    position: "absolute",
    backgroundColor: "white",
    mixBlendMode: "overlay",
    fontSize: "2.5vmin",
    color: "black",
    width: "calc(100% - .25rem)",
    height: "calc(100% - .25rem)",
    border: ".125rem solid #00858B",
  },
  nameAndAction: {
    position: "absolute",
    marginLeft: ".5rem",
    right: ".5rem",
    bottom: ".5rem",
    background: "#333",
    display: "flex",
    alignItems: "center",
    padding: ".5em .75em",
    borderRadius: ".5rem",
    gap: ".75rem",
    zIndex: 3,
    opacity: 0.7,
    userSelect: "none",
    fontSize: "clamp(0.7rem, 1.7vmin, 1rem)",
    "&:hover": {
      opacity: 1,
    },
    "& svg": {
      fontSize: "1rem",
    },
    [theme.breakpoints.up("sm")]: {
      "& svg": {
        fontSize: "1.5rem",
      },
    },
  },
  speaking: {
    backgroundColor:"#000",
    "&::before": {
      outline: ".25rem solid #FFF500",
      outlineOffset: "-.25rem",
      position: "absolute",
      content: "''",
      zIndex: 3,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  hidden: {
    "& video": {
      display: "none",
    },
  },
}));

const ParticipantBox = ({
  participant,
  isOnline,
  className,
  isTherapist,
  isSpeaking,
  namesVisibility,
  onlineUsers,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  return !participant ? null : (
    <div
      className={`${className ? className : ""} ${classes.generalContainer} ${
        isSpeaking ? classes.speaking : ""
      }`}
      id={participant.id}
    >
      {isOnline ? (
        (matches || namesVisibility) && (
          <div className={classes.nameAndAction}>
            {isTherapist
              ? `${participant.name} ${participant.lastName}`
              : participant.name}
          </div>
        )
      ) : (
        <>
          <div className={classes.avatarContainer}>
            <div>
              <PsyAvatar
                className={classes.participantAvatar}
                firstName={participant.name}
                lastName={participant.lastName}
              />
            </div>
          </div>
          <div>
            {matches && (
              <div>
                {isTherapist
                  ? `${participant.name} ${participant.lastName}`
                  : participant.name}
              </div>
            )}
            {onlineUsers ? "" : <div>ASSENTE</div>}
          </div>
        </>
      )}
    </div>
  );
};
export default ParticipantBox;
