import { Dialog, DialogContent, DialogContentText, DialogTitle, makeStyles } from "@material-ui/core";
import React from "react";
import { Close, DeleteOutlined, Undo, ZoomIn, ZoomOut } from '@material-ui/icons/';
import GenogramIcon from './icons/GenogramIcon';
import NavigationIcon from './icons/NavigationIcon';
import Pencil from './icons/Pencil';
import ShapesIcon from './icons/ShapesIcon';
import AddImageIcon from './icons/AddImageIcon';
import TextIcon from "./icons/TextIcon";
import PaletteIcon from './icons/PaletteIcon';
import SelectionIcon from './icons/SelectionIcon';
import PanIcon from "./icons/PanIcon";
import BrushIcon from "./icons/BrushIcon";
import RubberIcon from './icons/RubberIcon';
import ColorIcon from "./icons/ColorIcon";
import FillIcon from "./icons/FillIcon";
import SettingsIcon from "./icons/SettingsIcon";

const useStyles = makeStyles(theme => ({
    titleDialog: {
        display: "flex",
        justifyContent: 'end',
        padding: '.5rem'
    },
    exitButton: {
        margin: "0",
        padding: "0",
        backgroundColor: 'transparent',
        border: 'none',
        cursor: "pointer",
    },
    contextWrapper: {
        padding: "0 2rem",
    },
    toolTitle: {
        display: 'flex',
        gap: '.8rem'
    },
    toolWrapper: {
        marginBottom: "1rem",
    },
    list: {
        listStyleType: "none",
        marginTop: "0.3rem",
        "& > li": {
            marginTop: ".7rem",
            lineHeight: "normal",
        }
    },

}));

const WhiteboardGuide = ({ open, close }) => {
    const styles = useStyles();

    return (
        <Dialog open={open}>
            <DialogTitle className={styles.titleDialog} >
                <button className={styles.exitButton} onClick={close}>
                    <Close />
                </button>
            </DialogTitle>
            <DialogContent className={styles.contextWrapper}>
                <DialogContentText>
                    <div className={styles.toolWrapper}>
                        <span className={styles.toolTitle}>
                            <GenogramIcon />
                            <b>Genogramma</b>
                        </span>
                        <div>
                            <span> Usa i simboli preimpostati per costruire il genogramma.</span>
                        </div>
                    </div>
                    <div>
                        <span className={styles.toolTitle}>
                            <NavigationIcon />
                            <b>Naviga</b>
                        </span>
                        <ul className={styles.list}>
                            <li>
                                <span className={styles.toolTitle}>
                                    <SelectionIcon />
                                    <b>Seleziona</b>
                                </span>
                                <div>
                                    <span> Clicca su un elemento per spostarlo o clicca e trascina per raggruppare più elementi e spostarli insieme.</span>
                                </div>
                            </li>
                            <li>
                                <span className={styles.toolTitle}>
                                    <PanIcon />
                                    <b>Sposta</b>
                                </span>
                                <div>
                                    <span> Clicca sulla lavagna e sposta il foglio virtuale nella direzione che preferisci.</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <span className={styles.toolTitle}>
                            <Pencil />
                            <b>Strumenti</b>
                        </span>
                        <ul className={styles.list}>
                            <li>
                                <span className={styles.toolTitle}>
                                    <BrushIcon />
                                    <b>Disegna</b>
                                </span>
                                <div>
                                    <span> Disegna trascinando il mouse come fosse una penna. Scegli il colore che preferisci.</span>
                                </div>
                            </li>
                            <li>
                                <span className={styles.toolTitle}>
                                    <RubberIcon />
                                    <b>Cancella elemento</b>
                                </span>
                                <div>
                                    <span> Seleziona un elemento. poi clicca su Cancella elemento per eliminarlo.</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className={styles.toolWrapper}>
                        <span className={styles.toolTitle}>
                            <ShapesIcon />
                            <b>Forme</b>
                        </span>
                        <div>
                            <span> Scegli la forma che preferisci e cliccando, trascina sulla lavagna per disegnarla. </span>
                        </div>
                    </div>
                    <div className={styles.toolWrapper}>
                        <span className={styles.toolTitle}>
                            <AddImageIcon />
                            <b>Immagini</b>
                        </span>
                        <div>
                            <span> Scegli l'immagine che vuoi dal tuo dispositivo per caricarla nella lavagna. Ingrandiscila o riducila quanto preferisci. </span>
                        </div>
                    </div>
                    <div className={styles.toolWrapper}>
                        <span className={styles.toolTitle}>
                            <TextIcon />
                            <b>Testo</b>
                        </span>
                        <div>
                            <span> Scrivi un testo. Scegli il colore e ingrandiscilo o riducilo quanto preferisci. </span>
                        </div>
                    </div>
                    <div>
                        <span className={styles.toolTitle}>
                            <PaletteIcon />
                            <b>Colore</b>
                        </span>
                        <div>
                            <ul className={styles.list}>
                                <li>
                                    <span className={styles.toolTitle}>
                                        <ColorIcon />
                                        <b>Colori</b>
                                    </span>
                                    <div>
                                        <span> Scegli il colore per ciascuna delle funzionalità disponibili.</span>
                                    </div>
                                </li>
                                <li>
                                    <span className={styles.toolTitle}>
                                        <FillIcon />
                                        <b>Riempi</b>
                                    </span>
                                    <div>
                                        <span> Clicca su un elemento per riempirlo del colore selezionato</span>
                                    </div>
                                </li>
                            </ul>
                            <div>
                                <span className={styles.toolTitle}>
                                    <SettingsIcon />
                                    <b>Opzioni</b>
                                </span>
                                <ul className={styles.list}>
                                    <li>
                                        <span className={styles.toolTitle}>
                                            <Undo />
                                            <b>Annulla</b>
                                        </span>
                                        <div>
                                            <span> Annulla l'ultima azione.</span>
                                        </div>
                                    </li>
                                    <li>
                                        <span className={styles.toolTitle}>
                                            <DeleteOutlined />
                                            <b>Cancella</b>
                                        </span>
                                        <div>
                                            <span> Cancella l'intera lavagna. Una volta eliminata, non sarà più possibile recuperarla</span>
                                        </div>
                                    </li>
                                    <li>
                                        <span className={styles.toolTitle}>
                                            <ZoomOut />
                                            <b>Zoom Indietro</b>
                                        </span>
                                        <div>
                                            <span> Allontana la lavagna per vederla nel suo insieme.</span>
                                        </div>
                                    </li>
                                    <li>
                                        <span className={styles.toolTitle}>
                                            <ZoomIn />
                                            <b>Zoom Avanti</b>
                                        </span>
                                        <div>
                                            <span> Avvicina la lavagna per vederne meglio una sezione.</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </DialogContentText>
            </DialogContent>
        </Dialog >
    );
};

export default WhiteboardGuide;