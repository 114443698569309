
import { SvgIcon } from '@material-ui/core';
import React from 'react';
const Pencil = props => {
    return (
        <SvgIcon {...props}>
            <path d="M4.38302 15.9982L16.62 3.76022C17.1035 3.29758 17.7488 3.04262 18.4179 3.04993C19.087 3.05725 19.7267 3.32625 20.1999 3.79934C20.6731 4.27243 20.9423 4.912 20.9498 5.5811C20.9573 6.2502 20.7025 6.89564 20.24 7.37921L8.00102 19.6172C7.72182 19.8964 7.36621 20.0868 6.97902 20.1642L3.05002 20.9502L3.83602 17.0202C3.91347 16.633 4.1038 16.2774 4.38302 15.9982V15.9982Z" stroke="currentColor" fill="none" stroke-width="1.20902" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M14.55 6.45021L17.55 9.45021" stroke="currentColor" fill="none" stroke-width="1.20902" />
        </SvgIcon>
    );
};
export default Pencil;
