import {
  makeStyles,
  MenuItem,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Settings, Videocam, VolumeUp } from "@material-ui/icons";
import React, { useState } from "react";
import { useUserProfile } from "../contexts/UserContext";
import GroupTherapyIcon from "./icons/GroupTherapyIcon";
import AdaptedViewIcon from "./icons/AdaptedViewIcon";
import FullViewIcon from "./icons/FullViewIcon";
import useMediaDevice from "../hooks/useMediaDevice";
import { useAgoraContext } from "../contexts/AgoraContext";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    display: "grid",
    gridTemplateColumns: "min-content 1fr",
  },
  tabs: {
    borderRight: "0.125rem solid rgba(255,255,255,.25)",
  },
  tabIcon: {
    "--size": "2.5rem",
    width: "var(--size)",
    height: "var(--size)",
    border: 0,
    outline: 0,
    color: "rgba(255,255,255,.4)",
    cursor: "pointer",
    background: "transparent",
  },
  active: {
    color: "white",
    boxShadow: "inset 0 0.25rem 0.625rem rgba(255, 255, 255, 0.2)",
    position: "relative",
    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      bottom: 0,
      right: 0,
      width: "0.125rem",
      background: "white",
    },
  },
  innerPanes: {
    padding: "1rem",
  },
  participantsContainer: {
    display: "grid",
    gridTemplateRows: "1fr min-content",
    height: "100%",
  },
  noWaitingRoom: {
    fontStyle: "italic",
    fontWeight: "normal",
    fontSize: "0.75rem",
  },
  waitingRoom: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  waitingRoomElement: {
    padding: "1rem 0",
    display: "grid",
    gridTemplateColumns: "max-content 1fr max-content",
    alignItems: "center",
    fontSize: "1rem",
    gap: "1rem",
  },
  waitingRoomAvatar: {
    fontSize: "0.625rem",
    width: "1.5rem",
    height: "1.5rem",
    backgroundColor: "white",
    color: "black",
    fontWeight: 600,
    border: `0.125rem solid ${theme.palette.primary.main}`,
    boxShadow: "0px 0.25rem 0.25rem 0 rgb(0, 0, 0, .25)",
  },
  admit: {
    cursor: "pointer",
  },
  admitLoader: {
    position: "absolute",
    display: "grid",
    placeItems: "center",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "0.25rem",
  },
  divider: {
    backgroundColor: "white",
    marginTop: "1rem",
  },
  participants: {
    marginTop: "2rem",
  },
  participant: {
    display: "grid",
    gridTemplateColumns: "1fr min-content min-content",
    gridAutoFlow: "dense",
    gap: ".5rem",
  },
  footerParticipants: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
    padding: "1rem",
    "& > a": {
      color: "white",
      fontSize: "0.75rem",
    },
  },
  copyLink: {
    backgroundColor: "rgba(255,255,255,.8)",
    color: "black",
    borderRadius: "10rem",
    textTransform: "none",
    padding: ".5rem",
    "&:hover": {
      backgroundColor: "rgba(255,255,255,1)",
      color: "black",
    },
  },
  title: {
    fontSize: "18px",
    fontWeight: 600,
    margin: "0",
    textAlign: "center",
    marginBottom: "1.5rem",
  },
  subtitle: {
    fontSize: "16px",
    fontWeight: 600,
    margin: "0",
    marginBottom: "1.5rem",
  },
  select: {
    display: "block",
    marginTop: "1rem",
    maxWidth: "12rem",
    color: "white",
    background: "rgba(255,255,255,.1)",
  },
  adornment: {
    marginRight: "1rem",
  },
  iconOutlinedStyles: {
    color: "white !important",
  },
  outlinedStyles: {
    color: "white !important",
    fontSize: "0.875rem",
  },
  labelStyles: {
    color: "white !important",
  },
  notchedOutlineStyles: {
    borderColor: "white !important",
  },
  hidden: {
    opacity: 0,
    pointerEvents: "none",
    height: 0,
    overflow: "hidden",
  },
  spaced: {
    marginTop: "2rem",
  },
  blankButton: {
    border: 0,
    margin: 0,
    padding: 0,
    background: "transparent",
    cursor: "pointer",
    color: "white",
    gridColumn: "-1",
  },
}));

const tabs = [GroupTherapyIcon, Settings];

const VideocallSidebar = ({
  currentView,
  setCurrentView,
  isTherapistVideoPlaying,
  videoCallSession,
  networkConditions,
  patient,
  changeVideoCallSettings,
  setSavedCamera,
}) => {
  const userProfile = useUserProfile();
  const styles = useStyles();
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesBelow1000px = useMediaQuery("@media (max-width:62.5rem)");
  const { cameraList, microphoneList } = useMediaDevice();
  const {
    currentMicrophone,
    setCurrentMicrophone,
    currentCamera,
    setCurrentCamera,
  } = useAgoraContext();

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        {tabs.map((TabIcon, id) => (
          <button
            onClick={() => setActiveTab(id)}
            className={`${styles.tabIcon} ${
              activeTab === id ? styles.active : ""
            }`}
            key={id}
          >
            <TabIcon />
          </button>
        ))}
      </div>
      <div className={styles.innerPanes}>
        <div
          className={`${styles.participantsContainer} ${
            activeTab !== 0 ? styles.hidden : ""
          }`}
        >
          <div>
            <h2 className={styles.title}>Partecipanti</h2>
            <div className={styles.participants}>
              <h3 className={styles.subtitle}>Pazienti (1):</h3>
              <div className={styles.participant}>
                <span>
                  {videoCallSession?.patientFirstName == "Guest" ? patient?.name : videoCallSession?.patientFirstName   } {" "}
                  { videoCallSession?.patientLastName === "Guest" ? patient?.surname : videoCallSession?.patientLastName}
                </span>
              </div>
              <h3 className={`${styles.subtitle} ${styles.spaced}`}>
                Terapeuta ({isTherapistVideoPlaying ? "1" : "0"}):
              </h3>
              <div className={`${styles.participant}`}>
                {isTherapistVideoPlaying ? (
                  <span>
                    {videoCallSession?.therapistFirstName}{" "}
                    {videoCallSession?.therapistLastname}
                  </span>
                ) : (
                  <span>Nessun terapeuta</span>
                )}
              </div>
            </div>
          </div>
          <div className={styles.footerParticipants}>
            {matchesBelow1000px && networkConditions()}
            <a
              href="https://www.psycare.it/risoluzione-problemi-paziente/"
              target="_blank"
            >
              Problemi audio/video?
            </a>
          </div>
        </div>
        <div className={activeTab !== 1 && styles.hidden}>
          <h2 className={styles.title}>Impostazioni</h2>
          <div style={{ marginBottom: "1.5rem" }}>
            <h3 className={styles.subtitle}>Vista</h3>
            <TextField
              SelectProps={{
                classes: {
                  outlined: styles.outlinedStyles,
                  iconOutlined: styles.iconOutlinedStyles,
                },
              }}
              InputProps={{
                classes: {
                  notchedOutline: styles.notchedOutlineStyles,
                  focused: styles.notchedOutlineStyles,
                  adornedStart: styles.outlinedStyles,
                },
                startAdornment:
                  currentView !== "contain" ? (
                    <AdaptedViewIcon className={styles.adornment} />
                  ) : (
                    <FullViewIcon className={styles.adornment} />
                  ),
              }}
              InputLabelProps={{
                classes: {
                  root: styles.labelStyles,
                },
              }}
              margin="dense"
              variant="outlined"
              className={styles.select}
              label="Vista"
              value={currentView}
              select
              fullWidth
              onChange={(e) => {
                setCurrentView(e.target.value);
              }}
            >
              <MenuItem value="contain">Originale</MenuItem>
              <MenuItem value="cover">Adatta a schermo</MenuItem>
            </TextField>
          </div>
          <div style={{ marginBottom: "1.5rem" }}>
            <h3 className={styles.subtitle}>Audio/Video</h3>
            <TextField
              SelectProps={{
                classes: {
                  outlined: styles.outlinedStyles,
                  iconOutlined: styles.iconOutlinedStyles,
                },
              }}
              InputProps={{
                classes: {
                  notchedOutline: styles.notchedOutlineStyles,
                  focused: styles.notchedOutlineStyles,
                  adornedStart: styles.outlinedStyles,
                },
                startAdornment: <Videocam className={styles.adornment} />,
              }}
              InputLabelProps={{
                classes: {
                  root: styles.labelStyles,
                },
              }}
              margin="dense"
              variant="outlined"
              className={styles.select}
              label="Fotocamera"
              key={`currentCamera-${!!currentCamera}`}
              value={currentCamera?.deviceId}
              select
              fullWidth
              onChange={(e) => {
                const newCamera = cameraList.find(
                  (camera) => camera.deviceId === e.target.value
                );
                if (!newCamera) return;
                setSavedCamera(currentCamera)
                setCurrentCamera(newCamera);
                changeVideoCallSettings(newCamera, "video");
              }}
            >
              {cameraList.map((videoInput) => (
                <MenuItem key={videoInput.deviceId} value={videoInput.deviceId}>
                  {videoInput.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              SelectProps={{
                classes: {
                  outlined: styles.outlinedStyles,
                  iconOutlined: styles.iconOutlinedStyles,
                },
              }}
              InputProps={{
                classes: {
                  notchedOutline: styles.notchedOutlineStyles,
                  focused: styles.notchedOutlineStyles,
                  adornedStart: styles.outlinedStyles,
                },
                startAdornment: <VolumeUp className={styles.adornment} />,
              }}
              InputLabelProps={{
                classes: {
                  root: styles.labelStyles,
                },
              }}
              margin="dense"
              variant="outlined"
              className={styles.select}
              label="Microfono"
              value={currentMicrophone?.deviceId}
              key={`currentMicrophone-${!!currentMicrophone}`}
              onChange={(e) => {
                const newMic = microphoneList.find(
                  (mic) => mic.deviceId === e.target.value
                );
                if (!newMic) return;
                setCurrentMicrophone(newMic);
                changeVideoCallSettings(newMic, "audio");
              }}
              select
              fullWidth
            >
              {microphoneList.map((audioInput) => (
                <MenuItem key={audioInput.deviceId} value={audioInput.deviceId}>
                  {audioInput.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>
      </div>
    </div>
  );
};
export default VideocallSidebar;
