import React, { useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { UserContext, useUserManager } from '../contexts/UserContext'

function SignoutOidc() {
    const userManager= useUserManager();
    //const { userManager } = useContext(UserContext)
    const history = useHistory()
    useEffect(() => {
        async function signoutRedirectCallback() {
            userManager.clearStaleState()
            userManager.removeUser()
            return userManager.signoutRedirectCallback()
        }
        signoutRedirectCallback()
        window.location="https://psycare.it";
    }, [history])

    return (
        <div>
            Redirecting...
        </div>
    )
}

export default SignoutOidc
