
import { SvgIcon } from '@material-ui/core';
import React from 'react';
const MoltoViciniIcon = props => {
        return (
                <SvgIcon {...props}>
                        <line x1="2" y1="13.5" x2="22" y2="13.5" stroke="currentColor" />
                        <line x1="2" y1="10.5" x2="22" y2="10.5" stroke="currentColor" />
                </SvgIcon>
        )
};
export default MoltoViciniIcon;
