import {
  Box,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Redirecting from "../components/Redirecting";
import { useGlobalSnackbar } from "../contexts/GlobalSnackbarContext";
import { useNotificationApi } from "../contexts/NotificationContext";
import {
  useUser,
  useUserProfile,
  useSetUserProfile,
} from "../contexts/UserContext";
import {
  useSetIsVideoCallModalOpened,
  useSetVideoCallSession,
} from "../contexts/VideoCallModalContext";
import useApi from "../hooks/useApi";
import { sha1 } from "../utils/UtilsFunction";

const Register = (props) => {
  const history = useHistory();
  const { therapist } = useParams();
  const api = useApi();
  const user = useUser();
  const userProfile = useUserProfile();
  const setUserProfile = useSetUserProfile();
  const setVideoCallSession = useSetVideoCallSession();
  const setIsVideoCallModalOpened = useSetIsVideoCallModalOpened();
  const [globalSnackbar, setGlobalSnackbar] = useGlobalSnackbar();
  const [error, setError] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const startVideoCall = useCallback(
    async (session) => {
      const ids = await api(
        user,
        `sessions/ids/${userProfile?.idTherapist}/${user?.profile?.sub}`
      );
      if (ids.ok !== false) {
        history.push(`/session/${ids.nanoId}`, session);
        return;
      }
      setGlobalSnackbar({
        open: true,
        severity: "error",
        message: "Impossibile avviare la videochiamata.",
      });
    },
    [user, userProfile]
  );
  const startVirtualRoomAsync = useCallback(async () => {
    //hash the id of the therapist and the id of the patient
    const hashedTherapistId = await sha1(
      `${userProfile.idTherapist}${user.profile.sub}`
    );
    //if the URL hash doesen't match the hashed therapist+patient id this meqans they are not allowed to enter the room
    if (therapist !== hashedTherapistId) {
      setError(true);
      return;
    }
    let settingsResponse = await api(
      user,
      `patients/${user?.profile?.sub}/${userProfile?.idTherapist}/settings`,
      "GET"
    );
    if (settingsResponse.ok === false) {
      setGlobalSnackbar({
        open: true,
        severity: "error",
        message: "Impossibile avviare la videoseduta",
      });
      history.replace("/home");
      return;
    }
    setUserProfile({ ...userProfile, isArchived: settingsResponse.isArchived });
    if (!settingsResponse.isArchived) {
      startVideoCall();
    } else {
      setGlobalSnackbar({
        open: true,
        severity: "error",
        message: "Impossibile avviare la videoseduta",
      });
      history.replace("/home");
    }
  }, [userProfile, user, startVideoCall, setUserProfile]);
  useEffect(() => {
    startVirtualRoomAsync();
  }, []);
  return error ? (
    <Box p={matches ? 12 : 3} textAlign="center">
      <Paper>
        <Box pt={3}>
          <Typography variant="h4">
            Impossibile accedere a questa stanza
          </Typography>
        </Box>
        <Box p={3}>
          È possibile accedere solamente alla stanza del proprio Terapeuta.
        </Box>
      </Paper>
    </Box>
  ) : (
    <Redirecting />
  );
};

export default Register;
