
import { SvgIcon } from '@material-ui/core';
import React from 'react';
const InformazioneMancanteIcon = props => {
        return (
                <SvgIcon {...props}>
                        <path d="M10.4987 19H12.8313V16.6667H10.4987V19ZM11.665 5C9.08759 5 7 7.08833 7 9.66667H9.3325C9.3325 8.38333 10.3821 7.33333 11.665 7.33333C12.9479 7.33333 13.9975 8.38333 13.9975 9.66667C13.9975 12 10.4987 11.7083 10.4987 15.5H12.8313C12.8313 12.875 16.33 12.5833 16.33 9.66667C16.33 7.08833 14.2424 5 11.665 5Z" />
                </SvgIcon>
        )
};
export default InformazioneMancanteIcon;
