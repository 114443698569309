
import { SvgIcon } from '@material-ui/core';
import React from 'react';
const UomoIcon = props => {
        return (
                <SvgIcon {...props}>
                        <rect x="2.5" y="2.5" width="19" height="19" fill="none" stroke="currentColor" />
                </SvgIcon>
        )
};
export default UomoIcon;
