import React from 'react';
import { useUserManager } from '../contexts/UserContext';

const useApi = () => {
    const userManager = useUserManager();
    const returnFunction = async (user, endpoint, method, body = null, isJson=true, needsAuth = true) => {
        const baseUrl = "/api/";
        let headers = new Headers();
        if(isJson){
            headers.append("Content-Type", "application/json");
        }
        if (needsAuth && user!=null) {
            headers.append("Authorization", `Bearer ${user.access_token}`);
        }
        let options = {
            method,
            body: body != null && method != "GET" ? JSON.stringify(body) : null,
            headers
        }
        if(body instanceof FormData){
            options.body=body;
        }
        let queryParameters = "";
        if (method == "GET" && body!=null) {
            let qp = new URLSearchParams();
            Object.entries(body).forEach((param) => {
                qp.append(param[0], param[1]);
            });
            queryParameters = `?${qp.toString()}`;
        }
        let res = await fetch(`${baseUrl}${endpoint}${queryParameters}`, options);
        if (res.status === 401 && userManager) {
            userManager.clearStaleState()
            userManager.removeUser()
            userManager.signoutRedirect({ 'id_token_hint': user.id_token })
        }
        if(res.status!=200 && res.status!=204){
            return {
                ok: false,
                message: await res.text()
            }
        }
        let json;
        try {
            json = await res.clone().json();
        }catch(e){
            json={
                ok: res.status==200 || res.status==204,
                message: await res.text()
            }
        }
        return json;
    };
    return returnFunction;
};

export default useApi;