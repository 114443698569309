import { Box, Paper, Typography, Link } from '@material-ui/core';
import { Gavel } from '@material-ui/icons';
import React, {useEffect} from 'react';

const links = [
    {
        href: "https://www.psycare.it/codice-deontologico-degli-psicologi-italiani/",
        title: "Codice Deontologico degli psicologi italiani"
    }, {
        href: "https://www.psycare.it/linee-guida-per-le-prestazioni-psicologiche-via-internet-e-a-distanza/",
        title: "Linee guida per le prestazioni psicologiche a distanza"
    },
]

const Norms = props => {

    useEffect(()=>{
        if(props.layoutOptions){
            props.layoutOptions({
                title: "Normative",
                disabled: false,
                Icon: Gavel,
            });
        }
    },[]);

    return (
        <>
            <span style={{ fontStyle: "italic" }}>In questa pagina può trovare tutti i riferimenti al Codice Deontologico e alle Linee Guida.</span>
            <Paper>
                <Box p={3} marginTop={3}>
                    <Typography>
                        <ul>
                            {
                                links.map(link => (
                                    <li key={link.href}>
                                        <Link href={link.href} target="_blank">{link.title}</Link>
                                    </li>
                                ))
                            }
                        </ul>
                    </Typography>
                </Box>
            </Paper>
        </>
    )
};

export default Norms;