import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import useApi from "../hooks/useApi";
import {
  useUser,
  useUserManager,
  useUserProfile,
} from "../contexts/UserContext";
import { Box, Paper, Typography } from "@material-ui/core";
import {
  useSetIsVideoCallModalOpened,
  useSetVideoCallSession,
} from "../contexts/VideoCallModalContext";
import { useGlobalSnackbar } from "../contexts/GlobalSnackbarContext";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Register = (props) => {
  const location = useQuery();
  const history = useHistory();
  const userProfile = useUserProfile();
  const token = location.get("invitationtoken");
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(true);
  const api = useApi();
  const userManager = useUserManager();
  const user = useUser();
  const setVideoCallSession = useSetVideoCallSession();
  const setIsVideoCallModalOpened = useSetIsVideoCallModalOpened();
  const [globalSnackbar, setGlobalSnackbar] = useGlobalSnackbar();
  const startVideoCall = useCallback(
    async (session) => {
      const ids = await api(
        user,
        `sessions/ids/${userProfile?.idTherapist}/${user?.profile?.sub}`
      );
      if (ids.ok !== false) {
        history.push(`/session/${ids.nanoId}`, session);
        return;
      }
      setGlobalSnackbar({
        open: true,
        severity: "error",
        message: "Impossibile avviare la videochiamata.",
      });
    },
    [user, userProfile]
  );
  useEffect(() => {
    async function verifyToken() {
      let jsonVerify = await api(
        null,
        `verifysessiontoken?sessiontoken=${token}`,
        "GET"
      );
      if (jsonVerify?.ok) {
        let session = await api(
          user,
          `sessions/${jsonVerify?.sessionInvitation?.idSession}`,
          "GET"
        );
        if (session.id) {
          startVideoCall(session);
        }
      }
      setVerified(jsonVerify.ok);
      setLoading(false);
    }
    verifyToken();
  }, []);
  return (
    <div>
      {!loading && !verified && (
        <Box p={12} textAlign="center">
          <Paper>
            <Box pt={3}>
              <Typography variant="h4">Token non valido</Typography>
            </Box>
            <Box p={3}>
              Il link non è valido. Si prega di contattare il proprio terapeuta
              per farsi invitare di nuovo.
            </Box>
          </Paper>
        </Box>
      )}
    </div>
  );
};

export default Register;
