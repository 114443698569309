
import { SvgIcon } from '@material-ui/core';
import React from 'react';
const DivorzioIcon = props => {
        return (
                <SvgIcon {...props}>
                        <line x1="2" y1="12.5" x2="22" y2="12.5" stroke="currentColor" />
                        <line x1="22.3333" y1="7" x2="22.3333" y2="13" stroke="currentColor" />
                        <line x1="2.5" y1="7" x2="2.5" y2="13" stroke="currentColor" />
                        <line y1="-0.5" x2="8.43349" y2="-0.5" transform="matrix(0.656179 -0.754606 0.656179 0.754606 8.08691 16)" stroke="currentColor" />
                        <line y1="-0.5" x2="8.43349" y2="-0.5" transform="matrix(0.656179 -0.754606 0.656179 0.754606 10.6957 16.364)" stroke="currentColor" />
                </SvgIcon>
        )
};
export default DivorzioIcon;
