import AgoraRTC from "agora-rtc-sdk-ng";
import { useEffect, useState } from "react";
import {
  useAgoraContext,
  useAgoraMediaListener,
} from "../contexts/AgoraContext";

const useMediaDevice = () => {
  const [cameraList, setCameraList] = useState<MediaDeviceInfo[]>([]);
  const [microphoneList, setMicrophoneList] = useState<MediaDeviceInfo[]>([]);
  const [playbackList, setPlaybackList] = useState<MediaDeviceInfo[]>([]);
  const {
    currentMicrophone,
    setCurrentMicrophone,
    currentCamera,
    setCurrentCamera,
  } = useAgoraContext();

  const getCameraList = async () => {
    const cameras = await AgoraRTC.getCameras();
    setCameraList(cameras ?? []);
    if (!currentCamera) {
      setCurrentCamera(cameras[0]);
    }
  };

  const getMicrophoneList = async () => {
    const microphones = await AgoraRTC.getMicrophones();
    setMicrophoneList(microphones ?? []);
    if (!currentMicrophone) {
      setCurrentMicrophone(microphones[0]);
    }
  };
  const getPlaybackDeviceList = async () => {
    const playbackDevices = await AgoraRTC.getPlaybackDevices();
    setPlaybackList(playbackDevices ?? {});
  };

  useAgoraMediaListener("cameraChanged", () => {
    getCameraList();
  });
  useAgoraMediaListener("microphoneChanged", () => {
    getMicrophoneList();
  });
  useAgoraMediaListener("playbackDeviceChanged", () => {
    getPlaybackDeviceList();
  });

  useEffect(() => {
    getCameraList();
    getMicrophoneList();
    getPlaybackDeviceList();
  }, []);

  return {
    cameraList,
    microphoneList,
    playbackList,
    currentCamera,
    currentMicrophone,
  };
};

export default useMediaDevice;
