import {
  Box,
  Button,
  CssBaseline,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { MoreVert, Refresh, Videocam } from "@material-ui/icons";
import { MTableActions } from "material-table";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import OpenWhiteboardIcon from "../components/icons/OpenWhiteboardIcon";
import PsyTable from "../components/PsyTable";
import {
  useUser,
  useUserProfile,
  useSetUserProfile,
} from "../contexts/UserContext";
import {
  useIsVideoCallModalOpened,
  useSetVideoCallSession,
  useSetIsVideoCallModalOpened,
  useVideoCallSession,
} from "../contexts/VideoCallModalContext";
import { useGlobalSnackbar } from "../contexts/GlobalSnackbarContext";
import { useNotificationApi } from "../contexts/NotificationContext";
import { usePopups } from "../contexts/PopupContext";
import useApi from "../hooks/useApi";
import {
  onlyDateFormatter,
  hoursFormatter,
  dateFormatter,
  differenceInMinutes,
  downloadFile,
  WHITEBOARD_PERMISSIONS,
} from "../utils/UtilsFunction";
import GroupCallIcon from "../components/icons/GroupCallIcon";
import PsyTabs from "../components/PsyTabs";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ChoseDownloadTypePopup from "../components/ChoseDownloadTypePopup";

//metodh to retrieve the start of a session given a session object
const getSessionStart = (session) => {
  //convert the sessionstart to date
  const therapistStartDate = new Date(`${session.sessionStart}Z`);
  //convert the patientSessionStart to date
  const patientStartDate = new Date(`${session.patientSessionStart}Z`);
  //check if both dates are valid date, this is made with the reduce function
  //the accumulator start as true and it loops through both dates
  //ANDing the value of !isNaN (which is true if the date is valid false otherwise)
  const isBothValidDates = [therapistStartDate, patientStartDate].reduce(
    (accumulator, current) => accumulator && !isNaN(current),
    true
  );
  //if both are valid dates return the highest of the two otherwise return null because is not started yet
  //(because one of the two dates is not yet set)
  return isBothValidDates
    ? new Date(Math.max(therapistStartDate, patientStartDate))
    : null;
};

//metodh to retrieve the end of a session given a session object
const getSessionEnd = (session) => {
  //convert the sessionend to date
  const therapistEndDate = new Date(`${session.sessionEnd}Z`);
  //convert the patientSessionStart to date
  const patientEndDate = new Date(`${session.patientSessionEnd}Z`);
  //check if both dates are valid date, this is made with the reduce function
  //the accumulator start as true and it loops through both dates
  //ANDing the value of !isNaN (which is true if the date is valid false otherwise)
  const isBothValidDates = [therapistEndDate, patientEndDate].reduce(
    (accumulator, current) => accumulator && !isNaN(current),
    true
  );
  //check if both dates are invalid date, this is made with the reduce function
  //the accumulator start as true and it loops through both dates
  //ANDing the value of isNaN (which is true if the date is valid false otherwise)
  //note that this is different from !isBothValidDates because it's not possible
  //to distinguish between a single or a double date being invalid
  const isBothInvalidDates = [therapistEndDate, patientEndDate].reduce(
    (accumulator, current) => accumulator && isNaN(current),
    true
  );
  //if both dates are valid return the minumun value
  if (isBothValidDates) {
    return new Date(Math.min(therapistEndDate, patientEndDate));
  }
  //if both are invalid the session does not have an end date yet
  if (isBothInvalidDates) {
    return null;
  }
  //if just one of the two dates is valid return the valid one
  //this means at leat one of the two has already closed the video call
  return !isNaN(therapistEndDate) ? therapistEndDate : patientEndDate;
};

const Sessions = (props) => {
  const history = useHistory();

  const [sessions, setSessions] = useState([]);
  const [genericError, setGenericError] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const setVideoCallSession = useSetVideoCallSession();
  const setIsVideoCallModalOpened = useSetIsVideoCallModalOpened();
  const isVideoCallModalOpened = useIsVideoCallModalOpened();
  const videoCallSession = useVideoCallSession();
  const user = useUser();
  const userProfile = useUserProfile();
  const setUserProfile = useSetUserProfile();
  const [globalSnackbar, setGlobalSnackbar] = useGlobalSnackbar();
  const joinSessionFromTable = useCallback(
    async (_, rowData) => {
      let settingsResponse = await api(
        user,
        `patients/${user?.profile?.sub}/${userProfile?.idTherapist}/settings`,
        "GET"
      );
      if (settingsResponse.ok === false) {
        setGlobalSnackbar({
          open: true,
          severity: "error",
          message:
            "Impossibile avviare la videoseduta. Contatta il tuo terapeuta.",
        });
        return;
      }
      ///Update context
      setUserProfile({
        ...userProfile,
        isArchived: settingsResponse.isArchived,
      });
      if (!settingsResponse.isArchived) {
        const ids = await api(
          user,
          `sessions/ids/${userProfile?.idTherapist}/${user?.profile?.sub}`
        );
        if (ids.ok !== false) {
          history.push(`/session/${ids.nanoId}`);
          return;
        }
        setGlobalSnackbar({
          open: true,
          severity: "error",
          message: "Impossibile avviare la videochiamata.",
        });
      } else {
        setGlobalSnackbar({
          open: true,
          severity: "error",
          message:
            "Impossibile avviare la videoseduta. Contatta il tuo terapeuta.",
        });
      }
    },
    [user, userProfile, setUserProfile]
  );
  const api = useApi();

  const [activeTab, setActiveTab] = useState(0);

  const [popups, setPopups] = usePopups();
  const [menuPosition, setMenuPosition] = useState(null);
  const [menuRowData, setMenuRowData] = useState(null);

  //This callback need to fetch the list of all group's sessions
  //Need to be change because now this callback retrieve only a list of owner groups (not sessions)
  const getGroupSessionsAsync = useCallback(async () => {
    setIsGroupSessionsLoading(true);
    let res = await api(user, "grouptherapies/grouptipologies", "GET");
    if (res.ok !== false) {
      setTypeOfGroups(res);
    } else {
      setGlobalSnackbar({
        open: true,
        severity: "error",
        message: "Impossibile caricare la lista delle tipologie di gruppo",
      });
    }
    let groupSessions = await api(
      user,
      `grouptherapies/${user?.profile?.sub}/groupshistory`,
      "GET"
    );
    if (groupSessions.ok === false) {
      setGlobalSnackbar({
        open: true,
        message: "Impossibile scaricare la lista di sessioni",
        severity: "error",
      });
    } else {
      setGroupSessionsData(groupSessions);
      setIsGroupSessionsLoading(false);
    }
  }, []);

  const [isGroupSessionsLoading, setIsGroupSessionsLoading] = useState(false);
  const [isListGroupTherapyDownload, setIsListGroupTherapyDownload] =
    useState(false);
  const [typeOfGroups, setTypeOfGroups] = useState([]);
  const [groupSessionsData, setGroupSessionsData] = useState([]);
  const [openTypeFile, setOpenTypeFile] = useState(false);
  const [selectedWhiteboard, setSelectedWhiteboard] = useState();
  const [order, setOrder] = useState({ orderDirection: "desc" });
  const [orderGroup, setOrderGroup] = useState({ orderDirection: "desc" });

  const handleOrderChange = (orderDirection) => {
    setOrder({ orderDirection });
  };
  const handleOrderChangeGroup = (orderDirection) => {
    setOrderGroup({ orderDirection });
  };

  // const downloadWhiteboardImage = useCallback(async () => {
  //   if (!user || !menuRowData) return;
  //   const imageResponse = await api(
  //     user,
  //     `sessions/${menuRowData.id}/whiteboard/image`,
  //     "GET"
  //   );
  //   if (imageResponse.ok !== false) {
  //     downloadFile({
  //       base64File: imageResponse?.image?.replace("data:image/png;base64,", ""),
  //       fileName: "Disegno.png",
  //     });
  //   } else {
  //     setGlobalSnackbar({
  //       open: true,
  //       severity: "error",
  //       message: "Impossibile scaricare l'immagine",
  //     });
  //   }
  //   setMenuRowData(null);
  // }, [user, menuRowData]);

  const getSessionsAsync = useCallback(async () => {
    setIsLoading(true);
    let response = await api(
      user,
      `sessions/patient/${user.profile.sub}/${userProfile.idTherapist}`,
      "GET"
    );
    if (response.ok === false) {
      setGenericError("Impossibile caricare le sessioni");
    } else {
      setSessions(response);
    }
    setIsLoading(false);
  }, [user, userProfile]);

  useEffect(() => {
    getSessionsAsync();
  }, []);
  //variable to skip the second refresh on mount
  //on mounting isVideoCallModalOpened changes to false
  //so the useEffect below runs. Since the useEffects
  //needs to run just when the modal closes this variable
  //will stop the unneeded refresh.
  const isFirstTime = useRef(true);
  useEffect(() => {
    if (isFirstTime.current) {
      isFirstTime.current = false;
      return;
    }
    if (!isVideoCallModalOpened) {
      getSessionsAsync();
    }
  }, [isVideoCallModalOpened]);

  useEffect(() => {
    if (activeTab === 0) {
      getSessionsAsync();
    } else if (activeTab === 1) {
      getGroupSessionsAsync();
    }
  }, [activeTab]);

  useEffect(() => {
    if (props.layoutOptions) {
      props.layoutOptions({
        title: "Lista sedute online",
        disabled: false,
        Icon: Videocam,
      });
    }
  }, []);

  return (
    <>
      <CssBaseline />
      <div>
        <PsyTabs
          indicatorColor="primary"
          textColor="primary"
          value={activeTab}
          variant="scrollable"
          scrollButtons="auto"
          onChange={(_, value) => {
            setActiveTab(value);
          }}
        >
          <Tab label="Sedute individuali" icon={<Videocam />} />
          <Tab label="Sedute di gruppo" icon={<GroupCallIcon />} />
        </PsyTabs>
        {genericError && (
          <Box width="100%" textAlign="center" color="red">
            {genericError}
          </Box>
        )}
        <Box hidden={activeTab !== 0}>
          <PsyTable
            title=""
            isLoading={isLoading}
            components={{
              Actions: (props) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <MTableActions {...props} />
                </div>
              ),
            }}
            columns={[
              {
                hideInDetailPanel: true,
                title: "Terapeuta",
                field: "therapistFirstName",
                sorting: false,
                render: (rowData) =>
                  `${rowData.therapistLastname} ${rowData.therapistFirstName}`,
              },
              {
                showOnMobile: true,
                mobileTitle: "Sedute effettuate",
                title: "Data",
                defaultSort: "desc",
                sorting: true,
                render: (rowData) => {
                  const sessionStart = getSessionStart(rowData);
                  return sessionStart
                    ? onlyDateFormatter.format(new Date(rowData.sessionStart))
                    : onlyDateFormatter.format(new Date(rowData.createdAt));
                },
                customSort: (a, b) => {
                  const dateA = a.sessionStart
                    ? new Date(a.sessionStart)
                    : new Date(a.createdAt);
                  const dateB = b.sessionStart
                    ? new Date(b.sessionStart)
                    : new Date(b.createdAt);
                  return dateA - dateB;
                },
                // render: (rowData) => {
                //   const sessionStart = getSessionStart(rowData);
                //   const sessionEnd = getSessionEnd(rowData);
                //   return sessionStart
                //     ? onlyDateFormatter.format(sessionStart)
                //     : sessionEnd
                //     ? "Seduta annullata"
                //     : "Non ancora iniziata";
                // },
                // customFilterAndSearch: (term, rowData) => {
                //   const sessionStart = getSessionStart(rowData);
                //   const sessionEnd = getSessionEnd(rowData);
                //   return (
                //     sessionStart
                //       ? hoursFormatter.format(sessionStart)
                //       : sessionEnd
                //       ? "Seduta annullata"
                //       : "Non ancora iniziata"
                //   )
                //     .toLowerCase()
                //     .includes(term.toLowerCase());
                // },
                mobileRender: (rowData) => {
                  const sessionStart = getSessionStart(rowData);
                  const sessionEnd = getSessionEnd(rowData);
                  const creationDate = onlyDateFormatter.format(
                    new Date(rowData.createdAt)
                  );
                  const toShow = sessionStart
                    ? dateFormatter.format(sessionStart)
                    : sessionEnd
                    ? "Seduta annullata"
                    : "Non ancora iniziata";
                  return (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Box>
                        {`${rowData.therapistLastname} ${rowData.therapistFirstName}`}
                      </Box>
                      {/* {sessionStart
                          ? hoursFormatter.format(sessionStart)
                          : sessionEnd
                          ? "Seduta annullata"
                          : "Non ancora iniziata"} */}
                      {toShow === "Seduta annullata" && (
                        <span>{creationDate}</span>
                      )}
                      <span>{toShow}</span>
                    </div>
                  );
                },
              },
              {
                title: "Ora di inizio",
                field: "sessionStart",
                sorting: false,
                render: (rowData) => {
                  const sessionStart = getSessionStart(rowData);
                  const sessionEnd = getSessionEnd(rowData);
                  return sessionStart
                    ? hoursFormatter.format(sessionStart)
                    : sessionEnd
                    ? "Seduta annullata"
                    : "Non ancora iniziata";
                },
                customFilterAndSearch: (term, rowData) => {
                  const sessionStart = getSessionStart(rowData);
                  const sessionEnd = getSessionEnd(rowData);
                  return (
                    sessionStart
                      ? hoursFormatter.format(sessionStart)
                      : sessionEnd
                      ? "Seduta annullata"
                      : "Non ancora iniziata"
                  )
                    .toLowerCase()
                    .includes(term.toLowerCase());
                },
              },
              {
                title: "Durata",
                sorting: false,
                width: "100px",
                customSort: (rowA, rowB) =>
                  differenceInMinutes(
                    getSessionStart(rowA),
                    getSessionEnd(rowA)
                  ) -
                  differenceInMinutes(
                    getSessionStart(rowB),
                    getSessionEnd(rowB)
                  ),
                render: (rowData) => {
                  const dim = differenceInMinutes(
                    getSessionStart(rowData),
                    getSessionEnd(rowData)
                  );
                  return `${dim} ${dim == 1 ? "minuto" : "minuti"}`;
                },
              },
            ]}
            data={sessions}
            options={{
              orderDirection: order.orderDirection,
              onOrderChange: handleOrderChange,
              thirdSortClick: false,
              exportButton: false,
              pageSize: 10,
              draggable: false,
              padding: "dense",
              actionsColumnIndex: -1,
              rowStyle: (row) => {
                //if the id of the row is the same as the videoCall
                //saved globally it will hiligh the row
                if (row.id === videoCallSession?.id) {
                  return { backgroundColor: "#00858b4d" };
                }
              },
            }}
            actions={[
              {
                icon: () => <Refresh style={{ color: "white" }} />,
                tooltip: "Aggiorna",
                isFreeAction: true,
                onClick: getSessionsAsync,
              },
              (rowData) => ({
                //it shows a button to open the video call modal
                //only if session start is null
                icon: () => (
                  <Button size="small" color="primary" variant="contained">
                    INIZIA
                  </Button>
                ),
                onClick: joinSessionFromTable,
                hidden:
                  getSessionStart(rowData) != null ||
                  getSessionEnd(rowData) != null ||
                  !userProfile ||
                  userProfile.isArchived,
              }),
              (rowData) => {
                const sessionStart = getSessionStart(rowData);
                const isHidden =
                  !sessionStart ||
                  rowData.permissions === WHITEBOARD_PERMISSIONS.NOWHITEBOARD ||
                  rowData.permissions === WHITEBOARD_PERMISSIONS.LOCKED;
                return {
                  icon: OpenWhiteboardIcon,
                  iconProps: {
                    style: {
                      opacity: isHidden ? 0 : 1,
                    },
                  },
                  disabled: isHidden,
                  onClick: () => {
                    setPopups((prev) => ({
                      ...prev,
                      whiteboardPopup: {
                        open: true,
                        session: rowData,
                      },
                    }));
                  },
                  tooltip: isHidden ? null : "Lavagna virtuale",
                };
              },
              (rowData) => ({
                icon: MoreVert,
                disabled:
                  !rowData.haveImage ||
                  rowData.permissions === WHITEBOARD_PERMISSIONS.LOCKED,
                onClick: (e) => {
                  const getBoundingClientRect =
                    e.currentTarget.getBoundingClientRect();
                  setMenuPosition({
                    top: getBoundingClientRect.top,
                    left: getBoundingClientRect.left,
                  });
                  setMenuRowData(rowData);
                },
              }),
            ]}
            localization={{
              header: {
                actions: "",
              },
              body: {
                emptyDataSourceMessage: (
                  <>
                    Non hai ancora effettuato nessuna seduta.
                    <br />
                    Quando avrai cominciato, troverai qui la cronologia delle
                    tue sedute!
                  </>
                ),
              },
              toolbar: {
                searchPlaceholder: "Cerca sedute...",
              },
              pagination: {
                labelDisplayedRows: "{from}-{to} di {count}",
                firstTooltip: "Prima pagina",
                lastTooltip: "Ultima pagina",
                nextTooltip: "Successiva",
                previousTooltip: "Precedente",
              },
            }}
          />
        </Box>
        <Box hidden={activeTab !== 1}>
          <PsyTable
            isLoading={isGroupSessionsLoading || isListGroupTherapyDownload}
            components={{
              Actions: (props) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <MTableActions {...props} />
                </div>
              ),
            }}
            columns={[
              // {
              //   showOnMobile: true,
              //   title: "",
              //   render: (rowData, userProfile) => (
              //       <Tooltip title="Inizia la seduta">
              //       <IconButton
              //         onClick={() => {
              //           joinSessionFromTable(false)
              //         }}
              //       >
              //         <Videocam
              //           style={{
              //             color: "black",
              //           }}
              //         />
              //       </IconButton>
              //     </Tooltip>
              //   ),
              //   sorting: false,
              //   cellStyle: {
              //     padding: "0 5px",
              //     width: "2%",
              //   },
              // },
              {
                title: "Nome",
                mobileTitle: "Nome",
                sorting: false,
                customSort: (nameA, nameB) =>
                  `${nameA.name}`.toLowerCase() < `${nameB.name}`.toLowerCase()
                    ? 1
                    : -1,
                customFilterAndSearch: (term, rowData) =>
                  `${rowData?.name || ""}`
                    .toLowerCase()
                    .includes(term.toLowerCase()),
                showOnMobile: true,
                mobileRender: (rowData) => {
                  return (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {`${rowData.name || ""}`}
                      <span>
                        {rowData.groupStartDate
                          ? onlyDateFormatter.format(
                              new Date(`${rowData.groupStartDate}Z`)
                            )
                          : "Non ancora iniziata"}
                      </span>
                    </div>
                  );
                },
                render: (rowData) => rowData.name || "",
              },
              {
                title: "Data di inizio",
                sorting: true,
                defaultSort: "desc",
                hideInDetailPanel: true,
                field: "groupStartDate",
                render: (rowData) =>
                  rowData.groupStartDate
                    ? onlyDateFormatter.format(
                        new Date(`${rowData.groupStartDate}Z`)
                      )
                    : "Non ancora iniziata",
              },
              {
                title: "Ora di inizio",
                sorting: false,
                field: "groupStartDate",
                render: (rowData) =>
                  rowData.groupStartDate
                    ? hoursFormatter.format(
                        new Date(`${rowData.groupStartDate}Z`)
                      )
                    : "-",
              },
              // {
              //   title: "Ora di fine",
              //   sorting: false,
              //   render: (rowData) =>
              //     rowData.groupEndDate
              //       ? hoursFormatter.format(
              //           new Date(`${rowData.groupEndDate}Z`)
              //         )
              //       : "-",
              // },
              {
                title: "Durata",
                sorting: false,
                render: (rowData) => {
                  const minutes = differenceInMinutes(
                    rowData.groupStartDate,
                    rowData.groupEndDate
                  );
                  return `${minutes} ${minutes === 1 ? "minuto" : "minuti"}`;
                },
              },
              {
                title: "Tipologia",
                sorting: false,
                render: (rowData) =>
                  typeOfGroups?.filter(
                    (e) => e.idTipology == rowData?.typology
                  )[0]?.tipologyName || "-",
              },
            ]}
            data={groupSessionsData}
            options={{
              search: true,
              orderDirection: orderGroup.orderDirection,
              onOrderChange: handleOrderChangeGroup,
              thirdSortClick: false,
              exportButton: false,
              pageSize: 10,
              draggable: false,
              padding: "dense",
              actionsColumnIndex: -1,
              //searchFieldAlignment: 'left',
              rowStyle: (row) => {
                //if the id of the row is the same as the videoCall
                //saved globally it will hiligh the row
                if (row.id === videoCallSession?.id) {
                  return { backgroundColor: "#00858b4d" };
                }
              },
            }}
            actions={[
              {
                icon: () => <Refresh style={{ color: "white" }} />,
                tooltip: "Aggiorna",
                isFreeAction: true,
                onClick: getGroupSessionsAsync,
              },
            ]}
            localization={{
              header: {
                actions: "",
              },
              body: {
                emptyDataSourceMessage: (
                  <>
                    Non hai ancora effettuato nessuna seduta di gruppo.
                    <br />
                    Quando avrai cominciato, troverai qui la cronologia delle
                    tue sedute!
                  </>
                ),
              },
              toolbar: {
                searchPlaceholder: "Cerca per gruppo...",
              },
              pagination: {
                labelDisplayedRows: "{from}-{to} di {count}",
                firstTooltip: "Prima pagina",
                lastTooltip: "Ultima pagina",
                nextTooltip: "Successiva",
                previousTooltip: "Precedente",
              },
            }}
          />
        </Box>
        <Menu
          PaperProps={{
            style: {
              maxHeight: 200,
            },
          }}
          open={Boolean(menuRowData)}
          anchorReference="anchorPosition"
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          anchorPosition={menuPosition}
          onClose={() => {
            setMenuRowData(null);
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: ".5rem",
                paddingRight: ".75rem",
              }}
            >
              <p style={{ paddingLeft: "1rem", fontWeight: "600" }}>
                Storico Lavagne
              </p>
              <Tooltip title="Aggiornare la pagina per visualizzare una lavagna appena salvata">
                <InfoOutlinedIcon />
              </Tooltip>
            </div>
            {menuRowData?.haveImage &&
              menuRowData?.whiteboardList?.map((element) => {
                return (
                  <MenuItem
                    value={element?.createdAt}
                    key={element?.lastSaved}
                    onClick={() => {
                      setOpenTypeFile(true);
                      setSelectedWhiteboard(element);
                    }}
                  >
                    {dateFormatter.format(new Date(`${element?.lastSaved}Z`))}
                  </MenuItem>
                );
              })}
          </div>
        </Menu>
      </div>
      <ChoseDownloadTypePopup
        idDocument={menuRowData?.id}
        patientName={menuRowData?.patientFirstName}
        patientSurname={menuRowData?.patientLastName}
        whiteboard={selectedWhiteboard}
        whiteboardId={selectedWhiteboard?.id}
        isManual={selectedWhiteboard?.isManual}
        date={
          selectedWhiteboard?.lastSaved != null
            ? dateFormatter.format(
                new Date(`${selectedWhiteboard?.lastSaved}Z`)
              )
            : ""
        }
        isOpen={openTypeFile}
        onClose={() => {
          setOpenTypeFile(false);
        }}
      />
    </>
  );
};

export default Sessions;

/*
<Typography variant="h6" paragraph>
                <Box fontStyle="italic" borderBottom="1px solid">
                    Lista sedute online
                </Box>
            </Typography>
*/
