import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useGlobalSnackbar } from "../contexts/GlobalSnackbarContext";
import { usePopups } from "../contexts/PopupContext";
import { useUser } from "../contexts/UserContext";
import useApi from "../hooks/useApi";
import { WHITEBOARD_PERMISSIONS } from "../utils/UtilsFunction";
import PsyWhiteboard from "./PsyWhiteboard";
import {
  useSignaling,
  useSignalingListener,
} from "../contexts/SignalingContext";

const useStyles = makeStyles({
  paperWidthFalse: {
    width: "97%",
    height: "97%",
    maxWidth: "unset",
    maxHeight: "unset",
    margin: "unset",
  },
  noPaddingContent: {
    padding: 0,
  },
  loading: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "grid",
    placeItems: "center",
    backgroundColor: "rgba(255,255,255,.7)",
    zIndex: 9,
  },
});
const WhiteboardPopup = () => {
  const signaling = useSignaling();
  const [{ whiteboardPopup }, setPopups] = usePopups();
  const [jsonCanvas, setJsonCanvas] = useState();
  const [loading, setLoading] = useState(false);
  const [globalSnackbar, setGlobalSnackbar] = useGlobalSnackbar();
  const [session, setSession] = useState();
  const user = useUser();
  const styles = useStyles();
  const api = useApi();
  const psyWhiteboardRef = useRef();

  useSignalingListener(
    "whiteboardpopup:update-canvas",
    (data) => {
      if (data.idSession === whiteboardPopup?.session?.id) {
        setJsonCanvas(data.canvas);
      }
    },
    [whiteboardPopup?.session?.id]
  );

  useEffect(() => {
    if (!whiteboardPopup.open || !user) return;
    const initSession = async () => {
      setLoading(true);
      const canvasResponse = await api(
        user,
        `sessions/${whiteboardPopup?.session?.id}/whiteboard/json`,
        "GET"
      );
      const canvasError = {
        open: true,
        severity: "error",
        message: "Impossibile recuperare la whiteboard.",
      };
      if (canvasResponse.ok !== false) {
        try {
          setJsonCanvas(JSON.parse(canvasResponse.whiteboard));
        } catch (e) {
          setGlobalSnackbar(canvasError);
        }
      } else {
        setGlobalSnackbar(canvasError);
      }
      setLoading(false);
    };
    initSession();
    return () => {
      setJsonCanvas({});
    };
  }, [whiteboardPopup?.open, user, whiteboardPopup?.session?.id]);

  const onExit = useCallback(() => {
    if (whiteboardPopup?.session && psyWhiteboardRef?.current) {
      api(
        user,
        `sessions/${whiteboardPopup?.session?.id}/whiteboard/image`,
        "PATCH",
        {
          sessionId: whiteboardPopup?.session?.id,
          image: psyWhiteboardRef?.current?.getWhiteboardPng(),
        }
      );
    }
    setPopups((prev) => ({
      ...prev,
      whiteboardPopup: {
        ...prev.whiteboardPopup,
        open: false,
      },
    }));
  }, [whiteboardPopup?.session, user, setPopups]);

  return (
    <Dialog
      classes={styles}
      disableBackdropClick
      disableEscapeKeyDown
      open={whiteboardPopup?.open && whiteboardPopup?.session}
      scroll="paper"
      fullWidth
      maxWidth={false}
    >
      <DialogContent
        classes={{
          root: styles.noPaddingContent,
        }}
      >
        {loading && (
          <div className={styles.loading}>
            <CircularProgress />
          </div>
        )}
        {whiteboardPopup?.open && (
          <PsyWhiteboard
            onExit={onExit}
            ref={psyWhiteboardRef}
            readOnly={
              whiteboardPopup?.session?.permissions !==
              WHITEBOARD_PERMISSIONS.CANMODIFY
            }
            jsonCanvas={jsonCanvas}
            onCanvasUpdate={(json, skipValue) => {
              if (!skipValue) {
                setJsonCanvas(json);
              }
              json.idSession = whiteboardPopup?.session?.id;
              try {
                signaling.send(
                  whiteboardPopup?.session?.idTherapist,
                  "whiteboardpopup:update-canvas",
                  {
                    canvas: json,
                    idSession: whiteboardPopup?.session?.id,
                  }
                );
              } catch (e) {}
              if (whiteboardPopup?.session) {
                api(
                  user,
                  `sessions/${whiteboardPopup?.session?.id}/whiteboard/json`,
                  "PATCH",
                  {
                    sessionId: whiteboardPopup?.session?.id,
                    whiteboard: JSON.stringify(json),
                  }
                );
              }
            }}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};
export default WhiteboardPopup;
