import { SvgIcon } from '@material-ui/core';
import React from 'react';

const BrushIcon = props => {
    return (
        <SvgIcon {...props}>
            <path d="M16.6098 0.0225017C15.982 0.0225017 15.3498 0.275252 14.875 0.750002L5.359 10.125L4.891 10.5705C4.3285 10.6755 3.79075 10.953 3.367 11.4143C2.74225 12.0953 2.62075 12.9293 2.383 13.8278C2.1445 14.7248 1.843 15.7223 1.117 16.8278L0.367004 17.9993H1.75C4.68625 17.9993 6.62125 16.5968 7.60975 15.6083L7.63225 15.5858C8.0845 15.123 8.33725 14.5395 8.4295 13.9448L8.8045 13.5923L18.3205 4.21725V4.19475C18.7766 3.73741 19.0327 3.11788 19.0327 2.472C19.0327 1.82612 18.7766 1.2066 18.3205 0.749252C17.845 0.274502 17.2368 0.0217514 16.609 0.0217514L16.6098 0.0225017ZM16.6098 1.5C16.8445 1.5 17.0658 1.605 17.266 1.8045C17.6658 2.205 17.6658 2.7405 17.266 3.141L10.492 9.84375L9.133 8.484L15.9295 1.8045C16.1298 1.605 16.3743 1.5 16.6098 1.5ZM8.05375 9.54L9.4135 10.8975L8.2885 12L6.928 10.6403L8.053 9.53925L8.05375 9.54ZM5.4775 12.0225C5.88537 12.037 6.27501 12.1951 6.57775 12.4688C7.16125 13.0028 7.17475 13.9343 6.55525 14.5538C5.90275 15.2063 4.74925 16.0433 3.0625 16.359C3.4225 15.5715 3.67375 14.8365 3.835 14.2268C4.0855 13.2855 4.2685 12.6398 4.46875 12.4215C4.729 12.1365 5.09725 12.012 5.47675 12.0225H5.4775Z" fill="currentColor" />
        </SvgIcon>
    );
};
export default BrushIcon;