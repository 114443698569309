import React, { useEffect } from 'react';
import Redirecting from '../components/Redirecting';
import { useUserManager } from '../contexts/UserContext';

const RedirectToIdentity = props => {
    const userManager = useUserManager();

    useEffect(() => {
        userManager.signinRedirect({
            extraQueryParams: {
                isPatient: true
            }
        });
    }, [])

    return (
        <Redirecting />
    )
};

export default RedirectToIdentity;