import {
  Box,
  CircularProgress,
  Checkbox,
  Fade,
  IconButton,
  Paper,
  Snackbar,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import { Refresh } from "@material-ui/icons";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import GetAppIcon from "@material-ui/icons/GetApp";
import { Alert } from "@material-ui/lab";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { FileField } from "../components";
import AlertDialog from "../components/AlertDialog";
import PsyTable from "../components/PsyTable";
import { useUser, useUserProfile } from "../contexts/UserContext";
import useApi from "../hooks/useApi";
import { dateFormatter, acceptedExtensions } from "../utils/UtilsFunction";
import PsyTabs from "../components/PsyTabs";
import SignDocumentIcon from "../components/icons/SignDocumentIcon";
import ContactPageIcon from "../components/icons/ContactPageIcon";
import CloseIcon from "@material-ui/icons/Close";
import { useGlobalSnackbar } from "../contexts/GlobalSnackbarContext";

const getLoaderName = (loader) => {
  return `${loader.userFromName} ${loader.userFromSurname}`;
};

const tableLocalization = {
  body: {
    emptyDataSourceMessage: "Nessun documento...",
  },
  toolbar: {
    searchTooltip: "",
    searchPlaceholder: "Cerca documenti...",
  },
  header: {
    actions: "",
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count}",
    firstTooltip: "Prima pagina",
    lastTooltip: "Ultima pagina",
    nextTooltip: "Successiva",
    previousTooltip: "Precedente",
    labelRowsSelect: "righe",
  },
};

const getLocalization = (emptyDataSourceMessage) => ({
  ...tableLocalization,
  body: { ...tableLocalization.body, emptyDataSourceMessage },
});

//method to start the download of a file upon receiving a RawDocumentDTO
let downloadFile = (rawFile) => {
  //converting the base64 string to a byt array
  const bytes = Uint8Array.from(atob(rawFile.base64File), (c) =>
    c.charCodeAt(0)
  );
  //creating the new blob
  let blob = new Blob([bytes]);
  //creating a ficticious a element that will never be added to the page
  let link = document.createElement("a");
  //setting the blob url as the href
  link.href = window.URL.createObjectURL(blob);
  //setting the download name
  link.download = rawFile.fileName;
  //programmatically clicking on it to start the download
  link.click();
};

const Documents = (props) => {
  const user = useUser();
  const userProfile = useUserProfile();
  const api = useApi();
  const history = useHistory();
  const [isDocumentsLoading, setIsDocumentsLoading] = useState(false);
  const [isDocumentUploading, setIsDocumentUploading] = useState(false);
  const [generalError, setGeneralError] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [apiError, setApiError] = useState("");
  const [alert, setAlert] = useState({
    open: false,
  });
  const [documents, setDocuments] = useState([]);
  const [signedDocuments, setSignedDocuments] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
  });
  const [files, setFiles] = useState(null);

  const [order, setOrder] = useState({ orderDirection: "desc" });
  const [orderSigned, setOrderSigned] = useState({ orderDirection: "desc" });

  const handleOrderChange = (orderDirection) => {
    setOrder({ orderDirection });
  };
  const handleOrderChangeSigned = (orderDirection) => {
    setOrder({ orderDirection });
  };

  const isExtensionsOk = useMemo(() => {
    if (!files || files.length === 0) return false;
    return [...files].reduce(
      (isOk, file) =>
        isOk &&
        acceptedExtensions.includes(
          file.name.substring(file.name.lastIndexOf(".")).toLowerCase()
        ),
      true
    );
  }, [files]);
  const isFileNameOk = useMemo(() => {
    if (!files || files.length === 0) return false;
    return [...files].reduce(
      (isOk, file) => isOk && file.name.length < 100,
      true
    );
  }, [files]);

  const isDimensionsOk = useMemo(() => {
    if (!files || files.length === 0) return false;
    return [...files].reduce(
      (isOk, file) => isOk && file.size / 1000000 < 8,
      true
    );
  }, [files]);
  const [dialog, setDialog] = useState(false);

  const [isSelected, setIsSelected] = useState([]);
  const [allDocument, setAllDocument] = useState(false);
  const [_, setGlobalSnackbar] = useGlobalSnackbar();

  /**
   * Method to download a document from the table
   * @param {*} event - The event returned from material table
   * @param {*} rowData - The row data, in this case a document
   */
  const downloadDocument = useCallback(
    async (event, rowData) => {
      //reset the errors and show a popup informing of the download of the file
      setGeneralError("");
      setApiError("");
      setSnackbar({
        open: true,
        message: `Il download del file ${rowData.fileName} partirà a breve.`,
      });
      //the base endpoint is to download a document
      let endpoint = `documents/download/${rowData.id}`;
      //in case the active tab is 1 we are downloading a signed document
      if (activeTab === 2) {
        endpoint = `signeddocuments/download/${rowData.id}`;
      }
      //get the base 64 of the file
      const rawFile = await api(user, endpoint, "GET");
      if (rawFile.ok !== false) {
        //if it's a successful request start the download of the file
        downloadFile(rawFile);
      } else {
        //otherwise set the errors
        setGeneralError("Impossibile scaricare il file. Riprovare in seguito");
        setApiError(rawFile.message);
      }
    },
    [activeTab]
  );

  const refreshDocuments = useCallback(async () => {
    if (!user?.profile?.sub || !userProfile) {
      return;
    }
    setGeneralError("");
    setIsSelected([]);
    setAllDocument(false);
    setApiError("");
    setIsDocumentsLoading(true);
    //the base endpoint is to retrieve the documents
    let endpoint = `documents/${userProfile.idTherapist}/${
      user.profile.sub
    }?isPersonal=${activeTab === 1 ? "true" : "false"}`;
    let updateDocumentsFunction = setDocuments;
    if (activeTab === 2) {
      //if active tab is 1 we retrieve the signed documents
      endpoint = `signeddocuments/${userProfile.idTherapist}/${user.profile.sub}`;
      updateDocumentsFunction = setSignedDocuments;
    }
    const response = await api(user, endpoint);
    if (response.ok !== false) {
      updateDocumentsFunction(response);
    } else {
      setGeneralError(
        `Impossibile caricare la lista dei documenti. Riprovare in seguito.`
      );
      setApiError(response.message);
    }
    setIsDocumentsLoading(false);
  }, [user, userProfile, activeTab]);

  /**
   * Refresh the documents every time activeTab changes.
   */
  useEffect(() => {
    refreshDocuments();
  }, [activeTab]);

  useEffect(() => {
    if (props.layoutOptions) {
      props.layoutOptions({
        title: "Documenti",
        disabled: false,
        Icon: DescriptionIcon,
      });
    }
  }, []);

  //TO DO: Change EP
  const downloadDocuments = useCallback(
    async (documentsList) => {
      let res;
      setIsDocumentsLoading(true);
      if (activeTab === 2) {
        res = await api(
          user,
          "signeddocuments/download/multi/zip",
          "POST",
          documentsList
        );
      } else {
        res = await api(
          user,
          "documents/download/multi/zip",
          "POST",
          documentsList
        );
      }

      if (res.ok !== false) {
        setIsDocumentsLoading(false);
        setGlobalSnackbar({
          open: "true",
          severity: "success",
          message: "Il file è stato scaricato",
        });
        setIsSelected([]);
        setAllDocument(false);
        downloadFile(res);
      } else {
        setIsDocumentsLoading(false);
        setGlobalSnackbar({
          open: "true",
          severity: "error",
          message: "Non è stato possibile scaricare il file.",
        });
      }
    },
    [activeTab]
  );

  return (
    <>
      <CssBaseline />
      <Container style={{ paddingLeft: 0, paddingRight: 0, maxWidth: "unset" }}>
        <Box color="red" width="100%" textAlign="center">
          <Typography>{generalError}</Typography>
        </Box>
        <Box color="red" width="100%" textAlign="center">
          <Typography>{apiError}</Typography>
        </Box>

        <Paper elevation={0}>
          <PsyTabs
            indicatorColor="primary"
            textColor="primary"
            value={activeTab}
            variant="scrollable"
            scrollButtons="auto"
            onChange={(_, value) => {
              setActiveTab(value);
            }}
          >
            <Tab icon={<DescriptionIcon />} label="Documenti Condivisi" />
            <Tab icon={<ContactPageIcon />} label="Documenti di identità" />
            <Tab icon={<SignDocumentIcon />} label="Documenti Firmati" />
          </PsyTabs>
          <Box p={2}>
            {activeTab !== 2 ? (
              <Button
                size="small"
                onClick={() => {
                  setDialog(true);
                }}
                variant="contained"
                color="primary"
                startIcon={<AddCircleOutlineIcon />}
              >
                Aggiungi documento
              </Button>
            ) : (
              <span style={{ padding: "4px" }}>
                In questa sezione si troveranno tutti i documenti firmati
                digitalmente
              </span>
            )}
          </Box>
          {isSelected.length > 0 && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Tooltip title="Deseleziona tutti">
                <IconButton
                  onClick={() => {
                    setAllDocument(false);
                    setIsSelected([]);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <p>Elementi selezionati: {isSelected?.length}</p>
              <Tooltip title="scarica documenti selezionati in .zip">
                <IconButton
                  onClick={() => {
                    downloadDocuments(isSelected);
                  }}
                >
                  <GetAppIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
          <div
            style={{ display: activeTab !== 2 ? "" : "none", padding: "16px" }}
          >
            <PsyTable
              isLoading={isDocumentsLoading}
              components={{
                Container: (props) => <div>{props.children}</div>,
              }}
              columns={[
                {
                  title: (
                    <Checkbox
                      checked={
                        allDocument && isSelected.length == documents.length
                      }
                      onChange={(e) => {
                        const check = e.target.checked;
                        if (check) {
                          const newDocumentList = documents.map((el) => el.id);
                          setIsSelected(newDocumentList);
                        } else {
                          setIsSelected([]);
                        }
                        setAllDocument(check);
                      }}
                      style={{
                        color: "#fff",
                        display: documents.length < 1 ? "none" : "",
                      }}
                    />
                  ),
                  hideInDetailPanel: true,
                  field: "id",
                  width: "1%",
                  sorting: false,
                  render: (rowData) => (
                    <Checkbox
                      checked={isSelected.includes(rowData.id)}
                      color="primary"
                      value={rowData.id}
                      onChange={(e) => {
                        if (isSelected.includes(e.target.value)) {
                          const newList = isSelected.filter(
                            (el) => el !== e.target.value
                          );
                          setIsSelected(newList);
                        } else {
                          setIsSelected((prev) => [...prev, e.target.value]);
                        }
                      }}
                    />
                  ),
                },
                {
                  title: "Nome file",
                  cellStyle: {
                    wordBreak: "break-word",
                  },
                  field: "fileName",
                  hideInDetailPanel: true,
                  sorting: false,
                  customSort: (fileA, fileB) =>
                    `${fileA.fileName}`.toLowerCase() <
                    `${fileB.fileName}`.toLowerCase()
                      ? 1
                      : -1,
                },
                {
                  showOnMobile: true,
                  mobileTitle: "Documenti",
                  title: "Data",
                  cellStyle: {
                    width: "20%",
                  },
                  field: "dateUpload",
                  type: "datetime",
                  sorting: true,
                  defaultSort: "desc",
                  render: (rowData) =>
                    rowData.dateUpload
                      ? dateFormatter.format(new Date(`${rowData.dateUpload}Z`))
                      : "Data non disponibile",
                  mobileRender: (rowData) => {
                    return (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span style={{ wordBreak: "break-all" }}>
                          {rowData.fileName}
                        </span>
                        <span>
                          {rowData.dateUpload
                            ? dateFormatter.format(
                                new Date(`${rowData.dateUpload}Z`)
                              )
                            : "Data non disponibile"}
                        </span>
                      </div>
                    );
                  },
                },
                {
                  title: "Caricato Da",
                  sorting: false,
                  customSort: (loaderA, loaderB) =>
                    getLoaderName(loaderA).toLowerCase() <
                    getLoaderName(loaderB).toLowerCase()
                      ? 1
                      : -1,
                  render: (rowData) => getLoaderName(rowData),
                },
              ]}
              data={documents}
              actions={[
                {
                  icon: () => <GetAppIcon style={{ margin: "0 4px" }} />,
                  tooltip: "Scarica",
                  onClick: downloadDocument,
                },
                {
                  icon: () => <Refresh style={{ color: "white" }} />,
                  tooltip: "Aggiorna",
                  isFreeAction: true,
                  onClick: refreshDocuments,
                },
              ]}
              options={{
                thirdSortClick: false,
                exportButton: false,
                pageSize: 10,
                draggable: false,
                orderDirection: order.orderDirection,
                onOrderChange: handleOrderChange,
                padding: "dense",
                actionsColumnIndex: -1,
                showTitle: false,
              }}
              localization={
                activeTab == 0
                  ? getLocalization(
                      <>
                        Non hai ancora caricato nessun documento.
                        <br />
                        Clicca su Aggiungi documento per condividere i documenti
                        con il tuo terapeuta!
                      </>
                    )
                  : getLocalization(
                      <>
                        Non hai ancora caricato nessun documento.
                        <br />
                        Clicca su Aggiungi documento per cominciare!
                      </>
                    )
              }
            />
          </div>
          <div
            style={{ display: activeTab === 2 ? "" : "none", padding: "16px" }}
          >
            <PsyTable
              isLoading={isDocumentsLoading}
              components={{
                Container: (props) => <div>{props.children}</div>,
              }}
              columns={[
                {
                  cellStyle: {
                    width:"2%"
                  },
                  title: (
                    <Checkbox
                      checked={
                        allDocument &&
                        isSelected.length == signedDocuments.length
                      }
                      onChange={(e) => {
                        const check = e.target.checked;
                        if (check) {
                          const newDocumentList = signedDocuments
                            .filter((el) => el.firmedDate !== null)
                            .map((el) => el.id);
                          setIsSelected(newDocumentList);
                        } else {
                          setIsSelected([]);
                        }
                        setAllDocument(check);
                      }}
                      style={{
                        color: "#fff",
                        display: signedDocuments.length < 1 ? "none" : "",
                      }}
                    />
                  ),
                  hideInDetailPanel: true,
                  field: "id",
                  sorting: false,
                  render: (rowData) => (
                    <Checkbox
                      checked={isSelected.includes(rowData.id)}
                      disabled={!rowData?.firmedDate}
                      color="primary"
                      value={rowData.id}
                      onChange={(e) => {
                        if (isSelected.includes(e.target.value)) {
                          const newList = isSelected.filter(
                            (el) => el !== e.target.value
                          );
                          setIsSelected(newList);
                        } else {
                          setIsSelected((prev) => [...prev, e.target.value]);
                        }
                      }}
                    />
                  ),
                },
                {
                  title: "Nome file",
                  cellStyle: {
                    wordBreak: "break-all",
                  },
                  field: "fileName",
                  hideInDetailPanel: true,
                  sorting: false,
                  customSort: (fileA, fileB) =>
                    `${fileA.fileName}`.toLowerCase() <
                    `${fileB.fileName}`.toLowerCase()
                      ? 1
                      : -1,
                },
                {
                  showOnMobile: true,
                  mobileTitle: "Documenti",
                  title: "Data",
                  cellStyle: {
                    whiteSpace: "nowrap",
                    width:"20%"
                  },
                  field: "dateUpload",
                  type: "datetime",
                  sorting: true,
                  defaultSort: "desc",
                  render: (rowData) =>
                    rowData.dateUpload
                      ? dateFormatter.format(new Date(`${rowData.dateUpload}Z`))
                      : "Data non disponibile",
                  mobileRender: (rowData) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <span style={{ wordBreak: "break-all" }}>
                          {rowData.fileName}
                        </span>
                        <span>
                          {rowData.dateUpload
                            ? dateFormatter.format(
                                new Date(`${rowData.dateUpload}Z`)
                              )
                            : "Data non disponibile"}
                        </span>
                      </div>
                    );
                  },
                },
                {
                  title: "Caricato Da",
                  // customSort: (loaderA, loaderB) =>
                  //   getLoaderName(loaderA) < getLoaderName(loaderB) ? 1 : -1,
                  sorting: false,
                  render: (rowData) => getLoaderName(rowData),
                },
                {
                  title: "Firmato il",
                  field: "firmedDate",
                  type: "datetime",
                  cellStyle: {
                    width: "15%",
                  },
                  soting: false,
                  render: (rowData) => (
                    <span
                      style={
                        rowData.firmedDate
                          ? { color: "green" }
                          : { color: "red" }
                      }
                    >
                      {rowData.firmedDate
                        ? dateFormatter.format(
                            new Date(`${rowData.firmedDate}Z`)
                          )
                        : "In attesa di firma"}
                    </span>
                  ),
                },
              ]}
              data={signedDocuments}
              actions={[
                (rowData) => ({
                  icon: () => <GetAppIcon style={{ margin: "0 4px" }} />,
                  tooltip: "Scarica",
                  hidden: !rowData.firmedDate,
                  onClick: downloadDocument,
                }),
                {
                  icon: () => <Refresh style={{ color: "white" }} />,
                  tooltip: "Aggiorna",
                  isFreeAction: true,
                  onClick: refreshDocuments,
                },
              ]}
              options={{
                thirdSortClick: false,
                exportButton: false,
                pageSize: 10,
                draggable: false,
                rowStyle: {
                  border: "none",
                },
                orderDirection: orderSigned.orderDirection,
                onOrderChange: handleOrderChangeSigned,
                padding: "dense",
                actionsColumnIndex: -1,
                showTitle: false,
              }}
              localization={getLocalization(
                <>
                  Non hai ancora firmato digitalmente nessun documento.
                  <br />
                  Quando succederà, troverai qui l'elenco!
                </>
              )}
            />
          </div>
        </Paper>
      </Container>
      <Dialog
        open={dialog}
        onClose={() => {
          setFiles(null);
          setDialog(false);
        }}
        scroll="paper"
        fullWidth
        maxWidth="sm"
      >
        {activeTab === 0 ? (
          <>
            <DialogTitle>Carica nuovo documento condiviso</DialogTitle>
            <Typography style={{ padding: "0 1.5rem", fontSize: ".8rem" }}>
              Il tuo terapeuta vedrà questi documenti.
            </Typography>{" "}
          </>
        ) : (
          <>
            <DialogTitle>Carica nuovo documento di identità</DialogTitle>{" "}
          </>
        )}
        <DialogContent>
          <Grid style={{ position: "relative" }} container spacing={2}>
            {isDocumentUploading && (
              <Box
                zIndex="999"
                display="flex"
                justifyContent="center"
                alignItems="center"
                bgcolor="rgba(255,255,255,.7)"
                position="absolute"
                width="100%"
                height="100%"
              >
                <CircularProgress />
              </Box>
            )}
            <Grid item xs={12}>
              <FileField
                accept={acceptedExtensions.join(",")}
                multiple
                onChange={(event) => {
                  setFiles(event.target.files);
                }}
              />
            </Grid>
            {files && files.length !== 0 && !isDimensionsOk && (
              <Grid item xs={12}>
                <Box color="red">I file non devono superare 8mb.</Box>
              </Grid>
            )}
            {files && files.length !== 0 && !isFileNameOk && (
              <Grid item xs={12}>
                <Box color="red">
                  Il nome del file non può superare i 100 caratteri.
                </Box>
              </Grid>
            )}

            {files && files.length !== 0 && !isExtensionsOk && (
              <Grid item xs={12}>
                <Box color="red">
                  Uno dei formati dei file caricati non è supportato.
                </Box>
              </Grid>
            )}
            <Grid item xs={12}>
              I formati dei file ammessi sono i seguenti:{" "}
              {acceptedExtensions.join(", ")}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setFiles(null);
              setDialog(false);
            }}
            color="primary"
          >
            Annulla
          </Button>
          <Button
            disabled={!(isExtensionsOk && isDimensionsOk && isFileNameOk)}
            onClick={async () => {
              if (!files) {
                return;
              }
              setGeneralError("");
              setApiError("");
              setIsDocumentUploading(true);
              let formData = new FormData();
              formData.append("idtherapist", userProfile.idTherapist);
              formData.append("idpatient", user.profile.sub);
              let i = 0;
              for (let file of files) {
                formData.append(`documentinfo[${i}].attachmentfile`, file);
                formData.append(`documentinfo[${i}].documenttype`, "");
                formData.append(`documentinfo[${i}].documentnumber`, "");
                formData.append(`documentinfo[${i}].isfront`, false);
                i++;
              }
              formData.append("IsPersonal", activeTab === 1);
              const response = await api(
                user,
                "documents",
                "POST",
                formData,
                false
              );
              if (response.ok !== false) {
                setDialog(false);
                setFiles(null);
                refreshDocuments();
              } else {
                setGeneralError(
                  "Impossibile caricare il file, riprovare in seguito"
                );
                setApiError(response.message);
                setDialog(false);
                setFiles(null);
              }
              setIsDocumentUploading(false);
            }}
            color="primary"
          >
            Carica
          </Button>
        </DialogActions>
      </Dialog>

      <AlertDialog
        open={alert.open}
        title={alert.title}
        onClose={alert.onClose}
      >
        {alert.body}
      </AlertDialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={() => {
          setSnackbar({ ...snackbar, open: false });
        }}
        style={{ width: 500 }}
        TransitionComponent={Fade}
        disableWindowBlurListener={true}
      >
        <Alert severity="success" style={{ width: "100%" }}>
          {snackbar.message || "---"}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Documents;
