import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  Link,
  Paper,
  makeStyles,
  CircularProgress,
  Avatar,
  Tooltip,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import { Refresh } from "@material-ui/icons";
import VideocamIcon from "@material-ui/icons/Videocam";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import PhoneIcon from "@material-ui/icons/Phone";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
} from "react";
import { useHistory } from "react-router-dom";
import { LastClickedContext } from "../contexts/LastClickedContext";
import {
  useUser,
  useUserProfile,
  useUserManager,
  useSetUserProfile,
} from "../contexts/UserContext";
import useBrowserInfo from "../hooks/useBrowserInfo";
import useLocalStorage from "../hooks/useLocalStorage";
import {
  sha1,
  dateFormatter,
  differenceInMinutes,
  onlyDateFormatter,
  hoursFormatter,
} from "../utils/UtilsFunction";
import useApi from "../hooks/useApi";
import { useNotificationApi } from "../contexts/NotificationContext";
import { useGlobalSnackbar } from "../contexts/GlobalSnackbarContext";
import {
  useSetVideoCallSession,
  useSetIsVideoCallModalOpened,
  useIsVideoCallModalOpened,
  useVideoCallSession,
} from "../contexts/VideoCallModalContext";
import PsyTable from "../components/PsyTable"; //Session part (temp comment)
import GroupCallIcon from "../components/icons/GroupCallIcon";

const getSessionStart = (session) => {
  if (!session.isSession) {
    const startDate = new Date(`${session.sessionStart}Z`);
    return isNaN(startDate) ? null : startDate;
  }
  //convert the sessionstart to date
  const therapistStartDate = new Date(`${session.sessionStart}Z`); //convert the patientSessionStart to date

  const patientStartDate = new Date(`${session.patientSessionStart}Z`); //check if both dates are valid date, this is made with the reduce function
  //the accumulator start as true and it loops through both dates
  //ANDing the value of !isNaN (which is true if the date is valid false otherwise)

  const isBothValidDates = [therapistStartDate, patientStartDate].reduce(
    (accumulator, current) => accumulator && !isNaN(current),
    true
  ); //if both are valid dates return the highest of the two otherwise return null because is not started yet
  //(because one of the two dates is not yet set)

  return isBothValidDates
    ? new Date(Math.max(therapistStartDate, patientStartDate))
    : null;
}; //metodh to retrieve the end of a session given a session object

const getSessionEnd = (session) => {
  if (!session.isSession) {
    const endDate = new Date(`${session.sessionEnd}Z`);
    return isNaN(endDate) ? null : endDate;
  }
  //convert the sessionend to date
  const therapistEndDate = new Date(`${session.sessionEnd}Z`); //convert the patientSessionStart to date

  const patientEndDate = new Date(`${session.patientSessionEnd}Z`); //check if both dates are valid date, this is made with the reduce function
  //the accumulator start as true and it loops through both dates
  //ANDing the value of !isNaN (which is true if the date is valid false otherwise)

  const isBothValidDates = [therapistEndDate, patientEndDate].reduce(
    (accumulator, current) => accumulator && !isNaN(current),
    true
  ); //check if both dates are invalid date, this is made with the reduce function
  //the accumulator start as true and it loops through both dates
  //ANDing the value of isNaN (which is true if the date is valid false otherwise)
  //note that this is different from !isBothValidDates because it's not possible
  //to distinguish between a single or a double date being invalid

  const isBothInvalidDates = [therapistEndDate, patientEndDate].reduce(
    (accumulator, current) => accumulator && isNaN(current),
    true
  ); //if both dates are valid return the minumun value

  if (isBothValidDates) {
    return new Date(Math.min(therapistEndDate, patientEndDate));
  } //if both are invalid the session does not have an end date yet

  if (isBothInvalidDates) {
    return null;
  } //if just one of the two dates is valid return the valid one
  //this means at leat one of the two has already closed the video call

  return !isNaN(therapistEndDate) ? therapistEndDate : patientEndDate;
}; //End session part (temp comment)

const Home = (props) => {
  const [sessions, setSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGroups, setIsLoadingGroups] = useState(false);
  const [genericError, setGenericError] = useState([]);
  const isVideoCallModalOpened = useIsVideoCallModalOpened();
  const videoCallSession = useVideoCallSession(); //Information for Terapist

  const [userDTO, setUserDTO] = useState(null);
  const [mobilePhone, setMobilePhone] = useState(null);
  const [phone, setPhone] = useState(null);
  const api = useApi();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useUser();
  const userProfile = useUserProfile();
  const setUserProfile = useSetUserProfile();

  const [groupList, setGroupList] = useState([]);

  const [firstAccess, setFirstAccess] = useLocalStorage(
    `firstAccess-${user.profile.sub}`,
    true
  );
  const history = useHistory();
  const { setLastClicked } = useContext(LastClickedContext);
  const browserInfo = useBrowserInfo();
  const classes = useStyles();
  const setVideoCallSession = useSetVideoCallSession();
  const setIsVideoCallModalOpened = useSetIsVideoCallModalOpened();
  const [globalSnackbar, setGlobalSnackbar] = useGlobalSnackbar();
  const [order, setOrder] = useState({ orderDirection: "desc" });


  const handleOrderChange = (orderDirection) => {
    setOrder({ orderDirection });
  };

  const startVideoCall = useCallback(async () => {
    const ids = await api(
      user,
      `sessions/ids/${userProfile?.idTherapist}/${user?.profile?.sub}`
    );
    if (ids.ok !== false) {
      history.push(`/session/${ids.nanoId}`);
      return;
    }
    setGlobalSnackbar({
      open: true,
      severity: "error",
      message: "Impossibile avviare la videochiamata.",
    });
  }, [user, userProfile]);

  const openVirtualRoom = useCallback(
    async (isIndividual, idGroup) => {
      let settingsResponse = await api(
        user,
        `patients/${user?.profile?.sub}/${userProfile?.idTherapist}/settings`,
        "GET"
      );

      if (settingsResponse.ok === false) {
        setGlobalSnackbar({
          open: true,
          severity: "error",
          message: "Impossibile avviare la videoseduta",
        });
        return;
      } ///Update context

      setUserProfile({
        ...userProfile,
        isArchived: settingsResponse.isArchived,
      });

      if (!settingsResponse.isArchived) {
        if (isIndividual) {
          startVideoCall();
        } else {
          history.push(`/groupsession/${idGroup}`);
        }
      } else {
        setGlobalSnackbar({
          open: true,
          severity: "error",
          message:
            "Impossibile avviare la videoseduta. Contatta il tuo terapeuta.",
        });
      }
    },
    [startVideoCall, userProfile, user, setUserProfile]
  );

  const getGroupListAsync = useCallback(async () => {
    setIsLoadingGroups(true);
    let response = await api(
      user,
      `grouptherapies/${user.profile.sub}/getlist`,
      "GET"
    );
    if (response.ok !== false) {
      setGroupList(response);
    }
    setIsLoadingGroups(false);
  }, [user]);

  const getSessionsAsync = useCallback(async () => {
    setIsLoading(true);
    let response = await api(
      user,
      `sessionsandgroups/patient/${user.profile.sub}/${userProfile.idTherapist}`,
      "GET"
    );

    if (response.ok === false) {
      setGenericError("Impossibile caricare le sessioni");
    } else {
      setSessions(response);
    }

    setIsLoading(false);
  }, [user, userProfile]);

  const isFirstTime = useRef(true);
  useEffect(() => {
    if (isFirstTime.current) {
      isFirstTime.current = false;
      return;
    }

    if (!isVideoCallModalOpened) {
      getSessionsAsync();
    }
  }, [isVideoCallModalOpened]);

  useEffect(() => {
    getSessionsAsync();
    getGroupListAsync();
  }, []); //For fetch fully therapist profile

  useEffect(() => {
    const getUserAsync = async () => {
      const response = await api(
        user,
        `therapists/${userProfile.idTherapist}/fullprofile`,
        "GET"
      );

      if (response.isSuccessStatusCode === false) {
      } else {
        setUserDTO(response);
        setMobilePhone(
          response?.company?.listPhones?.find(
            (elem) => elem.type == "cellphone"
          )
        );
        setPhone(
          response?.company?.listPhones?.find((elem) => elem.type == "phone")
        );
      }
    };

    if (user) {
      getUserAsync();
    }
  }, [user, userProfile]);
  useEffect(() => {
    if (props.layoutOptions) {
      props.layoutOptions({
        title: "",
        disabled: true,
      });
    }
  }, []);
  const terapistNameSurnameInitials = useMemo(() => {
    if (!userDTO || !userDTO?.therapist) return null; //You can't get name and surname

    const initialName = userDTO.therapist.firstName
      ? userDTO.therapist.firstName.charAt(0)
      : "";
    const initialSurname = userDTO.therapist.lastName
      ? userDTO?.therapist?.lastName.charAt(0)
      : "";
    return `${initialName}${initialSurname}`;
  }, [userDTO?.therapist?.firstName, userDTO?.therapist?.lastName]);
  return (
    <div className={classes.container}>
      <Grid
        style={{
          minHeight: "100%",
        }}
        container
        spacing={2}
      >
        {(browserInfo.name === "Safari" || browserInfo.isException) && (
          <Grid
            style={{
              padding: "1rem",
            }}
            items
            xs={12}
          >
            <Alert
              style={{
                color: "black",
              }}
              variant="filled"
              severity="warning"
            >
              <AlertTitle>
                Per effettuare le videochiamate, consigliamo di utilizzare un
                browser diverso.{" "}
                <Link target="_blank" href="https://www.google.com/chrome/">
                  Scarica o utilizza Google Chrome.
                </Link>
              </AlertTitle>
            </Alert>
          </Grid>
        )}
        <Box className={classes.dashboardContainer}>
          {/* WELCOME TITLE */}
          <Box className={classes.welcomeBox}>
            <Typography
              className={classes.typographyText}
              variant="h2"
            >{`Benvenuto`}</Typography>
            <Typography
              className={classes.typographyText}
              variant="h2"
            >{`${userProfile.firstName} ${userProfile.lastName}`}</Typography>
          </Box>
          <Box className={classes.dashBoardPaperBox}>
            {/* PAPER START SESSION */}
            <Paper className={classes.paper}>
              <Box
                display="flex"
                flexDirection="column"
                padding="1rem"
                width="100%"
                height="100%"
              >
                <Box
                  height="100%"
                  display="flex"
                  justifyContent="space-evenly"
                  alignItems="center"
                  flexDirection="row"
                  flexWrap="wrap"
                >
                  <Box
                    style={{
                      gap: "10px",
                    }}
                    display="flex"
                    flexDirection="column"
                    width="100%"
                    height="100%"
                  >
                    <Box className={classes.paperTitle}>Seduta individuale</Box>
                    <Box width="100%" textAlign="start">
                      Inizia da qui la seduta con il tuo terapeuta
                    </Box>
                    <Box className={classes.paperFooter}>
                      <Box
                        className={classes.therapistText}
                        onClick={() => {
                          history.push("/therapist");
                        }}
                      >
                        {userDTO?.therapist?.firstName &&
                        userDTO?.therapist?.lastName
                          ? `${userDTO?.therapist?.firstName} ${userDTO?.therapist?.lastName}`
                          : `Il tuo terapeuta`}
                      </Box>
                      <Button
                        className={classes.btn}
                        disabled={!userProfile || userProfile?.isArchived}
                        variant="contained"
                        color="primary"
                        startIcon={<VideocamIcon />}
                        onClick={() => openVirtualRoom(true)}
                      >
                        Seduta individuale
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Paper>
            {/* PAPER TERAPIST */}
            <Paper className={classes.paper}>
              {
                //Circular progress for waiting fetch data for terapist information
                !userDTO || isLoadingGroups ? (
                  <Box
                    width="100%"
                    padding="2rem"
                    height="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Box>
                ) : groupList.length > 0 ? (
                  <Paper className={classes.paper}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      padding="1rem"
                      width="100%"
                      height="100%"
                    >
                      <Box
                        height="100%"
                        display="flex"
                        justifyContent="space-evenly"
                        alignItems="center"
                        flexDirection="row"
                        flexWrap="wrap"
                      >
                        <Box
                          style={{
                            gap: "10px",
                          }}
                          display="flex"
                          flexDirection="column"
                          width="100%"
                          height="100%"
                        >
                          <Box className={classes.paperTitle}>
                            Seduta di gruppo
                          </Box>
                          <Box width="100%" textAlign="start">
                            Partecipa alla seduta di gruppo da qui
                          </Box>
                          {groupList?.map((group) => (
                            <Box
                              key={group.idGroup}
                              className={classes.paperFooter}
                              style={{}}
                            >
                              <span
                                style={
                                  matches
                                    ? {
                                        wordBreak: "break-word",
                                        padding: ".625rem",
                                      }
                                    : { width: "80%" }
                                }
                              >
                                {group.name ? group.name : `Gruppo`}
                              </span>
                              <br />
                              <Button
                                className={classes.btn}
                                disabled={
                                  !userProfile || userProfile?.isArchived
                                }
                                variant="contained"
                                color="primary"
                                startIcon={<GroupCallIcon />}
                                onClick={() => {
                                  openVirtualRoom(false, group.idGroup);
                                }}
                              >
                                Seduta di gruppo
                              </Button>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    padding="1rem"
                    width="100%"
                    height="100%"
                  >
                    <Box
                      height="100%"
                      display="flex"
                      justifyContent="space-evenly"
                      alignItems="center"
                      flexDirection="row"
                      flexWrap="wrap"
                    >
                      <Box
                        style={{
                          gap: "10px",
                          minHeight: !matchesDownSm
                            ? ""
                            : "calc(18.75rem - 1rem)",
                        }}
                        display="flex"
                        justifyContent="space-between"
                        flexDirection="column"
                        width="100%"
                        height="100%"
                      >
                        <Box className={classes.headerTerapistPaper}>
                          <Box className={classes.terapistAvatarBox}>
                            <Avatar className={classes.avatarIcon}>
                              {terapistNameSurnameInitials || <PersonIcon />}
                            </Avatar>
                          </Box>
                          <Box className={classes.terapistNameProfileBox}>
                            <Typography
                              style={{
                                fontSize: "1.125rem",
                                fontWeight: "600",
                              }}
                              variant="h2"
                            >{`Dott.`}</Typography>
                            <Typography
                              style={{
                                fontSize: "1.125rem",
                                fontWeight: "600",
                              }}
                              variant="h2"
                            >{`${userDTO?.therapist?.firstName} ${userDTO?.therapist?.lastName}`}</Typography>
                            <Typography
                              onClick={() => {
                                history.push("/therapist");
                              }}
                              className={classes.seeProfileText}
                              variant="h2"
                            >
                              {`Vedi profilo`}
                            </Typography>
                          </Box>
                        </Box>
                        <Box className={classes.terapistInfoBox}>
                          <Box className={classes.terapistInfoIcon}>
                            <AlternateEmailIcon className={classes.icon} />
                          </Box>
                          <Box
                            className={classes.terapistInfoText}
                          >{`${userDTO?.therapist?.email}`}</Box>
                        </Box>
                        <Box className={classes.terapistInfoBox}>
                          <Box className={classes.terapistInfoIcon}>
                            <PhoneAndroidIcon className={classes.icon} />
                          </Box>
                          <Box className={classes.terapistInfoText}>
                            {mobilePhone?.phoneNumber
                              ? `${
                                  mobilePhone?.phonePrefix
                                    ? `+${mobilePhone?.phonePrefix}`
                                    : ""
                                }${mobilePhone?.phoneNumber}`
                              : "Cellulare non disponibile"}
                          </Box>
                        </Box>
                        <Box className={classes.terapistInfoBox}>
                          <Box className={classes.terapistInfoIcon}>
                            <PhoneIcon className={classes.icon} />
                          </Box>
                          <Box className={classes.terapistInfoText}>
                            {phone?.phoneNumber
                              ? `${
                                  phone?.phonePrefix
                                    ? `+${phone?.phonePrefix}`
                                    : ""
                                }${phone?.phoneNumber}`
                              : "Telefono non disponibile"}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )
              }
            </Paper>
          </Box>
          {matches && (
            <Box className={classes.tableContainer}>
              <PsyTable
                title="Sedute effettuate:"
                isLoading={isLoading}
                columns={[
                  {
                    title: "",
                    sorting: false,
                    cellStyle: {
                      padding: "0 5px",
                      width: "2%",
                    },
                  },
                  {
                    title: "Nome",
                    field: "name",
                    sorting: false,
                    customSort: (nameA, nameB) =>
                      `${nameA.name}`.toLowerCase() < `${nameB.name}`.toLowerCase() ? 1 : -1,
                  },
                  {
                    title: "Data",
                    defaultSort: "desc",
                    sorting: true,
                    render: (rowData) => {
                      const sessionStart = getSessionStart(rowData);
                      return sessionStart
                        ? onlyDateFormatter.format(
                            new Date(rowData.sessionStart)
                          )
                        : onlyDateFormatter.format(new Date(rowData.createdAt));
                    },
                    customSort: (a, b) => {
                      const dateA = a.sessionStart
                        ? new Date(a.sessionStart)
                        : new Date(a.createdAt);
                      const dateB = b.sessionStart
                        ? new Date(b.sessionStart)
                        : new Date(b.createdAt);
                      return dateA - dateB;
                    },
                  },
                  {
                    title: "Ora di inizio",
                    field: "sessionStart",
                    sorting: false,
                    render: (rowData) => {
                      const sessionStart = getSessionStart(rowData);
                      const sessionEnd = getSessionEnd(rowData);
                      return sessionStart
                        ? hoursFormatter.format(sessionStart)
                        : sessionEnd
                        ? "Seduta annullata"
                        : "Non ancora iniziata";
                    },
                    // customFilterAndSearch: (term, rowData) => {
                    //   const sessionStart = getSessionStart(rowData);
                    //   const sessionEnd = getSessionEnd(rowData);
                    //   return (
                    //     sessionStart
                    //       ? hoursFormatter.format(sessionStart)
                    //       : sessionEnd
                    //       ? "Seduta annullata"
                    //       : "Non ancora iniziata"
                    //   )
                    //     .toLowerCase()
                    //     .includes(term.toLowerCase());
                    // },
                    // sorting: false,
                  },
                  // {
                  //   title: "Ora di fine",
                  //   render: (rowData) => {
                  //     const sessionStart = getSessionStart(rowData);
                  //     const sessionEnd = getSessionEnd(rowData);
                  //     const ret = sessionEnd
                  //       ? hoursFormatter.format(sessionEnd)
                  //       : sessionStart
                  //       ? "Sessione non terminata"
                  //       : "Non ancora iniziata";
                  //     return sessionEnd
                  //       ? hoursFormatter.format(sessionEnd)
                  //       : sessionStart
                  //       ? "Sessione non terminata"
                  //       : "Non ancora iniziata";
                  //   },
                  //   customFilterAndSearch: (term, rowData) => {
                  //     const sessionStart = getSessionStart(rowData);
                  //     const sessionEnd = getSessionEnd(rowData);
                  //     return (
                  //       sessionEnd
                  //         ? hoursFormatter.format(sessionEnd)
                  //         : sessionStart
                  //         ? "Sessione non terminata"
                  //         : "Non ancora iniziata"
                  //     )
                  //       .toLowerCase()
                  //       .includes(term.toLowerCase());
                  //   },
                  //   sorting: false,
                  // },
                  {
                    title: "Durata",
                    sorting: false,
                    customSort: (rowA, rowB) =>
                      differenceInMinutes(
                        getSessionStart(rowA),
                        getSessionEnd(rowA)
                      ) -
                      differenceInMinutes(
                        getSessionStart(rowB),
                        getSessionEnd(rowB)
                      ),
                    render: (rowData) => {
                      const dim = differenceInMinutes(
                        getSessionStart(rowData),
                        getSessionEnd(rowData)
                      );
                      return `${dim} ${dim == 1 ? "minuto" : "minuti"}`;
                    },
                  },
                ]}
                data={sessions}
                options={{
                  thirdSortClick: false,
                  exportButton: false,
                  pageSize: 10,
                  draggable: false,
                  orderDirection: order.orderDirection,
                  onOrderChange: handleOrderChange,
                  padding: "dense",
                  actionsColumnIndex: -1,
                  rowStyle: (row) => {
                    //if the id of the row is the same as the videoCall
                    //saved globally it will hiligh the row
                    if (row.id === videoCallSession?.id) {
                      return {
                        backgroundColor: "#00858b4d",
                      };
                    }
                  },
                }}
                actions={[
                  {
                    icon: () => (
                      <Refresh
                        style={{
                          color: "white",
                        }}
                      />
                    ),
                    tooltip: "Aggiorna",
                    isFreeAction: true,
                    onClick: getSessionsAsync,
                  },
                ]}
                localization={{
                  header: {
                    actions: "",
                  },
                  body: {
                    emptyDataSourceMessage: (
                      <>
                        Non hai ancora effettuato nessuna seduta.
                        <br />
                        Quando avrai cominciato, troverai qui la cronologia
                        delle tue sedute!
                      </>
                    ),
                  },
                  toolbar: {
                    searchPlaceholder: "Cerca sedute...",
                  },
                  pagination: {
                    labelDisplayedRows: "{from}-{to} di {count}",
                    firstTooltip: "Prima pagina",
                    lastTooltip: "Ultima pagina",
                    nextTooltip: "Successiva",
                    previousTooltip: "Precedente",
                  },
                }}
              />
            </Box>
          )}
        </Box>
      </Grid>
    </div>
  );
};
/*
BreakPoint
xs, extra-small: 0px
sm, small: 600px
md, medium: 960px
lg, large: 1280px
xl, extra-large: 1920px
*/
//const styles for reduce in-line argument

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.main,
  },
  avatarIcon: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.25)",
  },
  dashboardContainer: {
    padding: "0 .625rem",
    [theme.breakpoints.up("sm")]: {
      padding: "2rem 2.5rem",
      width: "100%",
      maxWidth: "85rem",
      margin: "0 auto",
    },
  },
  dashBoardPaperBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "1.5rem",
    marginBottom: "1.5rem",
    "& > * + *": {
      marginLeft: "2.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      "& > * + *": {
        marginLeft: "unset",
        marginTop: "2.5rem",
      },
    },
  },
  btn: {
    marginLeft: ".625rem",
    minWidth: "fit-content",
    textShadow: "1px 1px 1px rgba(0, 0, 0, 0.25)",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      marginTop: ".625rem",
      width: "80%",
    },
  },
  therapistText: {
    marginLeft: ".625rem",
    fontWeight: "400",
    minWidth: "fit-content",
    textDecoration: "underline",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      marginTop: ".625rem",
      width: "80%",
    },
  },
  seeProfileText: {
    fontSize: "0.875rem",
    fontWeight: "400",
    color: "#00858B",
    textDecoration: "underline",
    cursor: "pointer",
  },
  typographyText: {
    maxWidth: "100%",
    fontSize: "1.5em",
    marginRight: "5px",
    fontWeight: "400",
  },
  headerTerapistPaper: {
    display: "flex",
    flexDirection: "row",
  },
  terapistAvatarBox: {
    flex: 1,
    justifyContent: "end",
    alignItems: "center",
    display: "contents",
  },
  terapistNameProfileBox: {
    flex: 3,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    paddingLeft: "1.5rem",
  },
  terapistInfoBox: {
    display: "flex",
    flexDirection: "row",
  },
  terapistInfoIcon: {
    display: "flex",
    justifyContent: "center",
  },
  terapistInfoText: {
    paddingLeft: "1rem",
    fontSize: "1rem",
    fontWeight: "400",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  paperTitle: {
    width: "100%",
    fontWeight: "600",
    fontSize: "1rem",
    textAlign: "start",
  },
  paperFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "0 -1rem",
    padding: ".5rem",
    backgroundColor: "#f3f3f3",
    flexWrap: "wrap",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  paper: {
    maxWidth: "31.25rem",
    height: "auto",
    flex: 1,
    boxShadow: "2px 2px 4px rgb(0 0 0 / 25%)",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "none",
      width: "100%",
    },
  },
  welcomeBox: {
    width: "100%",
  },
  historyBox: {
    border: "1px solid",
    width: "100%",
    height: "100px",
  },
  topDashBoard: {
    //border: "1px solid",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  topDashBoardItem: {
    width: "300px",
    borderRadius: "8px",
    padding: "8px",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    margin: "0px 10px 0px 10px",
    boxShadow: "3px 3px 3px grey",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "10px 0px 10px 0px",
    },
  },
  topDashBoardItemFooter: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: "10px",
  },
  dashImageBox: {
    padding: "20px 0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  dashImage: {
    width: "50%",
  },
  doctorHeader: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
  },
  ul_noDot: {
    listStyleType: "none",
    margin: "0px",
  },
  doctorItemInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "10px",
  },
  doctorIconInfo: {
    marginRight: "10px",
  },
  linkText: {
    color: "primary",
    textDecoration: "underline",
  },

  container: { paddingTop: "3rem" },
}));
export default Home;
/* Temp old Dashboard -> firstAccess = true */

/*
<Box style={{ gap: "2rem", backgroundColor: "white", minHeight: "100%", padding: "1rem" }} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    {
                        matches &&
                        <Typography style={{ maxWidth: "100%" }} variant="h2">
                            Benvenuto in
                        </Typography>
                    }
                    <Box height={matches ? "15rem" : "10rem"}>
                        <img style={{ maxWidth: "100%", height: "100%", objectFit: "contain" }} src="./logo_transparent_psycare.png" />
                    </Box>
                    <Box style={{ gap: "1rem" }} padding="1rem" maxWidth="45rem" textAlign="justify" display="flex" flexDirection="column">
                        <Typography style={{ fontSize: matches ? "1.5rem" : "1.25rem" }} variant="body1">
                            PsyCare è il tuo compagno ideale per rimanere in contatto con il tuo dottore. PsyCare non necessita di installazione: effettua le sedute online con il tuo dottore ovunque ti trovi, da PC, tablet o smartphone.
                        </Typography>
                        <Typography style={{ fontSize: matches ? "1.5rem" : "1.25rem" }} variant="body1">
                            Le videochiamate sono sicure, criptate e a norma di legge. Nessuno può accedere alle informazioni scambiate con il tuo dottore, se non tu e lui.
                        </Typography>
                    </Box>
                    <Button style={{ fontSize: "1.3em" }} size="large" variant="contained" color="primary" onClick={() => setFirstAccess(false)}>Inizia ora</Button>
                </Box>
*/
