import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import it from "date-fns/locale/it";
import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';

const DateField = (props, ref) => {
    const [date, setDate] = useState(props?.defaultValue || null);
    const handleChange = useCallback((date) => {
        setDate(date)
        if(props.onChange){
            props.onChange(date);
        }
    }, [props.onChange]);
    useImperativeHandle(ref, ()=>({
        reset: ()=>{
            setDate(props.defaultValue);
        }
    }),[props.defaultValue])
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={it}>
            <KeyboardDatePicker
                {...props}
                value={date}
                onChange={handleChange}
                format="dd/MM/yyyy"
                cancelLabel="Annulla"
            />
        </MuiPickersUtilsProvider>
    )
};
export default forwardRef(DateField);