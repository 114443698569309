
import { SvgIcon } from '@material-ui/core';
import React from 'react';
const AbusoFisicoIcon = props => {
        return (
                <SvgIcon {...props}>
                        <path d="M12.5243 16.533L11.1362 8.04316" stroke="currentColor" />
                        <path d="M9.82617 16.4632L11.3099 8.04317" stroke="currentColor" />
                        <path d="M9.91586 16.514L8.52769 8.02411" stroke="currentColor" />
                        <path d="M7.21747 16.4442L8.70115 8.02411" stroke="currentColor" />
                        <path d="M7.3071 16.514L5.91893 8.02411" stroke="currentColor" />
                        <path d="M4.6087 16.4442L6.09238 8.02411" stroke="currentColor" />
                        <path d="M4.6984 16.514L3.31022 8.02411" stroke="currentColor" />
                        <path d="M2 16.4442L3.48368 8.02411" stroke="currentColor" />
                        <path d="M14.9539 15.88L13.5657 8.0432" stroke="currentColor" />
                        <path d="M12.3434 16.4632L13.7393 8.04317" stroke="currentColor" />
                        <path d="M17.6521 16.4899L16.1686 8.04314" stroke="currentColor" />
                        <path d="M17.5789 15.7965L18.5952 11.9586" stroke="currentColor" />
                        <path d="M14.8606 16.4632L16.342 8.04311" stroke="currentColor" />
                        <path d="M22.3535 12.272C22.5488 12.0767 22.5488 11.7601 22.3535 11.5649L19.1716 8.38288C18.9763 8.18762 18.6597 8.18762 18.4645 8.38288C18.2692 8.57814 18.2692 8.89472 18.4645 9.08998L21.2929 11.9184L18.4645 14.7468C18.2692 14.9421 18.2692 15.2587 18.4645 15.4539C18.6597 15.6492 18.9763 15.6492 19.1716 15.4539L22.3535 12.272ZM18.5217 12.4184H22V11.4184H18.5217V12.4184Z" />
                </SvgIcon>
        )
};
export default AbusoFisicoIcon;
