import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ErrorPopup from '../components/ErrorPopup';
import Redirecting from '../components/Redirecting';
import { useSetUser, useSetUserProfile, useUserManager } from '../contexts/UserContext';
import useApi from '../hooks/useApi';

const SigninOidc = props => {
    const history = useHistory();
    const setUser = useSetUser();
    const userManager = useUserManager();
    const setUserProfile = useSetUserProfile();
    const [error, setError] = useState(false);
    const api = useApi();
    useEffect(() => {
        async function signinAsync() {
            let userSignin;
            try {
                userSignin = await userManager.signinRedirectCallback();
            } catch (e) {
                history.push("/accedi");
                return;
            }
            setUser({ ...userSignin });
            let res = await api(userSignin, `patients/${userSignin.profile.sub}`, "GET");
            if (res?.hasOwnProperty("isCompleted")) {
                setUserProfile({
                    isCompleted: res?.isCompleted,
                    idTherapist: res?.idTherapist,
                    therapistName: res?.therapistFirstName,
                    therapistLastName: res?.therapistLastName,
                    firstName: res?.name,
                    lastName: res?.lastName
                });
                history.push(userSignin.state?.redirectTo ? userSignin.state.redirectTo : "/");
            } else {
                setError(true);
                setTimeout(() => {
                    userManager.signoutRedirect({ 'id_token_hint': userSignin.id_token });
                }, 5000);
            }
        }
        signinAsync();
    }, []);

    return (
        error ?
            <ErrorPopup message="Siamo spiacenti, ma sembra che tu non abbia l'autorizzazione per accedere a questa pagina." redirecting={true} /> :
            <Redirecting />
    );
};

export default SigninOidc;