
import { SvgIcon } from '@material-ui/core';
import React from 'react';
const SelectionIcon = props => {
    return (
        <SvgIcon {...props}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.53938 14.6409L2.12214 15.2642C2.34461 15.4131 2.61216 15.5 2.9 15.5H4.65V14.75V14H3V12.525H2.25H1.5V14.1C1.5 14.3878 1.58686 14.6554 1.73581 14.8778L2.35903 14.4606L2.89769 14.1L2.9 14.0984L2.90467 14.0953L2.90155 14.1L2.9 14.1023L2.53938 14.6409ZM15.15 14.75V15.5H16.5431L17.82 20.2654L19.9884 18.097L22.0457 20.1543C22.2966 20.4052 22.7034 20.4052 22.9543 20.1543C23.2052 19.9034 23.2052 19.4966 22.9543 19.2457L20.897 17.1884L23.0654 15.02L18.3 13.7431V12.525H17.55H16.8V13.3412L15.9 13.1L16.1411 14H15.15V14.75ZM17.55 7.27497H18.3V5.69997C18.3 5.41214 18.2131 5.14459 18.0642 4.92211L17.441 5.33935L16.9023 5.69997L16.9 5.70152L16.8953 5.70465L16.8985 5.69997L16.9 5.69766L17.2606 5.15901L17.6779 4.53578C17.4554 4.38684 17.1878 4.29997 16.9 4.29997H15.15V5.04997V5.79997H16.8V7.27497H17.55ZM2.9 4.29997H4.65V5.04997V5.79997H3V7.27497H2.25H1.5V5.69997C1.5 5.41213 1.58686 5.14459 1.73581 4.92211L2.35903 5.33935L2.89769 5.69997L2.9 5.70152L2.90467 5.70465L2.90154 5.69997L2.9 5.69767L2.53938 5.15901L2.12214 4.53578C2.34461 4.38684 2.61216 4.29997 2.9 4.29997ZM2.25 11.475H1.5V8.32497H2.25H3V11.475H2.25ZM5.81667 5.04997V4.29997H9.31667V5.04997V5.79997H5.81667V5.04997ZM10.4833 5.04997V4.29997H13.9833V5.04997V5.79997H10.4833V5.04997ZM17.55 8.32497H18.3V11.475H17.55H16.8V8.32497H17.55ZM13.9833 14.75V15.5H10.4833V14.75V14H13.9833V14.75ZM9.31667 14.75V15.5H5.81667V14.75V14H9.31667V14.75Z" fill="currentColor" />
        </SvgIcon>
    );
};
export default SelectionIcon;
