import React, { useState, createContext, useContext } from 'react';

const LastClickedContext = createContext(null);
const HistoryContext = createContext(null);

const useLocalHistory = () => {
    return useContext(HistoryContext);
}

const LastClickedProvider = ({ children }) => {
    const [lastClicked, setLastClicked] = useState("home");
    const [localHistory, setLocalHistory] = useState([]);
    return (
        <HistoryContext.Provider value={[localHistory, setLocalHistory]}>
            <LastClickedContext.Provider value={{ lastClicked, setLastClicked }}>
                {children}
            </LastClickedContext.Provider>
        </HistoryContext.Provider>
    )
}

export default LastClickedProvider;
export { LastClickedContext, useLocalHistory };