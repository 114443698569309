import React, { useState } from 'react'
import { useMediaQuery, makeStyles } from '@material-ui/core';
//Docs information for use
/**
 * Represents a session summary popup.
 * @param {Object} props
 * @param {Object} props.Component - Component page to visualize
 * @param {Object} props.ComponentProps - Props applied to props.Component
 */
const PageLayout = props => {
    const classes = useStyles();
    const [options, setOptions] = useState({});
    return (
        <>
            {
                options.disabled ?
                    <props.Component {...props.ComponentProps} layoutOptions={setOptions} />
                    :
                    (
                        <div className={classes.layoutContainer}>
                            <div className={classes.pageTitleAndImageContainer}>
                            {
                                options.Icon &&
                                <div className={classes.imageContainer}>
                                        <options.Icon/>
                                </div>
                            }
                                <div className={classes.titleContainer}>
                                    <h2 className={classes.titleStyle}>{options.title}</h2>
                                </div>
                            </div>
                            <div className={classes.whitePageContainer}>
                                <props.Component {...props.ComponentProps} layoutOptions={setOptions} />
                            </div>
                        </div>
                    )
            }
        </>
    )
}
const useStyles = makeStyles(theme => ({
    imageContainer: {
        display: 'flex',
        alignItems: 'center',
        color: `${theme.palette.primary.main}`,
        paddingRight: '1.25rem',
    },
    pageTitleAndImageContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '1.563rem',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    whitePageContainer: {
        backgroundColor: 'white',
        borderRadius: '1.25rem',
        padding: '1.25rem 1rem',
        [theme.breakpoints.up('sm')]:{
            padding: '1.25rem 2.5rem',
        }
    },
    layoutContainer: {
        marginTop: '2.5rem',
        marginLeft: '0rem',
        marginRight: '0rem',
        [theme.breakpoints.up('sm')]: {
            marginTop: '2.5rem',
            marginLeft: '2.5rem',
            marginRight: '2.5rem',
        }
    },
    titleStyle: {
        fontSize: '1.5rem',
        fontWeight: '600',
        margin: '0rem',
    },
}));
export default PageLayout